import { TextField, Typography, Stack, CircularProgress } from '@mui/material'
import { theme5 } from 'styles/theme5'
import styles from 'styles/app.ts'

const UserEmailSearch = ({ searchEmail, setSearchEmail, isLoading }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
      px={3}
      pb={3}
      sx={{ background: theme5.palette.grey[100] }}
    >
      <Typography variant="h4">User</Typography>
      <TextField
        variant="filled"
        placeholder="email"
        helperText="Contains"
        color="warning"
        onChange={(e) => setSearchEmail(e.target.value)}
        value={searchEmail}
        sx={{
          '& .MuiInputBase-input.MuiFilledInput-input': {
            pl: '1px',
            fontSize: '1.8rem',
            background: theme5.palette.grey[100],
          },
        }}
        FormHelperTextProps={{
          sx: { fontSize: '1rem', mx: '1px' },
        }}
      />
      {isLoading && <CircularProgress color="primary" sx={styles.center} />}
    </Stack>
  )
}

export default UserEmailSearch
