import { LocalId } from 'types/global'
import { head, take, takeLast } from 'ramda'

type LocalIdValidator = () => boolean

export const validateDanishLocalId: LocalIdValidator = ({ id }) => {
  if (id.toString().length !== 8) {
    return false
  }

  // The weights used for CVR modulus 11 validation
  const weights = [2, 7, 6, 5, 4, 3, 2, 1]

  const weightedSum = id
    .toString()
    .split('')
    .map((digit, index) => Number(digit) * weights[index])
    .reduce((a, b) => a + b)

  return weightedSum % 11 === 0
}

/**
 * References:
 * [1] https://www.wikiwand.com/sv/Organisationsnummer
 * [2] https://www.wikiwand.com/en/Luhn_algorithm
 */
export const validateSwedishLocalId: LocalIdValidator = ({ id }) => {
  const strId = id.toString()

  if (strId.length !== 10 && strId.length !== 12 && strId.length !== 14) {
    return false
  }

  // If it has a length of 12, then it must start with one of those four combinations (reference 1)
  if (
    strId.length === 12 &&
    !new Set(['16', '18', '19', '20']).has(take(2, strId))
  ) {
    return false
  }

  return true
}
export const validateFinishLocalId: LocalIdValidator = ({ id }) => {
  return true // TODO: implement
}
export const validateNorwegianLocalId: LocalIdValidator = ({ id }) => {
  if (id.toString().length !== 9) {
    return false
  }

  // The weights used for org.nr. modulus 11 validation
  const weights = [3, 2, 7, 6, 5, 4, 3, 2, 1]

  const weightedSum = id
    .toString()
    .split('')
    .map((digit, index) => Number(digit) * weights[index])
    .reduce((a, b) => a + b)

  return weightedSum % 11 === 0
}

/**
 * Validate a Local Id from any of the countries that we support.
 */
export const validateLocalId: LocalIdValidator = (localId) => {
  const country = localId.country.toLowerCase()

  switch (country) {
    case 'dk':
      return validateDanishLocalId(localId)
    case 'se':
      return validateSwedishLocalId(localId)
    case 'no':
      return validateNorwegianLocalId(localId)
    case 'fi':
      return validateFinishLocalId(localId)
    default:
      return false
  }
}

/**
 * Examples: DK37677892, 37677892DK
 */
const _extractLocalIdWithCountry = (
  maybeId: string,
  countries: string[],
): ?LocalId => {
  const countryMatch = countries
    .map((country) => {
      if (
        maybeId.toLowerCase().startsWith(country) ||
        maybeId.toLowerCase().endsWith(country)
      ) {
        return { id: maybeId.toLowerCase().replace(country, ''), country }
      }

      return null
    })
    .filter((x) => x != null)

  if (countryMatch.length === 1) {
    return head(countryMatch)
  }

  return null
}

/**
 * Will try and figure out which Local ID it represents.
 */
export const inferLocalId = (maybeId: string): ?LocalId => {
  const countries = ['dk', 'se', 'no']

  const maybeLocalId = _extractLocalIdWithCountry(maybeId, countries)

  if (maybeLocalId != null && validateLocalId(maybeLocalId)) {
    return maybeLocalId
  }

  const validLocalIds = countries
    .map((country) => ({ id: maybeId, country }))
    .filter(validateLocalId)

  if (validLocalIds.length === 1) {
    return head(validLocalIds)
  }

  return null
}

/**
 * Copy the given string to the user's clipboard.
 *
 * This uses an intermediate container,
 * a hidden textarea, in order to copy.
 *
 * @param {string} str The string to copy.
 */
export const copyToClipboard = (str) => {
  const el = document.createElement('textarea')

  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  document.body.appendChild(el)

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false

  el.select()

  document.execCommand('copy')
  document.body.removeChild(el)

  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}
