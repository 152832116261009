import callCmAPI from 'services/helpers/callCmAPI'

/******************
 * Fetch from API *
 ******************/

export const issueUserApiToken = (apiUserId: string) =>
  callCmAPI(`/user/api/token/${apiUserId}`, {
    method: 'get',
  })
    .then((res) => res.data)
    .catch((err) => err)
