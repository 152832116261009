import React, { useState } from 'react'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  select: {
    fontSize: '1em',
  },
})

const SelectSimpleRole = ({ onChange }) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const handleChange = (e) => {
    const newVal = e.target.value
    setValue(newVal)
    onChange(newVal)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="role">Role</InputLabel>
      <Select
        className={classes.select}
        labelId="role"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={'admin'}>Admin</MenuItem>
        <MenuItem value={'user'}>User</MenuItem>
      </Select>
    </FormControl>
  )
}

export default SelectSimpleRole
