import callCmAPI from 'services/helpers/callCmAPI'

/******************
 * Fetch from API *
 ******************/

export const getResendEmail = (userEmail) =>
  callCmAPI(`/mail/activation/${userEmail}`, {
    method: 'get',
  })
    .then((res) => res.data)
    .catch((err) => console.error(err))
