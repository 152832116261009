import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons'
import { Button } from 'components'
import colors from 'styles/colors'
import { useUpdateCustomerSingle } from 'services/queries/useUpdateCustomerSingle'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

function ExtendTrial({ customerData }) {
  const expiresAt = customerData?.expires_at
  const { mutate: updateCustomerSingle, isLoading } = useUpdateCustomerSingle()
  const handleExtendTrial = (newDate) => {
    // Date After 7 days

    updateCustomerSingle({
      companyId: customerData.id,
      data: {
        expires_at: newDate ? moment(newDate).toDate() : newDate,
      },
    })
  }
  return (
    <>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          disableToolbar
          format="DD.MM.YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Select expiration date"
          value={expiresAt ? moment(expiresAt).format('YYYY-MM-DD') : null}
          onChange={handleExtendTrial}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <Button
        disabled={isLoading}
        onClick={() => handleExtendTrial(null)}
        icon={<FontAwesomeIcon icon={faCalendarPlus} />}
        style={
          !isLoading
            ? {
                backgroundColor: colors?.risika,
                color: 'white',
                alignSelf: 'center',
              }
            : null
        }
        variant="contained"
      >
        Never Expires
      </Button>
    </>
  )
}
export default ExtendTrial
