import SignIn from './Pages/SignIn'
import Home from './Pages/Home'
import { useUserAuth } from './contexts/googleAuth'

function App() {
  const { isLoading, user } = useUserAuth()

  return <> {!isLoading && user ? <Home /> : <SignIn />} </>
}

export default App
