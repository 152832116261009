import React, { Dispatch, SetStateAction } from 'react'
import { Typography, Stack, Select, MenuItem } from '@mui/material'
import { theme } from 'styles/RootTheme'

type Props = {
  title: string
  options: string[]
  value: string
  changeFn: Dispatch<SetStateAction<string>>
  isDisabled: boolean
}

const SingleSelect = ({
  title,
  options,
  value,
  changeFn,
  isDisabled,
}: Props) => {
  return (
    <Stack>
      <Typography pb={1.5} variant="h4">
        {title}
      </Typography>
      <Select
        value={value}
        onChange={(e) => changeFn(e.target.value)}
        variant="outlined"
        disabled={isDisabled}
        sx={{
          '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
            pl: theme.spacing(0.2),
            pr: theme.spacing(1),
            fontSize: '1.5rem',
            mb: '-0.8rem',
          },
        }}
        MenuProps={{
          sx: {
            '& .MuiButtonBase-root.MuiMenuItem-root': {
              display: 'block',
              p: '5px 15px',
              fontSize: '1.5rem',
            },
          },
        }}
      >
        {options.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

export default SingleSelect
