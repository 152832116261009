import * as React from 'react'
import { TextField, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ActionDialog, Button, Stack } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import colors from 'styles/colors'
import { useDeleteCustomer } from 'services/queries/useDeleteCustomer'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

const DeleteCompany = ({ selectedCustomer, customerName }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { mutate: deleteCustomer, isLoading } = useDeleteCustomer()
  const navigate = useNavigate()
  const [inputValue, setInputValue] = React.useState('')
  const handleDeleteCompany = () => {
    if (inputValue === customerName) {
      deleteCustomer(selectedCustomer)
      navigate('/')

      return (
        <Typography variant="h4" component="div">
          {enqueueSnackbar('Deleted successfully', { variant: 'success' })}
        </Typography>
      )
    }
    return (
      <Typography variant="h4">
        {enqueueSnackbar(`Company name doesn't match`)}
      </Typography>
    )
  }
  return (
    <ActionDialog
      disabled={inputValue.length < 1}
      title={
        <FormattedMessage
          id={intl.customerManagement('delete-company-dialog-title')}
        />
      }
      content={() => (
        <Stack spacing={2} direction="column">
          <Typography>
            This action cannot be undone! This will permanently delete{' '}
            <b>{customerName}</b> from our database!
          </Typography>
          <Typography>
            Please type <b>{customerName}</b> to confirm.
          </Typography>
          <TextField
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
          />
        </Stack>
      )}
      action={handleDeleteCompany}
      maxWidth="sm"
      fullWidth={true}
      render={(open) => (
        <Button
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          style={{
            backgroundColor: colors?.rating_1,
            color: 'white',
            alignSelf: 'center',
          }}
          variant="contained"
          onClick={open}
          disabled={isLoading}
        >
          {isLoading ? 'Loading' : 'Delete Company'}
        </Button>
      )}
      agreeText="Delete"
    />
  )
}

export default DeleteCompany
