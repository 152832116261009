import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { CopyItem } from 'components'
import moment from 'moment'

const figureOutIfExpired = ({ data, field }) => {
  if (field === 'expires_at') {
    const date = moment(data)
    const now = moment()
    const isExpired = date.isBefore(now)
    return isExpired
  }
  return false
}

const StaticItem = ({ data, item: { title, field, isDate = false } }) => {
  const value = isDate
    ? data
      ? moment(data).format('DD.MM.YYYY')
      : 'Never'
    : data
  const isExpired = figureOutIfExpired({ data, field })
  return (
    <Box>
      <Typography
        sx={isExpired ? { color: 'red !important' } : null}
        variant="h5"
      >
        {isExpired ? `${title}d!!!` : title}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>{value}</Typography>
        <CopyItem item={value} />
      </Stack>
    </Box>
  )
}

export default StaticItem
