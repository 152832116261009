import React, { useState, useEffect } from 'react'
import ReactTable from './ReactTable'
import { useCustomers } from 'services/queries/useCustomers'
import { CircularProgress } from '@mui/material'
import styles from 'styles/app.ts'
import UserEmailSearch from './UserEmailSearch'
import useColumns from './useColumns'
import { useCustomersFromEmail } from 'services/queries/useCustomersFromEmail'

const CustomersTable = () => {
  const [searchEmail, setSearchEmail] = useState('')
  const [delayedSearchEmail, setDelayedSearchEmail] = useState('')

  const memoizedColumns = useColumns()

  const getCustomersQuery = useCustomers()
  const customers = getCustomersQuery.data
  const customersFromEmailQuery = useCustomersFromEmail(delayedSearchEmail)
  const customersFromEmail = customersFromEmailQuery?.data

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setDelayedSearchEmail(searchEmail)
    }, 300)

    return () => {
      clearTimeout(debounceFn)
    }
  }, [searchEmail])

  const memoizedData = React.useMemo(
    () => customersFromEmail || customers || [],
    [customers, customersFromEmail],
  )

  if (getCustomersQuery.isLoading) {
    return <CircularProgress color="primary" sx={styles.center} />
  }

  return (
    <>
      <UserEmailSearch
        searchEmail={searchEmail}
        setSearchEmail={setSearchEmail}
        isLoading={Boolean(customersFromEmailQuery?.isLoading)}
      />
      <ReactTable data={memoizedData} columns={memoizedColumns} />
    </>
  )
}

export default CustomersTable
