import callCmAPI from 'services/helpers/callCmAPI'
import { ApiUserT, UserT } from 'types'

/******************
 * Fetch from API *
 ******************/
export type GetUsersReturnType = {
  userData: UserT
  apiUserData: ApiUserT
}
export const getUsers = (customerId): Promise<GetUsersReturnType[]> =>
  callCmAPI(`/user/${customerId}`, {
    method: 'get',
  })
    .then(({ data }) => {
      return data.data
    })
    .catch((err) => err)
