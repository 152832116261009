import { useQuery } from 'react-query'
import { getCustomer } from 'services/cmAPI/getCustomer'
import { useSnackbar } from 'notistack'

export function useCustomer(customerId) {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(['getCustomer' + customerId], () => getCustomer(customerId), {
    enabled: Boolean(customerId),
    onError: (err) => {
      enqueueSnackbar('Cannot get customer', { variant: 'error' })
    },
  })
}
