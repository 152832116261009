import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { selectStyles } from '../Restrictions.styles'

function SingleSelect({
  value,
  label,
  onChange,
  isLoading,
  options,
}: {
  value: string
  label: string
  onChange: (newValue: string | string[]) => void
  isLoading: boolean
  options: {
    label: string
    key: string | number
  }[]
}) {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={isLoading}
        value={value}
        label={label}
        onChange={(e) => (!isLoading ? onChange(e.target.value) : null)}
      >
        {options.map((option) => (
          <MenuItem
            sx={selectStyles.selectMenuItem}
            key={option.key}
            value={option.key}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
