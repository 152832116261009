import { Typography } from '@material-ui/core'
import PhoneNumber from 'awesome-phonenumber'

const PhoneView = ({ phone }) => {
  const internationalPhone = new PhoneNumber(
    phone?.toString() ?? '',
    'dk',
  ).getNumber('international')
  return (
    <Typography style={{ width: '100%' }} value={internationalPhone}>
      {internationalPhone}
    </Typography>
  )
}
export default PhoneView
