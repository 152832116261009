import callCmAPI from 'services/helpers/callCmAPI'

/******************
 * Fetch from API *
 ******************/

export const getRisikaRefreshToken = (customerId: number) =>
  callCmAPI(`/customer/generate/token/${customerId.toString()}`, {
    method: 'get',
  })
    .then((res) => res.data)
    .catch((err) => err)
