import { useQuery } from 'react-query'
import { getCustomersFromEmail } from 'services/cmAPI/getCustomersFromEmail'
import { useSnackbar } from 'notistack'

export function useCustomersFromEmail(userEmail) {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    ['getCustomers', userEmail],
    () => getCustomersFromEmail(userEmail),
    {
      enabled: Boolean(localStorage.getItem('FB_ACCESS_TOKEN')),
      onError: (err) => {
        enqueueSnackbar('Cannot retrieve customers from email', {
          variant: 'error',
        })
        console.error('getCustomersFromEmail failed: ', err)
      },
    },
  )
}
