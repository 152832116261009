import * as React from 'react'
import { Button as MUIButton, Box } from '@material-ui/core'
import { default as cx } from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles, useCustomButton } from './styles/button.js'

export const Button = React.forwardRef((props: any, ref) => {
  const {
    className,
    children,
    color,
    disabled,
    icon,
    onClick,
    loading = false,
    newFeatureDate,
    size = 'medium',
    text,
    variant = 'contained',
    vertical = false,
    ...other
  } = props
  let displayNewFeatureTag = false
  if (newFeatureDate) {
    const newFeatureDaysLimit = 60
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - newFeatureDate.getTime()
    const daysDifference = timeDifference / (1000 * 3600 * 24)
    if (daysDifference < newFeatureDaysLimit) displayNewFeatureTag = true
  }
  const classes = useStyles()
  const customButton = useCustomButton({ color })
  const Icon = (props) => (icon ? React.cloneElement(icon, { ...props }) : null)
  const allowedColors = ['default', 'inherit', 'primary', 'secondary']
  return (
    <MUIButton
      ref={ref}
      className={className}
      classes={customButton}
      color={allowedColors.includes(color) ? color : 'default'}
      disabled={disabled || loading}
      onClick={onClick}
      size={size}
      variant={variant}
      elevation={0}
      // $FlowFixMe Pass through props not working properly
      {...other}
    >
      <Icon className={cx(classes.icon, vertical ? null : classes.leftIcon)} />
      {children ?? text}
      {displayNewFeatureTag && <Box className={classes.newFeature}>NEW!</Box>}
      {loading ? (
        <CircularProgress className={classes.buttonProgress} size={20} />
      ) : (
        ''
      )}
    </MUIButton>
  )
})

export default Button
