import colors from 'styles/colors'

const box = () => ({
  background: colors.risikaLight,
  width: '100vw',
  height: '100vh',
})

const center = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const topSide = () => ({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const googleButton = () => ({
  border: `1px solid ${colors.risikaTertiaryDark}`,
  color: colors.risikaTertiaryDark,
  fontSize: '1.7rem',
})

const loading = () => ({
  color: colors.risikaTertiaryDark,
})

export default {
  center,
  box,
  googleButton,
  loading,
  topSide,
}
