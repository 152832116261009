import { useMutation, useQueryClient } from 'react-query'
import { createUsers } from 'services/cmAPI/createUsers'
import { useSnackbar } from 'notistack'

export function useCreateUsers() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createUsers, {
    onSuccess: async (data) => {
      enqueueSnackbar('Create user success', { variant: 'success' })
      queryClient.refetchQueries({
        // @ts-ignore
        predicate: (query) => query?.queryKey?.[0]?.startsWith('getUsers'),
      })
    },
    onError: (err: any) => {
      enqueueSnackbar(`${err?.message}`, { variant: 'error' })
      console.log(err)
    },
  })
}
