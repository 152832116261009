import callCmAPI from 'services/helpers/callCmAPI'
import { expandAllowCreditPolicy } from 'utils/transformLegacyRights'

// UpdateCustomerBodyT = {
//   // /customer a.k.a. general customer info here
//   {
//     // customer/plan a.k.a restrictions here
//   }
// }

export type UpdateCustomerBodyT = {
  data: Object
  is_revoked?: boolean
  responsible_user_id?: number
  domain?: string
  is_removed?: boolean
  responsible_user?: string
}

export type UpdateCustomerDataT = {
  companyId: string
  reqBody: UpdateCustomerBodyT
}

// TODO fix type
export const updateCustomer = ({
  companyId,
  reqBody,
}: UpdateCustomerDataT): any => {
  reqBody.data = expandAllowCreditPolicy(reqBody.data)

  return callCmAPI(`/customer/${companyId}`, {
    method: 'put',
    data: reqBody,
  }).then((res) => res.data)
}
