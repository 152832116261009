import Email from './Email'
import Phone from './Phone'
import Name from './Name'
import PhoneView from './PhoneView'
import LastLoginView from './LastLoginView'
import CreatedAtView from './CreatedAtView'

const Fields = { Email, Phone, Name, PhoneView, LastLoginView, CreatedAtView }

export default Fields
