import callCmAPI from 'services/helpers/callCmAPI'
import { CustomerT, WebApi2Response } from 'types/global'
/******************
 * Fetch from API *
 ******************/

export const getCustomersFromEmail = (
  userEmail,
): Promise<WebApi2Response<CustomerT[]>> => {
  if (userEmail === '') return
  return callCmAPI(`/customer/search/${userEmail}`, {
    method: 'get',
  }).then(({ data }) => {
    return data.data
  })
}
