import callCmAPI from 'services/helpers/callCmAPI'
import { UserAfterT } from 'types'

/******************
 * Fetch from API *
 ******************/

export const createUsers = (data: UserAfterT[]) =>
  callCmAPI(`/user`, {
    method: 'post',
    data,
  })
    .then((res) => Promise.resolve(res.data.data))
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })
