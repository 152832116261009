const selectMenuItem = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '1rem !important',
  marginBottom: '0.5rem !important',
  justifyContent: 'start !important',
  fontSize: '2rem !important',
}

export const selectStyles = { selectMenuItem }
