import { useEffect, useState } from 'react'
import SingleSelect from './SingleSelect'
import { Stack, CircularProgress } from '@mui/material'
import { EditButtonComplexPenIcon } from 'components'
import { useRisikaUsers } from 'services/queries/useRisikaUsers'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'

const Changeable = ({ customerData, customerPlanData, planNames }) => {
  const risikaUsersReq = useRisikaUsers()
  const { mutate, isLoading } = useUpdateCustomer()

  const currentPlan = customerPlanData.subscription_plan
  const currentResponsibleId = customerData.responsible_user_id
  // Local state
  const [isEditing, setIsEditing] = useState(false)
  const [plan, setPlan] = useState<string>('')
  const [responsibleEmail, setResponsibleEmail] = useState<string>('')

  useEffect(() => {
    if (currentPlan) setPlan(currentPlan)
  }, [currentPlan])

  useEffect(() => {
    if (currentResponsibleId && risikaUsersReq.data)
      setResponsibleEmail(getResponsibleEmail(currentResponsibleId))
  }, [currentResponsibleId])

  // Utility fn
  const getResponsibleEmail = (responsibleId: number): string => {
    if (risikaUsersReq.isError || risikaUsersReq.data.success === false)
      throw new Error(
        'getResponsibleId --> Cannot get responsible Risika user id',
      )

    const responsibleUserInfo = risikaUsersReq.data.data.find(
      (user) => user.id === responsibleId,
    )

    return responsibleUserInfo?.email ?? 'email not found'
  }

  const getResponsibleIdFromEmail = () => {
    let newResponsibleUser = { id: null }
    if (risikaUsersReq.isSuccess && risikaUsersReq.data.success) {
      newResponsibleUser = risikaUsersReq.data.data.find(
        (user) => user.email === responsibleEmail,
      )
    }
    return newResponsibleUser.id
  }

  // Manage editing
  const cancelEditing = () => {
    setPlan(currentPlan)
    setResponsibleEmail(getResponsibleEmail(currentResponsibleId))
    setIsEditing(false)
  }

  const completeEditing = () => {
    if (
      getResponsibleIdFromEmail() === responsibleEmail &&
      currentPlan === plan
    )
      return //due to no change

    const body: any = {}
    // It always must have data filed even if it is empty object
    // data represents /customer/plan
    body.data = {}
    if (
      getResponsibleIdFromEmail() !== responsibleEmail ||
      currentPlan !== plan
    ) {
      body.responsible_user_id = getResponsibleIdFromEmail()
      body.data = {}
      body.data.subscription_plan = plan
    }

    mutate({ reqBody: body, companyId: customerData.id.toString() })
    setIsEditing(false)
  }
  return (
    <Stack direction="row" spacing={2}>
      <SingleSelect
        title="Plan"
        options={planNames}
        value={plan}
        changeFn={setPlan}
        isDisabled={!isEditing}
      />
      {risikaUsersReq.isSuccess && risikaUsersReq.data.success ? (
        <SingleSelect
          title="Responsible"
          options={risikaUsersReq.data?.data.map((user) => user.email).sort()}
          value={responsibleEmail}
          changeFn={setResponsibleEmail}
          isDisabled={!isEditing}
        />
      ) : (
        <CircularProgress />
      )}
      <Stack alignItems="center" justifyContent="flex-end">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <EditButtonComplexPenIcon
            startSavingProcedure={() => setIsEditing(true)}
            cancelSavingProcedure={() => cancelEditing()}
            completeSavingProcedure={() => completeEditing()}
            isEditing={isEditing}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default Changeable
