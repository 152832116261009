import {
  useContext,
  createContext,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import {
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth'
import { auth } from '../firebase-config'

type Props = {
  children: ReactNode
}

type UserT = {
  uid: string
  email: string
  emailVerified: boolean
  displayName: string
  accessToken: string
} | null

type AppContextT = {
  googleSignIn: () => Promise<UserCredential | undefined>
  logOut: () => void
  cleanCashe: () => void
  user: UserT
  isLoading: boolean
}

const AuthContext = createContext<AppContextT | null>(null)

export const AuthContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<UserT>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (user) {
      localStorage.setItem('FB_ACCESS_TOKEN', user.accessToken)
      localStorage.setItem('FB_NAME', user.displayName)
      localStorage.setItem('FB_EMAIL', user.email)
    }
  }, [user])

  const googleSignIn = async () => {
    setIsLoading(true)
    let signInResponse
    try {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        hd: 'risika.com',
      })

      signInResponse = await signInWithPopup(auth, provider).catch((error) =>
        console.log('error', error),
      )
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error(err)
    }
    return signInResponse
  }

  const cleanCashe = () => {
    setUser(null)
    localStorage.removeItem('FB_ACCESS_TOKEN')
    localStorage.removeItem('FB_NAME')
    localStorage.removeItem('FB_EMAIL')
  }

  const logOut = () => {
    cleanCashe()
    signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      const newUser: UserT = currentUser
        ? {
            uid: currentUser?.uid,
            email: currentUser?.email,
            emailVerified: currentUser?.emailVerified,
            displayName: currentUser?.displayName,
            accessToken: currentUser?.accessToken,
          }
        : null
      setUser(newUser)
      setIsLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const value: AppContextT = {
    googleSignIn,
    cleanCashe,
    logOut,
    user,
    isLoading,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useUserAuth = () => {
  return useContext(AuthContext) as AppContextT
}
