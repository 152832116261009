import * as React from 'react'

import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useGetSettingsStructure } from 'services/queries/useGetSettingsStructure'
import SettingsKeys from './SettingsKeys'
import { Add } from '@mui/icons-material'
import { Show } from 'components'
import { usePutUserSettings } from 'services/queries/usePutUserSettings'
import { useDeleteUserSettings } from 'services/queries/useDeleteUserSettings'
import Loading from './NewUserSetting.loading'

function NewUserSetting() {
  const { data, isLoading: isFetching } = useGetSettingsStructure()
  const { mutate: addNewSetting, isLoading: isUpdating } = usePutUserSettings()
  const { mutate: deleteUserSetting, isLoading: isDeleting } =
    useDeleteUserSettings()
  const isLoading = isFetching || isUpdating || isDeleting
  const [addKey, setIsAddKey] = React.useState<
    | 'credit_check'
    | 'company_info'
    | 'monitor_table'
    | 'credit_check_sidebar'
    | ''
  >('')
  const [newFieldValue, setNewFieldValue] = React.useState('')
  const [isVisible, setIsVisible] = React.useState(false)

  const handleDeleteKey = (key: string) => {
    const inferredTableName = data?.find((table) => {
      return table.keys.includes(key)
    })?.tableName

    deleteUserSetting({
      tableName: inferredTableName,
      key,
    })
  }

  const handleOpenNewKeyField = (tableName) => {
    setIsAddKey(tableName)
  }
  const handleAddNewKey = (tableName) => {
    if (addKey !== '') {
      addNewSetting({
        key: newFieldValue,
        visibility: isVisible,
        tableName: addKey,
      })
      setIsAddKey('')
    }
  }
  if (isLoading) return <Loading />
  return (
    <Stack spacing={5}>
      {data?.map((structure) => {
        return (
          <>
            <Stack direction="row" spacing={5}>
              <Typography key={structure?.tableName} variant="h2">
                {structure?.tableName}
              </Typography>
              <Button
                onClick={() => handleOpenNewKeyField(structure.tableName)}
                variant="outlined"
                startIcon={
                  <Add sx={{ color: 'green', width: '2rem', height: '2rem' }} />
                }
              >
                Add new key
              </Button>
            </Stack>
            <Stack direction="row" spacing={2} px={2}>
              <Show when={addKey === structure.tableName}>
                <Stack width={'100%'}>
                  <Stack sx={{ width: '100%' }} direction="row">
                    <TextField
                      onChange={(e) => setNewFieldValue(e.target.value)}
                      value={newFieldValue}
                      fullWidth
                      id="standard-basic"
                      label="New Field"
                      variant="outlined"
                    />
                    <IconButton
                      onClick={() => handleAddNewKey(structure.tableName)}
                    >
                      <Add
                        sx={{
                          color: 'green',
                          width: '2rem',
                          height: '2rem',
                        }}
                      />
                    </IconButton>
                  </Stack>
                  <FormControl>
                    <RadioGroup
                      sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        p: 3,
                        gap: 2,
                      }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isVisible}
                      onChange={() => setIsVisible(!isVisible)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Not Visible"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Visible"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Show>
            </Stack>
            <SettingsKeys
              keys={structure?.keys}
              onDelete={(key) => handleDeleteKey(key)}
            />
          </>
        )
      })}
    </Stack>
  )
}

export default NewUserSetting
