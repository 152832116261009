import callCmAPI from 'services/helpers/callCmAPI'
import { CustomerT } from 'types/global'
/******************
 * Fetch from API *
 ******************/

export const getCustomerData = (customerId): Promise<CustomerT> =>
  callCmAPI(`/customer/${customerId}`, {
    method: 'get',
  })
    .then(({ data }) => {
      return data.data
    })
    .catch((err) => {
      return err
    })
