// @flow

/**
 * Generate the ID for the given element.
 */
const generateLocaleID = (component: string) => (element: string) => {
  return `${component}.${element}`
}

export default generateLocaleID
