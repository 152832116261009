import * as React from 'react'
import { FormGroup } from '@mui/material'
import { themeNew } from 'styles/theme-new'
import { FormControlLabel, Switch } from '@material-ui/core'

function CustomSwitch({
  value,
  label,
  onChange,
  isLoading,
  disabled,
}: {
  value: boolean
  label: string
  onChange: () => void
  isLoading: boolean
  disabled?: boolean
}) {
  return (
    <FormGroup>
      <FormControlLabel
        labelPlacement="end"
        control={
          <Switch
            disabled={disabled ? disabled : isLoading}
            color="default"
            style={{
              color: value
                ? themeNew.palette.success[500]
                : themeNew.palette.grey[600],
            }}
            checked={value}
            onChange={!isLoading ? onChange : null}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default CustomSwitch
