import { inputTypes } from '../Restrictions.model'
import CustomSwitch from '../Inputs/CustomSwitch'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'
import { useParams } from 'react-router'
import CustomSelect from '../Inputs/SingleSelect'
import MultiSelect from '../Inputs/MultiSelect'
import CustomNumeric from '../Inputs/CustomNumeric'
import ScoreModels from '../Inputs/ScoreModels'
import { Element, NewValue } from '../Restrictions.types'
import { handleUpdateRestrictions } from '../Restrictions.controller'

type CreateInputElementProps = {
  data: any
  element: Element
}
const CreateInputElement = ({ data, element }: CreateInputElementProps) => {
  const { type, label, helperWord } = element
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer()
  const { id: selectedCustomer } = useParams()
  const handleUpdateCustomer = (
    type: 'boolean' | 'select' | 'numeric' | 'scoreModel',
    newValue?: NewValue,
  ) => {
    switch (type) {
      case 'boolean':
        handleUpdateRestrictions({
          selectedCustomer,
          updateCustomer,
          newValue: !getElementValue(),
          element,
        })
        break
      case 'select':
      case 'numeric':
      case 'scoreModel':
        handleUpdateRestrictions({
          selectedCustomer,
          updateCustomer,
          newValue,
          element,
        })
        break

      default:
        break
    }
  }

  const getElementValue = () => {
    if (element.updateLocation) {
      return data[element.updateLocation][element.key]
    }
    return data[element.key]
  }

  switch (type) {
    case inputTypes.BOOLEAN:
      return (
        <CustomSwitch
          isLoading={isLoading}
          onChange={() => handleUpdateCustomer('boolean')}
          value={getElementValue()}
          label={label}
        />
      )
    case inputTypes.SINGLE_SELECT:
      return (
        <CustomSelect
          isLoading={isLoading}
          onChange={(selectedValue) =>
            handleUpdateCustomer('select', selectedValue)
          }
          value={data[element.key]}
          label={label}
          options={element.options}
        />
      )
    case inputTypes.MULTI_SELECT:
      return (
        <MultiSelect
          isLoading={isLoading}
          onChange={(selectedValue) =>
            handleUpdateCustomer('select', selectedValue)
          }
          value={data[element.key]}
          label={label}
          options={element.options}
        />
      )
    case inputTypes.SCORE_MODEL:
      return (
        <ScoreModels
          isLoading={isLoading}
          onChange={(selectedValue) =>
            handleUpdateCustomer('scoreModel', selectedValue)
          }
          value={data[element.key]}
          label={label}
          options={element.options}
          availableModels={element.availableModels}
        />
      )
    case inputTypes.NUMERIC:
      return (
        <CustomNumeric
          isLoading={isLoading}
          onChange={(newValue) => handleUpdateCustomer('numeric', newValue)}
          value={data.caps[element.key]}
          max={data.counters[element.key]}
          label={label}
          helperWord={helperWord}
        />
      )
    default:
      return null
  }
}

export default CreateInputElement
