import { Button } from 'components'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { theme } from 'styles/RootTheme'

const ExportUsers = ({ customerData, usersData }) => {
  return (
    <Button icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}>
      <CSVLink
        style={{
          textDecoration: 'none',
          color: theme.palette.text.primary,
        }}
        filename={`${customerData?.name} users.csv`}
        label={`User data for: ${customerData?.name}`}
        data={usersData?.map((user) => ({
          name: user.name,
          email: user.email,
          created: moment(user.created_at).format('DD.MM.YYYY, hh:mm'),
        }))}
      >
        {
          <FormattedMessage
            id={intl.riskMonitoringNew('export-companies-button')}
          />
        }
      </CSVLink>
    </Button>
  )
}

export default ExportUsers
