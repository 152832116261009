// @flow

import ReactTable from 'react-table-6'
import { injectIntl } from 'react-intl'
import intl from 'localization/components'

const IntlReactTable = (props) => {
  const {
    intl: { messages },
  } = props

  const intlProps = {
    previousText: messages[intl.table('previous')],
    nextText: messages[intl.table('next')],
    loadingText: messages[intl.table('loading')],
    noDataText: messages[intl.table('no-rows')],
    pageText: messages[intl.table('page')],
    ofText: messages[intl.table('of')],
    rowsText: messages[intl.table('rows')],
  }

  return <ReactTable {...props} {...intlProps} />
}

export default injectIntl(IntlReactTable)
