import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { updateSettingsVisibility } from 'services/cmAPI/updateSettingsVisibility'

export function useUpdateSettingsVisibility() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateSettingsVisibility, {
    onMutate: async ({ tableName, key, customerId, state }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('getSettingsAll')
      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData('getSettingsAll')

      // Return a context object with the snapshotted value
      return { previousSettings }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('getSettingsAll')
      enqueueSnackbar('Visibility is updated', { variant: 'success' })
    },
    onError: (err) => {
      enqueueSnackbar('Failed to update visibility', { variant: 'error' })
      console.error('Deletion failed: ', err)
    },
  })
}
