import { useState, useEffect } from 'react'
import { CustomerStatusProvider } from 'contexts/CustomerStatusProvider'
import { AccordionWrapper, CustomTabs, UserTable } from 'components'
import { deleteUser } from 'services/cmAPI/deleteUser'
import { createUsers } from 'services/cmAPI/createUsers'
import { updateUser } from 'services/cmAPI/updateUser'
import { Paper } from '@mui/material'
import GeneralInformation from './widgets/GeneralInformation'
import { useParams } from 'react-router-dom'
import { useGetSelectedCustomerData } from './SelectedCustomer.controller'
import Restrictions from './widgets/Restrictions'
import UserSettingsHandler from './widgets/UserSettingsHandler'

const SelectedCustomer = () => {
  const [planNames, setPlanNames] = useState<string[]>([])
  const { id: selectedCustomer } = useParams()
  const { data, isLoading, isSuccess } =
    useGetSelectedCustomerData(selectedCustomer)

  const { usersData, customerData, customerPlanData, plansData } = data
  const isExpired = customerData?.expires_at < new Date().toISOString()
  useEffect(() => {
    if (isSuccess) setPlanNames(plansData?.map((planObj) => planObj.name))
  }, [plansData, isSuccess])
  const dashboardUsers = usersData?.filter((user) => user.userData !== null)
  return (
    <Paper sx={{ height: '100%', minHeight: '100vh' }}>
      <AccordionWrapper isLoading={isLoading} title="General" expanded={true}>
        <GeneralInformation
          planNames={planNames}
          customerData={customerData}
          customerPlanData={customerPlanData}
        />
      </AccordionWrapper>
      <CustomTabs
        items={[
          <UserTable
            isExpired={isExpired}
            key="User Table"
            customerData={customerData}
            removeUser={deleteUser}
            updateUser={updateUser}
            createNewUser={createUsers}
            users={usersData}
            maxUsers={customerPlanData?.max_users}
            showTitle={false}
          />,
          <CustomerStatusProvider
            key="Restrictions"
            id={selectedCustomer}
            users={usersData}
          >
            <Restrictions usersLength={dashboardUsers?.length} />
          </CustomerStatusProvider>,
          <UserSettingsHandler
            customerData={customerData}
            key="User Settings"
          />,
        ]}
        tabs={[
          {
            label: `Users (${dashboardUsers?.length})`,
            index: 0,
          },
          {
            label: 'Restrictions',
            index: 1,
          },
          {
            label: 'User Settings',
            index: 2,
          },
        ]}
        isLoading={isLoading}
      />
    </Paper>
  )
}

export default SelectedCustomer
