import { useState, createContext, useContext } from 'react'
import { useSnackbar } from 'notistack'
import lodash from 'lodash'
import { useCustomer } from 'services/queries/useCustomer'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'
import useDefaults from 'utils/useDefaults'

const ThemeContext = createContext()

const useCompanyStatusContext = () => {
  return useContext(ThemeContext)
}

const CustomerStatusProvider = ({ children, id, users }) => {
  const [newData, setNewData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const { getDefaultPlan } = useDefaults()
  const defaultPlan = getDefaultPlan()

  const { enqueueSnackbar } = useSnackbar()
  const { data, status } = useCustomer(id)
  const { mutate } = useUpdateCustomer()

  const startSavingProcedure = () => {
    setIsEditing(true)
  }

  const completeSavingProcedure = async () => {
    setIsEditing(false)
    // mutate passes object with variables!
    if (newData && Object.keys(newData).length) {
      // mutate({ ...localId, data: newData })
      mutate({ companyId: id, reqBody: { data: newData } })
      setNewData(null)
    }
  }

  const cancelSavingProcedure = () => {
    setNewData(null)
    setIsEditing(false)
  }

  const deleteField = (field) => {
    setNewData((currentData) => {
      if (currentData) {
        delete currentData[field]
      }
      return currentData
    })
  }

  const changeField = (field, newValue) => {
    if (newValue === null) {
      enqueueSnackbar(
        'You just changed field to null which is not appropriate value!',
        { variant: 'error' },
      )
      return
    }
    if (lodash.isEqual(newValue, defaultPlan[field])) {
      deleteField(field)
      return
    }
    setNewData((currentData) => {
      return { ...currentData, [field]: newValue }
    })
  }

  const removeRestriction = (field) => {
    setNewData((currentData) => {
      return { ...currentData, [field]: null }
    })
  }

  return (
    <ThemeContext.Provider
      value={{
        data,
        status,
        currentCompanyUsers: users,
        startSavingProcedure,
        completeSavingProcedure,
        cancelSavingProcedure,
        isEditing,
        changeField,
        removeRestriction,
        deleteField,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export { CustomerStatusProvider, useCompanyStatusContext }
