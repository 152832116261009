// @flow

import { Button } from 'components'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type AreYouSureProps = {
  open: boolean,
  setOpen: Function,
  onSubmit: Function,
  title: string,
  content: string,
}
const AreYouSure = ({
  open,
  setOpen,
  onSubmit,
  title,
  content,
}: AreYouSureProps): JSX.Element => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleAgree = () => {
    onSubmit()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {<FormattedMessage id={intl.confirmAction('cancel')} />}
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          {<FormattedMessage id={intl.confirmAction('agree')} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AreYouSure
