import { env } from 'services/helpers/env'

/**************
 * Risika API *
 **************/

export const RISIKA_API_VERSION = env.RISIKA_API_VERSION
// export const RISIKA_API_VERSION = 'v1.2'
// export const RISIKA_API_VERSION = 'beta'
// export const RISIKA_API_VERSION = ''
export const RISIKA_API_BASE_URL = env.RISIKA_API_BASE_URL
// export const RISIKA_API_BASE_URL = 'localhost:8000'

/***************
 * Thorn API *
 ***************/

export const THORN_API_BASE_URL = env.THORN_API_BASE_URL

/***************
 * Web API *
 ***************/

export const WEB_API_BASE_URL = env.WEB_API_BdASE_URL

/***************************
 * Local Storage constants *
 ***************************/

export const RISIKA_API_ACCESS_TOKEN = 'RISIKA_API_ACCESS_TOKEN'
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN'
export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN'
