import { Tooltip, Typography } from '@mui/material'
import Fields from './Fields'
import RolesColumn from './RolesColumn'
import SelectSimpleRole from './SelectSimpleRole'
import {
  faArrowUp,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faColumns,
  faFilter,
  faPen,
  faPlus,
  faSave,
  faSearch,
  faStepBackward,
  faStepForward,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LayersClearIcon from '@mui/icons-material/LayersClear'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import colors from 'styles/colors'
import { VpnKey } from '@mui/icons-material'

export const localization = (messages) => {
  return {
    pagination: {
      // labelDisplayedRows: '{from}-{to} of {count}'
      firstAriaLabel: messages[intl.generic('first-page')],
      firstTooltip: messages[intl.generic('first-page')],
      previousAriaLabel: messages[intl.generic('previous-page')],
      previousTooltip: messages[intl.generic('previous-page')],
      nextAriaLabel: messages[intl.generic('next-page')],
      nextTooltip: messages[intl.generic('next-page')],
      lastAriaLabel: messages[intl.generic('last-page')],
      lastTooltip: messages[intl.generic('last-page')],
      labelRowsSelect: messages[intl.generic('rows')],
    },
    toolbar: {
      searchTooltip: messages[intl.searchBusiness('search-field')],
      searchPlaceholder: messages[intl.searchBusiness('search-field')],
    },
    header: {
      actions: messages[intl.generic('actions')],
    },
    body: {
      addTooltip: messages[intl.generic('add')],
      editTooltip: messages[intl.generic('edit')],
      deleteTooltip: messages[intl.riskMonitoringNew('delete-items')],
      editRow: {
        deleteText: messages[intl.settings('are-you-sure-delete-user')],
        cancelTooltip: messages[intl.confirmAction('cancel')],
        saveTooltip: messages[intl.confirmAction('confirm')],
      },
    },
  }
}

export const columns = (messages) => {
  return [
    {
      title: messages[intl.generic('first-name')],
      field: 'userData.name',
      // editable: 'onAdd',
      customFilterAndSearch: (term, rowData) => {
        return (
          rowData?.userData?.name
            ?.toLowerCase()
            ?.includes(term?.toLowerCase()) ||
          rowData?.apiUserData?.nickname
            ?.toLowerCase()
            ?.includes(term?.toLowerCase())
        )
      },
      editComponent: (props) => {
        return <Fields.Name messages={messages} props={props} />
      },
      render: ({ userData, apiUserData }) => {
        if (!apiUserData) {
          return (
            <Tooltip
              placement="top"
              title="This user does not have a corresponding API account!"
            >
              <Typography
                sx={{
                  display: 'inline-block',
                  color: `${colors.rating_1} !important`,
                }}
              >
                {userData?.name}
              </Typography>
            </Tooltip>
          )
        }
        if (userData) {
          return (
            <Typography sx={{ display: 'inline-block' }}>
              {userData?.name}
            </Typography>
          )
        }
        return (
          <Tooltip placement="top" title="API user">
            <Typography
              sx={{
                display: 'inline-block',
                color: `${colors.risikaBlue} !important`,
              }}
            >
              {apiUserData?.nickname}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      title: messages[intl.companyInfo('email')],
      field: 'userData.email',
      editable: 'onAdd',
      customFilterAndSearch: (term, rowData) => {
        return rowData?.userData?.email
          ?.toLowerCase()
          ?.includes(term?.toLowerCase())
      },
      editComponent: (props) => {
        return <Fields.Email messages={messages} props={props} />
      },
      render: ({ userData }) => (
        <Typography>{userData?.email ?? '-'}</Typography>
      ),
    },
    {
      title: messages[intl.companyInfo('phone')],
      editComponent: (props) => {
        return <Fields.Phone messages={messages} props={props} />
      },
      render: ({ userData }) => {
        if (!userData) return '-'
        return <Fields.PhoneView phone={userData?.phone_number ?? '-'} />
      },
      field: 'userData.phone_number',
      editable: 'always',
    },
    {
      title: messages[intl.generic('roles')],
      field: 'userData.role',
      customFilterAndSearch: (term, rowData) => {
        return rowData?.userData?.role
          ?.toLowerCase()
          ?.includes(term?.toLowerCase())
      },
      render: ({ userData }) => {
        if (!userData) return '-'
        return (
          <RolesColumn
            role={userData?.role}
            isVerified={userData?.is_verified}
            email={userData?.email}
          />
        )
      },
      editComponent: (props) => <SelectSimpleRole onChange={props.onChange} />,
      customSort: (a, b) => {
        if (a.role === 'admin' && b.role === 'user') return 1
        if (a.role === 'user' && b.role === 'admin') return -1
        return 0
      },
    },
    {
      title: messages[intl.settings('created-at')],
      editable: 'never',
      customSort: (a, b) => {
        const aDate = new Date(a?.createdAt)
        const bDate = new Date(b?.createdAt)
        if (aDate > bDate) return -1
        if (bDate > aDate) return 1
        return 0
      },
      render: (props) => <Fields.CreatedAtView props={props} />,
    },
    {
      title: messages[intl.settings('last-login')],
      field: 'last_login',
      editable: 'never',
      customSort: (a, b) => {
        if (a?.lastLogin > b?.lastLogin) {
          return -1
        }
        if (b?.lastLogin > a?.lastLogin) {
          return 1
        }
        return 0
      },
      render: (props) => <Fields.LastLoginView props={props} />,
    },
  ]
}

const CheckIcon = () => <FontAwesomeIcon icon={faCheck} />
const DetailPanelIcon = () => <FontAwesomeIcon icon={faChevronRight} />
const DeleteIcon = () => <FontAwesomeIcon icon={faTrash} />
const ExportIcon = () => <FontAwesomeIcon icon={faSave} />
const FilterIcon = () => <FontAwesomeIcon icon={faFilter} />
const FirstPageIcon = () => <FontAwesomeIcon icon={faStepBackward} />
const LastPageIcon = () => <FontAwesomeIcon icon={faStepForward} />
const NextPageIcon = () => <FontAwesomeIcon icon={faChevronRight} />
const PreviousPageIcon = () => <FontAwesomeIcon icon={faChevronLeft} />
const SearchIcon = () => <FontAwesomeIcon icon={faSearch} />
const ThirdStateCheckIcon = () => <FontAwesomeIcon icon={faTrash} />
const AddIcon = () => <FontAwesomeIcon icon={faPlus} />
const SortArrowIcon = ({ className }) => (
  <FontAwesomeIcon icon={faArrowUp} className={className} />
)
const ClearIcon = () => <FontAwesomeIcon icon={faTimes} />
const EditIcon = () => <FontAwesomeIcon icon={faPen} />
const ViewColumnIcon = () => <FontAwesomeIcon icon={faColumns} />
const DeleteApiIcon = () => <LayersClearIcon />
const UserApiToken = () => <VpnKey />

export const icons = {
  Check: CheckIcon,
  DetailPanel: DetailPanelIcon,
  Delete: DeleteIcon,
  Export: ExportIcon,
  Filter: FilterIcon,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: NextPageIcon,
  PreviousPage: PreviousPageIcon,
  Search: SearchIcon,
  ThirdStateCheck: ThirdStateCheckIcon,
  Add: AddIcon,
  SortArrow: SortArrowIcon,
  Clear: ClearIcon,
  Edit: EditIcon,
  ViewColumn: ViewColumnIcon,
  DeleteApi: DeleteApiIcon,
  UserApiToken,
}

type availableActions = 'create' | 'update' | 'delete'

export const snackbarText = (action: availableActions, isSuccess: boolean) => {
  return (
    <Typography variant="h5">
      <FormattedMessage
        id={intl.userManagement(
          `user-${action}-${isSuccess ? 'success' : 'failed'}`,
        )}
      />
    </Typography>
  )
}
