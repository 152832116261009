import callCmAPI from 'services/helpers/callCmAPI'
import { shrinkAllowCreditPolicy } from 'utils/transformLegacyRights'

export const getDefaultPlans = () =>
  callCmAPI(`/customer/plans`, {
    method: 'get',
  })
    .then(({ data: { data } }) => {
      const mappedData = data.map((plan) => {
        const newPlan = {
          ...plan,
          max_users: plan.number_of_users,
          allow_countries: plan.allow_countries.map((country) =>
            country.toUpperCase(),
          ),
          allow_credit_policy: shrinkAllowCreditPolicy(
            plan.allow_credit_policy,
          ),
          credit_ratings: plan.number_of_credit_ratings,
          credit_reports: plan.number_of_credit_reports,
          lookups: plan.number_of_creditchecks,
          monitoring_count: plan.number_of_monitoring,
          monitoring_exports: plan.number_of_monitoring_exports,
          search_exports: plan.number_of_search_exports,
        }

        delete newPlan.number_of_creditchecks
        delete newPlan.number_of_credit_ratings
        delete newPlan.number_of_credit_reports
        delete newPlan.number_of_monitoring
        delete newPlan.number_of_search_exports
        delete newPlan.number_of_monitoring_exports

        return newPlan
      })

      return mappedData
    })
    .catch((err) => err)
