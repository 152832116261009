import colors from 'styles/colors'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  ebcContainerVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > *': {
      my: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  },
  ebcIcon: {
    width: '1em !important',
    height: '1em !important',
  },
  ebcButtonIconAccept: {
    backgroundColor: colors?.rating_10,
    opacity: '0.8',
  },
  ebcButtonIconReject: {
    backgroundColor: colors?.rating_1,
    opacity: '0.8',
  },
}))
