import { useIntl } from 'react-intl'

const dateFormat = (date, format) => {
  if (format === 'relative') {
    return date.fromNow()
  }

  if (format === 'duration') {
    return date.fromNow(true)
  }

  return date.format(format)
}

/**
 * A localized date.
 *
 * @param {moment} date A moment date object.
 */
const HumanDate = ({ children, date, format = 'relative' }) => {
  const intl = useIntl()
  date.locale(intl?.locale)

  if (children && typeof children === 'function') {
    return children(dateFormat(date, format))
  }

  return <span>{dateFormat(date, format)}</span>
}

export default HumanDate
