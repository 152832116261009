import { useMutation, useQueryClient } from 'react-query'
import { deleteCustomer } from 'services/cmAPI/deleteCustomer'
import { useSnackbar } from 'notistack'

export function useDeleteCustomer() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(deleteCustomer, {
    onSuccess: async () => {
      await queryClient.refetchQueries('getCustomers')
    },
    onError: (err) => {
      enqueueSnackbar('Delete customer failed', { variant: 'error' })
      console.error('deleteCustomer failed: ', err)
    },
  })
}
