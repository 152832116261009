import { Grid, Paper, Stack, Typography } from '@mui/material'
import {
  countriesForCountrySpecific,
  countrySpecific,
} from '../Restrictions.model'
import CreateInputElement from './CreateInputElement'
import { CountrySpecificProps } from '../Restrictions.types'

function CountrySpecific({ data, search }: CountrySpecificProps) {
  const searchFilter = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }
  const alphabeticalSort = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }

  const sortedCountrySpecific = countrySpecific.sort(alphabeticalSort)

  const removeInactiveOptions = (data, availableModels) => {
    return {
      ...data,
      score_models: data?.score_models?.filter(
        (option) =>
          availableModels.map((x) => x.model).includes(option.model) ?? {},
      ),
    }
  }

  return (
    <Paper sx={{ width: '100%' }} elevation={3}>
      <Stack sx={{ padding: '5rem' }} spacing={3} alignItems="center">
        <Typography variant="h3">Country Specific</Typography>
        <Stack sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {countriesForCountrySpecific.map(
              ({ availableModels, countryCode, country }) => (
                <Grid item xs={6} key={countryCode}>
                  <Stack spacing={4} key={countryCode}>
                    <Typography variant="h4">{country}</Typography>
                    {sortedCountrySpecific
                      .filter(searchFilter)
                      .map((element) => (
                        <CreateInputElement
                          key={element.key}
                          data={removeInactiveOptions(
                            data[countryCode],
                            availableModels,
                          )}
                          element={{
                            ...element,
                            availableModels,
                            updateLocation: countryCode,
                          }}
                        />
                      ))}
                  </Stack>
                </Grid>
              ),
            )}
          </Grid>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default CountrySpecific
