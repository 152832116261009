import React from 'react'
import { TextField } from '@mui/material'
import { theme5 } from 'styles/theme5'
const StringsContainFilter = ({ column }) => {
  const { setFilter } = column
  const [fieldVal, setFieldVal] = React.useState('')
  return (
    <TextField
      variant="filled"
      placeholder="Filter"
      helperText="Contains"
      value={fieldVal}
      color="warning"
      onChange={(e) => {
        const currentVal = e.target.value
        setFieldVal(currentVal)
        setFilter(currentVal)
      }}
      sx={{
        '& .MuiInputBase-input.MuiFilledInput-input': {
          pl: '1px',
          fontSize: '1.8rem',
          background: theme5.palette.grey[100],
        },
      }}
      FormHelperTextProps={{
        sx: { fontSize: '1rem', mx: '1px' },
      }}
    />
  )
}

export default StringsContainFilter
