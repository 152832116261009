import { Typography } from '@material-ui/core'
import { Stack } from 'components'
import { useStyles } from './styles/header'

export const Header = ({ children, title }) => {
  const classes = useStyles()
  return (
    <Stack
      className={classes.default}
      justify="space-between"
      items="center"
      flex="1"
      wrap="wrap"
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <div>{children}</div>
    </Stack>
  )
}

export default Header
