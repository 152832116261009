import callCmAPI from 'services/helpers/callCmAPI'
import { UserSettingsTableNameEnum } from 'types'

/******************
 * Fetch from API *
 ******************/

type SettingsStructure = {
  keys: string[]
  tableName: UserSettingsTableNameEnum
}

type AddUserSetting = {
  tableName: UserSettingsTableNameEnum
  key: string
}

export const deleteUserSetting = ({
  tableName,
  key,
}: AddUserSetting): Promise<SettingsStructure[]> =>
  callCmAPI('/user-settings/structure', {
    method: 'delete',
    data: {
      tableName,
      key,
    },
  })
    .then((res) => Promise.resolve(res.data.data)) // :)
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })
