import { makeStyles } from '@material-ui/core/styles'
import colors from 'styles/colors'
import { emphasize } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  buttonContrast: {
    backgroundColor: theme.palette?.contrast?.main,
    color: theme.palette?.contrast?.contrastText,
    '&:hover': {
      backgroundColor: `${theme.palette?.contrast?.hover} !important`,
    },
  },
  buttonPrimary: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary?.hover} !important`,
    },
  },
  icon: {
    width: 'auto',
    height: '2rem',
  },
  buttonProgress: {
    color: colors?.risikaContrast,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -8,
  },
  leftIcon: {
    marginRight: '1rem',
  },
  newFeature: {
    fontSize: '1.2rem',
    color: colors?.risikaWhite,
    backgroundColor: colors?.red,
    padding: '0.5rem',
    borderRadius: '0.5rem',
    marginLeft: '1rem',
  },
}))

export const useCustomButton = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.3, 2),
    fontSize: theme.typography.htmlFontSize,
    lineHeight: 0,
    fontWeight: 500,
    textTransform: 'none',
    boxShadow: 'none',
  },
  contained: {
    backgroundColor: (props) =>
      props.color ? theme.palette[props.color].main : colors?.risikaLight,
    color: (props) =>
      props.color
        ? theme.palette[props.color].contrastText
        : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: (props) =>
        props.color
          ? emphasize(theme.palette[props.color].main, 0.2)
          : emphasize(colors?.risikaLight, 0.2),
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    justifyContent: 'center',
  },
}))
