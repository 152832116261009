import * as React from 'react'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import { useGetSettingsAll } from 'services/queries/useGetSettingsAll'
import CustomSwitch from '../Restrictions/Inputs/CustomSwitch'
import { CustomerT } from 'types'
import { useUpdateSettingsVisibility } from 'services/queries/useUpdateSettingsVisibility'
function UserSettingsHandler({ customerData }: { customerData: CustomerT }) {
  const { data, isLoading: isGettingAll } = useGetSettingsAll(customerData.id)
  const { mutate: updateSettingVisibility, isLoading: isUpdatingSetting } =
    useUpdateSettingsVisibility()
  const [updatingKey, setUpdatingKey] = React.useState('')
  const isLoading = isGettingAll || isUpdatingSetting
  const handleUpdateSetting = ({ tableName, key, state }) => {
    updateSettingVisibility({
      customerId: customerData.id,
      key,
      tableName,
      state,
    })
  }

  const handleChange = ({ tableName, key, state }) => {
    setUpdatingKey(key)
    handleUpdateSetting({
      tableName,
      key,
      state,
    })
  }

  return (
    <Stack width={1} alignItems="center">
      <Stack
        width={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
      ></Stack>
      <Grid sx={{ minHeight: '10rem', flexGrow: 1 }} container spacing={2}>
        {data?.map((item) => (
          <Grid key={item.tableName} display="flex" item xs={6}>
            <Paper sx={{ width: '100%' }} elevation={3}>
              <Stack sx={{ padding: '5rem' }} spacing={3} alignItems="center">
                <Typography variant="h3">{item.tableName}</Typography>
                <Stack sx={{ width: '100%' }}>
                  <Box
                    display="flex"
                    sx={{ height: '25rem' }}
                    flexWrap="wrap"
                    flexDirection="column"
                  >
                    {item.settings.map(({ key, visible }) => (
                      <>
                        <CustomSwitch
                          isLoading={updatingKey === key && isLoading}
                          onChange={() =>
                            handleChange({
                              tableName: item.tableName,
                              key,
                              state: !visible,
                            })
                          }
                          value={visible}
                          label={key}
                        />
                      </>
                    ))}
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export default UserSettingsHandler
