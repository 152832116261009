import React from 'react'
import Changeable from './Changeable'
import { Stack } from '@mui/material'
import Static from './Static'
import ColorfulManageGroup from './ColorfulManageGroup'
import { theme5 } from 'styles/theme5'

const GeneralInformation = ({ planNames, customerData, customerPlanData }) => {
  return (
    <Stack
      p={2}
      sx={{
        bgcolor: theme5.palette.grey[100],
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        rowGap={theme5.spacing(2)}
        pb={3}
      >
        <Changeable
          customerData={customerData}
          customerPlanData={customerPlanData}
          planNames={planNames}
        />
        <ColorfulManageGroup customerData={customerData} />
      </Stack>
      <Static />
    </Stack>
  )
}

export default GeneralInformation
