import { AllowCreditPolicyT, CustomerCreditPolicyT } from 'types'
import isEqual from 'lodash/isEqual'

// Expands allow_credit_policy property of whole rights/exceptions object
export const expandAllowCreditPolicy = (rightsObj) => {
  if (!('allow_credit_policy' in rightsObj)) return rightsObj
  const newRightsObj = { ...rightsObj }

  switch (rightsObj['allow_credit_policy'].toLowerCase()) {
    case 'advanced':
      newRightsObj['allow_credit_policy'] = [
        'exclude_risika_scores',
        'exclude_company_types',
        'exclude_level_of_auditor_assistances',
        'exclude_industries',
        'exclude_companies',
        'exclude_persons',
        'exclude_vat',
        'bankruptcy_limit',
        'age_limit',
        'negative_equity_limit',
        'prepaid_phone_number',
        'phone_number_registry',
        'distance_to_ceo',
        'bankruptcy_limit_long',
        'auditor_has_id',
        'payment_remarks_count',
        'payment_remarks_creditors',
        'payment_remarks_total_amount',
        'payment_remarks_max_repayment_time',
        'debt',
        'short_term_debt',
        'profit_loss',
        'number_of_employees',
      ]
      break
    case 'simple':
      newRightsObj['allow_credit_policy'] = [
        'exclude_risika_scores',
        'exclude_company_types',
        'negative_equity_limit',
        'age_limit',
      ]
      break
    case 'none':
      newRightsObj['allow_credit_policy'] = []
      break
    case null:
      newRightsObj['allow_credit_policy'] = null
      break
    default:
      console.error('Invalid credit policy | UI form')
      return
  }

  return newRightsObj
}

// Shrink just allow_credit_policy object
export const shrinkAllowCreditPolicy = (
  policy: AllowCreditPolicyT,
): CustomerCreditPolicyT => {
  const advancedPolicy = [
    'exclude_risika_scores',
    'exclude_company_types',
    'exclude_level_of_auditor_assistances',
    'exclude_industries',
    'exclude_companies',
    'exclude_persons',
    'exclude_vat',
    'bankruptcy_limit',
    'age_limit',
    'negative_equity_limit',
    'prepaid_phone_number',
    'phone_number_registry',
    'distance_to_ceo',
    'bankruptcy_limit_long',
    'auditor_has_id',
    'payment_remarks_count',
    'payment_remarks_creditors',
    'payment_remarks_total_amount',
    'payment_remarks_max_repayment_time',
    'debt',
    'short_term_debt',
    'profit_loss',
    'number_of_employees',
  ]
  const simplePolicy = [
    'exclude_risika_scores',
    'exclude_company_types',
    'negative_equity_limit',
    'age_limit',
  ]

  if (isEqual(policy, advancedPolicy)) return 'Advanced'
  if (isEqual(policy, simplePolicy)) return 'Simple'
  if (isEqual(policy, null) || isEqual(policy, [])) return 'None'
  return 'Advanced'
  // throw new Error('transformCreditPolicy: invalid customer policy.')
}
