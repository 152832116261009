import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: ({ flex }) => flex,
    flexWrap: ({ wrap }) => wrap,
    flexDirection: ({ direction }) => direction,
    alignItems: ({ items }) => items,
    justifyContent: ({ justify }) => justify,
    '& > *:not(:last-child)': {
      marginBottom: ({ direction, spacing }) =>
        direction === 'column' || direction === 'column-reverse'
          ? theme.spacing(spacing)
          : null,
      marginRight: ({ direction, spacing }) =>
        direction === 'row' || direction === 'row-reverse'
          ? theme.spacing(spacing)
          : null,
    },
  },
}))
