import * as React from 'react'

import { Box, Tab, Tabs, Typography } from '@mui/material'
import Show from './Show'

type Props = {
  items: JSX.Element[]
  tabs: { label: string; index: number }[]
  isLoading?: boolean
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const CustomTabs = ({ tabs, items, isLoading }: Props) => {
  const [value, setValue] = React.useState(0)
  return (
    <Box sx={{ margin: '3rem' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(e, newValue) => setValue(newValue)}>
          {tabs.map((tab, i) => (
            <Tab
              sx={{ marginLeft: i > 0 ? '3rem !important' : '' }}
              disabled={isLoading}
              key={tab.label}
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      {items.map((item, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            <Show when={!isLoading}>{item}</Show>
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default CustomTabs
