import callCmAPI from 'services/helpers/callCmAPI'
import { UserSettingsTableNameEnum } from 'types'

/******************
 * Fetch from API *
 ******************/

type SettingsStructure = {
  keys: string[]
  tableName: UserSettingsTableNameEnum
}

export const getSettingsStructure = (): Promise<SettingsStructure[]> =>
  callCmAPI('/user-settings/structure', {
    method: 'get',
  })
    .then((res) => Promise.resolve(res.data.data)) // :)
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })
