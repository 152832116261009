import callCmAPI from 'services/helpers/callCmAPI'
import { SubscriptionPlan } from 'types'
import { AvailableCountriesCapital } from 'types'
import { shrinkAllowCreditPolicy } from 'utils/transformLegacyRights'
/******************
 * Fetch from API *
 ******************/

type creditPolicyOptions =
  | 'exclude_risika_scores'
  | 'exclude_company_types'
  | 'exclude_level_of_auditor_assistances'
  | 'exclude_industries'
  | 'exclude_companies'
  | 'exclude_persons'
  | 'exclude_vat'
  | 'bankruptcy_limit'
  | 'age_limit'
  | 'negative_equity_limit'
  | 'prepaid_phone_number'
  | 'phone_number_registry'
  | 'distance_to_ceo'
  | 'bankruptcy_limit_long'
  | 'auditor_has_id'

export type CustomerRestrictionsResponse = {
  subscription_plan: SubscriptionPlan
  max_users: number
  caps: {
    exclude_companies: number
    exclude_persons: number
    credit_ratings: number
    credit_reports: number
    lookups: number
    monitoring_count: number
    monitoring_exports: number
    search_exports: number
  }
  counters: {
    exclude_companies?: number
    exclude_persons?: number
    credit_ratings?: number
    credit_reports?: number
    lookups?: number
    monitoring_count?: number
    monitoring_exports?: number
    search_exports?: number
  }
  rights: RightsType
}
type AvailableModels = 'BOHR' | 'NEWTON'
type ScoreModels = { model?: AvailableModels; explanation?: boolean }[]

export type CountrySpecificTypes = {
  [key in AvailableCountriesCapital]: { score_models: ScoreModels }
}
export interface RightsType extends CountrySpecificTypes {
  global: {
    observational_lists: boolean
    credit_override: boolean
    fraud_indicators: boolean
  }
  allow_api: boolean
  allow_basic: boolean
  allow_browser_extension: boolean
  allow_company_export: boolean
  allow_company_search: boolean
  allow_countries: AvailableCountriesCapital[]
  allow_credit: boolean
  allow_credit_policy: creditPolicyOptions[]
  allow_ean: boolean
  allow_financial: boolean
  allow_hierarchy: boolean
  allow_highlights: boolean
  allow_monitoring: boolean
  allow_monitoring_export: boolean
  allow_notes: boolean
  allow_pdf_reports: boolean
  allow_person_export: boolean
  allow_person_search: boolean
  allow_relations: boolean
  search_exports_limit: number
  tier: 1 | 2 | 3 | 4
}

export type CountersType = {
  credit_ratings?: number
  credit_reports?: number
  exclude_companies?: number
  exclude_persons?: number
  lookups?: number
  monitoring_count?: number
  monitoring_exports?: number
  search_exports?: number
}
export type CapsType = {
  credit_ratings: number
  credit_reports: number
  exclude_companies: number
  exclude_persons: number
  lookups: number
  monitoring_count: number
  monitoring_exports: number
  search_exports: number
}

export interface CapsWithExtras extends CapsType {
  max_users: number
  search_exports_limit: number
}
export interface CountersWithExtras extends CountersType {
  max_users: number
  search_exports_limit: number
}

export type CountersCapsType = {
  counters: CountersType
  caps: CapsType
}

export type CountersCapsTypeWithExtras = {
  counters: CountersWithExtras
  caps: CapsWithExtras
}

export const getCustomer = (
  companyId: number,
): Promise<CustomerRestrictionsResponse> =>
  callCmAPI(`/customer/plan/${companyId}`, {
    method: 'get',
  })
    .then(({ data: { data } }) => {
      return {
        // special
        subscription_plan: data.subscription_plan,
        max_users: data.max_users,
        // caps
        caps: {
          exclude_companies: data.caps.credit_policy.exclude_companies,
          exclude_persons: data.caps.credit_policy.exclude_persons,
          credit_ratings: data.caps.credit_ratings,
          credit_reports: data.caps.credit_reports,
          lookups: data.caps.lookups,
          monitoring_count: data.caps.monitoring_count,
          monitoring_exports: data.caps.monitoring_exports,
          search_exports: data.caps.search_exports,
        },
        // counters
        counters: {
          exclude_companies: data.counters.credit_policy.exclude_companies,
          exclude_persons: data.counters.credit_policy.exclude_persons,
          credit_ratings: data.counters.credit_ratings,
          credit_reports: data.counters.credit_reports,
          lookups: data.counters.lookups,
          monitoring_count: data.counters.monitoring_count,
          monitoring_exports: data.counters.monitoring_exports,
          search_exports: data.counters.search_exports,
        },
        // rights
        // allow_api: data.legacy_rights.allow_api,
        // allow_basic: data.legacy_rights.allow_basic,
        // allow_browser_extension: data.legacy_rights.allow_browser_extension,
        // allow_company_export: data.legacy_rights.allow_company_export,
        // allow_company_search: data.legacy_rights.allow_company_search,
        // allow_countries: data.legacy_rights.allow_countries,
        // allow_credit: data.legacy_rights.allow_credit,
        // allow_credit_policy: shrinkAllowCreditPolicy(
        //   data.legacy_rights.allow_credit_policy,
        // ),
        // allow_ean: data.legacy_rights.allow_ean,
        // allow_financial: data.legacy_rights.allow_financial,
        // allow_hierarchy: data.legacy_rights.allow_hierarchy,
        // allow_highlights: data.legacy_rights.allow_highlights,
        // // allow_models: data.legacy_rights.allow_models,
        // allow_monitoring: data.legacy_rights.allow_monitoring,
        // allow_monitoring_export: data.legacy_rights.allow_monitoring_export,
        // allow_notes: data.legacy_rights.allow_notes,
        // allow_pdf_reports: data.legacy_rights.allow_pdf_reports,
        // allow_person_export: data.legacy_rights.allow_person_export,
        // allow_person_search: data.legacy_rights.allow_person_search,
        // allow_relations: data.legacy_rights.allow_relations,
        // search_exports_limit: data.legacy_rights.search_exports_limit,
        // tier: data.legacy_rights.tier,
        rights: {
          ...data.rights,
          ...{
            ...data.legacy_rights,
            allow_credit_policy: shrinkAllowCreditPolicy(
              data.legacy_rights.allow_credit_policy,
            ),
          },
        },
      }
    })
    .catch((err) => err)
