import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import styles from '../styles/app'
import { useUserAuth } from 'contexts/googleAuth'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from 'firebase-config'
import { Show } from 'components'

const SignIn = () => {
  const { isLoading, user } = useUserAuth()
  const [isPossibleIssueWithLogin, setIsPossibleIssueWithLogin] =
    React.useState(false)
  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      hd: 'risika.com',
    })

    signInWithPopup(auth, provider).catch((err) => {
      console.log('Error signing in with Google', err)
    })
  }

  setTimeout(() => {
    setIsPossibleIssueWithLogin(true)
  }, 10000)

  React.useEffect(() => {
    if (!isLoading && !user) {
      googleSignIn()
    }
  }, [user])

  return (
    <Box sx={styles.box}>
      <Stack alignItems="center" sx={styles.topSide}>
        <Box
          onClick={() => googleSignIn()}
          component="img"
          sx={{
            cursor: 'pointer',
            height: 233,
            width: 350,
          }}
          src="/risika-logo-shield-and-text.svg"
        />
      </Stack>
      <Show when={isPossibleIssueWithLogin}>
        <Typography
          variant="h4"
          sx={{
            position: 'absolute',
            top: '4%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          If you don`t see the popup, please click the Risika logo
        </Typography>
      </Show>
    </Box>
  )
}

export default SignIn
