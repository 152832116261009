import { OutlinedSelect } from 'components'

import { TextField } from '@material-ui/core'
import colors from 'styles/colors'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  fields: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    margin: '1rem',
  },
  validationContainer: {
    position: 'relative',
    display: 'flex',
  },
  validationIcon: {
    position: 'absolute',
    width: '3rem !important',
    height: '3rem !important',
    right: '-5rem',
    top: '2.2rem',
  },
}))

const CreateCompany = ({
  handleCreateCustomerChange,
  createCustomerState,
  messages,
  responsibleOptions,
  validate,
}) => {
  const classes = useStyles()
  return (
    <div className="flex flex-col">
      <div className={classes.fields}>
        <OutlinedSelect
          onChange={handleCreateCustomerChange('country')}
          value={createCustomerState.country}
          name="country"
          label={messages[intl.customerManagement('country')]}
          className={classes.textField}
          options={[
            {
              value: 'DK',
              text: 'DK',
            },
            {
              value: 'SE',
              text: 'SE',
            },
            {
              value: 'NO',
              text: 'NO',
            },
            {
              value: 'FI',
              text: 'FI',
            },
          ]}
        />
        <div className={classes.validationContainer}>
          <TextField
            value={createCustomerState.local_id}
            style={{ flex: 1 }}
            name="company_id"
            label={messages[intl.generic('cvr-number')]}
            className={classes.textField}
            onChange={handleCreateCustomerChange('local_id')}
            margin="normal"
            variant="outlined"
            required
          />
          <FontAwesomeIcon
            style={{
              color: validate.local_id
                ? colors?.bg_rating_10
                : colors?.bg_rating_1,
            }}
            className={classes.validationIcon}
            icon={validate.local_id ? faCheckCircle : faTimesCircle}
          />
        </div>
        <TextField
          name="name"
          value={createCustomerState.name}
          label={messages[intl.generic('name')]}
          className={classes.textField}
          onChange={handleCreateCustomerChange('name')}
          margin="normal"
          variant="outlined"
          disabled
        />
        <OutlinedSelect
          className={classes.textField}
          onChange={handleCreateCustomerChange('plan')}
          value={createCustomerState.plan}
          id="customer_plan"
          name="customer_plan"
          label={
            <FormattedMessage id={intl.customerManagement('customer-plan')} />
          }
          options={[
            {
              value: 'FREEMIUM',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-freemium')}
                />
              ),
            },
            {
              value: 'TRIAL',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-trial')}
                />
              ),
            },
            {
              value: 'TRIAL_V2',
              text: 'Trial V2',
            },
            {
              value: 'BASIC',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-basic')}
                />
              ),
            },
            {
              value: 'STANDARD',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-standard')}
                />
              ),
            },
            {
              value: 'PRO',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-pro')}
                />
              ),
            },
            {
              value: 'ENTERPRISE',
              text: (
                <FormattedMessage
                  id={intl.customerManagement('customer-plan-enterprise')}
                />
              ),
            },
          ]}
        />
        <OutlinedSelect
          onChange={handleCreateCustomerChange('responsible_user_id')}
          value={createCustomerState.responsible_user_id}
          id="responsible_user"
          name="responsible_user"
          className={classes.textField}
          label={messages[intl.customerManagement('responsible-person')]}
          options={responsibleOptions}
        />
      </div>
    </div>
  )
}

export default CreateCompany
