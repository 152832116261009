import { TextField } from '@material-ui/core'

import intl from 'localization/components'
const Email = ({ messages, props }) => {
  const { value, onChange } = props

  return (
    <TextField
      style={{ width: '100%' }}
      value={value}
      label={messages[intl.companyInfo('email')]}
      onChange={(values) => {
        return onChange(values.target.value)
      }}
    />
  )
}

export default Email
