import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { createEconomicIntegration } from 'services/cmAPI/createEconomicIntegration'

export function useCreateEconomicIntegration() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createEconomicIntegration, {
    onSuccess: async () => {
      enqueueSnackbar('Creating integration succeeded', { variant: 'success' })
      queryClient.invalidateQueries('useGetIntegrationsForCustomer')
    },
    onError: (err) => {
      enqueueSnackbar('Creating integration failed', { variant: 'error' })
    },
  })
}
