import { Button, Grid, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { themeNew } from 'styles/theme-new'
import { ExceptionType, NewValue } from '../../Restrictions.types'
function Exception({
  exception,
  handleRestore,
}: {
  exception: ExceptionType
  handleRestore: (exception: ExceptionType) => void
}) {
  const { currValue, defaultValue, label } = exception

  const renderValue = (value: NewValue) => {
    switch (typeof value) {
      case 'string':
        return value
      case 'number':
        return value
      case 'boolean':
        return value ? 'Yes' : 'No'
      case 'object':
        // @ts-ignore
        if (value?.[0]?.model) {
          return value.map((x) => x.model).join(', ')
        }
        return value.join(', ')
      case 'undefined':
        return 'No value'
      default:
        return ''
    }
  }

  return (
    <>
      <Grid item xs={3}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5">{renderValue(currValue)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5"> {renderValue(defaultValue)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <ThemeProvider theme={themeNew}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleRestore(exception)}
            startIcon={<SettingsBackupRestoreIcon />}
          >
            Restore
          </Button>
        </ThemeProvider>
      </Grid>
    </>
  )
}

export default Exception
