import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { Skeleton } from '@mui/material'

type Props = {
  title: string,
  variant?: string,
  expanded?: boolean,
  children: JSX.Element,
  isLoading?: boolean,
}

const useStyles = makeStyles((theme) => ({
  awSummary: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  awAccordion: {
    background: theme.palette.grey[100],
  },
}))

/**
 * Takes a component and wraps it into MUI Accordion
 *
 * @param title <string>
 * @param children <Component />
 * @returns component
 */

const AccordionWrapper = ({
  title,
  variant = 'h4',
  children,
  expanded = false,
  isLoading,
}: Props) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(expanded)

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded((prev) => !prev)}
      className={classes.awAccordion}
    >
      <AccordionSummary
        className={classes.awSummary}
        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '4rem', width: '15rem' }} />
        ) : (
          <Typography variant={variant}>{title}</Typography>
        )}
      </AccordionSummary>
      {!isLoading ? <AccordionDetails>{children}</AccordionDetails> : null}
    </Accordion>
  )
}

export default AccordionWrapper
