import React from 'react'
import { Typography, capitalize } from '@material-ui/core'
import { Button } from 'components'
import colors from 'styles/colors'
import { useResendEmail } from 'services/queries/useResendEmail'

const RolesColumn = ({ role, isVerified, email }) => {
  const { refetch: resendEmail } = useResendEmail(email)
  return (
    <>
      {isVerified ? (
        <div style={{ display: 'flex' }}>
          <Typography style={{ marginRight: '0.3rem' }}>
            {capitalize(role)}
          </Typography>
        </div>
      ) : (
        <Button
          onClick={() => resendEmail()}
          style={{ backgroundColor: colors?.risikaContrast }}
        >
          Resend email
        </Button>
      )}
    </>
  )
}

export default RolesColumn
