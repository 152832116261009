import { useMutation, useQueryClient } from 'react-query'
import { createCustomer } from 'services/cmAPI/createCustomer'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'

export function useCreateCustomer() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(createCustomer, {
    onSuccess: async ({ data }) => {
      queryClient.invalidateQueries('getCustomers')

      enqueueSnackbar('Create user success', { variant: 'success' })
      await queryClient.refetchQueries('getCustomers')
      navigate(`/customer/${data.customer.id}`)
    },
    onError: (err) => {
      enqueueSnackbar('Create user failed', { variant: 'error' })
      console.error('createUsers failed: ', err)
    },
  })
}
