import { createTheme } from '@material-ui/core/styles'

export const userTableTheme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: 'inherit',
      },
    },
    MuiSvgIcon: {
      root: {
        width: '2rem',
        height: '2rem',
      },
    },
    MuiIcon: {
      root: {
        width: 'auto',
        height: 'auto',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.3rem',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#fafafa',
        zIndex: 0,
      },
    },
    MuiButtonBase: {
      root: {
        fontSize: '1.6rem',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 'inherit',
      },
      h6: {
        fontSize: '2.5rem',
      },
      caption: {
        fontSize: '1.4rem',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 'inherit',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 'inherit',
      },
    },
    MuiFormControl: {
      root: {
        '@media screen and (max-width: 819px)': {
          paddingRight: '0 !important',
        },
      },
    },
    MuiToolbar: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media screen and (max-width: 819px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
    },
  }, // Overrides
})
