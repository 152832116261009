import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.css'
import { AuthContextProvider } from './contexts/googleAuth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { IntlProvider } from 'react-intl'
import locale from 'localization'
import { themeNew, themeNew5 } from 'styles/theme-new'
import { ThemeProvider } from '@material-ui/styles'
import { ThemeProvider as ThemeProvider5 } from '@mui/material'

const language = 'en'
const queryClient = new QueryClient()
queryClient.setDefaultOptions({
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeNew}>
      <ThemeProvider5 theme={themeNew5}>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <IntlProvider
              locale={language}
              messages={locale[language]}
              key={language}
            >
              <ReactQueryDevtools />
              <App />
            </IntlProvider>
          </QueryClientProvider>
        </AuthContextProvider>
      </ThemeProvider5>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
