import { Stack } from '@mui/material'
import CopyApiToken from './CopyApiToken'
import RevokeAccess from './RevokeAccess'
import AddUsers from './AddUsers'
import DeleteCompany from './DeleteCompany'
import { useParams } from 'react-router-dom'
import { Show } from 'components'
import ExtendTrial from './ExtendTrial'
import EconomicIntegration from './EconomicIntegration'

const ColorfulManageGroup = ({ customerData }) => {
  const { id: selectedCustomer } = useParams()
  return (
    <Stack>
      <Stack direction="row" spacing={2}>
        <ExtendTrial customerData={customerData} />
        <CopyApiToken customerData={customerData} />
        <AddUsers selectedCustomer={selectedCustomer} />
        <RevokeAccess
          customerData={customerData}
          selectedCustomer={selectedCustomer}
        />
        <DeleteCompany
          selectedCustomer={selectedCustomer}
          customerName={customerData.name}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <EconomicIntegration />
      </Stack>
    </Stack>
  )
}

export default ColorfulManageGroup
