import { Delete } from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material'

type SettingsKeysProps = {
  keys: string[]
  onDelete: (key: string) => void
}

const SettingsKeys = (props: SettingsKeysProps) => {
  const { keys, onDelete } = props

  return (
    <List dense={true}>
      {keys.map((key) => {
        return (
          <ListItem
            key={key}
            secondaryAction={
              <Tooltip
                title="We would generally discourage this action!"
                placement="top"
              >
                <IconButton
                  edge="end"
                  onClick={() => onDelete(key)}
                  aria-label="delete"
                >
                  <Delete sx={{ width: '2rem', height: '2rem' }} />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText primary={key} />
          </ListItem>
        )
      })}
    </List>
  )
}

export default SettingsKeys
