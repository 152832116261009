import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  scrollable: {
    width: 'max-content',
  },
  scrollableContainer: {
    maxWidth: '100%',
    scrollSnapType: 'x mandatory',
    webkitOverflowScrolling: 'touch',
    overflowX: 'scroll',
  },
}))
