import { useQuery } from 'react-query'
import { getResendEmail } from 'services/cmAPI/getResendEmail'

export function useResendEmail(customerId) {
  return useQuery(['getResendEmail'], () => getResendEmail(customerId), {
    onError: (err) => {
      console.error('getResendEmail failed: ', err)
    },
    enabled: false,
  })
}
