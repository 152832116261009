import React from 'react'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SelectedCustomer from './SelectedCustomer'
import CustomersTable from './CustomersTable'
import MainHeader from './MainHeader'
import { Box } from '@mui/material'

const snackbar = {
  '& .SnackbarContent-root': {
    fontSize: '2rem',
  },
}

const Home = () => {
  return (
    <Box>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={snackbar}
      >
        <BrowserRouter>
          <MainHeader />
          <Routes>
            <Route path="/" element={<CustomersTable />} />
            <Route path="/customer/:id" element={<SelectedCustomer />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </Box>
  )
}

export default Home
