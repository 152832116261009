import callCmAPI from 'services/helpers/callCmAPI'
import { CreateCustomerDataT } from 'types'

export const createCustomer = (data: CreateCustomerDataT) => {
  return callCmAPI(`/customer`, {
    method: 'post',
    data,
  }).then((res) => {
    return res.data
  })
}
