import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  multiUserContainer: {
    border: '1px dotted black',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    borderRadius: '0.25rem',
  },
  userIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  validationContainer: {
    position: 'relative',
    display: 'flex',
  },
  validationIcon: {
    position: 'absolute',
    width: '3rem !important',
    height: '3rem !important',
    right: '-5rem',
    top: '2.7rem',
  },
  validationText: {
    position: 'absolute',
    // width: '3rem !important',
    height: '3rem !important',
    top: '7rem',
    right: '0',
    textAlign: 'right',
  },
}))
