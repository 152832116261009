import callCmAPI from 'services/helpers/callCmAPI'
import { RisikaUserT, WebApi2Response } from 'types'

/******************
 * Fetch from API *
 ******************/

export const getRisikaUsers = (): Promise<WebApi2Response<RisikaUserT[]>> =>
  callCmAPI(`/user/risika`, {
    method: 'get',
  })
    .then((res) => res.data)
    .catch((err) => err)
