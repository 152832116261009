import { useQuery } from 'react-query'
import { getCustomers } from 'services/cmAPI/getCustomers'
import { useSnackbar } from 'notistack'

export function useCustomers() {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(['getCustomers'], () => getCustomers(), {
    enabled: Boolean(localStorage.getItem('FB_ACCESS_TOKEN')),
    onError: (err) => {
      enqueueSnackbar('Cannot get customers list', { variant: 'error' })
      console.error('getCustomers failed: ', err)
    },
  })
}
