import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { updateCustomerSingle } from 'services/cmAPI/updateCustomerSingle'

export function useUpdateCustomerSingle() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateCustomerSingle, {
    onMutate: async ({ companyId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('getCustomerPlan' + companyId)
      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData('getUserSettings')

      // Return a context object with the snapshotted value
      return { previousSettings }
    },
    onSuccess: async ({ data }) => {
      const customerId = data.id
      await queryClient.invalidateQueries('getCustomerPlan' + customerId)
      enqueueSnackbar('User is updated', { variant: 'success' })
    },
    onError: (err) => {
      enqueueSnackbar('Failed to update', { variant: 'error' })
      console.error('updateCustomer failed: ', err)
    },
  })
}
