import React, { useEffect } from 'react'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import { useNavigate } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Typography,
  Paper,
} from '@mui/material'
import { theme5 } from 'styles/theme5'
const ReactTable = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    // used in pagination
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useSortBy,
    usePagination,
  )
  const navigate = useNavigate()

  useEffect(() => {
    setPageSize(25)
    // TODO: What the fuck is this?
  }, [data])

  const handleClickOnRow = (customer) => {
    navigate(`/customer/${customer.id}`)
  }

  return (
    <TableContainer
      sx={{ height: '100%', borderRadius: '0px' }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} {...getTableProps()}>
        <TableHead sx={{ background: theme5.palette.grey[100] }}>
          {headerGroups.map((headerGroup) => {
            return (
              <TableRow
                key={Math.random()}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    key={column.Header}
                    sx={{
                      minWidth: `${column.minWidth}px`,
                      position: 'relative',
                      height: '100px',
                    }}
                  >
                    <Box sx={{ height: '100%' }}>
                      <Box
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        <Typography variant="h4" pb={3}>
                          {column.render('Header')}
                        </Typography>
                      </Box>

                      <Box>{column.Filter && column.render('Filter')}</Box>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, ind) => {
            prepareRow(row)
            return (
              <TableRow
                key={row.original.name}
                {...row.getRowProps()}
                onClick={() => handleClickOnRow(row.original)}
                sx={{
                  bgcolor:
                    ind % 2
                      ? theme5.palette.grey[300]
                      : theme5.palette.grey[100],
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      key={cell.column.header}
                      {...cell.getCellProps()}
                      sx={{ fontSize: 15 }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
          <TableRow sx={{ height: '70px' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={(e, newPage) => gotoPage(newPage)}
              onRowsPerPageChange={(e) => setPageSize(+e.target.value)}
              showFirstButton={true}
              showLastButton={true}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& li': {
                      display: 'block',
                      fontSize: '1.8rem',
                      width: '80px',
                      textAlign: 'center',
                    },
                  },
                },
              }}
              sx={{
                '&, &  *': {
                  fontSize: '1.8rem !important',
                },
                '&  div': {
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                },
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReactTable
