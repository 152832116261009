import { CircularProgress } from '@material-ui/core'
import colors from 'styles/colors'

type ConditionallyRenderProps = {
  when: any,
  otherwise?: any,
  condition: boolean,
  isLoading?: boolean,
}

// When you add isLoading if the condition is false it shows a spinner.
const ConditionallyRender = ({
  when,
  otherwise = null,
  condition,
  isLoading,
}: ConditionallyRenderProps) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ color: colors?.risikaContrast }} />
      ) : condition ? (
        when
      ) : (
        otherwise
      )}
    </>
  )
}

export default ConditionallyRender
