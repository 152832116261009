import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  iconChecked: {
    color: 'lightgreen',
  },
  iconNotChecked: {
    cursor: 'pointer',
  },
}))
