import intl from '../components'

const messages = {
  [intl.advancedFilters('above')]: 'Above',
  [intl.advancedFilters('below')]: 'Below',
  [intl.advancedFilters('company-type')]: 'Company type',
  [intl.advancedFilters('count')]:
    '{count, plural, =0 {No companies found} one {{count} company found} other {{count} companies found}}',
  [intl.advancedFilters('employee-count')]: 'Employee count',
  [intl.advancedFilters('equity')]: 'Equity',
  [intl.advancedFilters('financial-data')]: 'Financial data',
  [intl.advancedFilters('hide-ad-protected')]: 'Hide advertisement protected',
  [intl.advancedFilters('industry-group')]: 'Industry group',
  [intl.advancedFilters('industry-specific')]: 'Specific industry',
  [intl.advancedFilters('municipality')]: 'Municipality',
  [intl.advancedFilters('region')]: 'Region',
  [intl.advancedFilters('reset-search')]: 'Reset search',
  [intl.advancedFilters('risika-score')]: 'Risika Score',
  [intl.advancedFilters('search-company-type')]: 'Search for company type',
  [intl.advancedFilters('search-industry-group')]: 'Search for industry group',
  [intl.advancedFilters('search-industry-specific')]:
    'Search for specific industry',
  [intl.advancedFilters('search-municipality')]: 'Search for municipality',
  [intl.advancedFilters('search-region')]: 'Search for region',
  [intl.advancedFilters('select-columns')]: 'Select columns to export',
  [intl.advancedFilters('show-inactive')]: 'Show inactive',
  [intl.advancedFilters('title')]: 'Filtering',
  [intl.advancedFilters('illegal-character')]:
    'Invalid {count, plural, one {character} other {characters}} "{char}"! The only allowed characters are "0-9", "-" and ","',
  [intl.advancedFilters('advanced-zipcodes-helper-text')]:
    'Allowed format: 1000, 2000-2500',
  [intl.advancedFilters('values-or-ranges')]: 'Write single values or ranges',
  [intl.advancedFilters('show-all')]:
    '{count, plural, =0 {No industries selected} one {Show {count} industry} other {Show {count} industries}}',
  [intl.advancedFilters('hide-all')]:
    '{count, plural, =0 {No industries selected} one {Hide {count} industry} other {Hide {count} industries}}',
  [intl.advancedFilters('search-for-industry')]: 'Search for an industry',

  [intl.advancedSearch('add-filters')]: 'Add Filters',
  [intl.advancedSearch('all-filters')]: 'All Filters',
  [intl.advancedSearch('apply-filters')]: 'Apply Filters',
  [intl.advancedSearch('assets')]: 'Assets',
  [intl.advancedSearch('auditor')]: 'Auditor',
  [intl.advancedSearch('between')]: 'Between',
  [intl.advancedSearch('cash')]: 'Cash',
  [intl.advancedSearch('tree-industry')]: 'Industry',
  [intl.advancedSearch('company-info')]: 'Company Info',
  [intl.advancedSearch('company_status')]: 'Company status',
  [intl.advancedSearch('company_type')]: 'Company Type',
  [intl.advancedSearch('credit')]: 'Credit',
  [intl.advancedSearch('current_financial_assets')]: 'Current Financial Assets',
  [intl.advancedSearch('date_of_incorporation')]: 'Date of Incorporation',
  [intl.advancedSearch('employees')]: 'Number of Employees (interval)',
  [intl.advancedSearch('employees_number')]: 'Number of employees',
  [intl.advancedSearch('enter-percentage')]: 'Enter value in percentage (%)',
  [intl.advancedSearch('enter-value')]: 'Enter a value',
  [intl.advancedSearch('equity')]: 'Equity',
  [intl.advancedSearch('excludes')]: 'Excludes',
  [intl.advancedSearch('financial-data-section')]: 'Financial data',
  [intl.advancedSearch('financial-data-select-all')]:
    'Select all financial data',
  [intl.advancedSearch('financial-numbers')]: 'Financial Numbers',
  [intl.advancedSearch('financial-ratios')]: 'Key Figures',
  [intl.advancedSearch('fixed_assets')]: 'Fixed Assets',
  [intl.advancedSearch('general-data-section')]: 'General data',
  [intl.advancedSearch('general-data-select-all')]: 'Select all general data',
  [intl.advancedSearch('gross_result')]: 'Gross Result',
  [intl.advancedSearch('has_auditor')]: 'Auditor',
  [intl.advancedSearch('has_email')]: 'Has Email',
  [intl.advancedSearch('has_phone_number')]: 'Has Phone Number',
  [intl.advancedSearch('has_webpage')]: 'Has webpage',
  [intl.advancedSearch('hide_advertisement_protected')]:
    'Hide Advertisement Protected',
  [intl.advancedSearch('hide_holding_company')]: 'Hide Holding Companies',
  [intl.advancedSearch('includes')]: 'Includes',
  [intl.advancedSearch('industry_group')]: 'Industry Group',
  [intl.advancedSearch('liquidity')]: 'Liquidity',
  [intl.advancedSearch('load-filters')]: 'Saved Filters',
  [intl.advancedSearch('load-filters-title')]: 'Load one of your saved filters',
  [intl.advancedSearch('match')]: 'Match',
  [intl.advancedSearch('municipality')]: 'Municipality',
  [intl.advancedSearch('no-filters')]: 'No filters selected',
  [intl.advancedSearch('no-saved-filters')]: "You don't have any saved filters",
  [intl.advancedSearch('over')]: 'Over',
  [intl.advancedSearch('placeholder-auditor')]:
    'Enter CVR or name of the auditor',
  [intl.advancedSearch('profit_loss')]: 'Profit Loss',
  [intl.advancedSearch('range-from')]: 'From',
  [intl.advancedSearch('range-to')]: 'To',
  [intl.advancedSearch('region')]: 'Region',
  [intl.advancedSearch('reset')]: 'Reset',
  [intl.advancedSearch('results-time-elapsed')]:
    '{count, plural, one {# result} other {# results}} in {time, plural, other {#}} seconds',
  [intl.advancedSearch('return_on_assets')]: 'Return on Assets',
  [intl.advancedSearch('save')]: 'Save',
  [intl.advancedSearch('save-filter-label')]: 'Provide a name for your filter',
  [intl.advancedSearch('save-filter-title')]: 'Save your filter for future use',
  [intl.advancedSearch('score')]: 'Risika Score',
  [intl.advancedSearch('search-filter')]: 'Search for your filter',
  [intl.advancedSearch('select-all')]: 'Select All',
  [intl.advancedSearch('share_capital')]: 'Share Capital',
  [intl.advancedSearch('show_inactive')]: 'Show Inactive',
  [intl.advancedSearch('snackbar-delete-filters-failed')]:
    'Something went wrong when trying to delete your filter',
  [intl.advancedSearch('snackbar-delete-filters-success')]:
    'Your filter was deleted',
  [intl.advancedSearch('snackbar-saved-filters-failed')]:
    'Something went wrong when trying to save your filter',
  [intl.advancedSearch('snackbar-saved-filters-success')]:
    'Your filter was saved',
  [intl.advancedSearch('snackbar-update-filters-failed')]:
    'Something went wrong when trying to update your filter',
  [intl.advancedSearch('snackbar-update-filters-success')]:
    'Your filter was updated',
  [intl.advancedSearch('solidity')]: 'Solidity',
  [intl.advancedSearch('specific_industry')]: 'Specific Industry',
  [intl.advancedSearch('status_valid_from')]: 'Company status from',
  [intl.advancedSearch('total_debt')]: 'Total debt',
  [intl.advancedSearch('under')]: 'Under',
  [intl.advancedSearch('zipcode')]: 'Zip Code',
  [intl.advancedSearch('duration')]: 'Duration',
  [intl.advancedSearch('period')]: 'Period',

  [intl.annualReportInformation('approvalDate')]: 'Approval date',
  [intl.annualReportInformation('averageNumberOfEmployees')]:
    'Average number of employees',
  [intl.annualReportInformation('classOfReportingEntity')]:
    'Class of reporting entity',
  [intl.annualReportInformation('company_name')]: 'Auditor',
  [intl.annualReportInformation('description')]: 'Auditor description',
  [intl.annualReportInformation('get-auditor-information')]:
    'Get auditor information',
  [intl.annualReportInformation('ifrs')]: 'IFRS',
  [intl.annualReportInformation('name')]: 'Auditor name',
  [intl.annualReportInformation('no_information')]: 'No information',
  [intl.annualReportInformation('show_less')]: 'Show less',
  [intl.annualReportInformation('show_more')]: 'Show more',
  [intl.annualReportInformation('title')]: 'Annual report information',
  [intl.annualReportInformation('type_of_assistance')]: 'Type of assistance',
  [intl.auditorInfo('auditors_report')]: "Auditor's Report",
  [intl.auditorInfo('auditors_report_on_extended_review')]:
    "Auditor's Report On Extended Review",
  [intl.auditorInfo('auditors_reports_(other_non-assurance_reports)')]:
    "Auditor's Reports (Other Non-Assurance Reports)",
  [intl.auditorInfo('company_name')]: 'Company Name',
  [intl.auditorInfo('name_of_auditor')]: 'Name of Auditor',
  [intl.auditorInfo('no_auditor_assistance')]: 'No Auditor Assistance',
  [intl.auditorInfo('the_independent_auditors_reports_(review)')]:
    "The Independent Auditor's Reports (Review)",
  [intl.auditorInfo('type_of_assistance')]: 'Type of Assistance',
  [intl.auth('account-already-verified')]: 'Your account is already verified',
  [intl.auth('account-deactivated')]: 'You account has been deactivated',
  [intl.auth('account-deactivated-subtitle')]:
    'You should have received an email with instructions on how to re-activate your account.',
  [intl.auth('account-deactivated-title')]: 'Your account is deactivated',
  [intl.auth('account-verified-failed')]:
    'We had some trouble verifying your account',
  [intl.auth('account-verified-failed-subtitle')]:
    'Please reach out to us on the chat so we can make sure you get access immediately.',
  [intl.auth('account-verified-failed-title')]: 'Issues with verifying account',
  [intl.auth('account-verified-success')]:
    'Your account has been verified, you can now log in',
  [intl.auth('already-have-an-account')]: 'Already have an account?',
  [intl.auth('change-password')]: 'Change password',
  [intl.auth('change-password-failed')]:
    'Seems like there was a problem changing your password',
  [intl.auth('change-password-success')]:
    'You can now log in with your new password!',
  [intl.auth('change-password-title')]: 'Change your password',
  [intl.auth('company-already-exists')]: 'Company has already been registered',
  [intl.auth('company-label')]: 'Company',
  [intl.auth('company-placeholder')]: 'Search for your company',
  [intl.auth('confirm-new-password-label')]: 'Confirm new password',
  [intl.auth('confirm-new-password-placeholder')]: 'Confirm your new password',
  [intl.auth('contact-us')]: 'Contact us',
  [intl.auth('do-not-think-email-received')]:
    "Can't find the email in your inbox?",
  [intl.auth('email')]: 'Email',
  [intl.auth('email-already-exists')]:
    'This email is already registered. Contact us at contact@risika.dk',
  [intl.auth('email-label')]: 'Email',
  [intl.auth('email-placeholder')]: 'me@company.com',
  [intl.auth('email-sent-to')]: 'We sent the email to',
  [intl.auth('email-should-have-been-resend')]:
    'You should have received a new email',
  [intl.auth('finalize-account')]: 'Finalize account',
  [intl.auth('first-name-label')]: 'First name',
  [intl.auth('first-name-placeholder')]: 'Your first name',
  [intl.auth('forgot-password')]: 'Forgot your password?',
  [intl.auth('forgot-password-email-subtitle')]:
    "If you can't see the email in your inbox, please check your spam folder.",
  [intl.auth('forgot-password-email-title')]: 'We have sent you an email!',
  [intl.auth('forgot-password-failed')]:
    'Sorry, it looks like something wen wrong',
  [intl.auth('forgot-password-subtitle')]:
    'Enter your email and we will send you a link that lets you change your password',
  [intl.auth('forgot-password-title')]: 'Forgot your password?',
  [intl.auth('get-me-in')]: 'Get me in',
  [intl.auth('get-started-free')]: 'Get started for free →',
  [intl.auth('go-back')]: 'Go back',
  [intl.auth('go-back-to-log-in')]: 'Go back to log in',
  [intl.auth('invalid-local-id')]: 'Please use a valid CVR number',
  [intl.auth('last-name-label')]: 'Last name',
  [intl.auth('last-name-placeholder')]: 'Your last name',
  [intl.auth('log-in')]: 'Log in',
  [intl.auth('login-header')]: 'Log in to your account',
  [intl.auth('login-to-your-account')]: 'Log in to your account →',
  [intl.auth('min-password-length')]: 'At least 8 characters',
  [intl.auth('new-email-sent')]: 'We have sent a new email',
  [intl.auth('new-password-label')]: 'New password',
  [intl.auth('new-password-placeholder')]: 'Your new password',
  [intl.auth('no-account-yet')]: "Don't have an account yet?",
  [intl.auth('password-label')]: 'Password',
  [intl.auth('password-placeholder')]: 'Your password',
  [intl.auth('personal-information')]: 'Personal information',
  [intl.auth('phone')]: 'Phone',
  [intl.auth('phone-number-label')]: 'Phone number',
  [intl.auth('please-use-work-mail')]: 'Please use your work mail',
  [intl.auth('register-account')]: 'Create account',
  [intl.auth('register-account-subtitle')]: 'All fields are required',
  [intl.auth('register-header')]: 'Create your account',
  [intl.auth('remembered-your-password')]: 'Remembered your password?',
  [intl.auth('resend-email')]: 'Resend email',
  [intl.auth('send-email')]: 'Send email',
  [intl.auth('session-expired')]: 'Your session expired, please log in again.',
  [intl.auth('showcase-subtitle')]:
    'Risika helps B2B companies create profitable growth and minimize financial risk. Start today with credit assessments, intelligent monitoring of customers and financial recommendations.',
  [intl.auth('showcase-title')]: 'Turn risk awareness into profitable growth',
  [intl.auth('sso-login')]: 'Log in with Microsoft',
  [intl.auth('terms-of-service')]: 'Terms of Service',
  [intl.auth('understand-and-accept')]: 'I understand and accept the',
  [intl.auth('user-registered-subtitle')]:
    'We have sent you an email to verify your account. As soon as you have done that, you can start using Risika.',
  [intl.auth('user-registered-title')]: 'Verify your account',
  [intl.auth('user-with-email-exists')]:
    'There is already a user with that email',
  [intl.auth('work-email-label')]: 'Work mail',
  [intl.auth('wrong-email-or-password')]: 'Wrong email or password!',
  [intl.auth('your-company')]: 'Your company',

  [intl.companyHierarchy('equity')]: 'Equity',
  [intl.companyHierarchy('local-id')]: 'Local ID',
  [intl.companyHierarchy('risika-score')]: 'Risika score',
  [intl.companyHierarchy('CEO')]: 'CEO',
  [intl.companyHierarchy('profit-loss')]: 'Profit loss',
  [intl.companyHierarchy('accessories')]: 'Accessories',
  [intl.companyHierarchy('collapse-all')]: 'Collapse',
  [intl.companyHierarchy('coming-soon')]: 'Coming soon',
  [intl.companyHierarchy('company_information')]: 'Company information',
  [intl.companyHierarchy('credit-max:')]: 'Recommended credit limit:',
  [intl.companyHierarchy('d3-child-name')]: 'Subsidiary to',
  [intl.companyHierarchy('detailed-view')]: 'Detailed view',
  [intl.companyHierarchy('equity')]: 'Equity',
  [intl.companyHierarchy('equity:')]: 'Equity:',
  [intl.companyHierarchy('expand-all')]: 'Expand all {number} companies',
  [intl.companyHierarchy('expand-all-d3')]: 'Expand',
  [intl.companyHierarchy('header')]: 'Company hierarchy',
  [intl.companyHierarchy('list-view')]: 'List view',
  [intl.companyHierarchy('tree-view')]: 'Tree view',
  [intl.companyHierarchy('display-props')]: 'Display properties',

  [intl.companyHierarchy('listed-in')]: 'Listed in: ',
  [intl.companyHierarchy('local-id:')]: 'Local id:',
  [intl.companyHierarchy('monitor-all')]: 'Monitor all',
  [intl.companyHierarchy('name')]: 'Name',
  [intl.companyHierarchy('no-credit-score')]: 'The company has no credit score',
  [intl.companyHierarchy('no-legal-owners')]: 'No legal owners',
  [intl.companyHierarchy('no-real-owners')]: 'No real owners',
  [intl.companyHierarchy('ownership')]: 'Ownership',
  [intl.companyHierarchy('profit_loss')]: 'Profit loss',
  [intl.companyHierarchy('profit_loss:')]: 'Profit loss:',
  [intl.companyHierarchy('relations')]: 'Relations',
  [intl.companyHierarchy('save-view-description')]:
    'Here you can save the hierarchy view for the current session. Once you log out all your saved views will be gone.',
  [intl.companyHierarchy('save-view-title')]: 'Save hierarchy view',
  [intl.companyHierarchy('select-list')]: 'Select a list',
  [intl.companyHierarchy('sort_by')]: 'Sort by',
  [intl.companyHierarchy('ownership')]: 'Ownership',
  [intl.companyHierarchy('risika-score')]: 'Risika score',
  [intl.companyHierarchy('CEO')]: 'CEO',
  [intl.companyHierarchy('profit-loss')]: 'Profit loss',
  [intl.companyHierarchy('equity')]: 'Equity',
  [intl.companyHierarchy('credit-policy')]: 'Credit policy',
  [intl.companyHierarchy('monitor')]: 'Monitor',

  [intl.companyHighlights('click-for-more')]: 'click for more',
  [intl.companyHighlights('date-of-bancrupcy')]: ' Since {date}',
  [intl.companyHighlights('title')]: 'Company highlights',
  [intl.companyHighlights('profit-loss')]: 'Profit loss',
  [intl.companyInfo('ad-protection')]: 'Advertisement protection',
  [intl.companyInfo('ad-protection-allowed')]:
    'Allowed to contact this company with unsolicited advertising.',
  [intl.companyInfo('ad-protection-denied')]:
    'This company has refused to be contacted with unsolicited advertising.',
  [intl.companyInfo('add-internal-id-label')]: 'Internal ID',
  [intl.companyInfo('add-internal-id-title')]: 'Add internal ID',
  [intl.companyInfo('address')]: 'Address',
  [intl.companyInfo('auditors')]: 'Auditors',
  [intl.companyInfo('bank')]: 'Bank',
  [intl.companyInfo('company-id')]: 'Local company ID',
  [intl.companyInfo('company-info-re-arrange')]:
    'Tailor the General company information to your needs by dragging the fields below!',
  [intl.companyInfo('company-name')]: 'Company name',
  [intl.companyInfo('company-name-required')]: 'Company name *',
  [intl.companyInfo('company-type')]: 'Company type',
  [intl.companyInfo('date-of-establishment')]: 'Date of establishment',
  [intl.companyInfo('date-of-incorporation')]: 'Established before 1900',
  [intl.companyInfo('ean')]: 'EAN Number',
  [intl.companyInfo('edit-internal-id-label')]: 'Internal ID',
  [intl.companyInfo('edit-internal-id-title')]: 'Edit internal ID',
  [intl.companyInfo('email')]: 'Email',
  [intl.companyInfo('employees')]: 'Employees',
  [intl.companyInfo('financial_year')]: 'Financial year',
  [intl.companyInfo('founders_auditors')]: 'Founders',
  [intl.companyInfo('fte')]: 'FTE (Full Time Employees)',
  [intl.companyInfo('industry')]: 'Industry codes',
  [intl.companyInfo('industry')]: 'Industry',
  [intl.companyInfo('internal-id')]: 'Internal ID',
  [intl.companyInfo('invalid')]: 'Invalid',
  [intl.companyInfo('land_value')]: 'Land value',
  [intl.companyInfo('listed')]: 'Listed',
  [intl.companyInfo('number-of-employees')]: 'Number of employees',
  [intl.companyInfo('phone')]: 'Phone',
  [intl.companyInfo('powers-to-bind')]: 'Powers to bind',
  [intl.companyInfo('production-units')]: 'Production units',
  [intl.companyInfo('property_id')]: 'Property ID',
  [intl.companyInfo('property_type')]: 'Property type',
  [intl.companyInfo('property_value')]: 'Property value',
  [intl.companyInfo('purpose')]: 'Purpose',
  [intl.companyInfo('registered-for-vat')]: 'Registered for VAT',
  [intl.companyInfo('secondary-names')]: 'Secondary names',
  [intl.companyInfo('share-capital')]: 'Share capital',
  [intl.companyInfo('source')]: 'Source',
  [intl.companyInfo('tinglysning')]: 'Property',
  [intl.companyInfo('title')]: 'General Company Information',
  [intl.companyInfo('valid-from')]: 'Valid from',
  [intl.companyInfo('valid-to')]: 'Valid to',
  [intl.companyInfo('valuation_date')]: 'Valuation date',
  [intl.companyInfo('webpage')]: 'Website',
  [intl.companyRoles('advanced-view')]: 'Advanced',
  [intl.companyRoles('analysis-of-bankruptcies')]: 'Analysis of bankruptcies',
  [intl.companyRoles('before-1987')]: 'Before 1987',
  [intl.companyRoles('board-of-directors')]: 'Board of directors',
  [intl.companyRoles('get-overview')]: 'Get overview',
  [intl.companyRoles('hide-historical-data')]: 'Hide historical data',
  [intl.companyRoles('historical-management')]: 'Historical management',
  [intl.companyRoles('history-hide')]: 'Hide history',
  [intl.companyRoles('history-show')]: 'Show history',
  [intl.companyRoles('legal-owners')]: 'Legal owners',
  [intl.companyRoles('minimize-table')]: 'Minimize table',
  [intl.companyRoles('month')]: '1 month',
  [intl.companyRoles('months')]: '{months} months',
  [intl.companyRoles('no-board-of-directors')]: 'No board of directors found',
  [intl.companyRoles('no-information')]: 'Information unavailable',
  [intl.companyRoles('no-management')]: 'No management found',
  [intl.companyRoles('no-owners')]: 'No owners',
  [intl.companyRoles('other-positions')]: ' see more...',
  [intl.companyRoles('real-owners')]: 'Beneficial owners',
  [intl.companyRoles('see-hierarchy')]: 'See company hierarchy',
  [intl.companyRoles('see-historical-data')]: 'See historical data',
  [intl.companyRoles('shares')]: 'shares',
  [intl.companyRoles('show-all')]: 'Show all',
  [intl.companyRoles('show-full-table')]: 'Show full table',
  [intl.companyRoles('show-less')]: 'Show less',
  [intl.companyRoles('simple-view')]: 'Simple',
  [intl.companyRoles('subsidiaries')]: 'Subsidiaries',
  [intl.companyRoles('title')]: 'Management',
  [intl.companyRoles('title-owners')]: 'Owners',
  [intl.companyRoles('total')]: 'Total: ',
  [intl.companyRoles('total-owners')]: 'Total owners',
  [intl.companyRoles('year')]: '1 year ',
  [intl.companyRoles('years')]: '{years} years ',
  [intl.confirmAction('agree')]: 'Agree',
  [intl.confirmAction('cancel')]: 'Cancel',
  [intl.confirmAction('confirm')]: 'Confirm',
  [intl.confirmAction('delete')]: 'Delete',
  [intl.confirmAction('save')]: 'Save',
  [intl.country('de')]: 'Germany',
  [intl.country('dk')]: 'Denmark',
  [intl.country('fi')]: 'Finland',
  [intl.country('no')]: 'Norway',
  [intl.country('se')]: 'Sweden',
  [intl.country('uk')]: 'UK',
  [intl.creditCheck('auditor')]: 'Auditor Information',
  [intl.creditCheck('boundary-line-1')]:
    'There was a problem processing your request. Please try again later!',
  [intl.creditCheck('boundary-line-2')]:
    'If that does not work, try to log out and back in.',
  [intl.creditCheck('boundary-line-3')]:
    'We have been notified of the issue and are working at full speed to solve it.',
  [intl.creditCheck('beneficial_owner')]: 'Beneficial owners',
  [intl.creditCheck('cant-score-comapny')]: "We couldn't score this company!",
  [intl.creditCheck('check-use-fail')]: 'No information for selected company',
  [intl.creditCheck('check-use-success')]: 'You used one credit check',
  [intl.creditCheck('credit-check-re-arrange')]:
    'Tailor the Credit check page to your needs by dragging the fields below!',
  [intl.creditCheck('from')]: 'From',
  [intl.creditCheck('generate-report')]: 'Download PDF Report',
  [intl.creditCheck('highlights')]: 'Highlights',
  [intl.creditCheck('historical-scores')]: 'View historical scores',
  [intl.creditCheck('monitor-company')]: 'Monitor company',
  [intl.creditCheck('name')]: 'Name',
  [intl.creditCheck('no-company-redirect')]:
    'No company selected, taking you to Search Business',
  [intl.creditCheck('no-data')]:
    'Company hierarchy is currently unavailable for this country',
  [intl.creditCheck('no-subsidiaries')]: 'The company has no subsidiaries',
  [intl.creditCheck('otherPosition')]: 'Other positions',
  [intl.creditCheck('ownership')]: 'Ownership',
  [intl.creditCheck('pep')]: 'PEP',
  [intl.creditCheck('period')]: 'Annual report period',
  [intl.creditCheck('position')]: 'Position',
  [intl.creditCheck('report-button')]: 'Share Report',
  [intl.creditCheck('select-list')]: 'Select a list',
  [intl.creditCheck('seniority')]: 'Seniority',
  [intl.creditCheck('share')]: 'Share',
  [intl.creditCheck('share-internal-header')]: 'People in your organization',
  [intl.creditCheck('share-report')]: 'Share PDF Report',
  [intl.creditCheck('share-report-description')]:
    'Send a credit report to an email of your choice or to someone from your organization from the list below.',
  [intl.creditCheck('share-report-message')]:
    'Add a custom message to send with the report',
  [intl.creditCheck('shares')]: 'Shares',
  [intl.creditCheck('specialRights')]: 'Special remarks',
  [intl.creditCheck('stop-monitor-company')]: 'Stop monitoring',
  [intl.creditCheck('subheader-placeholder')]: "Recipient's email",
  [intl.creditCheck('title')]: 'Credit Check',
  [intl.creditCheck('to')]: 'To',
  [intl.creditCheck('use-check-for-auditor-title')]:
    'Are you sure you want to use one credit?',
  [intl.creditCheck('use-credit')]: 'Use credit',
  [intl.creditCheck('votingRights')]: 'Voting rights',
  [intl.creditPolicy("AUDITOR'S REPORT ON EXTENDED REVIEW")]:
    "Auditor's Report On Extended Review",
  [intl.creditPolicy("AUDITOR'S REPORT")]: "Auditor's Report",
  [intl.creditPolicy("AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)")]:
    "Auditor's Reports (Other Non-Assurance Reports)",
  [intl.creditPolicy("THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)")]:
    "The Independent Auditor's Reports (Review)",
  [intl.creditPolicy('1')]: '1',
  [intl.creditPolicy('1-and-more')]: '1 and more',
  [intl.creditPolicy('1-year')]: '1 year',
  [intl.creditPolicy('10-and-more')]: '10',
  [intl.creditPolicy('2')]: '2',
  [intl.creditPolicy('2-and-more')]: '2 and more',
  [intl.creditPolicy('2-year')]: '2 years',
  [intl.creditPolicy('3')]: '3',
  [intl.creditPolicy('3-and-more')]: '3 and more',
  [intl.creditPolicy('3-year')]: '3 years',
  [intl.creditPolicy('4')]: '4',
  [intl.creditPolicy('4-and-more')]: '4 and more',
  [intl.creditPolicy('5')]: '5',
  [intl.creditPolicy('5-and-more')]: '5 and more',
  [intl.creditPolicy('5-and-more')]: '5 and more',
  [intl.creditPolicy('6-and-more')]: '6 and more',
  [intl.creditPolicy('7-and-more')]: '7 and more',
  [intl.creditPolicy('8-and-more')]: '8 and more',
  [intl.creditPolicy('9-and-more')]: '9 and more',
  [intl.creditPolicy('NO AUDITOR ASSISTANCE')]: 'No Auditor Assistance',
  [intl.creditPolicy('POLICY_AGE')]: 'Company too young',
  [intl.creditPolicy('POLICY_AUDITOR_ASSISTANCE')]:
    'Type of Auditor Assistance not allowed',
  [intl.creditPolicy('POLICY_BANKRUPTCIES')]: 'Too many Bankruptcies',
  [intl.creditPolicy('POLICY_COMPANY_TYPE')]: 'Rejected Company Type',
  [intl.creditPolicy('POLICY_DISTANCE_TO_CEO')]: 'Long distance to CEO',
  [intl.creditPolicy('POLICY_ENTITY_ID')]: 'Rejected Person',
  [intl.creditPolicy('POLICY_INDUSTRY')]: 'Industry Rejected',
  [intl.creditPolicy('POLICY_LOCAL_ID')]: 'Rejected Company',
  [intl.creditPolicy('POLICY_NEGATIVE_EQUITY')]: 'Negative Equity',
  [intl.creditPolicy('POLICY_PHONE_NUMBER_REGISTRY')]:
    'Phone number not registered ',
  [intl.creditPolicy('POLICY_PREPAID_PHONE_NUMBER')]: 'Prepaid phone number',
  [intl.creditPolicy('POLICY_RISIKA_SCORE')]: 'Low credit score',
  [intl.creditPolicy('POLICY_VAT')]: 'VAT',
  [intl.creditPolicy('activate-advanced-view')]:
    'This is a advanced feature, please contact us if you want to try it out!',
  [intl.creditPolicy('age-limitation-information')]:
    'Select the minimum company age that you would like to allow in your credit policy',
  [intl.creditPolicy('age-limitation-title')]: 'Minimum company age',
  [intl.creditPolicy('allow-phone-number')]: 'Allow',
  [intl.creditPolicy('are-you-sure')]:
    'It looks like you made some changes to your Credit Policy. Are you sure you want to leave without saving?',
  [intl.creditPolicy('are-you-sure-dialog')]:
    'This action will turn off Credit Policy for your whole organization. Are you sure you want to continue?',
  [intl.creditPolicy('are-you-sure-dialog-clear-field')]:
    'This action will delete all entries in this field. Are you sure you want to continue?',
  [intl.creditPolicy('auditor-assistance-information')]:
    'Select the minimum level of auditor assistance that you would like to allow in your credit policy',
  [intl.creditPolicy('auditor-assistance-title')]:
    'Not allowed type of auditor assistance',
  [intl.creditPolicy('auditor-placeholder')]: 'Select many',
  [intl.creditPolicy('bancrupcy-limit-information')]:
    'Select the maximum amount of connected bankruptcies that you would like to allow in your credit policy',
  [intl.creditPolicy('bancrupcy-limit-title')]:
    'Maximum amount of connected bankruptcies',
  [intl.creditPolicy('company-does-not-comply-with-policy')]:
    'Company does not comply with Credit policy',
  [intl.creditPolicy('company-types-information')]:
    'Reject company types in your credit policy',
  [intl.creditPolicy('company-types-title')]: 'Rejected company types',
  [intl.creditPolicy('copied-success')]: 'Policy copied successfully',
  [intl.creditPolicy('copy-policy')]: 'Copy policy',
  [intl.creditPolicy('copy-policy-title')]:
    'Select the country and fields you want to copy!',
  [intl.creditPolicy('distance-to-ceo-information')]:
    'Distance in km that the CEO is situated away from the company address',
  [intl.creditPolicy('distance-to-ceo-title')]: 'Distance to CEO',
  [intl.creditPolicy('dk')]: 'Denmark',
  [intl.creditPolicy('dont-allow-phone-number')]: 'Do not allow',
  [intl.creditPolicy('enter-number')]: 'Enter distance (km)',
  [intl.creditPolicy('exclude-companies-information')]:
    'Reject specific companies in your credit policy',
  [intl.creditPolicy('exclude-companies-title')]:
    'Reject specific companies in your credit policy',
  [intl.creditPolicy('exclude-persons-information')]:
    'Reject specific persons in your credit policy',
  [intl.creditPolicy('exclude-persons-title')]: 'Reject persons',
  [intl.creditPolicy('exclude-vat-information')]:
    'Should a company be registered for VAT or should it just be under the allowed limit for a country?',
  [intl.creditPolicy('exclude-vat-title')]:
    'Exclude companies not registered for VAT',
  [intl.creditPolicy('less-than-1')]: 'Less than 1',
  [intl.creditPolicy('more-than-3')]: '3 and more',
  [intl.creditPolicy('more-than-4')]: '4 and more',
  [intl.creditPolicy('more-than-3-years')]: 'More than 3 years',
  [intl.creditPolicy('more-than-4-years')]: 'More than 4 years',
  [intl.creditPolicy('more-than-5')]: '5 and more years',
  [intl.creditPolicy('name_of_auditor')]: 'Name of Auditor',
  [intl.creditPolicy('negative-equity-information')]:
    'Select the amount of years without negative equity that you would like to allow in your credit policy',
  [intl.creditPolicy('negative-equity-title')]:
    'Past years without any negative equity',
  [intl.creditPolicy('no')]: 'Norway',
  [intl.creditPolicy('no-conflicts')]: 'No conflicts with your credit policy.',
  [intl.creditPolicy('none')]: 'None',
  [intl.creditPolicy('off')]: 'Allow',
  [intl.creditPolicy('phone-number-registry-information')]:
    'Is the phone number registered in the phone book',
  [intl.creditPolicy('phone-number-registry-title')]: 'Registered phone number',
  [intl.creditPolicy('policy-not-available-for-non-admin-users')]:
    'The credit policy is only available for admin users in your organization. If you think you should be able to edit the credit policy, please contact your system administrator or us at contact@risika.dk.',
  [intl.creditPolicy('policy-off')]: 'Policy OFF',
  [intl.creditPolicy('policy-on')]: 'Policy ON',
  [intl.creditPolicy('policy-switch-failed')]: 'Failed to switch Credit Policy',
  [intl.creditPolicy('policy-switch-off-success')]:
    'Successfully turned off Credit Policy for {country}',
  [intl.creditPolicy('policy-switch-on-success')]:
    'Successfully turned on Credit Policy for {country}',
  [intl.creditPolicy('prepaid-phone-number-information')]:
    'Be notified if a company is created with a prepaid phone number',
  [intl.creditPolicy('prepaid-phone-number-title')]:
    'Allow prepaid phone numbers',
  [intl.creditPolicy('risika-score-information')]:
    'Select the minimum Risika Score that you would like to allow in your credit policy',
  [intl.creditPolicy('risika-score-title')]: 'Minimum Risika Score',
  [intl.creditPolicy('save-failed')]:
    'Failed to save Credit policy for {country}. Please try again later!',
  [intl.creditPolicy('save-success')]:
    'Successfully saved Credit Policy for {country}',
  [intl.creditPolicy('se')]: 'Sweden',
  [intl.creditPolicy('search')]: 'Search and add',
  [intl.creditPolicy('select')]: 'Select',
  [intl.creditPolicy('select-many')]: 'Select many',
  [intl.creditPolicy('should-be')]: 'Do not allow',
  [intl.creditPolicy('should-be-and-exceeded')]:
    'Only if above the local VAT limit',
  [intl.creditPolicy('specific-address-title')]: 'Exclude addresses',
  [intl.creditPolicy('specific-industry-information')]:
    'Reject specific industries in your credit policy',
  [intl.creditPolicy('specific-industry-title')]: 'Reject industries',
  [intl.creditPolicy('too-many-values')]:
    'Values for {country} exceed the limit. Added only 500 companies!',
  [intl.creditPolicy('type_of_assistance')]: 'Type of Assistance',
  [intl.creditPolicy('uk')]: 'UK',
  [intl.creditPolicy('up-to-1')]: '1 year',
  [intl.creditPolicy('up-to-2')]: '2 years',
  [intl.creditPolicy('up-to-3')]: '3 years',
  [intl.creditPolicy('up-to-4')]: '4 years',
  [intl.creditPolicy('up-to-5')]: '5 years',
  [intl.customerManagement('add-new-users')]: 'Add New Users',
  [intl.customerManagement('are-you-sure-dialog')]:
    'Are you sure you want to revoke access for this company?',
  [intl.customerManagement('customer-plan')]: 'Customer Plan',
  [intl.customerManagement('customer-plan-basic')]: 'Basic',
  [intl.customerManagement('customer-plan-enterprise')]: 'Enterprise',
  [intl.customerManagement('customer-plan-freemium')]: 'Freemium',
  [intl.customerManagement('customer-plan-pro')]: 'Pro',
  [intl.customerManagement('customer-plan-standard')]: 'Standard',
  [intl.customerManagement('customer-plan-trial')]: 'Trial',
  [intl.customerManagement('customer-type')]: 'Customer Type',
  [intl.customerManagement('customer-type-customer')]: 'Customer',
  [intl.customerManagement('customer-type-lost-customer')]: 'Lost Customer',
  [intl.customerManagement('customer-type-lost-trial')]: 'Lost Trial',
  [intl.customerManagement('customer-type-risika')]: 'Risika (internal)',
  [intl.customerManagement('customer-type-trial')]: 'Trial',
  [intl.customerManagement('customer-updated')]: 'Customer updated',
  [intl.customerManagement('delete-company-dialog-title')]: 'Delete customer',
  [intl.customerManagement('existing-customers')]: 'Existing Customers',
  [intl.customerManagement('existing-users')]: 'Existing Users',
  [intl.customerManagement('expiry-date')]: 'Expiry Date',
  [intl.customerManagement('handle-access')]: 'Revoke access',
  [intl.customerManagement('manage-customer')]: 'Manage Customer',
  [intl.customerManagement('my-customers')]: 'My Customers',
  [intl.customerManagement('new-customer')]: 'New Customer',
  [intl.customerManagement('new-customer-title')]: 'Create New Customer',
  [intl.customerManagement('responsible-person')]: 'Set responsible person',
  [intl.customerManagement('revoke-access')]: 'Revoke Access',
  [intl.customerManagement('title')]: 'Customer Management',
  [intl.customerManagement('users')]: 'Users',
  [intl.developer('confirm-remove-webhook-description')]:
    'Beware: if you add a webhook again, a new secret will be generated which means you will have to update it.',
  [intl.developer('confirm-remove-webhook-title')]:
    'Are you sure you want to delete your webhook?',
  [intl.developer('documentation')]: 'documentation',
  [intl.developer('title')]: 'Developer',
  [intl.developer('webhook-add-info')]:
    'For more information about webhooks and how to use them, you can refer to our',
  [intl.developer('webhook-existing-info')]:
    'This is your webhook. You can click on the input fields to copy the URL or Secret. The secret is used to verify that the request is from us. For more information please refer to our',
  [intl.developer('webhook-existing-info')]:
    'Your webhook has been setup. You can read more about how to use it from our',
  [intl.developer('webhook-secret')]: 'Webhook Secret',
  [intl.developer('webhook-title')]: 'Webhook',
  [intl.developer('webhook-url')]: 'Webhook URL',
  [intl.developer('webhook-url-remove-button')]: 'ReTerms and conditionsmove',
  [intl.developer('webhook-url-save-button')]: 'Add',
  [intl.developer('webhook-url-test-button')]: 'Test',
  [intl.enforcedPasswordChange('confirm-password-label')]: 'Confirm password',
  [intl.enforcedPasswordChange('description')]:
    'For security reasons, you are required to change your password.',
  [intl.enforcedPasswordChange('min-characters')]: 'At least 8 characters',
  [intl.enforcedPasswordChange('must-match-password')]: 'Must match password',
  [intl.enforcedPasswordChange('password-change-success')]:
    'Perfect! You can now continue using Risika',
  [intl.enforcedPasswordChange('password-label')]: 'Password',
  [intl.enforcedPasswordChange('submit')]: 'Change password',
  [intl.enforcedPasswordChange('title')]: 'Password change required',
  [intl.exportButton('description')]:
    '{count, plural, =0 {No export selected} one {Export size of {count} row} other {Export size of {count} rows}}',
  [intl.exportButton('max-export')]:
    '{count, plural, other {Limited to the first {count}}}',
  [intl.exportButton('max-out-of')]:
    '{count, plural, other {out of {count} rows}}',
  [intl.exportButton('text')]: 'Export',
  [intl.faq('Antal ansatte')]: 'Number of employees',
  [intl.faq('Connected bankruptcies')]: 'Connected bankruptcies',
  [intl.faq('Credit days')]: 'Credit days',
  [intl.faq('Credit maximum')]: 'Credit maximum',
  [intl.faq('Distress percentage year')]: 'Distress percentage 2020',
  [intl.faq('Effect')]: 'Effect',
  [intl.faq('Erklæringstype')]: 'Declaration type',
  [intl.faq('Explanation')]: 'Explanation',
  [intl.faq('Financial key figures')]: 'Financial key figures',
  [intl.faq('Fraktil')]: 'Percentile',
  [intl.faq('How')]: 'How',
  [intl.faq('Key figure performance')]: 'Key figure performance',
  [intl.faq('Level of audit')]: 'Level of audit',
  [intl.faq('Maximum')]: 'Maximum',
  [intl.faq('Number of employees')]: 'Number of employees',
  [intl.faq('Other Ratios')]: 'Other Ratios',
  [intl.faq('Ownership')]: 'Ownership',
  [intl.faq('Performance')]: 'Performance',
  [intl.faq('Prevalence: 3.75%')]:
    'In 2019 2% of Danish companies went into distress.',
  [intl.faq('Reason')]: 'Reason',
  [intl.faq('Risika score')]: 'Risika score',
  [intl.faq('Risk')]: 'Risk',
  [intl.faq('Sole proprietorship model')]: 'Sole proprietorship model',
  [intl.faq('UC: 77.93%, Gini: 55.86%')]: 'UC: 77.93%, Gini: 55.86%',
  [intl.faq('Understanding risika score and figures')]:
    'Understanding risika score and figures',
  [intl.faq('Unscored companies')]: 'Unscored companies',
  [intl.faq('Virksomhedsstørelse')]: 'Company Size',
  [intl.faq('What')]: 'What',
  [intl.faq('Why')]: 'Why',
  [intl.faq(
    'With a decision threshold of 5 the model has a sensitivity of 81.01% and precision of 4.8%',
  )]:
    'With a decision threshold of 5 the model has a sensitivity of 81.01% and precision of 4.8%',
  [intl.faq(
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%',
  )]:
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%',
  [intl.faq('definition')]: 'Definition',
  [intl.faq('variable')]: 'Variable',
  [intl.financial('probability-of-closure')]: 'Probability of closure',
  [intl.financial('distress')]: 'Distress',
  [intl.financialRatio('no-content')]: 'No content provided',
  [intl.financialRatio('performance')]: 'Performance',
  [intl.firstTimeVisit('confirm-password-label')]: 'Confirm password',
  [intl.firstTimeVisit('description')]:
    'Safety first! You just need to choose your password for the platform and then you can get started.',
  [intl.firstTimeVisit('min-characters')]: 'At least 8 characters',
  [intl.firstTimeVisit('must-match-password')]: 'Must match password',
  [intl.firstTimeVisit('password-label')]: 'Password',
  [intl.firstTimeVisit('submit')]: 'Get started',
  [intl.firstTimeVisit('title')]: 'Choose your password',
  [intl.generic('actions')]: 'Actions',
  [intl.generic('active')]: 'Active',
  [intl.generic('add')]: 'Add',
  [intl.generic('advanced-views-not-allowed')]:
    'Advanced views are not available on your current plan. Click here to see more!',
  [intl.generic('all')]: 'All',
  [intl.generic('back')]: 'Back',
  [intl.generic('cancel')]: 'Cancel',
  [intl.generic('checks-left')]: 'credit checks left',
  [intl.generic('clear')]: 'Clear',
  [intl.generic('clear-selection')]: 'Clear selection',
  [intl.generic('companies')]: 'Companies',
  [intl.generic('company')]: 'Company',
  [intl.generic('confirm-password')]: 'Confirm password',
  [intl.generic('create')]: 'Create',
  [intl.generic('cvr-number')]: 'CVR Number',
  [intl.generic('denmark')]: 'Denmark',
  [intl.generic('do-not-want-to-wait')]:
    "Don't want to wait? Update your plan bellow!",
  [intl.generic('edit')]: 'Edit',
  [intl.generic('email')]: 'Email',
  [intl.generic('emails')]: 'Emails',
  [intl.generic('employees')]: 'employees',
  [intl.generic('error-reach-us-on-chat')]:
    'Something went wrong! Please reach us on the chat',
  [intl.generic('failed-to-fetch-list')]:
    'Something went wrong getting data for "{name}"!',
  [intl.generic('feature-not-available')]:
    'Feature not available on current plan',
  [intl.generic('first-name')]: 'First name',
  [intl.generic('first-page')]: 'First Page',
  [intl.generic('fte')]: 'FTE',
  [intl.generic('get-auditor-information')]: 'Get auditor information',
  [intl.generic('get-credit-information')]: 'Get credit information',
  [intl.generic('get-highlight-information')]: 'Get highlight information',
  [intl.generic('import')]: 'Import',
  [intl.generic('inactive')]: 'Inactive',
  [intl.generic('language')]: 'Language',
  [intl.generic('last-name')]: 'Last name',
  [intl.generic('last-page')]: 'Last Page',
  [intl.generic('learn-more')]: 'Learn more',
  [intl.generic('missing-address')]: 'Missing address',
  [intl.generic('monitor')]: 'Monitor',
  [intl.generic('monitor-stop')]: 'Stop',
  [intl.generic('more-checks-on-monday')]:
    'You will get {credit_rating_cap} more credit checks Monday.',
  [intl.generic('name')]: 'Name',
  [intl.generic('never')]: 'Not available',
  [intl.generic('next-page')]: 'Next Page',
  [intl.generic('no')]: 'No',
  [intl.generic('no-credit-information')]:
    'No available credit information for this company!',
  [intl.generic('no-data')]: 'No data',
  [intl.generic('norway')]: 'Norway',
  [intl.generic('number-of-companies')]: 'Number of companies',
  [intl.generic('optional')]: 'optional',
  [intl.generic('password')]: 'Password',
  [intl.generic('phone')]: 'Phone',
  [intl.generic('previous-page')]: 'Previous Page',
  [intl.generic('remove')]: 'Remove',
  [intl.generic('restore-default')]: 'Restore default',
  [intl.generic('risika-score')]: 'Risika Score',
  [intl.generic('roles')]: 'Roles',
  [intl.generic('rows')]: 'Rows',
  [intl.generic('save')]: 'Save',
  [intl.generic('selected')]: 'Selected',
  [intl.generic('send')]: 'Send',
  [intl.generic('show-more')]: 'Show more',
  [intl.generic('stop')]: 'Stop',
  [intl.generic('sweden')]: 'Sweden',
  [intl.generic('total')]: 'Total',
  [intl.generic('update')]: 'Update',
  [intl.generic('yes')]: 'Yes',
  [intl.generic('you-have')]: 'You have',
  [intl.generic('companies')]: 'Companies',
  [intl.generic('open-all')]: 'Open all',
  [intl.generic('close-all')]: 'Close all',
  [intl.generic('more-details')]: 'More details',
  [intl.generic('less-details')]: 'Less details',
  [intl.generic('days')]: 'days',
  [intl.generic('unknown')]: 'Unknown',

  [intl.generic('company-id')]: 'Company ID',
  [intl.generic('company-name')]: 'Company name',
  [intl.generic('company-type')]: 'Company type',
  [intl.generic('date-of-incorporation')]: 'Date of incorporation',
  [intl.generic('email')]: 'Email',
  [intl.generic('status')]: 'Status',
  [intl.generic('risika-score')]: 'Risika score',
  [intl.generic('credit-max')]: 'Credit max',
  [intl.generic('phone')]: 'Phone',
  [intl.generic('street')]: 'Street',
  [intl.generic('street-number')]: 'Street number',
  [intl.generic('coname')]: 'Coname',
  [intl.generic('zip-code')]: 'Zip code',
  [intl.generic('city')]: 'City',
  [intl.generic('municipality')]: 'Municipality',
  [intl.generic('postal-district')]: 'Postal district',
  [intl.generic('country')]: 'Country',
  [intl.generic('number-of-employees')]: 'Number of employees',
  [intl.generic('industry-code')]: 'Industry code',
  [intl.generic('industry-description')]: 'Industry description',
  [intl.generic('internal-id')]: 'Internal ID',

  [intl.generic('currency')]: 'Currency',
  [intl.generic('equity')]: 'Equity',
  [intl.generic('profit-loss')]: 'Profit and loss',
  [intl.generic('solidity')]: 'Solidity',
  [intl.generic('liquidity')]: 'Liquidity',
  [intl.generic('local-id')]: 'Local ID',

  [intl.grading('average')]: 'Average',
  [intl.grading('not-available')]: 'n/a',
  [intl.grading('strong')]: 'Strong',
  [intl.grading('very-strong')]: 'Very strong',
  [intl.grading('very-weak')]: 'Very weak',
  [intl.grading('weak')]: 'Weak',

  [intl.history('advancedfilters')]: 'Filtering',
  [intl.history('companyhierarchy')]: 'Company hierarchy',
  [intl.history('creditpolicy')]: 'Credit Policy',
  [intl.history('home')]: 'Home',
  [intl.history('monitoring')]: 'Risk Monitoring',
  [intl.history('company-comparison')]: 'Company Comparison',
  [intl.history('observational-lists')]: 'Observational Lists',
  [intl.home('faq-score-calculation-description')]:
    'Our credit model is a scorecard model that places all companies in risk groups 1 - 10 based on several industry-adjusted financial ratios, the company’s age, type of declaration, related bankruptcies and enforced closures and the industry in which it is located. Of the accounting figures and other variables included in the model are the profit or loss for the year, EBITDA, EBIT, financial expenses, current assets, assets, debt, equity, currency, type of business, date of establishment, type of declaration and industry.',
  [intl.home('faq-score-calculation-title')]:
    'How is the Risika Score calculated?',
  [intl.home('faq-title')]: 'Frequently Asked Questions (FAQ)',
  [intl.home('integrations-description')]:
    'Get an overview about how you can automate the credit process in your company by using an integration to your existing CRM and ERP systems.',
  [intl.home('integrations-title')]:
    'Integrate Risika in your existing systems',
  [intl.home('monitor-list-label')]: 'Your monitoring lists',
  [intl.home('monitor-overview-title')]: 'Your monitored companies',
  [intl.home('monitor-recent-changes')]: 'Recent changes',
  [intl.home('no-changes')]: 'No changes',
  [intl.home('sales-and-marketing-description')]:
    'Set up your own internal and industry-specific credit policy directly on the platform. All use of the Riska platform is automatically adjusted accordingly to the credit policy, maximizing security when entering agreements with your B2B customers.',
  [intl.home('sales-and-marketing-title')]:
    'Prioritize risk management and avoid credit loss',
  [intl.home('newsletter-description')]:
    'The new scorecard model is live on the Risika dashboard. Many companies in Denmark and Norway are getting new and more precise Risika score. You can read more about the new scorecard model here. You are always more than welcome to contact us, if you have any questions.',
  [intl.home('newsletter-title')]: 'Latest updates on the Risika Platform',
  [intl.home('subtitle')]: 'Learn how to get the most out of the platform.',
  [intl.home('title')]: 'Welcome to Risika',
  [intl.homePage('contact-availability')]: 'Available the next 3 hours',
  [intl.homePage('contact-title')]: 'Do you have a question?',
  [intl.homePage('go-to-monitoring')]: 'Go to monitoring',
  [intl.homePage('invite-button')]: 'Invite',
  [intl.homePage('invite-description')]:
    "If you invite a business partner and they try the system within a week, you'll receive 2 entrance tickets to Tivoli.",
  [intl.homePage('invite-description-fields')]:
    "Fill out the form below and we'll send an email with a trial login within 24 hours.",
  [intl.homePage('invite-form-company')]:
    'Search for company or enter CVR number',
  [intl.homePage('invite-form-email')]: 'Work mail',
  [intl.homePage('invite-form-invite-button')]: 'Send invite',
  [intl.homePage('invite-form-name')]: 'Full name',
  [intl.homePage('invite-form-no-options')]: 'No companies',
  [intl.homePage('invite-sent-success')]:
    "Thanks! We'll send an invite within 24 hours",
  [intl.homePage('invite-suplementary-text')]:
    'Do you know someone who wants to try Risika?',
  [intl.homePage('invite-title')]:
    'Invite a business partner and get tickets to Tivoli',
  [intl.homePage('monitor-changes-subtitle')]: 'Changes the past 7 days',
  [intl.homePage('monitor-changes-title')]: 'Most relevant changes',
  [intl.homePage('monitor-overview-empty')]: 'No monitored companies',
  [intl.homePage('monitor-overview-import')]:
    'You can import companies to get started',
  [intl.homePage('monitor-portfolio-subtitle')]:
    '{count, plural, =0 {You cannot monitor anymore companies} one {You can still monitor one more company} other {You can still monitor {count} more companies}}',
  [intl.homePage('monitor-portfolio-title')]: 'Your monitoring portfolio',
  [intl.homePage('monitor-suggestions-empty')]: 'No suggestions yet',
  [intl.homePage('monitor-suggestions-empty-description')]:
    'You will get suggestions based on your searches',
  [intl.homePage('monitor-suggestions-subtitle')]:
    'Based on your recent searches',
  [intl.homePage('monitor-suggestions-title')]: 'Monitor suggestions',
  [intl.homePage('news-letter-april-main')]: 'Risika’s april update',
  [intl.homePage('news-letter-april-secondary')]:
    'Read our latest newsletter with new improvements',
  [intl.homePage('news-letter-february-main')]: 'Risika’s february update',
  [intl.homePage('news-letter-february-secondary')]:
    'Read our latest newsletter with new improvements',
  [intl.homePage('news-letter-march-main')]: 'Risika’s march update',
  [intl.homePage('news-letter-march-secondary')]:
    'Read our latest newsletter with new improvements',
  [intl.homePage('recent-searches-subtitle')]: 'Based on your recent activity',
  [intl.homePage('recent-searches-title')]: 'Recent searches',
  [intl.homePage('relevant-changes-empty')]: 'No changes the past 7 days',
  [intl.homePage('relevant-changes-empty-description')]:
    'As soon as there are changes you will see them here',
  [intl.homePage('resources-and-integrations-title')]:
    'Learn more about Risika',
  [intl.homePage('resources-api-solution')]: 'API Solution',
  [intl.homePage('resources-api-solution-description')]:
    'Read more about our API solution',
  [intl.homePage('resources-click-copy-example')]:
    'Click to copy on the Credit Check page',
  [intl.homePage('resources-click-copy-example-description')]:
    'Easily copy data for a company',
  [intl.homePage('resources-dynamics-crm')]: 'Dynamics CRM',
  [intl.homePage('resources-dynamics-crm-description')]:
    'Read about our Microsoft Dynamics integration',
  [intl.homePage('resources-features-and-integrations')]:
    'Features & Integrations',
  [intl.homePage('resources-features-and-integrations-description')]:
    'Read about our features and integrations',
  [intl.homePage('resources-monitoring-delete-example')]:
    'Remove multiple companies from Risk Monitoring at once',
  [intl.homePage('resources-monitoring-delete-example-description')]:
    'Demo of removing multiple companies in Risk Monitoring',
  [intl.homePage('resources-monitoring-example')]:
    'Risk Monitoring – how does it work?',
  [intl.homePage('resources-monitoring-example-desription')]:
    'Demo of how our risk monitoring works',
  [intl.homePage('resources-monitoring-import-example')]:
    'Risk monitoring import',
  [intl.homePage('resources-monitoring-import-example-description')]:
    'Demo of importing companies in Risk Monitoring',
  [intl.homePage('resources-navision')]: 'Navision',
  [intl.homePage('resources-navision-description')]:
    'Read about our Microsoft Navision integration',
  [intl.homePage('resources-search-example')]: 'Searching for companies',
  [intl.homePage('resources-search-example-description')]:
    'Demo of how you can search for companies',
  [intl.homePage('welcome')]: 'Welcome {name}',
  [intl.importButton('description')]:
    'CSV and Excel (XLSX) format supported. The column containing the CVR numbers need to be labeled "cvr"',
  [intl.importButton('text')]: 'Import',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('')]: ' ',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('annual-report')]: 'Annual report',
  [intl.keyFinancialFigures('assets')]: 'Assets',
  [intl.keyFinancialFigures('balance')]: 'Liabilities and equity',
  [intl.keyFinancialFigures('basic-view')]: 'Minimize table',
  [intl.keyFinancialFigures('cash')]: 'Cash',
  [intl.keyFinancialFigures('consolidated-statement')]:
    'Consolidated statement',
  [intl.keyFinancialFigures('contributedCapital')]: 'Share capital',
  [intl.keyFinancialFigures('currentAssets')]: 'Current assets',
  [intl.keyFinancialFigures('currentFinancialAssets')]:
    'Current financial assets',
  [intl.keyFinancialFigures('debt')]: 'Total debt',
  [intl.keyFinancialFigures('depreciation')]: 'Depreciation',
  [intl.keyFinancialFigures('detailed-view')]: 'Detailed view',
  [intl.keyFinancialFigures('dividend')]: 'Dividend',
  [intl.keyFinancialFigures('ebit')]: 'EBIT',
  [intl.keyFinancialFigures('ebitda')]: 'EBITDA',
  [intl.keyFinancialFigures('equity')]: 'Equity',
  [intl.keyFinancialFigures('full-view')]: 'Show full table',
  [intl.keyFinancialFigures('grossResult')]: 'Gross result',
  [intl.keyFinancialFigures('intangibleAssets')]: 'Intangible assets',
  [intl.keyFinancialFigures('interestExpenses')]: 'Interest Expenses',
  [intl.keyFinancialFigures('interestIncome')]: 'Interest Income',
  [intl.keyFinancialFigures('inventories')]: 'Inventories',
  [intl.keyFinancialFigures('liabilitiesAndEquity')]: 'Liabilities and equity',
  [intl.keyFinancialFigures('longTermDebt')]: 'Long term debt',
  [intl.keyFinancialFigures('noncurrentAssets')]: 'Fixed assets',
  [intl.keyFinancialFigures('noncurrentFinancialAssets')]:
    'Noncurrent financial assets',
  [intl.keyFinancialFigures('not-available')]: 'Not available',
  [intl.keyFinancialFigures('ordinaryProfit')]: 'Ordinary Profit',
  [intl.keyFinancialFigures('otherNetFinancialIncome')]:
    'Other net financial income',
  [intl.keyFinancialFigures('otherShortTermDebt')]: 'Other short term debt',
  [intl.keyFinancialFigures('otherShortTermReceivables')]: 'Other Receivables',
  [intl.keyFinancialFigures('parent-statement')]: 'Parent statement',
  [intl.keyFinancialFigures('profitLoss')]: 'Profit loss',
  [intl.keyFinancialFigures('propertyPlantAndEquipment')]:
    'Property, plant and equipment',
  [intl.keyFinancialFigures('provisions')]: 'Provisions',
  [intl.keyFinancialFigures('reserves')]: 'Reserves',
  [intl.keyFinancialFigures('retainedEarnings')]: 'Retained earnings',
  [intl.keyFinancialFigures('revenue')]: 'Revenue',
  [intl.keyFinancialFigures('shortTermDebt')]: 'Short term debt',
  [intl.keyFinancialFigures('shortTermDebtToBanks')]:
    'Short term debt to banks',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Short term debt to group enterprises',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Short term receivables from group enterprises',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Receivables from sales and services',
  [intl.keyFinancialFigures('shortTermTaxPayables')]: 'Short term tax payables',
  [intl.keyFinancialFigures('shortTermTradePayables')]:
    'Short term trade payables',
  [intl.keyFinancialFigures('staffExpenses')]: 'Staff Expenses',
  [intl.keyFinancialFigures('taxExpenses')]: 'Tax expenses',
  [intl.keyFinancialFigures('title')]: 'Key Financial Figures',
  [intl.keyFinancialFigures('total-debt')]: 'Total debt',
  [intl.keyFinancialFigures('currency')]: 'Currency',
  [intl.keyFinancialFigures('equity')]: 'Equity',
  [intl.keyFinancialFigures('profit-loss')]: 'Profit and loss',
  [intl.keyFinancialFigures('solidity')]: 'Solidity',
  [intl.keyFinancialFigures('liquidity')]: 'Liquidity',
  [intl.keyFinancialFigures('capital')]: 'Capital',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Short term receivables from sales and services',
  [intl.loginRegister('forgot-your-password')]: 'Forgot your password',
  [intl.loginRegister('login-submit-button')]: 'Login',
  [intl.loginRegister('login-to-account')]: 'Login to your account',
  [intl.loginRegister('register-create-account')]: 'Create your account',
  [intl.loginRegister('register-create-button')]: 'Create',
  [intl.loginRegister('register-popup-failure')]:
    'Account creation failed! Please contact your system administrator',
  [intl.loginRegister('register-popup-password')]: 'Passwords do not match',
  [intl.loginRegister('register-popup-success')]:
    'Account created successfully! Redirecting to "Login" page',
  [intl.loginRegister('remember-me')]: 'Remember me',
  [intl.loginRegister('static-content-paragraph-1')]:
    'Our vision is to deliver the best financial risk management solutions to our clients globally.',
  [intl.loginRegister('static-content-paragraph-2')]:
    'To achieve this vision we extract valuable insights from both non-traditional and public data sources using advanced algorithms. The end-goal is customizable, automated and intuitive solutions that helps you and your company minimize risk, save time and take better decisions.',
  [intl.loginRegister('static-content-welcome')]: 'Welcome to Risika!',
  [intl.mainRecommendation('auditor_selected')]: 'Auditor selected',
  [intl.mainRecommendation('auditor_selected')]: 'Auditor selected',
  [intl.mainRecommendation('company-id')]: 'Local company ID',
  [intl.mainRecommendation('company-id-dk')]: 'Local company ID (CVR)',
  [intl.mainRecommendation('company-id-no')]: 'Local company ID (Orgnr.)',
  [intl.mainRecommendation('company-id-se')]: 'Local company ID (Org.nr.)',
  [intl.mainRecommendation('copy_company_address')]:
    'Company address copied to clipboard',
  [intl.mainRecommendation('copy_company_local_id')]:
    'Company local id copied to clipboard',
  [intl.mainRecommendation('copy_company_name')]:
    'Company name copied to clipboard',
  [intl.mainRecommendation('currency')]: 'Listed in ',
  [intl.mainRecommendation('edit-view')]: 'Edit view',
  [intl.mainRecommendation('get-credit-information')]: 'Get credit information',
  [intl.mainRecommendation('latest-annual-report')]: 'Latest annual report',
  [intl.mainRecommendation('performance')]: 'Performance',
  [intl.mainRecommendation('recommendation-credit')]: 'No Credit',
  [intl.mainRecommendation('recommendation-credit-unknown')]: 'Unknown',
  [intl.mainRecommendation('recommended-credit-description')]:
    'Recommended credit limit',
  [intl.mainRecommendation('recommended-days')]:
    '{amount, plural, =0 {Immediately} one {Max {amount} day} other {Max {amount} days}}',
  [intl.mainRecommendation('recommended-days-description')]:
    'Recommended days of credit',
  [intl.mainRecommendation('risika-estimation')]: "Risika's estimation",
  [intl.mainRecommendation('risk-level')]: 'Risk Level',
  [intl.mainRecommendation('risk-level-high')]: 'High Risk',
  [intl.mainRecommendation('risk-level-high-short')]: 'High',
  [intl.mainRecommendation('risk-level-low')]: 'Low Risk',
  [intl.mainRecommendation('risk-level-low-short')]: 'Low',
  [intl.mainRecommendation('risk-level-medium')]: 'Medium Risk',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Medium',
  [intl.mainRecommendation('risk-level-sole-proprietorship')]:
    'Sole Proprietorship',
  [intl.mainRecommendation('risk-level-unknown')]: 'Unknown Risk',
  [intl.mainRecommendation('statstidende-header')]: 'Bankruptcy notice',
  [intl.mainRecommendation('statstidende-title')]: 'Gazette',
  [intl.mainRecommendation('status')]: 'Status',
  [intl.mainRecommendation('status-active-from')]: 'Status change from',
  [intl.mainRecommendation('you-have')]: 'You have',
  [intl.mainRecommendation('explanation')]: 'Explanation',
  [intl.mainRecommendation('recommendation')]: 'Recommendation',
  [intl.mainRecommendation('credit-max')]: 'Credit max',
  [intl.mainRecommendation('credit-days')]: 'Credit days',
  [intl.mainRecommendation('credit-policy')]: 'Credit policy',
  [intl.mainRecommendation('date')]: 'Date: ',
  [intl.mainRecommendation('case-id')]: 'Case: ',
  [intl.mainRecommendation('statstidende-link')]: 'Link: ',
  [intl.mainRecommendation('bankruptcy-noticed')]: 'Bankruptcy noticed',
  [intl.mainRecommendation('status-label')]: 'Status: ',
  [intl.mainRecommendation('valid-from')]: 'Valid from: ',
  [intl.mainRecommendation('undergoing-bankruptcy')]: 'Undergoing bankruptcy',

  [intl.paymentRemarks('widget-title')]: 'Payment remarks',
  [intl.paymentRemarks('widget-text')]:
    '{count, plural, one {There is {count} registered payment remark on this company} other {There are {count} registered payment remarks on this company.}}',
  [intl.paymentRemarks('widget-open-modal')]: 'View the payment remarks',
  [intl.paymentRemarks('creditor')]: 'Creditor',
  [intl.paymentRemarks('type')]: 'Remark type',
  [intl.paymentRemarks('case-id')]: 'Case id',
  [intl.paymentRemarks('amount')]: 'Amount',
  [intl.paymentRemarks('registered')]: 'Registered',
  [intl.paymentRemarks('status')]: 'Status',
  [intl.paymentRemarks('total-remarks')]:
    '{count, plural, one {{count} remark in total} other {{count} remarks in total}}',
  [intl.paymentRemarks('asterisk-1')]:
    '* Unknown amounts are due to the currency of the case not being in NOK.',
  [intl.paymentRemarks('asterisk-2')]:
    '** When two companies merge, cases from the company who are dissolved after a merger will be reported with a new company house number',

  [intl.paymentRemarks('legal-collector')]:
    "Payment remarks are collected from {collector} and are valid for use in Credit Reporting in Norway. According to Norwegian law, payment remarks can be kept for four years, whereafter they're being deleted from our system.",
  [intl.paymentRemarks('total-unsettled-amount')]: 'Total unsettled amount',
  [intl.paymentRemarks('partially-paid-cases')]: 'Partially paid cases',
  [intl.paymentRemarks('unique-creditors')]: 'Unique creditors',
  [intl.paymentRemarks('avg-unsettled-time')]: 'Avg unsettled time',
  [intl.paymentRemarks('active-creditors')]: 'active creditors',

  [intl.monitorFrequency('daily')]: 'Every day',
  [intl.monitorFrequency('daily-select')]: 'Daily',
  [intl.monitorFrequency('friday-select')]: 'Friday',
  [intl.monitorFrequency('how-often-label')]: 'How often?',
  [intl.monitorFrequency('monday-select')]: 'Monday',
  [intl.monitorFrequency('monthly')]: 'Every month',
  [intl.monitorFrequency('monthly-select')]: 'Monthly (first monday)',
  [intl.monitorFrequency('saturday-select')]: 'Saturday',
  [intl.monitorFrequency('sunday-select')]: 'Sunday',
  [intl.monitorFrequency('thursday-select')]: 'Thursday',
  [intl.monitorFrequency('title')]: 'How often do you want to get notified?',
  [intl.monitorFrequency('tuesday-select')]: 'Tuesday',
  [intl.monitorFrequency('wednesday-select')]: 'Wednesday',
  [intl.monitorFrequency('weekly-friday')]: 'Every Friday',
  [intl.monitorFrequency('weekly-monday')]: 'Every Monday',
  [intl.monitorFrequency('weekly-saturday')]: 'Every Saturday',
  [intl.monitorFrequency('weekly-select')]: 'Weekly',
  [intl.monitorFrequency('weekly-sunday')]: 'Every Sunday',
  [intl.monitorFrequency('weekly-thursday')]: 'Every Thursday',
  [intl.monitorFrequency('weekly-tuesday')]: 'Every Tuesday',
  [intl.monitorFrequency('weekly-wednesday')]: 'Every Wednesday',
  [intl.monitorFrequency('which-day-label')]: 'Which day?',
  [intl.navigation('advanced-filters')]: 'Filtering',
  [intl.navigation('company-comparison')]: 'Company Comparison',
  [intl.navigation('cfo-view')]: 'CFO View',
  [intl.navigation('chat')]: 'Support',
  [intl.navigation('company-hierarchy')]: 'Company Hierarchy',
  [intl.navigation('credit-check')]: 'Credit Check',
  [intl.navigation('credit-policy')]: 'Credit Policy',
  [intl.navigation('customer-management')]: 'Customers',
  [intl.navigation('dashboard')]: 'Dashboard',
  [intl.navigation('developer')]: 'Developer',
  [intl.navigation('faq')]: 'FAQ',
  [intl.navigation('home')]: 'Home',
  [intl.navigation('login')]: 'Login',
  [intl.navigation('logout')]: 'Log out',
  [intl.navigation('relation-overview')]: 'People',
  [intl.navigation('risk-monitoring')]: 'Monitor',
  [intl.navigation('search-business')]: 'Search',
  [intl.navigation('settings')]: 'Settings',
  [intl.navigation('user-management')]: 'User Management',
  [intl.navigation('revoked-warning')]: 'You don’t have access',

  [intl.navigationTooltip('company-hierarchy')]:
    'Please search for a company with subsidiaries',
  [intl.navigationTooltip('credit-check')]: 'Please search for a company first',
  [intl.navigationTooltip('relation-overview')]:
    'Please search for a person first',
  [intl.news('no-articles')]: 'No articles at the moment',
  [intl.news('show-less')]: 'Less',
  [intl.news('show-more')]: 'More',
  [intl.news('title')]: 'News',
  [intl.notFound('best-experience')]: 'For the best experience',
  [intl.notFound('better-performance')]: 'Better performance',
  [intl.notFound('not-found-402')]:
    'If you are on a trial, the limitations of your subscription has probably been reached. Please contact the person responsible for your subscription in your company or contact us directly.',
  [intl.notFound('not-found-404')]:
    'Unfortunately, you have entered a page that does not exist. You can click on the button to get back to Dashboard.',
  [intl.notFound('not-found-500')]:
    'We are experiencing some technical issues at the moment. We will be back shortly!',
  [intl.notFound('not-found-503')]:
    'Something went wrong with your request. It is possible that our servers are down for maintenance or overloaded. Try again in few minutes.',
  [intl.notFound('not-found-button-dashboard')]: 'Go to "Dashboard"',
  [intl.notFound('not-found-button-risika-dk')]: 'Go to "Risika.dk"',
  [intl.notFound('not-supported-header')]: 'Not supported',
  [intl.notFound('not-supported-text')]:
    'To ensure the best experience for our users, we have disabled support for Internet Explorer. Please follow the links bellow to download one of our recommended browsers.',
  [intl.notFound('pricing-button')]: 'Go to "Plans & Pricing"',
  [intl.notFound('under-meintenance-header')]: 'Under maintenance!',
  [intl.notFound('under-meintenance-text')]:
    'Risika dashboard and API are currently under maintenance. We will be back shortly!',
  [intl.popconfirm('cancel')]: 'Cancel',
  [intl.popconfirm('confirm')]: 'Okay',
  [intl.popconfirm('title')]: 'Are you sure?',
  [intl.relationOverview('end-date')]: 'End date',
  [intl.relationOverview('function')]: 'Function',
  [intl.relationOverview('function-status')]: 'Function status',
  [intl.relationOverview('no-entity-redirect')]:
    'No company or individual selected, taking you back to Search Business',
  [intl.relationOverview('start-date')]: 'Start date',
  [intl.relationOverview('title')]: 'Relation Overview',
  [intl.relations('auditor')]: 'Auditor',
  [intl.relations('board_member')]: 'Board Member',
  [intl.relations('branch_manager')]: 'Branch Manager',
  [intl.relations('ceo')]: 'CEO',
  [intl.relations('founder')]: 'Founder',
  [intl.relations('legal_owner')]: 'Legal owner',
  [intl.relations('management')]: 'Management',
  [intl.relations('president')]: 'Chairman',
  [intl.relations('real_owner')]: 'Beneficial owner',
  [intl.relations('stakeholder')]: 'Stakeholder',
  [intl.relations('substitute')]: 'Substitute',
  [intl.relations('vice_president')]: 'Vice-Chairman',
  [intl.relevantFinancialRatios('asset_turnover')]: 'Asset turnover',
  [intl.relevantFinancialRatios('based-on-parent-statement')]:
    'Based on parent statement numbers',
  [intl.relevantFinancialRatios('basic_earning_power')]: 'Basic earning power',
  [intl.relevantFinancialRatios('capacity_ratio')]: 'Capacity ratio',
  [intl.relevantFinancialRatios('cash_conversion_rate')]:
    'Cash conversation rate',
  [intl.relevantFinancialRatios('cash_ratio')]: 'Cash ratio',
  [intl.relevantFinancialRatios('current_assets_to_equity')]:
    'Current assets to equity',
  [intl.relevantFinancialRatios('current_ratio')]: 'Current ratio',
  [intl.relevantFinancialRatios('debt_ratio')]: 'Debt ratio',
  [intl.relevantFinancialRatios('debt_to_equity_ratio')]:
    'Debt to equity ratio',
  [intl.relevantFinancialRatios('ebitda_to_debt_ratio')]:
    'Ebitda To Debt Ratio',
  [intl.relevantFinancialRatios('fixed_assets_to_long_term_liabilities_ratio')]:
    'FA to long term liabilities',
  [intl.relevantFinancialRatios('gross_margin')]: 'Gross margin',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Interest coverage',
  [intl.relevantFinancialRatios('interest_margin')]: 'Interest margin',
  [intl.relevantFinancialRatios('interest_rate_on_debt_with_average')]:
    'Interest rate on debt',
  [intl.relevantFinancialRatios('not-available')]: 'Not available',
  [intl.relevantFinancialRatios('one_year_change_in_equity')]:
    'Change in equity',
  [intl.relevantFinancialRatios('operating_margin')]: 'Operating margin',
  [intl.relevantFinancialRatios('profit_margin')]: 'Profit margin',
  [intl.relevantFinancialRatios('return_on_assets')]: 'Return on assets',
  [intl.relevantFinancialRatios('return_on_equity')]: 'Return on equity',
  [intl.relevantFinancialRatios('solidity_ratio')]: 'Solidity ratio',
  [intl.relevantFinancialRatios('title')]: 'Relevant Financial Ratios',
  [intl.reportBug('agreement')]:
    'By filing a report you allow us to use your email as a reference to this issue.',
  [intl.reportBug('description')]: 'Please describe the error',
  [intl.reportBug('drag-drop')]:
    'You can Drag & Drop or Browse to attach some images.',
  [intl.reportBug('error-in-credit-information')]:
    'Error in credit information',
  [intl.reportBug('error-in-financial-data')]: 'Error in financial data',
  [intl.reportBug('error-in-general-information')]:
    'Error in general information',
  [intl.reportBug('error-in-highlights')]: 'Error in highlights',
  [intl.reportBug('error-in-relation-data')]: 'Error in relation data',
  [intl.reportBug('other')]: 'Other',
  [intl.reportBug('report-bug-paper')]: 'Report an error',
  [intl.reportBug('send-button')]: 'File Report',
  [intl.reportBug('type-of-report')]: 'What type error did you encounter?',
  [intl.restriction('basic-description')]:
    'Your plan does not cover this feature. Click the button bellow for more information.',
  [intl.restriction('create-new-list-content')]:
    'This feature is unavailable for your plan',
  [intl.restriction('create-new-list-title')]: 'Monitoring lists',
  [intl.restriction('credit-reports-button')]: 'Pricing & Plans',
  [intl.restriction('credit-reports-description')]:
    'You do not have access to this feature. You can upgrade your current plan or buy the feature "Credit Report" as an separate add-on.',
  [intl.restriction('credit-reports-title')]: 'Credit Reports',
  [intl.restriction('export-button')]: 'Pricing & Plans',
  [intl.restriction('export-description')]:
    'You do not have access to this feature. You can upgrade your current plan or buy the "Export functionality" add-on as a separate feature.',
  [intl.restriction('export-title')]: 'Export',
  [intl.restriction('key-figures-title')]: 'Key figures',
  [intl.restriction('risk-monitoring-button')]: 'Pricing & Plans',
  [intl.restriction('risk-monitoring-description')]:
    'You do not have access to this feature. You can upgrade your plan or buy the "Risk Monitoring" add-on as an separate feature.',
  [intl.restriction('risk-monitoring-title')]: 'Risk Monitoring',
  [intl.riskMonitoring('intelligence-suite-tooltip')]: 'Intelligence Suite',
  [intl.riskMonitoring('company')]: 'Company',
  [intl.riskMonitoring('company-search')]: 'Search for a company',
  [intl.riskMonitoring('company-type')]: 'Company type',
  [intl.riskMonitoring('companyName')]: 'Company',
  [intl.riskMonitoring('companyType')]: 'Company type',
  [intl.riskMonitoring('country')]: 'Country',
  [intl.riskMonitoring('credit-max')]: 'Credit Max',
  [intl.riskMonitoring('currency')]: 'Currency',
  [intl.riskMonitoring('date-of-incorporation')]: 'Date of incorporation',
  [intl.riskMonitoring('dateOfIncorporation')]: 'Date of incorporation',
  [intl.riskMonitoring('edit-company-title')]: 'Edit company',
  [intl.riskMonitoring('employee-interval')]: 'Employee interval',
  [intl.riskMonitoring('employeeInterval')]: 'Employee interval',
  [intl.riskMonitoring('equity')]: 'Equity',
  [intl.riskMonitoring('from-to-count')]: '{from}-{to} of {count}',
  [intl.riskMonitoring('import-from-file')]: 'Import from file',
  [intl.riskMonitoring('import-from-file')]: 'Import from file',
  [intl.riskMonitoring('import-from-file-company-id-field')]:
    'Choose field with the company ID',
  [intl.riskMonitoring('import-from-file-include-internal-id')]:
    'Include internal ID',
  [intl.riskMonitoring('import-from-file-internal-id-field')]:
    'Choose field with your internal ID',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Enter manually',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Enter manually',
  [intl.riskMonitoring('import-from-manual-entry-helper-text')]:
    'Separate by space, comma, or semicolon',
  [intl.riskMonitoring('internal-id')]: 'Internal ID',
  [intl.riskMonitoring('internalId')]: 'Internal ID',
  [intl.riskMonitoring('liquidity')]: 'Liquidity',
  [intl.riskMonitoring('local-id')]: 'Local ID',
  [intl.riskMonitoring('local-id-search')]: 'Find by Local ID',
  [intl.riskMonitoring('internal-id-search')]: 'Find by Internal ID',
  [intl.riskMonitoring('localId')]: 'Local ID (CVR)',
  [intl.riskMonitoring('no-credit')]: 'No Credit',
  [intl.riskMonitoring('no-data')]: 'No data available',
  [intl.riskMonitoring('profit-loss')]: 'Profit loss',
  [intl.riskMonitoring('profitLoss')]: 'Profit loss',
  [intl.riskMonitoring('reject-companies')]: 'Add to list in Credit Policy',
  [intl.riskMonitoring('remaining-monitoring')]: 'Remaining monitoring',
  [intl.riskMonitoring('remove')]: 'Remove',
  [intl.riskMonitoring('risika-score')]: 'Score',
  [intl.riskMonitoring('rows-per-page')]: 'Per page',
  [intl.riskMonitoring('score')]: 'Score',
  [intl.riskMonitoring('solidity')]: 'Solidity',
  [intl.riskMonitoring('status')]: 'Status',
  [intl.riskMonitoring('summation-change-duration')]: 'Changes the past week',
  [intl.riskMonitoring('title')]: 'Risk Monitoring',
  [intl.riskMonitoring('top-negative')]: 'Top Negative',
  [intl.riskMonitoring('you-already-have-preset-with-name')]:
    'You already have a preset with this name',
  [intl.riskMonitoring('please-select-a-name')]: 'Please select a name',
  [intl.riskMonitoring('preset-name')]: 'Export name',
  [intl.riskMonitoring('select-preset')]: 'Select export',
  [intl.riskMonitoring('no-presets-saved')]: 'No export saved',
  [intl.riskMonitoring('capital')]: 'Capital',

  [intl.riskMonitoringNew('add')]: 'Add',
  [intl.riskMonitoringNew('add-companies-button')]: 'Import',
  [intl.riskMonitoringNew('add-list-name-snackbar')]:
    'Please provide a name for the list!',
  [intl.riskMonitoringNew('add-name')]: 'Select a name for the list',
  [intl.riskMonitoringNew('add-new-list')]: 'New list',
  [intl.riskMonitoringNew('add-new-subscription')]: 'Add notification',
  [intl.riskMonitoringNew('add-to-monitoring-counter')]:
    'We will import {count} companies to "{listName}".',
  [intl.riskMonitoringNew('add-to-monitoring-list-title')]:
    "Upload file or manually enter company id's",
  [intl.riskMonitoringNew('added-some-companies')]:
    'The amount exceeds your current monitoring plan. Added only some companies.',
  [intl.riskMonitoringNew('adding-companies-wait')]:
    'Adding companies. Please wait...',
  [intl.riskMonitoringNew('are-you-sure-delete')]:
    'Are you sure you want to delete {text}?',
  [intl.riskMonitoringNew('are-you-sure-delete-entries')]:
    '{count, plural, one {Are you sure you want to delete the selected company?} other {Are you sure you want to delete the selected {count} companies?}}',
  [intl.riskMonitoringNew('back')]: 'Back',
  [intl.riskMonitoringNew('copy-button-text')]: 'Copy',
  [intl.riskMonitoringNew('copy-to-another-list-title')]:
    'Select lists you would like to copy to',
  [intl.riskMonitoringNew('create-list-button')]: 'Save List',
  [intl.riskMonitoringNew('create-new-list-text-field')]: 'Create a new list',
  [intl.riskMonitoringNew('create-subscription')]: 'Create notification',
  [intl.riskMonitoringNew('cvt-textfield-label')]:
    "Or directly enter company id's here",
  [intl.riskMonitoringNew('delete')]: 'Delete',
  [intl.riskMonitoringNew('delete-companies-title')]:
    '{count, plural, one {Delete one company} other {Delete {count} companies}}',
  [intl.riskMonitoringNew('delete-dialog-title')]: 'Delete a list',
  [intl.riskMonitoringNew('delete-failed')]: 'Failed to delete list',
  [intl.riskMonitoringNew('delete-items')]: 'Delete',
  [intl.riskMonitoringNew('delete-list')]: 'Delete list',
  [intl.riskMonitoringNew('delete-success')]: 'List deleted successfully',
  [intl.riskMonitoringNew('deleting-companies-wait')]:
    'Deleting companies. Please wait...',
  [intl.riskMonitoringNew('drag-drop')]:
    'You can Drag & Drop or Browse to attach files (.csv, .xlsx).',
  [intl.riskMonitoringNew('edit-button')]: 'Rename',
  [intl.riskMonitoringNew('edit-list-button')]: 'Rename list',
  [intl.riskMonitoringNew('edit-list-text-field')]: 'Rename list',
  [intl.riskMonitoringNew('edit-name')]: 'New name',
  [intl.riskMonitoringNew('existing-subscriptions')]: 'Existing notifications',
  [intl.riskMonitoringNew('export-companies-button')]: 'Export',
  [intl.riskMonitoringNew('failed-validation')]:
    '{amount} values failed validation and where not added to list!',
  [intl.riskMonitoringNew('field-list-header')]:
    "Please select a field that contains company ID's",
  [intl.riskMonitoringNew('handle-settings-button')]: 'Table settings',
  [intl.riskMonitoringNew('handle-visibility-button')]: 'Visibility',
  [intl.riskMonitoringNew('items-copied')]:
    '{count, plural, one {You have successfully copied one company.} other {You have successfully copied {count} companies.}}',
  [intl.riskMonitoringNew('items-deleted')]:
    '{count, plural, one {You have successfully deleted one company.} other {You have successfully deleted {count} companies.}}',
  [intl.riskMonitoringNew('items-failed-to-copy')]: 'Companies failed to copy.',
  [intl.riskMonitoringNew('items-failed-to-delete')]:
    'Companies failed to delete',
  [intl.riskMonitoringNew('items-failed-to-import')]:
    'Companies failed to import.',
  [intl.riskMonitoringNew('items-failed-to-move')]: 'Companies failed to move',
  [intl.riskMonitoringNew('items-imported')]:
    'Companies successfully imported.',
  [intl.riskMonitoringNew('items-moved')]:
    '{count, plural, one {You have successfully moved one company.} other {You have successfully moved {count} companies.}}',
  [intl.riskMonitoringNew('monitor-add-subscription')]: 'Add subscription',
  [intl.riskMonitoringNew('monitor-settings')]: 'Set preferences',
  [intl.riskMonitoringNew('move-button-text')]: 'Move',
  [intl.riskMonitoringNew('move-to-another-list-title')]:
    'Select lists you would like to move to',
  [intl.riskMonitoringNew('new-list-added-failed')]: 'Failed to add new list',
  [intl.riskMonitoringNew('new-list-added-success')]:
    'New list added successfully',
  [intl.riskMonitoringNew('new-list-private')]: 'Private',
  [intl.riskMonitoringNew('new-list-public')]: 'Public',
  [intl.riskMonitoringNew('new-list-subtitle')]:
    'Please select users that can see this list',
  [intl.riskMonitoringNew('no-credit')]: 'No Credit',
  [intl.riskMonitoringNew('no-subscriptions-text')]:
    "Looks like you don't have any lists you want to receive emails for. Press the button above to add a new one!",
  [intl.riskMonitoringNew('remove-internal-id')]: 'Remove internal ID',
  [intl.riskMonitoringNew('remove-internal-id-description')]:
    '{count, plural, one {You are about to remove {count} internal ID} other {You are about to remove {count} internal IDs}}',
  [intl.riskMonitoringNew('remove-internal-id-title')]:
    '{count, plural, one {Remove internal ID} other {Remove internal IDs}}',
  [intl.riskMonitoringNew('save-changes')]: 'Save changes',
  [intl.riskMonitoringNew('select-lists')]: 'Select lists',
  [intl.riskMonitoringNew('select-subscription-lists')]:
    'Select lists you want to receive updates on',
  [intl.riskMonitoringNew('select-subscription-options')]:
    'Choose what you want to hear about',
  [intl.riskMonitoringNew('selected')]:
    '{count, plural, =0 {None selected} other {{count} selected}}',
  [intl.riskMonitoringNew('settings-header')]:
    'Choose which columns you would like to see',
  [intl.riskMonitoringNew('submit')]: 'Submit',
  [intl.riskMonitoringNew('subscribed-to-all')]:
    'You are subscribed to all available lists',
  [intl.riskMonitoringNew('subscription-add-failed')]:
    'We had some issues creating the notification',
  [intl.riskMonitoringNew('subscription-add-success')]: 'Notification created',
  [intl.riskMonitoringNew('subscription-delete-failed')]:
    'We had some issues deleting your notification',
  [intl.riskMonitoringNew('subscription-delete-success')]:
    'Notification deleted',
  [intl.riskMonitoringNew('subscription-updated-success')]:
    'Subscription updated successfully',
  [intl.riskMonitoringNew('table-actions-tooltip')]: 'Select a row first',
  [intl.riskMonitoringNew('text-field-hint')]:
    'Characters such as commas, periods, spaces separate each field',
  [intl.riskMonitoringNew('update')]: 'Update',
  [intl.riskMonitoringNew('update-subscriptions')]: 'Your Notifications',
  [intl.riskMonitoringNew('update-subscriptions-title')]: 'Your Notifications',
  [intl.riskMonitoringNew('visibility-header')]: 'Update who can see this list',
  [intl.riskMonitoringNew('your-lists')]: 'Your lists',
  [intl.riskMonitoringNew('sum-of-all-ids-found')]:
    'Sum of all IDs found in the file: {count}.',
  [intl.riskMonitoringNew('faulty-ids-found-message')]:
    '{count, plural, one {{count} faulty company ID found.} other {{count} faulty company IDs found.}}',
  [intl.riskMonitoringNew('faulty-ids-button-export-label')]:
    'Export faulty ID(s)',
  [intl.riskMonitoringNew('faulty-ids-file-name')]: 'FaultyIDs',
  [intl.riskMonitoringNew('duplicate-ids-found-message')]:
    '{count, plural, one {{count} duplicate company ID found.} other {{count} duplicate company IDs found.}}',
  [intl.riskMonitoringNew('duplicate-countries-found-message')]:
    "We found some Local Id's that could be assigned to a company in more than 1 country. Please help us select the correct one",
  [intl.riskMonitoringNew('duplicate-ids-button-export-label')]:
    'Export duplicate ID(s)',
  [intl.riskMonitoringNew('duplicate-ids-file-name')]: 'DuplicateIDs',
  [intl.riskMonitoringOverview('all')]: 'All',
  [intl.riskMonitoringOverview('da_DK')]: 'Denmark',
  [intl.riskMonitoringOverview('delete-all-button')]: 'Delete all',
  [intl.riskMonitoringOverview('delete-all-popup-text')]:
    'This action will delete all the companies you are currently monitoring. Are you sure you want to proceed?',
  [intl.riskMonitoringOverview('delete-all-popup-title')]:
    'Delete all monitored companies',
  [intl.riskMonitoringOverview('high-risk')]: 'High Risk',
  [intl.riskMonitoringOverview('low-risk')]: 'Low Risk',
  [intl.riskMonitoringOverview('medium-risk')]: 'Medium Risk',
  [intl.riskMonitoringOverview('no-score')]: 'No Score',
  [intl.riskMonitoringOverview('no_NO')]: 'Norway',
  [intl.riskMonitoringOverview('sv_SE')]: 'Sweden',
  [intl.riskMonitoringOverview('total')]: 'Total',
  [intl.riskMonitoringSubscription('add-description')]:
    'You can create a subscription for the parameters you want to hear about. We will send you a weekly mail with the changes in your monitoring list.',
  [intl.riskMonitoringSubscription('add-subscription')]: 'Add Monitoring',
  [intl.riskMonitoringSubscription('add-subscription-title')]:
    'Add a Monitoring Subscription',
  [intl.riskMonitoringSubscription('address')]: 'Address',
  [intl.riskMonitoringSubscription('all-important-changes')]:
    'All Important changes',
  [intl.riskMonitoringSubscription('all-regular-changes')]:
    'All Regular Changes',
  [intl.riskMonitoringSubscription('annual_reports')]: 'Annual Reports',
  [intl.riskMonitoringSubscription('company_type')]: 'Company Type',
  [intl.riskMonitoringSubscription('email')]: 'Email',
  [intl.riskMonitoringSubscription('employees')]: 'Number of Employees',
  [intl.riskMonitoringSubscription('gazette')]: 'Gazette',
  [intl.riskMonitoringSubscription('important-changes')]: 'Important Changes',
  [intl.riskMonitoringSubscription('industry')]: 'Industry',
  [intl.riskMonitoringSubscription('manage-description')]:
    'You can delete your subscription or change the parameters you want to hear about in the future.',
  [intl.riskMonitoringSubscription('manage-subscription')]: 'Preferences',
  [intl.riskMonitoringSubscription('manage-subscription-title')]:
    'Manage Your Monitoring Subscription',
  [intl.riskMonitoringSubscription('name')]: 'Company Name',
  [intl.riskMonitoringSubscription('phone_number')]: 'Phone Number',
  [intl.riskMonitoringSubscription('receive-updates-description')]:
    'Whenever there are changes relevant to you, we will make sure to send them your way.',
  [intl.riskMonitoringSubscription('receive-updates-title')]:
    'Updates to your email',
  [intl.riskMonitoringSubscription('regular-changes')]: 'Regular Changes',
  [intl.riskMonitoringSubscription('relations')]: 'Relations',
  [intl.riskMonitoringSubscription('remove-subscription')]:
    'Remove Subscription',
  [intl.riskMonitoringSubscription('score')]: 'Risika Score',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-added')]:
    'We unfortunately could not add your subscription',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-removed')]:
    'We unfortunately could not remove your subscription',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-updated')]:
    'We unfortunately could not update your subscription',
  [intl.riskMonitoringSubscription('snackbar-subscription-added')]:
    'Your subscription has been added',
  [intl.riskMonitoringSubscription('snackbar-subscription-removed')]:
    'Your subscription has been removed',
  [intl.riskMonitoringSubscription('snackbar-subscription-updated')]:
    'Your subscription has been updated',
  [intl.riskMonitoringSubscription('status')]: 'Company Status',
  [intl.searchBusiness('api-error')]: 'Request failed: {message}',
  [intl.searchBusiness('clear-range-picker')]: 'Clear Risika Score',
  [intl.searchBusiness('company')]: 'Company',
  [intl.searchBusiness('employees')]: 'Employees',
  [intl.searchBusiness('local-id')]: 'Local ID',
  [intl.searchBusiness('max-export')]:
    '{count, plural, other {Export limited to the first {count} rows}}',
  [intl.searchBusiness('no-data')]: 'No data available',
  [intl.searchBusiness('personal-id')]: 'Personal Id',
  [intl.searchBusiness('results-time-elapsed')]:
    '{count, plural, one {# result} other {# results}} in {time, plural, other {#}} seconds',
  [intl.searchBusiness('risika-score')]: 'Risika Score',
  [intl.searchBusiness('search-field')]: 'Search',
  [intl.searchBusiness('search-placeholder')]:
    'Enter company name/id, address, mail or phone number',
  [intl.searchBusiness('selector-companies')]:
    '{count, plural, one {Company ({countDisplay})} other {Companies ({countDisplay})}}',
  [intl.searchBusiness('selector-no-companies')]:
    "We couldn't find any companies. Searching for people instead.",
  [intl.searchBusiness('selector-no-people')]:
    "We couldn't find any peopls. Searching for people companies.",
  [intl.searchBusiness('selector-people')]:
    '{count, plural, one {Individual (#)} other {Individuals (#)}}',
  [intl.searchBusiness('status')]: 'Status',
  [intl.searchBusiness('title')]: 'Search',
  [intl.searchBusiness('type')]: 'Type',
  [intl.settings('address')]: 'Company address',
  [intl.settings('all-users')]: 'All users',
  [intl.settings('are-you-sure-delete-user')]:
    'Are you sure you want to delete this user?',
  [intl.settings('cant-delete-yourself')]: 'You can not delete yourself!',
  [intl.settings('cant-have-user-and-admin')]:
    'You can either select "User" or "Admin"!',
  [intl.settings('code-helper-text')]:
    'Copy this text and add it to your website',
  [intl.settings('company-details-title')]: 'Details about your subscription',
  [intl.settings('company-information-title')]: 'Company information',
  [intl.settings('company-information-updated-success')]:
    'Company information has been updated',
  [intl.settings('confirm-new-password')]: 'Confirm new password',
  [intl.settings('confirm-password-requirements')]:
    'Confirm password must match the password',
  [intl.settings('content-copied')]: 'Content copied to clipboard',
  [intl.settings('copy-icon')]: 'Copy content',
  [intl.settings('customerType')]: 'Subscription type',
  [intl.settings('dark')]: 'Dark',
  [intl.settings('domain-label')]: 'Domain',
  [intl.settings('email-validation')]: 'Invalid email address',
  [intl.settings('general-information')]: 'General information',
  [intl.settings('generate')]: 'Generate',
  [intl.settings('invalid-phone')]: 'Invalid phone number',
  [intl.settings('invalid-url')]: 'Invalid URL',
  [intl.settings('invalid-url')]: 'Please add a valid URL',
  [intl.settings('invalid-url-phone')]: 'Invalid URL and phone',
  [intl.settings('large')]: 'Large',
  [intl.settings('created-at')]: 'Created at',
  [intl.settings('last-login')]: 'Last activity',
  [intl.settings('light')]: 'Light',
  [intl.settings('link-type')]: 'Link type',
  [intl.settings('logo-title')]: 'Step 1: Tell us where will you use our logo',
  [intl.settings('mail-send')]: 'Email sent successfully',
  [intl.settings('medium')]: 'Medium',
  [intl.settings('monitoring-used')]: 'Number of monitoring',
  [intl.settings('name')]: 'Company name',
  [intl.settings('name-validation')]: 'Please add first name',
  [intl.settings('new-password')]: 'New password',
  [intl.settings('password-requirements')]:
    'Password has to be at least 4 characters long',
  [intl.settings('password-title')]: 'Password',
  [intl.settings('password-updated-successfully')]:
    'Password updated successfully',
  [intl.settings('phone-label')]: 'Phone number',
  [intl.settings('preview-title')]: 'Step 2: Design your logo',
  [intl.settings('radio-text')]: 'What background will the logo be on?',
  [intl.settings('remainingMonitoring')]: 'Remaining monitoring',
  [intl.settings('remainingUsers')]: 'Remaining users',
  [intl.settings('risika-logo')]: 'Get a Risika logo',
  [intl.settings('see-code')]: 'See code',
  [intl.settings('select-helper-text')]:
    'Do you need HTML or direct link code?',
  [intl.settings('share-logo')]: 'Share logo',
  [intl.settings('share-logo-message')]:
    'Add a custom message to send with the logo',
  [intl.settings('size-text')]: 'Size of your logo',
  [intl.settings('small')]: 'Small',
  [intl.settings('step-3')]: 'Step 3: Send the code to your developer',
  [intl.settings('termsOfService')]: 'Terms and conditions',
  [intl.settings('title')]: 'Settings',
  [intl.settings('update-avatar')]: 'UPDATE AVATAR',
  [intl.settings('update-company')]: 'Update company',
  [intl.settings('update-password')]: 'Update password',
  [intl.settings('update-user')]: 'Update user',
  [intl.settings('user-information-title')]: 'User information',
  [intl.settings('user-management')]: 'User Management',
  [intl.settings('users-used')]: 'Number of users',
  [intl.settings('whitelist')]: 'Website',
  [intl.settings('whitelist-helper-text')]:
    'After entering your website press enter to validate it. Then press Generate.',
  [intl.settings('scoring-model')]: 'Scoring model',
  [intl.settings('click-to-change-currency')]:
    'Click to select another currency',

  [intl.shareReport('add-custom-message')]: 'Message for recipient (optional)',
  [intl.shareReport('select-recipient')]: 'Select recipient',
  [intl.shareReport('sender-name')]: 'Name of sender (optional)',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from NO',
  )]:
    'Your subscription plan does not allow you to monitor resources from Norway',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from SE',
  )]:
    'Your subscription plan does not allow you to monitor resources from Sweden',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor this many companies',
  )]:
    'Your subscription plan does not allow you to monitor this many companies',
  [intl.snackbar('account-activated-failed')]: 'An error occurred!',
  [intl.snackbar('account-activated-success')]:
    'Perfect! You can now begin using Risika',
  [intl.snackbar('add-companies-monitoring')]:
    'Companies added to Risk Monitoring',
  [intl.snackbar('add-companies-monitoring-failed')]:
    'Import failed: please ensure you have a column called "cvr"',
  [intl.snackbar('add-company-monitoring')]: 'Company added to Risk Monitoring',
  [intl.snackbar('added-back')]: 'added back to risk monitoring',
  [intl.snackbar('all-added-back')]: 'companies added back to risk monitoring',
  [intl.snackbar('companies-added-to-list')]: 'Companies added to {list}',
  [intl.snackbar('companies-failed-to-add')]:
    'Companies failed to add in {list}',
  [intl.snackbar('company-added-to-list')]: 'Company added to {list}',
  [intl.snackbar('company-removed-from-list')]: 'Company removed from {list}',
  [intl.snackbar('copied')]: 'Copied',
  [intl.snackbar('dismiss')]: 'Dismiss',
  [intl.snackbar('export-failed')]: 'Failed to export',
  [intl.snackbar('export-success')]: 'Your export is ready',
  [intl.snackbar('failed-to-add-company')]: 'Failed to add the company',
  [intl.snackbar('failed-to-add-emails')]: 'Failed to add emails',
  [intl.snackbar('fields-required')]: 'Please fill the required fields',
  [intl.snackbar('invalid-email')]: 'Please add a valid email',
  [intl.snackbar('logo-shared-succes')]: 'Logo shared successfully',
  [intl.snackbar('maximum-export-exceded-exporting-less')]:
    'Maximum amount of companies to export exceeded. Exporting the first {exportLimit} companies',
  [intl.snackbar('no-subsidiaries')]: 'Selected company has no subsidiaries',
  [intl.snackbar('password-not-allowed')]: 'Please choose another password',
  [intl.snackbar('remove-companies-monitoring')]:
    'All companies removed from Risk Monitoring',
  [intl.snackbar('remove-company-monitoring')]:
    'Company removed from Risk Monitoring',
  [intl.snackbar('remove-failed')]: 'We failed to remove your webhook',
  [intl.snackbar('remove-successfull')]: 'Your webhook was removed',
  [intl.snackbar('report-error-something-wrong')]: 'Something went wrong',
  [intl.snackbar('report-error-timeout')]:
    'At this time we do not have a report for',
  [intl.snackbar('report-failed-to-submit')]: 'Report failed to submit',
  [intl.snackbar('report-submited')]: 'Report submitted successfully',
  [intl.snackbar('reset-filters')]: 'Filters have been reset',
  [intl.snackbar('settings-saved')]: 'Settings saved',
  [intl.snackbar('shared-succes')]: 'Report shared successfully',
  [intl.snackbar('test-failed')]: 'Something went wrong with the test: {error}',
  [intl.snackbar('test-successfull')]:
    'You will receive an example response from us within 2 minutes',
  [intl.snackbar('undo')]: 'Undo',
  [intl.snackbar('users-added-successfully')]: 'Users added successfully',
  [intl.snackbar('warn-user-of-new-version')]:
    'We have just launched a danish version of the platform. Please pick a language in the top right corner in order to solve the wrong texts on the platform.',
  [intl.table('loading')]: 'Loading...',
  [intl.table('next')]: 'Next',
  [intl.table('no-rows')]: 'No rows available',
  [intl.table('of')]: 'of',
  [intl.table('page')]: 'Page',
  [intl.table('previous')]: 'Previous',
  [intl.table('rows')]: 'rows',
  [intl.toolbar('add-email')]: 'Add email',
  [intl.toolbar('add-phone')]: 'Add phone',
  [intl.toolbar('billing-plan')]: 'Billing plan',
  [intl.toolbar('edit-profile')]: 'Edit profile',
  [intl.toolbar('greeting')]: 'Hello',
  [intl.toolbar('logout')]: 'Log out',
  [intl.toolbar('role')]: 'Role',
  [intl.toolbar('welcome-to-risika')]: 'Welcome to Risika Dashboard',
  [intl.tooltip('click-to-copy')]: 'Click to copy',
  [intl.userManagement('delete-dialog-content')]:
    'Are you sure you want to delete {user}?',
  [intl.userManagement('delete-dialog-title')]: 'Delete user',
  [intl.userManagement('failed-to-load-users')]: 'Failed to load users',
  [intl.userManagement('invalid-email')]: 'Invalid email',
  [intl.userManagement('invite-header')]: 'Invite users to Risika',
  [intl.userManagement('invite-users-button')]: 'Invite users',
  [intl.userManagement('invites-send-failed')]:
    'We could not send email to {email}',
  [intl.userManagement('invites-send-success')]:
    'Invite for {email} send successfully',
  [intl.userManagement('maximum-invites-reached')]:
    'You have reached the cap of your invites. Click here to purchase more',
  [intl.userManagement('more-than-30')]: 'More than 30 days',
  [intl.userManagement('no-data-yet')]: 'No data yet',
  [intl.userManagement('role-change-failed')]: 'Failed to change role',
  [intl.userManagement('role-change-success')]: 'Successfully changed role',
  [intl.userManagement('send-invite-button')]: 'Invite',
  [intl.userManagement('user-delete-failed')]: 'Failed to delete user',
  [intl.userManagement('user-delete-success')]: 'Successfully deleted user',
  [intl.userManagement('user-update-success')]: 'Successfully updated user',
  [intl.userManagement('user-update-failed')]: 'Failed to updated user',
  [intl.userManagement('user-create-success')]: 'Successfully created user',
  [intl.userManagement('user-create-failed')]: 'Failed to create user',
  [intl.visualScore('high-risk')]: 'High risk',
  [intl.visualScore('low-risk')]: 'Low risk',
  [intl.visualScore('medium-risk')]: 'Medium risk',
  [intl.companyComparison('title')]: 'Company Comparison',
  [intl.companyComparison('add-to-comparison-button')]: 'Add to Comparison',
  [intl.companyComparison('add-to-comparison-tooltip')]:
    'Add this company to comparative list',
  [intl.companyComparison('dialog-cannot-add-title')]: 'Error adding company',
  [intl.companyComparison('dialog-cannot-add-content')]:
    'The company could not be added to the comparison list.',
  [intl.observationalLists('title')]: 'Observational Lists',
  [intl.observationalLists('risika-score-title')]: 'Risika credit score',
  [intl.observationalLists('risika-score-by-time-title')]:
    'Development of the Risika credit score over time',
  [intl.observationalLists('risika-score-by-company-title')]:
    'Risika credit score by company type',
  [intl.observationalLists('risika-score-distribution-title')]:
    'Risika credit score distribution',
  [intl.observationalLists('risika-score-employees-title')]:
    'Risika credit score by number of employees',
  [intl.observationalLists('risika-score-industry-title')]:
    'Risika credit score by industry',
  [intl.observationalLists('key-figures-industry-title')]:
    'Key figures by industry',
  [intl.observationalLists('geographical-risika-score-title')]:
    'Risika credit score distributed geographically',
  [intl.observationalLists('development-selected-key-figures-title')]:
    'Development in selected key figures',
  [intl.observationalLists('risika-score-over-time-title')]:
    'Risika credit score development over time',
  [intl.observationalLists('risika-key-figures-by-company-type-title')]:
    'Key figures by company type',
  [intl.observationalLists('tab-risika-credit-score')]: 'Risika Credit Score',
  [intl.observationalLists('tab-financial-performance')]:
    'Financial Performance',
  [intl.observationalLists('tab-companies')]: 'Companies',
  [intl.observationalLists('annual-result-average-title')]:
    'Annual result, Avg.',
  [intl.observationalLists('equity-average-title')]: 'Equity, Avg.',
  [intl.observationalLists('employees-average-title')]:
    'Number of employees, Avg.',
  [intl.observationalLists('list-id-error')]:
    'Please provide the valid list ID!',
  [intl.autoSelectCompanies('search-box-title')]: 'Search companies',
  [intl.keyFigures('title')]: 'Key figures',
  [intl.keyFiguresDevelopment('title')]: 'Key figures development',
  [intl.keyFiguresDevelopment('liquidity-ratio')]: 'Liquidity ratio',
  [intl.keyFiguresDevelopment('result')]: 'Result',
  [intl.charts('radar-tooltip-score-since')]: 'Since',
  [intl.charts('radar-tooltip-ratio')]:
    'The {key} is {value}% which is considered a {rating} {key} in it’s industry.',
  [intl.charts('radar-key-figures-info')]:
    'The chart shows company key figure performance evaluated in its industry. The four chosen key figures represents company profitability, solidity, liquidity and risk of bankruptcy.',
  [intl.alikeCompanies('dialog-not-found-title')]: 'No company to show',
  [intl.alikeCompanies('dialog-not-found-content')]:
    'No similar company found.',
  [intl.alikeCompanies('tooltip')]:
    'A similar company will be found based on the first company that is in the company comparison. The similar company is based on active companies that are in the same industry and have roughly the same number of employees and capital.',
  [intl.portfolioInsights('drawer-trigger-button')]: 'Portfolio Insights',
  [intl.portfolioInsights('tab-summary-label')]: 'Summary',
  [intl.portfolioInsights('tab-compliance-label')]: 'Compliance',
  [intl.portfolioInsights('tab-scheduler-label')]: 'Scheduler',
  [intl.portfolioInsights('tab-scheduler-destination')]: 'Destination',
  [intl.portfolioInsights('tab-scheduler-destination-email')]: 'Email',
  [intl.portfolioInsights('tab-scheduler-destination-email-recipients')]:
    'Email recipients',
  [intl.portfolioInsights('tab-scheduler-destination-email-search')]:
    'Search email',
  [intl.portfolioInsights('tab-scheduler-frequency-label')]: 'Frequency',
  [intl.portfolioInsights('tab-scheduler-frequency-send-every')]: 'Send every',
  [intl.portfolioInsights('tab-scheduler-frequency-week')]: 'Week',
  [intl.portfolioInsights('tab-scheduler-frequency-month')]: 'Month',
  [intl.portfolioInsights('tab-scheduler-frequency-year')]: 'Year',
  [intl.portfolioInsights('tab-scheduler-frequency-send-on')]: 'Send on',
  [intl.portfolioInsights('tab-scheduler-frequency-day-mon')]: 'Mon',
  [intl.portfolioInsights('tab-scheduler-frequency-day-tue')]: 'Tue',
  [intl.portfolioInsights('tab-scheduler-frequency-day-wed')]: 'Wed',
  [intl.portfolioInsights('tab-scheduler-frequency-day-thu')]: 'Thu',
  [intl.portfolioInsights('tab-scheduler-frequency-day-fri')]: 'Fri',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sat')]: 'Sat',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sun')]: 'Sun',
  [intl.portfolioInsights('tab-scheduler-frequency-send-time')]: 'Send time',
  [intl.portfolioInsights('tab-scheduler-frequency-time')]: 'Time',
  [intl.portfolioInsights('tab-scheduler-status')]: 'Status',
  [intl.portfolioInsights('tab-scheduler-status-active')]: 'Active',
  [intl.portfolioInsights('tab-scheduler-status-disabled')]: 'Disabled',
  [intl.portfolioInsights('tab-scheduler-save-changes')]: 'Save changes',
  [intl.portfolioInsights('portfolio-score')]: 'PORTFOLIO SCORE',
  [intl.portfolioInsights('portfolio-risk-distribution')]:
    'Portfolio risk distribution',
  [intl.portfolioInsights('portfolio-risk-distribution-subtitle')]:
    '{currentCompaniesCount} of {allCompaniesCount} companies',
  [intl.portfolioInsights('Low risk')]: 'Low risk',
  [intl.portfolioInsights('Medium risk')]: 'Medium risk',
  [intl.portfolioInsights('High risk')]: 'High risk',
  [intl.portfolioInsights('Unknown risk')]: 'Unknown risk',
  [intl.portfolioInsights('portfolio-risk-history')]: 'Portfolio risk history',
  [intl.portfolioInsights('credit-policy-compliance')]:
    'Credit policy compliance',
  [intl.portfolioInsights('credit-policy-violation')]:
    'Credit policy violation',
  [intl.portfolioInsights('compliance-history')]: 'Compliance history',
  [intl.portfolioInsights('compliant-label')]: 'Compliant',
  [intl.portfolioInsights('non-compliant-label')]: 'Non-compliant',
  [intl.errors('hubspot_error')]:
    'Email validation failed, Please use a company email or contact our customer service',
  [intl.errors('companies_local_id_country_unique')]: 'Company ID already used',
  [intl.errors('company_already_exists')]: 'Company ID already used',
  [intl.errors('invalid_local_id')]:
    'You have used a invalid Company or Local id',
  [intl.errors('error_creating_new_customer')]:
    'Something went wrong on our end, please contact our customer service at contact@risika.com',
}

export default messages
