import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { selectStyles } from '../Restrictions.styles'

function MultiSelect({
  value,
  label,
  onChange,
  isLoading,
  options,
}: {
  value: string[]
  label: string
  onChange: (newValue: string | string[]) => void
  isLoading: boolean
  options: {
    label: string
    key: string | number
  }[]
}) {
  const correctOrderOptions = options.map((x) => x.key)
  const alwaysInTheCorrectOrder = (a: string, b: string) => {
    return correctOrderOptions.indexOf(a) - correctOrderOptions.indexOf(b)
  }
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={isLoading}
        value={value.sort(alwaysInTheCorrectOrder)}
        label={label}
        multiple={true}
        renderValue={(selected) => selected.join(', ')}
        onChange={(e) => (!isLoading ? onChange(e.target.value) : null)}
      >
        {options.map(({ key, label }) => (
          <MenuItem
            disabled={isLoading}
            sx={selectStyles.selectMenuItem}
            key={key}
            value={key}
          >
            <Checkbox checked={value.includes(key.toString())} />
            <Typography>{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelect
