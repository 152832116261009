import axios from 'axios'
import { THORN_API_BASE_URL } from 'services/helpers/config'
/******************
 * Fetch from API *
 ******************/

const searchSuggestions = (searchText, country = 'dk') => {
  return axios
    .get(
      `${THORN_API_BASE_URL}/search-suggestions?search=${searchText}&country=${country}`,
    )
    .then(({ data }) =>
      data.map(({ name, local_id }) => ({
        name,
        localId: local_id,
      })),
    )
}

export default searchSuggestions
