import { useQuery } from 'react-query'
import { getRisikaRefreshToken } from 'services/cmAPI/getRisikaRefreshToken'

export function useRisikaRefreshTokenWeb(customerId) {
  return useQuery(
    ['getRisikaRefreshToken'],
    () => getRisikaRefreshToken(customerId),
    {
      enabled: false,
    },
  )
}
