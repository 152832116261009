export const env = {
  RISIKA_API_VERSION: process.env.REACT_APP_RISIKA_API_VERSION,
  RISIKA_API_BASE_URL: process.env.REACT_APP_RISIKA_API_BASE_URL,
  THORN_API_BASE_URL: process.env.REACT_APP_THORN_API_BASE_URL,
  RISIKA_API_ACCESS_TOKEN: process.env.REACT_APP_RISIKA_API_ACCESS_TOKEN,
  AUTH_REFRESH_TOKEN: process.env.REACT_APP_AUTH_REFRESH_TOKEN,
  AUTH_ACCESS_TOKEN: process.env.REACT_APP_AUTH_ACCESS_TOKEN,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  PROFF_REPORT_API_BASE_URL: process.env.REACT_APP_PROFF_REPORT_API_BASE_URL,
  WEB_API_BASE_URL: process.env.REACT_APP_CM_API_BASE_URL,

  FB_API_KEY: process.env.REACT_APP_FB_API_KEY,
  FB_AUTH_DOMAIN: process.env.REACT_APP_FB_AUTH_DOMAIN,
  FB_PROJECT_ID: process.env.REACT_APP_FB_PROJECT_ID,
  FB_STORAGE_BUCKET: process.env.REACT_APP_FB_STORAGE_BUCKET,
  FB_MESSAGING_SENDER_ID: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  FB_APP_ID: process.env.REACT_APP_FB_APP_ID,
}
