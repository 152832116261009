import callCmAPI from 'services/helpers/callCmAPI'

export const createEconomicIntegration = ({
  customerId,
  economicToken,
}: {
  customerId: string
  economicToken: string
}) =>
  callCmAPI(`/integration/economic`, {
    method: 'post',
    data: {
      customer_id: Number(customerId),
      economic_token: economicToken,
    },
  })
    .then((res) => res.data)
    .catch((err) => err)
