// import { addAppLocaleData } from './localeData'
import english from './languages/english'
import danish from './languages/danish'
import norwegian from './languages/norwegian'

// Register the locales
// addAppLocaleData()

export const supportedLanguages = ['en_UK', 'da_DK', 'nb_NO']

export const defaultLanguage = 'da_DK'

// Export the translations for the supported languages
export default {
  en: english,
  da: danish,
  nb: norwegian,
}
