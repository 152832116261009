import React, { useEffect } from 'react'
import { Button } from 'components'
import { Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default as cx } from 'classnames'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { useRisikaRefreshTokenWeb } from 'services/queries/useRisikaRefreshToken'
import { copyToClipboard } from 'services/helpers/localId'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'styles/colors'

export const useStyles = makeStyles(() => ({
  ciColBtn: {
    display: 'flex',
  },
  ciBtn: {
    color: 'white',
    alignSelf: 'center',
  },
  ciBtnCopy: {
    backgroundColor: colors?.lowRisk,
  },
}))

const CopyApiToken = ({ customerData }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { data, refetch, isLoading } = useRisikaRefreshTokenWeb(customerData.id)

  useEffect(() => {
    if (!data) return
    if (data.success === true) {
      // TODO data type not defined
      copyToClipboard(data.data)
      enqueueSnackbar('Successfully copied API key', { variant: 'success' })
    }
    if (data.success === false) {
      enqueueSnackbar(data.error.name, { variant: 'error' })
    }
  }, [data])

  return (
    <Box component="div" className={classes.ciColBtn}>
      <Button
        className={cx(classes.ciBtn, classes.ciBtnCopy)}
        onClick={refetch}
        icon={<FontAwesomeIcon icon={faCopy} />}
        disabled={isLoading}
      >
        {isLoading ? 'Loading' : 'Copy API token'}
        {/* {isLoading ? 'Loading' : 'Copy API token'} */}
      </Button>
    </Box>
  )
}

export default CopyApiToken
