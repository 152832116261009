import React from 'react'
import { Stack } from '@mui/material'
import StaticItem from './StaticItem'
import { useParams } from 'react-router'
import { useCustomerData } from 'services/queries/useCustomerData'

export const StaticModel = [
  { title: 'Name', field: 'name' },
  { title: 'ID', field: 'id' },
  { title: 'Local ID', field: 'local_id' },
  { title: 'Created at', field: 'created_at', isDate: true },
  { title: 'Expire', field: 'expires_at', isDate: true },
]

const Static = () => {
  const { id: selectedCustomer } = useParams()
  const { data } = useCustomerData(selectedCustomer)
  return (
    <Stack direction="row" spacing={3} mb={3}>
      {StaticModel.map((item) => (
        <StaticItem key={item.title} data={data[item.field]} item={item} />
      ))}
    </Stack>
  )
}

export default Static
