import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
const mockedData = [
  {
    tableName: 'credit_check',
    keys: [
      'general-company-information',
      'key-financial-figures',
      'relevant-financial-ratios',
      'owners',
      'management',
      'map',
    ],
  },
  {
    tableName: 'company_info',
    keys: [
      'company-name',
      'company-id',
      'email',
      'phone',
      'webpage',
      'address',
      'ad-protection',
      'registered-for-vat',
      'ean',
      'company-type',
      'date-of-establishment',
      'founders_auditors',
      'auditors',
      'auditor_selected',
      'financial_year',
      'bank',
      'share-capital',
      'powers-to-bind',
      'purpose',
      'number-of-employees',
      'industry',
      'production-units',
      'listed',
      'secondary-names',
      'status',
      'internal-id',
      'property',
    ],
  },
  {
    tableName: 'monitor_table',
    keys: [
      'companyName',
      'localId',
      'creditMax',
      'score',
      'country',
      'status',
      'profitLoss',
      'equity',
      'employeeInterval',
      'currency',
      'companyType',
      'solidity',
      'dateOfIncorporation',
      'internalId',
      'capital',
      'liquidity',
    ],
  },
  {
    tableName: 'credit_check_sidebar',
    keys: [
      'norwegian-payment-remarks',
      'estimation',
      'performance',
      'credit-policy',
      'explanation',
      'company-highlights',
      'recommendation',
      'credit-override',
    ],
  },
]

type SettingsKeysProps = {
  keys: string[]
  onDelete: (key: string) => void
}

const SettingsKeys = (props: SettingsKeysProps) => {
  const { keys, onDelete } = props

  return (
    <List dense={true}>
      {keys.map((key) => {
        return (
          <Skeleton key={key}>
            <ListItem
              key={key}
              secondaryAction={
                <Tooltip
                  title="We would generally discourage this action!"
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    onClick={() => onDelete(key)}
                    aria-label="delete"
                  >
                    <Delete sx={{ width: '2rem', height: '2rem' }} />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText primary={key} />
            </ListItem>
          </Skeleton>
        )
      })}
    </List>
  )
}
function Loading() {
  const data = mockedData

  return (
    <Stack spacing={5}>
      {data?.map((structure) => {
        return (
          <>
            <Skeleton>
              <Stack direction="row" spacing={5}>
                <Typography key={structure?.tableName} variant="h2">
                  {structure?.tableName}
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={
                    <Add
                      sx={{ color: 'green', width: '2rem', height: '2rem' }}
                    />
                  }
                >
                  Add new key
                </Button>
              </Stack>
            </Skeleton>
            <SettingsKeys keys={structure?.keys} onDelete={(key) => () => {}} />
          </>
        )
      })}
    </Stack>
  )
}

export default Loading
