import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { Show } from 'components'
import CustomSwitch from './CustomSwitch'

type Value = {
  model: string
  explanation?: boolean
}
function ScoreModels({
  value,
  label,
  onChange,
  isLoading,
  options,
  availableModels,
}: {
  value: Value[]
  label: string
  onChange: (newValue: Value[]) => void
  isLoading: boolean
  options: {
    label: string
    key: string | number
  }[]
  availableModels: { model: string; canBeChanged: boolean }[]
}) {
  const handleUpdate = (
    field: 'model' | 'explanation',
    curValue: boolean,
    model: string,
  ) => {
    switch (field) {
      case 'model':
        if (!curValue) {
          if (model === 'BOHR') {
            onChange([...value, { model, explanation: false }])
          } else {
            onChange([...value, { model }])
          }
        } else {
          onChange(value.filter((x) => x.model !== model))
        }
        break
      case 'explanation':
        break

      default:
        break
    }
  }

  const handleValue = (value: Value[], model) => {
    if (value) {
      const modelValue = value.map((x) => x.model)
      return modelValue.includes(model)
    }
  }
  // In case explanation starts working at some point. It is not ready though.
  // const handleValueForExplanation = (value: Value[], model) => {
  //   const modelValue = value.filter((x) => x.model)[0]
  //   return modelValue.explanation
  // }

  const checkIfDisabled = (model) => {
    const modelValue = availableModels.filter((x) => x.model === model)[0]
    return !modelValue.canBeChanged
  }

  return (
    <Show when={value}>
      <Paper>
        {availableModels.length ? (
          availableModels.map(({ model }) => (
            <Stack key={model} sx={{ margin: 3 }}>
              <Typography variant="h5">{model}</Typography>
              <Tooltip
                placement="top"
                title={
                  checkIfDisabled(model) ? 'You can not change this model' : ''
                }
              >
                <Box display="flex">
                  <CustomSwitch
                    isLoading={isLoading}
                    // disabled={checkIfDisabled(model)}
                    onChange={() =>
                      handleUpdate('model', handleValue(value, model), model)
                    }
                    value={handleValue(value, model)}
                    label="Model"
                  />
                </Box>
              </Tooltip>
              {/* <Show when={handleValue(value, model)}>
                <CustomSwitch
                  isLoading={isLoading}
                  onChange={() =>
                    handleUpdate(
                      'explanation',
                      handleValue(value, model),
                      model,
                    )
                  }
                  value={handleValueForExplanation(value, model)}
                  label="Explanation"
                />
              </Show> */}
            </Stack>
          ))
        ) : (
          <Typography variant="h4">
            No available properties at this time
          </Typography>
        )}
      </Paper>
    </Show>
  )
}

export default ScoreModels
