import { useQuery } from 'react-query'
import { getIntegrationsForCustomer } from 'services/cmAPI/getIntegrationsForCustomer'

export function useGetIntegrationsForCustomer(customerId: string) {
  return useQuery(
    ['useGetIntegrationsForCustomer'],
    () => getIntegrationsForCustomer(customerId),
    {},
  )
}
