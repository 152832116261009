import { Grid, Paper, Stack, Typography } from '@mui/material'
import { counters } from '../Restrictions.model'
import { CountersCapsTypeWithExtras } from 'services/cmAPI/getCustomer'
import CreateInputElement from './CreateInputElement'
function Counters({
  data,
  search,
}: {
  data: CountersCapsTypeWithExtras
  search: string
}) {
  // Search filter
  const searchFilter = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }
  const alphabeticalSort = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }

  const sortedCounters = counters.sort(alphabeticalSort)
  return (
    <Paper sx={{ width: '100%' }} elevation={3}>
      <Stack sx={{ padding: '5rem' }} spacing={3} alignItems="center">
        <Typography variant="h3">Counters</Typography>
        <Grid container spacing={5}>
          {sortedCounters.filter(searchFilter).map((counter) => (
            <Grid key={counter.key} item xs={4}>
              <CreateInputElement
                key={counter.key}
                element={counter}
                data={data}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Paper>
  )
}

export default Counters
