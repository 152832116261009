import { createTheme } from '@material-ui/core'
import { createTheme as createTheme5 } from '@mui/material'
import { Shadows } from '@mui/material/styles/shadows'

const themeObject = (() => {
  const spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64, 80]
  const shape = { borderRadius: 4 }
  const shadows = [
    'none',
    '0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
    '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    '0px 4px 6px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.1)',
    '0px 6px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.12)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ] as Shadows

  const palette = {
    primary: {
      100: '#F6FBFC',
      300: '#ECFCFF',
      500: '#54BDCB',
      700: '#245157',
      900: '#0A373D',
      main: '#245157',
      light: '#ECFCFF',
      dark: '#0A373D',
    },
    secondary: {
      100: '#FFF3E2',
      300: '#FFCC89',
      500: '#FFB75A',
      700: '#F57C1F',
      900: '#E55319',
      main: '#FFB75A',
      light: '#FFCC89',
      dark: '#F57C1F',
    },
    error: {
      100: '#FFEAEE',
      300: '#EC6E74',
      500: '#FD3136',
      700: '#DC182F',
      900: '#AB0017',
      main: '#DC182F',
      light: '#EC6E74',
      dark: '#AB0017',
    },
    warning: {
      100: '#FFF8E1',
      300: '#FFD54F',
      500: '#FFC107',
      700: '#FFA000',
      900: '#DF6100',
      main: '#FFA000',
      light: '#FFD54F',
      dark: '#DF6100',
    },
    success: {
      100: '#E8F5E9',
      300: '#81C784',
      500: '#4CAF50',
      700: '#27882A',
      900: '#19581D',
      main: '#4CAF50',
      light: '#81C784',
      dark: '#27882A',
    },
    info: {
      100: '#E3F2FD',
      300: '#63B4F6',
      500: '#2196F3',
      700: '#1A75D2',
      900: '#0F3F93',
      main: '#2196F3',
      light: '#63B4F6',
      dark: '#1A75D2',
    },
    grey: {
      50: '#F8F9FA',
      100: '#F1F3F5',
      200: '#E9ECEF',
      300: '#DFE2E6',
      400: '#CFD4D9',
      500: '#AEB5BC',
      600: '#878E95',
      700: '#4A5056',
      800: '#353A3F',
      900: '#22252A',
      main: '#245157',
      light: '#54BDCB',
      dark: '#F1F3F5',
    },
    // TODO: Speak with Can to notify him (changed from neutral to common) and ask to change the name
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  }

  const typography = {
    allVariants: { color: palette.grey[800] },
    h1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 44,
      letterSpacing: -0.25,
      lineHeight: '48px',
    },
    h2: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 32,
      letterSpacing: 0,
      lineHeight: '38px',
    },
    h3: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: '28px',
    },
    h4: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: '26px',
    },
    h5: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    subtitle1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    subtitle2: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: 0.2,
      lineHeight: '22px',
    },
    body1: {
      fontFamily:
        '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      fontFamily:
        '"Lato","Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.25,
      lineHeight: '22px',
    },
    button: {
      fontFamily:
        '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: '20px',
    },
    caption: {
      fontFamily:
        '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: '18px',
    },
    overline: {
      fontFamily:
        '"Lato", "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 1.5,
      lineHeight: '21px',
    },
  }

  const components = {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '2px solid transparent',
          '&:focus': {
            border: '2px solid',
          },
          '&:disabled': {
            boxShadow: shadows[0],
            color: palette.grey[600],
            backgroundColor: palette.grey[300],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 'inherit',
        },
      },
    },
  }

  return {
    shadows,
    spacing,
    shape,
    palette,
    typography,
    components,
  }
})()

export const themeNew = createTheme(themeObject)
export const themeNew5 = createTheme5(themeObject)
