import { useCustomer } from 'services/queries/useCustomer'
import { useCustomerData } from 'services/queries/useCustomerData'
import { useDefaultPlans } from 'services/queries/useDefaultPlans'
import { useUsers } from 'services/queries/useUsers'
import { UserT, UserTableT } from 'types'

export function useGetSelectedCustomerData(selectedCustomer: string) {
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers(selectedCustomer)
  const {
    data: customerData,
    isLoading: customerLoading,
    isError: customerError,
  } = useCustomerData(selectedCustomer)
  const {
    data: customerPlanData,
    isLoading: customerPlanLoading,
    isError: customerPlanError,
  } = useCustomer(selectedCustomer)
  const {
    data: plansData,
    isSuccess: isPlansSuccess,
    isLoading: plansLoading,
    isError: plansError,
  } = useDefaultPlans()

  const data = {
    usersData,
    customerData,
    customerPlanData,
    plansData,
  }

  const isLoading =
    usersLoading || customerLoading || customerPlanLoading || plansLoading
  const isError = usersError || customerError || customerPlanError || plansError
  const isSuccess = isPlansSuccess
  return { data, isLoading, isError, isSuccess }
}

export const usersTableFormat = (users: UserT[]): UserTableT[] => {
  if (users) {
    if (!users.length) return []

    return users.map((user) => {
      return {
        userId: user.id,
        lastLogin: user?.last_login,
        name: user?.name,
        email: user.email,
        phoneNumber: user?.phone_number,
        isVerified: user.is_verified,
        role: user.role,
        createdAt: user.created_at,
      }
    })
  }
  return []
}
