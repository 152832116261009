import * as React from 'react'

import { FilePond } from 'react-filepond'
import * as fp from 'filepond'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { isNil } from 'ramda'
import { useDebounce } from 'react-use'

// import neatCsv from 'neat-csv'
import Papa from 'papaparse'
import xlsx from 'xlsx/dist/xlsx.core.min.js'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

import './styles/filepond.css'
fp.registerPlugin(FilePondPluginFileValidateType)
import 'filepond/dist/filepond.min.css'

const acceptedFileTypes = [
  'text/csv', // Mac CSV
  'application/csv', // Mac CSV
  'application/vnd.ms-excel', // Windows CSV
  'text/comma-separated-values, application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

/**
 * Check if the file is a CSV file
 */
const isFileCSV = (file): boolean =>
  file.name.split('.').pop().toLowerCase() === 'csv'

/**
 * Converts an Excel sheet to a CSV file.
 *
 * @param str The binary string to convert
 */
const convertExcelToCSV = (str: string): string => {
  const workbook = xlsx.read(str, { type: 'binary' })
  const sheetName = workbook.SheetNames[0]

  return xlsx.utils.sheet_to_csv(workbook.Sheets[sheetName])
}

// type Props = {
//   onFileLoaded: (rows: ?(Object[])) => any,
// }

const ImportDropzone = ({ onFileLoaded }) => {
  const filePondRef = React.useRef(null)
  const [files, setFiles] = React.useState([])
  const [debouncedFiles, setDebouncedFiles] = React.useState([])

  useDebounce(
    () => {
      setFiles(debouncedFiles)
    },
    300,
    [debouncedFiles],
  )

  const handleSetFiles = ([fileItem]) => {
    if (fileItem?.file) {
      setDebouncedFiles([fileItem.file])
    }
  }

  React.useEffect(() => {
    const [file] = files

    if (!isNil(file) && acceptedFileTypes?.includes(file.type)) {
      const fileReader = new FileReader()
      fileReader.readAsBinaryString(file)

      fileReader.onloadend = () => {
        const { result } = fileReader

        // Guard against data we can't use to stop Flow from complaining;
        // I think neatCSV has wrong typing in this instance.
        if (result == null || result instanceof ArrayBuffer) {
          onFileLoaded(null)
          return
        }

        const csv = isFileCSV(file) ? result : convertExcelToCSV(result)

        const results = Papa.parse(csv)
        onFileLoaded(results.data)
      }
    } else {
      onFileLoaded(null)
    }
  }, [files, onFileLoaded])

  return (
    <FormattedMessage id={intl.riskMonitoringNew('drag-drop')}>
      {(label) => (
        <FilePond
          ref={filePondRef}
          files={files}
          style={{ cursor: 'pointer' }}
          allowMultiple={false}
          labelIdle={label}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={handleSetFiles}
        ></FilePond>
      )}
    </FormattedMessage>
  )
}

export default ImportDropzone
