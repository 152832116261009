import * as React from 'react'
import {
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router'
import { useGetIntegrationsForCustomer } from 'services/queries/useGetIntegrationsForCustomer'
import { IntegrationsResponse } from 'services/cmAPI/getIntegrationsForCustomer'
import { useCreateEconomicIntegration } from 'services/queries/useCreateEconomicIntegration'
type FindIntegration = {
  integrationsArray: IntegrationsResponse['data']
  type: 'economic'
}
const findIntegration = ({ integrationsArray, type }: FindIntegration) => {
  return integrationsArray?.find((integration) => integration.type === type)
}
function EconomicIntegration() {
  const { id: selectedCustomer } = useParams()
  const { mutate: createEconomicIntegration } = useCreateEconomicIntegration()
  const { data: integrations } = useGetIntegrationsForCustomer(selectedCustomer)
  const [input, setInput] = React.useState('')
  const economicIntegration = findIntegration({
    integrationsArray: integrations ?? [],
    type: 'economic',
  })
  const handleCreateIntegration = () => {
    createEconomicIntegration({
      customerId: selectedCustomer,
      economicToken: input,
    })
  }

  React.useEffect(() => {
    if (economicIntegration) {
      setInput(economicIntegration.token)
    }
  }, [integrations])

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      {economicIntegration ? (
        <Tooltip title={input}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '10rem',
              color: 'grey !important',
            }}
          >
            {input}
          </Typography>
        </Tooltip>
      ) : (
        <TextField
          value={input}
          onChange={(e) => setInput(e.target.value)}
          name="Outlined"
          placeholder="Economic Key goes here..."
          variant="outlined"
        />
      )}
      <Tooltip
        title={
          economicIntegration
            ? 'Economic integration already exists'
            : 'This action will create an extra user for this company!'
        }
      >
        <Box sx={{ height: '100%' }}>
          <Button
            sx={{ height: '100%' }}
            onClick={handleCreateIntegration}
            disabled={economicIntegration ? true : input.length < 10}
            color={'primary'}
            variant="contained"
          >
            Create Integration
          </Button>
        </Box>
      </Tooltip>
    </Stack>
  )
}

export default EconomicIntegration
