export const booleans = [
  {
    label: 'Observational List',
    key: 'observational_lists',
    updateLocation: 'global',
    type: 'boolean',
  },
  { label: 'Ean', key: 'allow_ean', type: 'boolean' },
  { label: 'Monitoring', key: 'allow_monitoring', type: 'boolean' },
  { label: 'Api', key: 'allow_api', type: 'boolean' },
  {
    label: 'Browser Extension',
    key: 'allow_browser_extension',
    type: 'boolean',
  },
  {
    label: 'Company Search',
    key: 'allow_company_search',
    type: 'boolean',
  },
  { label: 'Person Search', key: 'allow_person_search', type: 'boolean' },
  {
    label: 'Company Export',
    key: 'allow_company_export',
    type: 'boolean',
  },
  { label: 'Person Export', key: 'allow_person_export', type: 'boolean' },
  {
    label: 'Monitoring Export',
    key: 'allow_monitoring_export',
    type: 'boolean',
  },
  { label: 'Relations', key: 'allow_relations', type: 'boolean' },
  { label: 'Highlights', key: 'allow_highlights', type: 'boolean' },

  { label: 'Basic', key: 'allow_basic', type: 'boolean' },
  { label: 'Financial', key: 'allow_financial', type: 'boolean' },
  { label: 'Credit', key: 'allow_credit', type: 'boolean' },
  { label: 'Hierarchy', key: 'allow_hierarchy', type: 'boolean' },
  { label: 'PDF Reports', key: 'allow_pdf_reports', type: 'boolean' },
  { label: 'Notes', key: 'allow_notes', type: 'boolean' },
  {
    label: 'Enterprise Credit Policy',
    key: 'enterprise_credit_policy',
    updateLocation: 'global',
    type: 'boolean',
  },
  {
    label: 'Fraud Indicators',
    key: 'fraud_indicators',
    updateLocation: 'global',
    type: 'boolean',
  },
]

export const countrySpecific = [
  {
    label: 'Score models',
    key: 'score_models',
    type: 'score_model',
    options: [
      { label: 'BOHR', key: 'BOHR' },
      { label: 'NEWTON', key: 'NEWTON' },
    ],
  },
]
export const countriesForCountrySpecific = [
  {
    country: 'Denmark',
    countryCode: 'DK',
    availableModels: [
      { model: 'BOHR', canBeChanged: false },
      { model: 'NEWTON', canBeChanged: true },
    ],
  },
  {
    country: 'Norway',
    countryCode: 'NO',
    availableModels: [
      { model: 'BOHR', canBeChanged: false },
      { model: 'NEWTON', canBeChanged: true },
    ],
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    availableModels: [{ model: 'BOHR', canBeChanged: false }],
  },
  {
    country: 'United Kingdom',
    countryCode: 'UK',
    availableModels: [{ model: 'BOHR', canBeChanged: false }],
  },
  {
    country: 'Finland',
    countryCode: 'FI',
    availableModels: [{ model: 'BOHR', canBeChanged: false }],
  },
  {
    country: 'Germany',
    countryCode: 'DE',
    availableModels: [],
  },
]

export const selects = [
  {
    label: 'Countries',
    key: 'allow_countries',
    type: 'multi_select',
    options: [
      { label: 'DK', key: 'DK' },
      { label: 'SE', key: 'SE' },
      { label: 'NO', key: 'NO' },
      { label: 'UK', key: 'UK' },
      { label: 'FI', key: 'FI' },
      { label: 'DE', key: 'DE' },
    ],
  },
  {
    label: 'Credit Policy',
    key: 'allow_credit_policy',
    type: 'single_select',
    options: [
      { label: 'None', key: 'None' },
      { label: 'Simple', key: 'Simple' },
      { label: 'Advanced', key: 'Advanced' },
    ],
  },
  {
    label: 'Tier',
    key: 'tier',
    type: 'single_select',
    options: [
      { label: '1', key: 1 },
      { label: '2', key: 2 },
      { label: '3', key: 3 },
      { label: '4', key: 4 },
    ],
  },
]

export const counters = [
  {
    label: 'Search Export Limit',
    key: 'search_exports_limit',
    type: 'numeric',
  },
  {
    label: 'Credit Ratings',
    key: 'credit_ratings',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Credit Reports',
    key: 'credit_reports',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Lookups',
    key: 'lookups',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Monitoring Count',
    key: 'monitoring_count',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Monitoring Exports',
    key: 'monitoring_exports',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Filter Exports',
    key: 'search_exports',
    type: 'numeric',
    helperWord: 'Remaining',
  },
  {
    label: 'Max Users',
    key: 'max_users',
    type: 'numeric',
    helperWord: 'Max',
  },
]

export const tooltipData = {
  max_users: 'Current users / Alter maximum users amount',

  allow_api: 'Allow the company to make API integrations',
  allow_basic: 'General company information (Name, address etc..)',
  allow_browser_extension: 'Enable/Disable the chrome extension',
  allow_company_export: 'Filtering export',
  allow_company_search: 'Search company',
  allow_countries: 'Which countries can the customer use',
  allow_credit: 'Credit rating (Credit max, Credit days)',
  allow_credit_policy: 'Which policy is the customer allowed to use',
  allow_ean: 'Numbers are shown in general company information',
  allow_financial: 'Key financial figures',
  allow_hierarchy: 'Company hierarchy',
  allow_highlights: 'Company highlights',
  allow_models: 'Which model can a company use',
  allow_monitoring: 'If they can add companies to the monitoring list',
  allow_monitoring_export: 'Are you allowed to export your monitoring lists',
  allow_notes: 'Internal Ids, phone numbers',
  allow_pdf_reports: 'Pdf report from the credit check page',
  allow_person_export: 'If you are allowed to export people (Not implemented)',
  allow_person_search: 'Person search',
  allow_relations: 'Company and person relations',
  search_exports_limit: 'Maximum amount of rows that can be exported at once',
  tier: 'How often can they do requests on the API (Ask Troles first)',

  credit_ratings:
    'Amount of times a company can get recommended credit limit and credit days',
  credit_reports: 'Amount of reports that can be downloaded',
  lookups: 'Amount of times a company can see General company information',
  monitoring_count: 'Amount of companies monitored',
  monitoring_exports:
    'How many times can they export a list in monitoring (no matter the size of the list)',
  search_exports:
    'How many times can they export a list in filtering (no matter the size of the list)',
}

// used structure of restrictions.json

export const inputTypes = {
  SCORING_MODEL: 'scoring_model',
  BOOLEAN: 'boolean',
  NUMERIC: 'numeric',
  MULTI_SELECT: 'multi_select',
  SINGLE_SELECT: 'single_select',
  SCORE_MODEL: 'score_model',
}

// export const fieldsData = {
//   max_users: {
//     label: 'Max users',
//     type: inputTypes.NUMERIC,
//     limitField: 'max_users',
//     counterField: 'currentUsers',
//   },
//   search_exports_limit: {
//     label: 'Search export limit',
//     limitField: 'search_exports_limit',
//     counterField: null,
//     type: inputTypes.NUMERIC,
//   },
//   credit_ratings: {
//     label: 'Credit ratings',
//     limitField: 'caps_credit_ratings',
//     counterField: 'counters_credit_ratings',
//     type: inputTypes.NUMERIC,
//   },
//   credit_reports: {
//     label: 'Credit reports',
//     limitField: 'caps_credit_reports',
//     counterField: 'counters_credit_reports',
//     type: inputTypes.NUMERIC,
//   },
//   lookups: {
//     label: 'Lookups',
//     limitField: 'caps_lookups',
//     counterField: 'counters_lookups',
//     type: inputTypes.NUMERIC,
//   },
//   monitoring_count: {
//     label: 'Monitoring count',
//     limitField: 'caps_monitoring_count',
//     counterField: 'counters_monitoring_count',
//     type: inputTypes.NUMERIC,
//   },
//   monitoring_exports: {
//     label: 'Monitoring exports',
//     limitField: 'caps_monitoring_exports',
//     counterField: 'counters_monitoring_exports',
//     type: inputTypes.NUMERIC,
//   },
//   search_exports: {
//     label: 'Filter exports', // Gardes chose that title label
//     limitField: 'caps_search_exports',
//     counterField: 'counters_search_exports',
//     type: inputTypes.NUMERIC,
//   },
//   allow_api: { label: 'API', type: inputTypes.CHECKBOX },
//   allow_basic: { label: 'Basics', type: inputTypes.CHECKBOX },
//   allow_browser_extension: {
//     label: 'Browser extension',
//     type: inputTypes.CHECKBOX,
//   },
//   allow_company_export: { label: 'Company export', type: inputTypes.CHECKBOX },
//   allow_company_search: { label: 'Company search', type: inputTypes.CHECKBOX },
//   allow_countries: { label: 'Countries', type: inputTypes.MULTIPLE_SELECT },
//   allow_credit: { label: 'Credit', type: inputTypes.CHECKBOX },
//   allow_credit_policy: {
//     label: 'Credit policy',
//     type: inputTypes.SINGLE_SELECT, // SINGLE SELECT
//   },
//   allow_ean: { label: 'EAN', type: inputTypes.CHECKBOX },
//   allow_financial: { label: 'Financial', type: inputTypes.CHECKBOX },
//   allow_hierarchy: { label: 'Hierarchy', type: inputTypes.CHECKBOX },
//   allow_highlights: { label: 'Highlights', type: inputTypes.CHECKBOX },
//   // allow_models: { label: 'Models', type: inputTypes.MULTIPLE_SELECT },
//   allow_monitoring: { label: 'Monitoring', type: inputTypes.CHECKBOX },
//   allow_monitoring_export: {
//     label: 'Monitoring export',
//     type: inputTypes.CHECKBOX,
//   },
//   allow_notes: { label: 'Notes', type: inputTypes.CHECKBOX },
//   allow_pdf_reports: { label: 'PDF exports', type: inputTypes.CHECKBOX },
//   allow_person_export: { label: 'Person export', type: inputTypes.CHECKBOX },
//   allow_person_search: { label: 'Person search', type: inputTypes.CHECKBOX },
//   allow_relations: { label: 'Relations', type: inputTypes.CHECKBOX },
//   tier: {
//     label: 'Tier',
//     type: inputTypes.SINGLE_SELECT, // SINGLE SELECT
//   },
//   // AvailableModels
//   // DK: { label: 'DK', type: inputTypes.MODEL_MULTI_CHECKBOX },
//   // SE: { label: 'SE', type: inputTypes.MODEL_MULTI_CHECKBOX },
//   // NO: { label: 'NO', type: inputTypes.MODEL_MULTI_CHECKBOX },
//   // UK: { label: 'UK', type: inputTypes.MODEL_MULTI_CHECKBOX },
//   // FI: { label: 'FI', type: inputTypes.MODEL_MULTI_CHECKBOX },
//   // DE: { label: 'DE', type: inputTypes.MODEL_MULTI_CHECKBOX },
// }

export const categoriesData = {
  users: {
    title: 'Users',
    fields: ['max_users'],
  },
  rights: {
    title: 'Rights',
    fields: [
      'allow_api',
      'allow_basic',
      'allow_browser_extension',
      'allow_company_export',
      'allow_company_search',
      'allow_countries',
      'allow_credit',
      'allow_credit_policy',
      'allow_ean',
      'allow_financial',
      'allow_hierarchy',
      'allow_highlights',
      // 'allow_models',
      'allow_monitoring',
      'allow_monitoring_export',
      'allow_notes',
      'allow_pdf_reports',
      'allow_person_export',
      'allow_person_search',
      'allow_relations',
      'search_exports_limit',
      'tier',
    ],
  },
  countersCaps: {
    title: 'Counters / Caps',
    fields: [
      'credit_ratings',
      'credit_reports',
      'lookups',
      'monitoring_count',
      'monitoring_exports',
      'search_exports',
    ],
  },
  models: {
    title: 'Available models',
    fields: ['DK', 'SE', 'NO', 'UK', 'FI', 'DE'],
  },
}
