import { Typography } from '@material-ui/core'
import moment from 'moment'
import { HumanDate, ConditionallyRender } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const LastLoginView = ({ props }) => {
  const lastLogin = props.userData?.last_login
  const daysSinceLastLogin = moment().diff(lastLogin, 'days')
  if (!props.userData) return <Typography>-</Typography>
  return (
    <ConditionallyRender
      condition={daysSinceLastLogin < 30}
      when={<HumanDate style={{ color: 'orange' }} date={moment(lastLogin)} />}
      otherwise={
        lastLogin ? (
          <Typography>
            {<FormattedMessage id={intl.userManagement('more-than-30')} />}
          </Typography>
        ) : (
          <Typography>
            {<FormattedMessage id={intl.userManagement('no-data-yet')} />}
          </Typography>
        )
      }
    />
  )
}

export default LastLoginView
