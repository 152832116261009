import React from 'react'
import StringsContainFilter from './StringsContainFilter'
import { useIntl } from 'react-intl'
import moment from 'moment'

function useColumns() {
  const intl = useIntl()

  const columns = [
    {
      Header: 'Company Name',
      minWidth: 250,
      filterable: true,
      accessor: 'name',
      Filter: StringsContainFilter,
    },
    {
      Header: 'Created at',
      minWidth: 100,
      filterable: false,
      accessor: 'created_at',
      Cell: ({ row }) => intl.formatDate(row.original?.created_at),
    },
    {
      Header: 'Responsible User',
      minWidth: 120,
      filterable: true,
      accessor: 'responsible_user',
      Filter: StringsContainFilter,
    },
    {
      Header: 'Local ID',
      minWidth: 50,
      filterable: true,
      accessor: 'local_id',
      Filter: StringsContainFilter,
    },
    {
      Header: 'Accounts',
      accessor: 'accounts',
      minWidth: 50,
    },
    {
      Header: 'Last Login',
      minWidth: 100,
      filterable: false,
      accessor: 'users',
      Cell: ({ row }) => {
        const lastLogin = row.original?.last_login
        return lastLogin ? moment(lastLogin).fromNow() : null
      },
    },
  ]
  const memoizedColumns = React.useMemo(() => columns, [])
  return memoizedColumns
}

export default useColumns
