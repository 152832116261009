import React from 'react'
import { UserBeforeT, UserAfterT } from 'types'
import { AddBulkUsers } from 'components'
import { useCreateUsers } from 'services/queries/useCreateUsers'

const AddUsers = ({ selectedCustomer }) => {
  const { mutate } = useCreateUsers()
  const [newUsers, setNewUsers] = React.useState([{ userName: '', email: '' }])

  const attachCustomerIdAndFormat = (users: UserBeforeT[]): UserAfterT[] => {
    return users.map((user) => {
      const newUser: UserAfterT = {
        name: user.userName,
        email: user.email,
        customer_id: Number(selectedCustomer),
      }
      return newUser
    })
  }

  const createUsers = () => {
    const transformedData = attachCustomerIdAndFormat(newUsers)
    mutate(transformedData)
  }

  return (
    <AddBulkUsers
      users={newUsers}
      setUsers={setNewUsers}
      action={createUsers}
    />
  )
}

export default AddUsers
