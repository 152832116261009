import callCmAPI from 'services/helpers/callCmAPI'

type AllowedKeys = {
  responsible_user_id: number | string
  is_revoked: boolean
  expires_at: string | Date
  domain: string
  data: any
}

export type UpdateCustomerDataT = {
  companyId: string
  data: Partial<AllowedKeys>
}

export const updateCustomerSingle = ({
  companyId,
  data,
}: UpdateCustomerDataT): any => {
  return callCmAPI(`/customer/${companyId}`, {
    method: 'put',
    data,
  }).then((res) => res.data)
}
