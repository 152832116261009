import React from 'react'
import {
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

import { removeDuplicates } from 'utils/removeDuplicates'

import colors from 'styles/colors'
import intl from 'localization/components'
import { ConditionallyRender, ImportDropzone } from 'components'

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  multiUserContainer: {
    border: '1px dotted black',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    borderRadius: '0.25rem',
  },
  userIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  validationContainer: {
    position: 'relative',
    display: 'flex',
  },
  validationIcon: {
    position: 'absolute',
    width: '3rem !important',
    height: '3rem !important',
    right: '-5rem',
    top: '2.7rem',
  },
  validationText: {
    position: 'absolute',
    height: '3rem !important',
    top: '7rem',
    right: '0',
    textAlign: 'right',
  },
}))

const SelectFields = ({ selectedFields, setSelectedFields, rows, classes }) => {
  return (
    <ConditionallyRender
      condition={selectedFields.length === 0}
      when={
        <div>
          <Typography>Select User Name field</Typography>
          <div>
            {Object.keys(rows[0] ?? {}).map((x) => (
              <List key={x} className={classes.root}>
                <ListItem
                  button
                  onClick={() => setSelectedFields([...selectedFields, x])}
                >
                  <ListItemText primary={x} secondary={rows[0][x]} />
                </ListItem>
              </List>
            ))}
          </div>
        </div>
      }
      otherwise={
        <div>
          <Typography>Select Email field</Typography>
          <div>
            {Object.keys(rows[0] ?? {}).map((x) =>
              !selectedFields.includes(x) ? (
                <List button key={x} className={classes.root}>
                  <ListItem
                    button
                    onClick={() => setSelectedFields([...selectedFields, x])}
                  >
                    <ListItemText primary={x} secondary={rows[0][x]} />
                  </ListItem>
                </List>
              ) : null,
            )}
          </div>
        </div>
      }
    />
  )
}

const CreateUsers = ({ messages, users, setUsers, setValidateUsers }) => {
  const classes = useStyles()
  const [rows, setRows] = React.useState([])
  const [selectedFields, setSelectedFields] = React.useState([])

  const updateTextField = (index, key, value) => {
    setUsers(
      users.map((x, i) => {
        if (i !== index) {
          return x
        } else {
          return {
            ...x,
            [key]: value,
          }
        }
      }),
    )
  }

  const handleSetFieldList = React.useCallback((rows) => {
    setRows(rows)
    setSelectedFields([])
  }, [])

  const restoreDefaults = () => {
    setRows([])
    setSelectedFields([])
  }

  React.useEffect(() => {
    if (selectedFields.length === 2) {
      const name = selectedFields[0]
      const email = selectedFields[1]
      setUsers(
        [
          ...users,
          ...rows.map((x) => ({
            userName: x[name],
            email: x[email],
          })),
        ].filter((x) => x.userName !== ''),
      )
      restoreDefaults()
    }
  }, [rows, selectedFields, setUsers, users])

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const validate = React.useCallback(
    (index, ret) => {
      const value = users[index].email
      if (value.length) {
        if (validateEmail(value)) {
          const sameEmails = users.filter(({ email }) => email === value)
          if (sameEmails.length < 2) {
            return ret === 'bool' ? true : ''
          } else {
            return ret === 'bool'
              ? false
              : 'Dublicate emails! Click the X to delete dublicates!'
          }
        } else {
          return ret === 'bool' ? false : 'Invalid email format!'
        }
      } else {
        return ret === 'bool' ? false : 'Empty field'
      }
    },
    [users],
  )

  React.useEffect(() => {
    const valid = users.filter((x, i) => validate(i))
    if (valid?.length === 0) {
      setValidateUsers(true)
    } else {
      setValidateUsers(false)
    }
  }, [setValidateUsers, users, validate])

  const handleIconClick = (i) => {
    if (
      validate(i, 'text') ===
      'Dublicate emails! Click the X to delete dublicates!'
    ) {
      setUsers(removeDuplicates(users, 'email'))
    }
  }

  return (
    <div className="flex flex-col">
      <div className={classes.fields}>
        <ImportDropzone onFileLoaded={handleSetFieldList} />
        <ConditionallyRender
          condition={rows && selectedFields.length < 2}
          when={
            <SelectFields
              classes={classes}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              rows={rows}
            />
          }
        />
        {users.map((user, i) => (
          <>
            <div className={classes.userIcon}>
              <ConditionallyRender
                condition={i > 0}
                when={
                  <IconButton
                    style={{ color: 'red' }}
                    onClick={() =>
                      setUsers(users.filter((x, index) => index !== i))
                    }
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </IconButton>
                }
              />
              <Typography>User {i + 1}</Typography>
            </div>
            <div
              className={
                users.length > 1
                  ? classes.multiUserContainer
                  : classes.userContainer
              }
              key={(user.email, user.name)}
            >
              <TextField
                multiline
                value={user.userName}
                type="name"
                label={messages[intl.generic('first-name')]}
                className={classes.textField}
                onChange={(e) => updateTextField(i, 'userName', e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <div className={classes.validationContainer}>
                <TextField
                  style={{ flex: 1 }}
                  multiline
                  value={user.email}
                  type="email"
                  label={messages[intl.generic('emails')]}
                  className={classes.textField}
                  onChange={(e) => updateTextField(i, 'email', e.target.value)}
                  margin="normal"
                  variant="outlined"
                />
                <FontAwesomeIcon
                  onClick={() => handleIconClick(i)}
                  style={{
                    color: validate(i, 'bool')
                      ? colors?.bg_rating_10
                      : colors?.bg_rating_1,
                    cursor:
                      validate(i, 'text') ===
                      'Dublicate emails! Click the X to delete dublicates!'
                        ? 'pointer'
                        : 'default',
                  }}
                  className={classes.validationIcon}
                  icon={validate(i, 'bool') ? faCheckCircle : faTimesCircle}
                />
                <Typography className={classes.validationText}>
                  {validate(i, 'text')}
                </Typography>
              </div>
            </div>
          </>
        ))}
      </div>
      <IconButton
        onClick={() => setUsers([...users, { userName: '', email: '' }])}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </IconButton>
    </div>
  )
}

export default CreateUsers
