import { TextField } from '@material-ui/core'

import intl from 'localization/components'

const Name = ({ messages, props }) => {
  const { value, onChange } = props

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <TextField
      style={{ width: '100%' }}
      value={capitalize(value ?? '')}
      label={messages[intl.generic('first-name')]}
      onChange={(values) => {
        return onChange(values.target.value)
      }}
    />
  )
}

export default Name
