import React from 'react'
import {
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { Button } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faTimesCircle,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

import { removeDuplicates } from 'utils/removeDuplicates'

import colors from 'styles/colors'
import intl from 'localization/components'
import { ConditionallyRender, ImportDropzone } from 'components'
import { FormattedMessage, useIntl } from 'react-intl'
import ActionDialog from './ActionDialog'
import { useStyles } from './styles/add-bulk-users'

const SelectFields = ({ selectedFields, setSelectedFields, rows, classes }) => {
  return (
    <ConditionallyRender
      condition={selectedFields.length === 0}
      when={
        <div>
          <Typography>Select User Name field</Typography>
          <div>
            {Object.keys(rows[0] ?? {}).map((x) => (
              <List key={x} className={classes.root}>
                <ListItem
                  button
                  onClick={() => setSelectedFields([...selectedFields, x])}
                >
                  <ListItemText primary={x} secondary={rows[0][x]} />
                </ListItem>
              </List>
            ))}
          </div>
        </div>
      }
      otherwise={
        <div>
          <Typography>Select Email field</Typography>
          <div>
            {Object.keys(rows?.[0] ?? {}).map((x) =>
              !selectedFields.includes(x) ? (
                <List button key={x} className={classes.root}>
                  <ListItem
                    button
                    onClick={() => setSelectedFields([...selectedFields, x])}
                  >
                    <ListItemText primary={x} secondary={rows?.[0][x]} />
                  </ListItem>
                </List>
              ) : null,
            )}
          </div>
        </div>
      }
    />
  )
}

type Props = {
  users: Array<any>,
  setUsers: Function,
  action: Function,
}

const AddBulkUsers = ({ users, setUsers, action }: Props): JSX.Element => {
  const classes = useStyles()
  const reactIntl = useIntl()
  const messages = reactIntl.messages
  const [rows, setRows] = React.useState([])
  const [selectedFields, setSelectedFields] = React.useState([])
  const [validateUsers, setValidateUsers] = React.useState(false)

  const updateTextField = (index, key, value) => {
    setUsers(
      users.map((x, i) => {
        if (i !== index) {
          return x
        } else {
          return {
            ...x,
            [key]: value,
          }
        }
      }),
    )
  }

  const handleSetFieldList = React.useCallback((rows) => {
    setRows(rows)
    setSelectedFields([])
  }, [])

  const restoreDefaults = () => {
    setRows([])
    setSelectedFields([])
  }

  React.useEffect(() => {
    if (selectedFields.length === 2) {
      const name = selectedFields[0]
      const email = selectedFields[1]
      setUsers(
        [
          ...users,
          ...rows.map((x) => ({
            userName: x[name],
            email: x[email],
          })),
        ].filter((x) => x.userName !== ''),
      )
      restoreDefaults()
    }
  }, [rows, selectedFields, setUsers, users])

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const validate = React.useCallback(
    (index, ret) => {
      const value = users[index].email
      if (value.length) {
        if (validateEmail(value)) {
          const sameEmails = users.filter(({ email }) => email === value)
          if (sameEmails.length < 2) {
            return ret === 'bool' ? true : ''
          } else {
            return ret === 'bool'
              ? false
              : 'Duplicate emails! Click the X to delete duplicates!'
          }
        } else {
          return ret === 'bool' ? false : 'Invalid email format!'
        }
      } else {
        return ret === 'bool' ? false : 'Empty field'
      }
    },
    [users],
  )

  React.useEffect(() => {
    const valid = users.filter((x, i) => validate(i))

    setValidateUsers(valid?.length === 0)
  }, [setValidateUsers, users, validate])

  const handleIconClick = (i) => {
    if (
      validate(i, 'text') ===
      'Duplicate emails! Click the X to delete duplicates!'
    ) {
      setUsers(removeDuplicates(users, 'email'))
    }
  }

  return (
    <ActionDialog
      disabled={!validateUsers}
      title={
        <FormattedMessage
          id={intl.riskMonitoringNew('add-to-monitoring-list-title')}
        />
      }
      content={() => (
        <div style={{ margin: '5rem' }} className="flex flex-col">
          <div className={classes.fields}>
            <ImportDropzone onFileLoaded={handleSetFieldList} />
            <ConditionallyRender
              condition={rows && selectedFields.length < 2}
              when={
                <SelectFields
                  classes={classes}
                  selectedFields={selectedFields}
                  setSelectedFields={setSelectedFields}
                  rows={rows}
                />
              }
            />
            {users.map((user, i) => (
              <React.Fragment key={user.email}>
                <div className={classes.userIcon}>
                  <ConditionallyRender
                    condition={i > 0}
                    when={
                      <IconButton
                        style={{ color: 'red' }}
                        onClick={() =>
                          setUsers(users.filter((x, index) => index !== i))
                        }
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </IconButton>
                    }
                  />
                  <Typography>User {i + 1}</Typography>
                </div>
                <div
                  className={
                    users.length > 1
                      ? classes.multiUserContainer
                      : classes.userContainer
                  }
                  key={(user.email, user.name)}
                >
                  <TextField
                    data-cy="first-name-input"
                    multiline
                    value={user.userName}
                    type="name"
                    label={messages[intl.generic('first-name')]}
                    className={classes.textField}
                    onChange={(e) =>
                      updateTextField(i, 'userName', e.target.value)
                    }
                    margin="normal"
                    variant="outlined"
                  />
                  <div className={classes.validationContainer}>
                    <TextField
                      data-cy="email-input"
                      style={{ flex: 1 }}
                      multiline
                      value={user.email}
                      type="email"
                      label={messages[intl.generic('emails')]}
                      className={classes.textField}
                      onChange={(e) =>
                        updateTextField(i, 'email', e.target.value)
                      }
                      margin="normal"
                      variant="outlined"
                    />
                    <FontAwesomeIcon
                      onClick={() => handleIconClick(i)}
                      style={{
                        color: validate(i, 'bool')
                          ? colors?.bg_rating_10
                          : colors?.bg_rating_1,
                        cursor:
                          validate(i, 'text') ===
                          'Duplicate emails! Click the X to delete duplicates!'
                            ? 'pointer'
                            : 'default',
                      }}
                      className={classes.validationIcon}
                      icon={validate(i, 'bool') ? faCheckCircle : faTimesCircle}
                    />
                    <Typography className={classes.validationText}>
                      {validate(i, 'text')}
                    </Typography>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <IconButton
            onClick={() => setUsers([...users, { userName: '', email: '' }])}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </IconButton>
        </div>
      )}
      action={action}
      maxWidth="sm"
      fullWidth={true}
      render={(open) => (
        <Button
          data-cy="add-bulk-users-button"
          icon={<FontAwesomeIcon icon={faUserPlus} />}
          style={{
            backgroundColor: colors?.risikaTertiary,
            color: 'white',
            alignSelf: 'center',
          }}
          variant="contained"
          onClick={open}
        >
          Add bulk users
        </Button>
      )}
      agreeText={<FormattedMessage id={intl.riskMonitoringNew('add')} />}
    />
  )
}

export default AddBulkUsers
