import callCmAPI from 'services/helpers/callCmAPI'
/******************
 * Fetch from API *
 ******************/

export const deleteOnlyApiUser = (apiUserId) => {
  return callCmAPI(`/user/api/${apiUserId}`, {
    method: 'delete',
  })
}
