import axios from 'axios'
import { auth } from 'firebase-config'
import { env } from 'services/helpers/env'

/**
 *
 * @param {string} endpoint The endpoint to call.
 * @param {*} request An optional request object.
 */

const getAccessToken = async () => {
  return auth.currentUser.getIdToken()
}

const callCmAPI = async (endpoint, request) => {
  const { method = 'get', data, headers } = request

  const url = `${env.WEB_API_BASE_URL}${endpoint}`
  const accessToken = await getAccessToken()
  return axios({
    url,
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      ...headers,
    },
    data,
  })
}

export default callCmAPI
