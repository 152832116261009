import { useState, useRef, useEffect } from 'react'
import {
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@material-ui/core'

const OutlinedSelect = ({
  value,
  label,
  name,
  id,
  className,
  options,
  onChange,
  style,
}) => {
  const inputLabelRef = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabelRef?.current?.offsetWidth)
  }, [inputLabelRef])

  return (
    <FormControl variant="outlined" className={className} style={style}>
      <InputLabel ref={inputLabelRef} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        input={<OutlinedInput labelWidth={labelWidth} name={name} id={id} />}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OutlinedSelect
