import { getCustomerData } from '../cmAPI/getCustomerData'
import { useQuery } from 'react-query'

export function useCustomerData(customerId) {
  return useQuery(
    ['getCustomerPlan' + customerId],
    () => getCustomerData(customerId),
    {
      enabled:
        Boolean(customerId) && Boolean(localStorage.getItem('FB_ACCESS_TOKEN')),
    },
  )
}
