import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { issueUserApiToken } from 'services/cmAPI/issueUserApiToken'

export function userIssueApiUserToken() {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(issueUserApiToken, {
    onSuccess: async ({ data }) => {
      enqueueSnackbar('Token copied to clipboard', {
        variant: 'success',
      })
      navigator.clipboard.writeText(data.token)
    },
    onError: () => {
      enqueueSnackbar('Token issue failed', { variant: 'error' })
    },
  })
}
