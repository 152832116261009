/* eslint-disable quotes */
import intl from '../components'

const messages = {
  [intl.advancedFilters('above')]: 'Over',
  [intl.advancedFilters('below')]: 'Under',
  [intl.advancedFilters('company-type')]: 'Virksomhedstype',
  [intl.advancedFilters('count')]:
    '{count, plural, =0 {Ingen virksomheder fundet} one {{count} virksomhed fundet} other {{count} virksomheder fundet}}',
  [intl.advancedFilters('employee-count')]: 'Antal medarbejdere',
  [intl.advancedFilters('equity')]: 'Egenkapital',
  [intl.advancedFilters('financial-data')]: 'Finansielle data',
  [intl.advancedFilters('hide-ad-protected')]: 'Fjern reklamebeskyttede',
  [intl.advancedFilters('industry-group')]: 'Branchegruppe',
  [intl.advancedFilters('industry-specific')]: 'Specifik branchegruppe',
  [intl.advancedFilters('municipality')]: 'Kommune',
  [intl.advancedFilters('region')]: 'Region',
  [intl.advancedFilters('reset-search')]: 'Nulstil søgning',
  [intl.advancedFilters('risika-score')]: 'Risika score',
  [intl.advancedFilters('search-company-type')]: 'Søg efter virksomhedstype',
  [intl.advancedFilters('search-industry-group')]: 'Søg efter branchegruppe',
  [intl.advancedFilters('search-industry-specific')]:
    'Søg efter specifik branchegruppe',
  [intl.advancedFilters('search-municipality')]: 'Søg efter kommune',
  [intl.advancedFilters('search-region')]: 'Søg efter region',
  [intl.advancedFilters('select-columns')]:
    'Vælg kolonner, der skal eksporteres',
  [intl.advancedFilters('show-inactive')]: 'Vis inaktive',
  [intl.advancedFilters('title')]: 'Filtrering',
  [intl.advancedFilters('illegal-character')]:
    '{count, plural, one {Ugyldigt tegn} other {Ugyldige tegn}} "{char}"! Tilladte tegn er "0-9", "-" og ","',
  [intl.advancedFilters('advanced-zipcodes-helper-text')]:
    'Tilladt format: 1000, 2000-2500',
  [intl.advancedFilters('values-or-ranges')]:
    'Skriv enkeltværdier eller intervaller',
  [intl.advancedFilters('show-all')]:
    '{count, plural, =0 {Ingen brancher valgt} one {Vis {count} branche} other {Vis {count} brancher}}',
  [intl.advancedFilters('hide-all')]:
    '{count, plural, =0 {Ingen brancher valgt} one {Skjul {count} branche} other {Skjul {count} brancher}}',
  [intl.advancedFilters('search-for-industry')]: 'Søg efter en branche',

  [intl.advancedSearch('add-filters')]: 'Vælg filtre',
  [intl.advancedSearch('all-filters')]: 'Alle filtre',
  [intl.advancedSearch('apply-filters')]: 'Anvend filtre',
  [intl.advancedSearch('assets')]: 'Aktiver',
  [intl.advancedSearch('auditor')]: 'Revisor',
  [intl.advancedSearch('between')]: 'Mellem',
  [intl.advancedSearch('cash')]: 'Likvider',
  [intl.advancedSearch('company-info')]: 'Virksomhedsinformation',
  [intl.advancedSearch('company_status')]: 'Virksomhedsstatus',
  [intl.advancedSearch('company_type')]: 'Virksomhedstype',
  [intl.advancedSearch('credit')]: 'Kredit',
  [intl.advancedSearch('tree-industry')]: 'Branchekoder',
  [intl.advancedSearch('current_financial_assets')]: 'Værdipapirer',
  [intl.advancedSearch('date_of_incorporation')]: 'Stiftelsesdato',
  [intl.advancedSearch('employees')]: 'Antal ansatte (interval)',
  [intl.advancedSearch('employees_number')]: 'Antal ansatte',
  [intl.advancedSearch('enter-percentage')]: 'Skriv værdien i procent (%)',
  [intl.advancedSearch('enter-value')]: 'Skriv værdien',
  [intl.advancedSearch('equity')]: 'Egenkapital',
  [intl.advancedSearch('excludes')]: 'Ekskluder',
  [intl.advancedSearch('financial-data-section')]: 'Regnskabsinformation',
  [intl.advancedSearch('financial-data-select-all')]:
    'Vælg alt regnskabsinformation',
  [intl.advancedSearch('financial-numbers')]: 'Regnskabstal',
  [intl.advancedSearch('financial-ratios')]: 'Nøgletal',
  [intl.advancedSearch('fixed_assets')]: 'Anlægsaktiver',
  [intl.advancedSearch('general-data-section')]:
    'Generel virksomhedsinformation',
  [intl.advancedSearch('general-data-select-all')]:
    'Vælg alt general virksomhedsinformation',
  [intl.advancedSearch('gross_result')]: 'Bruttoresultat',
  [intl.advancedSearch('has_auditor')]: 'Har revisor',
  [intl.advancedSearch('has_email')]: 'Har e-mail',
  [intl.advancedSearch('has_phone_number')]: 'Har telefonnummer',
  [intl.advancedSearch('has_webpage')]: 'Har hjemmeside',
  [intl.advancedSearch('hide_advertisement_protected')]:
    'Fravælg reklamebeskyttede',
  [intl.advancedSearch('hide_holding_company')]: 'Fravælg holdingselskaber',
  [intl.advancedSearch('includes')]: 'Inkluder',
  [intl.advancedSearch('industry_group')]: 'Branchegruppe',
  [intl.advancedSearch('liquidity')]: 'Likviditetsgrad',
  [intl.advancedSearch('load-filters')]: 'Gemte filtre',
  [intl.advancedSearch('load-filters-title')]: 'Anvend et af dine gemte filtre',
  [intl.advancedSearch('match')]: 'Match',
  [intl.advancedSearch('municipality')]: 'Kommune',
  [intl.advancedSearch('no-filters')]: 'Ingen filtre valgt',
  [intl.advancedSearch('no-saved-filters')]: 'Du har ikke nogle gemte filtre',
  [intl.advancedSearch('over')]: 'Over',
  [intl.advancedSearch('placeholder-auditor')]:
    'Skriv CVR eller revisorens navn',
  [intl.advancedSearch('profit_loss')]: 'Årsresultat',
  [intl.advancedSearch('range-from')]: 'Fra',
  [intl.advancedSearch('range-to')]: 'Til',
  [intl.advancedSearch('region')]: 'Region',
  [intl.advancedSearch('reset')]: 'Nulstil',
  [intl.advancedSearch('results-time-elapsed')]:
    '{count, plural, one {# resultat} other {# resultater}} på {time, plural, other {#}} sekunder',
  [intl.advancedSearch('return_on_assets')]: 'Afkastningsgrad',
  [intl.advancedSearch('save')]: 'Gem',
  [intl.advancedSearch('save-filter-label')]: 'Navngiv dit filter',
  [intl.advancedSearch('save-filter-title')]:
    'Gem dit filter til fremtidig brug',
  [intl.advancedSearch('score')]: 'Risika score',
  [intl.advancedSearch('search-filter')]: 'Søg efter dit filter',
  [intl.advancedSearch('select-all')]: 'Vælg alle',
  [intl.advancedSearch('share_capital')]: 'Selskabskapital',
  [intl.advancedSearch('show_inactive')]: 'Vis inaktive',
  [intl.advancedSearch('snackbar-delete-filters-failed')]:
    'Der opstod et problem med at slette dit filter',
  [intl.advancedSearch('snackbar-delete-filters-success')]:
    'Dit filter er slettet',
  [intl.advancedSearch('snackbar-saved-filters-failed')]:
    'Der opstod et problem med at gemme dit filter',
  [intl.advancedSearch('snackbar-saved-filters-success')]: 'Dit filter er gemt',
  [intl.advancedSearch('snackbar-update-filters-failed')]:
    'Der opstod et problem med at opdatere dit filter',
  [intl.advancedSearch('snackbar-update-filters-success')]:
    'Dit filter er opdateret',
  [intl.advancedSearch('duration')]: 'Varighed',
  [intl.advancedSearch('period')]: 'Periode',

  [intl.advancedSearch('solidity')]: 'Soliditetsgrad',
  [intl.advancedSearch('specific_industry')]: 'Specifik branchegruppe',
  [intl.advancedSearch('status_valid_from')]: 'Virkssomhedsstatus gyldigt fra',
  [intl.advancedSearch('total_debt')]: 'Samlet gæld',
  [intl.advancedSearch('under')]: 'Under',
  [intl.advancedSearch('zipcode')]: 'Postnummer',
  [intl.advancedSearch('set-to-today')]: 'Indstil til i dag',
  [intl.annualReportInformation('approvalDate')]:
    'Dato for godkendelse af årsrapporten',
  [intl.annualReportInformation('averageNumberOfEmployees')]:
    'Gennemsnitligt antal ansatte',
  [intl.annualReportInformation('classOfReportingEntity')]: 'Regnskabsklasse',
  [intl.annualReportInformation('company_name')]: 'Revisor',
  [intl.annualReportInformation('description')]: 'Revisorbetegnelse',
  [intl.annualReportInformation('get-auditor-information')]:
    'Få revisorinformation',
  [intl.annualReportInformation('ifrs')]: 'IFRS',
  [intl.annualReportInformation('name')]: 'Revisors navn',
  [intl.annualReportInformation('no_information')]: 'Ingen information',
  [intl.annualReportInformation('show_less')]: 'Vis mindre',
  [intl.annualReportInformation('show_more')]: 'Vis mere',
  [intl.annualReportInformation('title')]: 'Regnskabsinformationer',
  [intl.annualReportInformation('type_of_assistance')]: 'Erklæringstype',
  [intl.auditorInfo('auditors_report')]: 'Revisorpåtegning',
  [intl.auditorInfo('auditors_report_on_extended_review')]:
    'Erklæring om udvidet gennemgang',
  [intl.auditorInfo('auditors_reports_(other_non-assurance_reports)')]:
    'Andre erklæringer uden sikkerhed',
  [intl.auditorInfo('company_name')]: 'Revisionsfirma',
  [intl.auditorInfo('name_of_auditor')]: 'Revisor',
  [intl.auditorInfo('no_auditor_assistance')]: 'Ingen revisor',
  [intl.auditorInfo('the_independent_auditors_reports_(review)')]:
    'Den uafhængige revisors erklæringer (review)',
  [intl.auditorInfo('type_of_assistance')]: 'Erklæringstype',
  [intl.auth('account-already-verified')]: 'Din konto er allerede verificeret',
  [intl.auth('account-deactivated')]: 'Din konto er blevet spærret',
  [intl.auth('account-deactivated-subtitle')]:
    'Du burde have modtaget en mail med instruktioner for, hvordan du kan genaktiverer den.',
  [intl.auth('account-deactivated-title')]: 'Din konto er spærret',
  [intl.auth('account-verified-failed')]:
    'Vi havde lidt udfordringer med at verificere din konto',
  [intl.auth('account-verified-failed-subtitle')]:
    'Hvis du skriver til os i chatten, så hjælper vi dig med at få adgang med det samme.',
  [intl.auth('account-verified-failed-title')]: 'Problemer med verificering',
  [intl.auth('account-verified-success')]:
    'Din konto er verificieret, og du kan nu logge ind',
  [intl.auth('already-have-an-account')]: 'Har du allerede en konto?',
  [intl.auth('change-password')]: 'Skift password',
  [intl.auth('change-password-failed')]:
    'Ser ud til der opstod et problem med at ændre dit password',
  [intl.auth('change-password-success')]:
    'Du kan nu logge ind med dit nye password!',
  [intl.auth('change-password-title')]: 'Ændre dit password',
  [intl.auth('company-already-exists')]: 'Virksomheden er allerede oprettet',
  [intl.auth('company-label')]: 'Virksomhed',
  [intl.auth('company-placeholder')]: 'Søg efter din virksomhed',
  [intl.auth('confirm-new-password-label')]: 'Bekræft nye password',
  [intl.auth('confirm-new-password-placeholder')]: 'Bekræft dit nye password',
  [intl.auth('contact-us')]: 'Kontakt os:',
  [intl.auth('do-not-think-email-received')]:
    'Kan du ikke se mailen i din indbakke?',
  [intl.auth('email')]: 'Email',
  [intl.auth('email-already-exists')]:
    'Denne email er allerede registreret. Kontakt os på contact@risika.dk',
  [intl.auth('email-label')]: 'E-mail',
  [intl.auth('email-placeholder')]: 'mig@virksomhed.dk',
  [intl.auth('email-sent-to')]: 'Vi sendte mailen til',
  [intl.auth('email-should-have-been-resend')]:
    'Du burde have modtaget en ny mail',
  [intl.auth('finalize-account')]: 'Færdiggør konto',
  [intl.auth('first-name-label')]: 'Fornavn',
  [intl.auth('first-name-placeholder')]: 'Dit fornavn',
  [intl.auth('forgot-password')]: 'Glemt dit password?',
  [intl.auth('forgot-password-email-subtitle')]:
    'Hvis du ikke kan se mailen i din indboks, så tjek venligst i spam',
  [intl.auth('forgot-password-email-title')]: 'Vi har sendt dig en e-mail!',
  [intl.auth('forgot-password-failed')]:
    'Beklager, det ser ud til der opstod et problem',
  [intl.auth('forgot-password-subtitle')]:
    'Skriv din email-adresse, så sender vi et link hvor du kan ændre dit password',
  [intl.auth('forgot-password-title')]: 'Glemt dit password?',
  [intl.auth('get-me-in')]: 'Få mig ind',
  [intl.auth('get-started-free')]: 'Kom gratis i gang →',
  [intl.auth('go-back')]: 'Gå tilbage',
  [intl.auth('go-back-to-log-in')]: 'Gå tilbage til log ind',
  [intl.auth('invalid-local-id')]: 'Venlist brug et gyldigt CVR-nummer',
  [intl.auth('last-name-label')]: 'Efternavn',
  [intl.auth('last-name-placeholder')]: 'Dit efternavn',
  [intl.auth('log-in')]: 'Log ind',
  [intl.auth('login-header')]: 'Log ind på din konto',
  [intl.auth('login-to-your-account')]: 'Log ind på din konto →',
  [intl.auth('min-password-length')]: 'Mindst 8 tegn',
  [intl.auth('new-email-sent')]: 'Vi har sendt dig en ny mail',
  [intl.auth('new-password-label')]: 'Nyt password',
  [intl.auth('new-password-placeholder')]: 'Dit nye password',
  [intl.auth('no-account-yet')]: 'Har du ikke en konto endnu?',
  [intl.auth('password-label')]: 'Password',
  [intl.auth('password-placeholder')]: 'Dit password',
  [intl.auth('personal-information')]: 'Personlig information',
  [intl.auth('phone')]: 'Telefon',
  [intl.auth('phone-number-label')]: 'Telefonnummer',
  [intl.auth('please-use-work-mail')]: 'Venlist brug din arbejdsmail',
  [intl.auth('register-account')]: 'Opret konto',
  [intl.auth('register-account-subtitle')]: 'Alle felter er påkrævet',
  [intl.auth('register-header')]: 'Opret din konto',
  [intl.auth('remembered-your-password')]: 'Kom du i tanke om dit password?',
  [intl.auth('resend-email')]: 'Send ny mail',
  [intl.auth('send-email')]: 'Send e-mail',
  [intl.auth('session-expired')]: 'Din session er udløbet, log ind igen.',
  [intl.auth('showcase-subtitle')]:
    'Risika skaber sikkerhed i samhandlen mellem skandinaviske virksomheder via retvisende og præcise kreditvurderinger. ',
  [intl.auth('showcase-title')]: 'Den nemme vej til en sund forretning',
  [intl.auth('sso-login')]: 'Log ind med Microsoft',
  [intl.auth('terms-of-service')]: 'betingelserne',
  [intl.auth('understand-and-accept')]: 'Jeg forstår og accepterer',
  [intl.auth('user-registered-subtitle')]:
    'Vi har sendt dig en mail for at verificere din konto. Så snart du har gjort det, kan du komme i gang med Risika.',
  [intl.auth('user-registered-title')]: 'Verificer din konto',
  [intl.auth('user-with-email-exists')]:
    'Der findes allerede en bruger med den e-mail',
  [intl.auth('work-email-label')]: 'Arbejdsmail',
  [intl.auth('wrong-email-or-password')]: 'Forkert e-mail eller password!',
  [intl.auth('your-company')]: 'Din virksomhed',

  [intl.companyHierarchy('equity')]: 'Egenkapital',
  [intl.companyHierarchy('local-id')]: 'Virksomheds ID',
  [intl.companyHierarchy('risika-score')]: 'Risika score',
  [intl.companyHierarchy('CEO')]: 'CEO',
  [intl.companyHierarchy('profit-loss')]: 'Årets resultat',
  [intl.companyHierarchy('accessories')]: 'Valgmuligheder',
  [intl.companyHierarchy('collapse-all')]: 'Skjul',
  [intl.companyHierarchy('coming-soon')]: 'Kommer snart',
  [intl.companyHierarchy('company_information')]: 'Virksomhedsoplysninger',
  [intl.companyHierarchy('d3-child-name')]: 'Datterselskab til',
  [intl.companyHierarchy('detailed-view')]: 'Detaljeret oversigt',
  [intl.companyHierarchy('equity')]: 'Egenkapital',
  [intl.companyHierarchy('equity:')]: 'Egenkapital:',
  [intl.companyHierarchy('expand-all')]: 'Udvid alle {number} virksomheder',
  [intl.companyHierarchy('expand-all-d3')]: 'Udvid',
  [intl.companyHierarchy('header')]: 'Koncernhierarki',
  [intl.companyHierarchy('list-view')]: 'Listevisning',
  [intl.companyHierarchy('tree-view')]: 'Træstruktur',
  [intl.companyHierarchy('display-props')]: 'Vis egenskaber',

  [intl.companyHierarchy('listed-in')]: 'Angivet i: ',
  [intl.companyHierarchy('local-id:')]: 'Virksomheds ID:',
  [intl.companyHierarchy('monitor-all')]: 'Overvåg alle',
  [intl.companyHierarchy('name')]: 'Navn',
  [intl.companyHierarchy('no-credit-score')]: 'Selskabet har ingen kreditscore',
  [intl.companyHierarchy('no-legal-owners')]: 'Ingen legale ejere',
  [intl.companyHierarchy('no-real-owners')]: 'Ingen reelle ejere',
  [intl.companyHierarchy('ownership')]: 'Ejerandele',
  [intl.companyHierarchy('profit_loss')]: 'Årets resultat',
  [intl.companyHierarchy('profit_loss:')]: 'Årets resultat:',
  [intl.companyHierarchy('relations')]: 'Virksomhedsroller',
  [intl.companyHierarchy('save-view-description')]:
    'Her kan du gemme hierarkivisningen til den aktuelle session. Når du logger ud, bliver alle dine gemte visninger væk.',
  [intl.companyHierarchy('save-view-title')]: 'Gem hierarkivisning',
  [intl.companyHierarchy('select-list')]: 'Vælg en liste',
  [intl.companyHierarchy('sort_by')]: 'Sorter efter',

  [intl.companyHighlights('click-for-more')]: 'klik for mere',
  [intl.companyHighlights('date-of-bancrupcy')]: ' Fra {date}',
  [intl.companyHighlights('title')]: 'Virksomhedsinformationer',
  [intl.companyHighlights('title')]: 'Virksomhedsinformationer',
  [intl.companyHighlights('profit-loss')]: 'Årets resultat',
  [intl.companyInfo('ad-protection')]: 'Reklamebeskyttelse',
  [intl.companyInfo('ad-protection-allowed')]:
    'Virksomheden har accepteret at blive kontaktet vedrørende reklame',
  [intl.companyInfo('ad-protection-denied')]:
    'Virksomheden har nægtet at blive kontaktet vedrørende reklame',
  [intl.companyInfo('add-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('add-internal-id-title')]: 'Tilføj internt ID',
  [intl.companyInfo('address')]: 'Adresse',
  [intl.companyInfo('auditors')]: 'Revisor',
  [intl.companyInfo('bank')]: 'Bank',
  [intl.companyInfo('company-id')]: 'Virksomheds ID',
  [intl.companyInfo('company-info-re-arrange')]:
    'Skræddersy de generelle virksomhedsoplysninger til dine behov ved at trække felterne nedenfor!',
  [intl.companyInfo('company-name')]: 'Virksomhedsnavn',
  [intl.companyInfo('company-name-required')]: 'Virksomhedsnavn *',
  [intl.companyInfo('company-type')]: 'Virksomhedstype',
  [intl.companyInfo('date-of-establishment')]: 'Stiftelsesdato',
  [intl.companyInfo('date-of-incorporation')]: 'Stiftet før 1900',
  [intl.companyInfo('ean')]: 'EAN Nummer',
  [intl.companyInfo('edit-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('edit-internal-id-title')]: 'Rediger internt ID',
  [intl.companyInfo('email')]: 'E-mail',
  [intl.companyInfo('employees')]: 'Ansatte',
  [intl.companyInfo('financial_year')]: 'Regnskabsår',
  [intl.companyInfo('founders_auditors')]: 'Stiftere',
  [intl.companyInfo('fte')]: 'Årsværk',
  [intl.companyInfo('industry')]: 'Branche',
  [intl.companyInfo('industry')]: 'Branchekoder',
  [intl.companyInfo('internal-id')]: 'Internt ID',
  [intl.companyInfo('invalid')]: 'Ugyldigt',
  [intl.companyInfo('land_value')]: 'Grundværdi',
  [intl.companyInfo('listed')]: 'Børsnoteret',
  [intl.companyInfo('number-of-employees')]: 'Antal ansatte',
  [intl.companyInfo('phone')]: 'Telefon',
  [intl.companyInfo('powers-to-bind')]: 'Tegningsregel',
  [intl.companyInfo('production-units')]: 'Produktionsenheder',
  [intl.companyInfo('property_id')]: 'Bygningsnummer',
  [intl.companyInfo('property_type')]: 'Ejendomstype',
  [intl.companyInfo('property_value')]: 'Ejendomsværdi',
  [intl.companyInfo('purpose')]: 'Formål',
  [intl.companyInfo('registered-for-vat')]: 'Momsregistreret',
  [intl.companyInfo('secondary-names')]: 'Binavne',
  [intl.companyInfo('share-capital')]: 'Selskabskapital',
  [intl.companyInfo('share_capital')]: 'Selskabskapital',
  [intl.companyInfo('source')]: 'Kilde',
  [intl.companyInfo('tinglysning')]: 'Tinglysning',
  [intl.companyInfo('title')]: 'Generel virksomhedsinformation',
  [intl.companyInfo('valid-from')]: 'Gyldig fra',
  [intl.companyInfo('valid-to')]: 'Gyldig til',
  [intl.companyInfo('valuation_date')]: 'Vurderingsdato',
  [intl.companyInfo('webpage')]: 'Hjemmeside',
  [intl.companyRoles('advanced-view')]: 'Avanceret',
  [intl.companyRoles('analysis-of-bankruptcies')]: 'Konkursanalyse',
  [intl.companyRoles('before-1987')]: 'Før 1987',
  [intl.companyRoles('board-of-directors')]: 'Bestyrelse',
  [intl.companyRoles('get-overview')]: 'Få oversigt',
  [intl.companyRoles('hide-historical-data')]: 'Skjul historisk data',
  [intl.companyRoles('historical-management')]: 'Historical management',
  [intl.companyRoles('history-hide')]: 'Skjul historik',
  [intl.companyRoles('history-show')]: 'Vis historik',
  [intl.companyRoles('legal-owners')]: 'Legale ejere',
  [intl.companyRoles('minimize-table')]: 'Simpel oversigt',
  [intl.companyRoles('month')]: '1 måned',
  [intl.companyRoles('months')]: '{months} måneder',
  [intl.companyRoles('no-board-of-directors')]: 'Ingen bestyrelse fundet',
  [intl.companyRoles('no-information')]: 'Oplysningerne er ikke tilgængelige',
  [intl.companyRoles('no-management')]: 'Ingen ledelse fundet',
  [intl.companyRoles('no-owners')]: 'Ingen ejere',
  [intl.companyRoles('other-positions')]: ' se mere...',
  [intl.companyRoles('real-owners')]: 'Reelle ejere',
  [intl.companyRoles('see-hierarchy')]: 'Se koncernhierarki',
  [intl.companyRoles('see-historical-data')]: 'Se historisk data',
  [intl.companyRoles('shares')]: 'andel',
  [intl.companyRoles('show-all')]: 'Vis alle',
  [intl.companyRoles('show-full-table')]: 'Fuld oversigt',
  [intl.companyRoles('show-less')]: 'Vis færre',
  [intl.companyRoles('simple-view')]: 'Simpel',
  [intl.companyRoles('subsidiaries')]: 'Datterselskaber',
  [intl.companyRoles('title')]: 'Ledelse',
  [intl.companyRoles('title-owners')]: 'Ejere',
  [intl.companyRoles('total')]: 'Total: ',
  [intl.companyRoles('total-owners')]: 'I alt ejere',
  [intl.companyRoles('year')]: '1 år ',
  [intl.companyRoles('years')]: '{years} år ',
  [intl.confirmAction('agree')]: 'Bekræft',
  [intl.confirmAction('cancel')]: 'Annuller',
  [intl.confirmAction('confirm')]: 'Bekræft',
  [intl.confirmAction('delete')]: 'Slet',
  [intl.confirmAction('save')]: 'Gem',
  [intl.country('de')]: 'Tyskland',
  [intl.country('dk')]: 'Danmark',
  [intl.country('fi')]: 'Finland',
  [intl.country('no')]: 'Norge',
  [intl.country('se')]: 'Sverige',
  [intl.country('uk')]: 'UK',
  [intl.creditCheck('auditor')]: 'Revisoroplysninger',
  [intl.creditCheck('boundary-line-1')]:
    'Der opstod et problem under behandlingen af ​​din anmodning. Prøv igen senere!',
  [intl.creditCheck('boundary-line-2')]:
    'Hvis det ikke virker, så prøv at logge ud og ind igen',
  [intl.creditCheck('boundary-line-3')]:
    'Vi er blevet underrettet om problemet og er i fuld gang med at løse det',
  [intl.creditCheck('beneficial_owner')]: 'Reelle ejere',
  [intl.creditCheck('cant-score-comapny')]: 'Vi kunne ikke score dette firma!',
  [intl.creditCheck('check-use-fail')]: 'Ingen oplysninger for udvalgt firma',
  [intl.creditCheck('check-use-success')]: 'Du brugte ét kredittjek',
  [intl.creditCheck('credit-check-re-arrange')]:
    'Tilpas din visning ved at flytte på felterne nedenfor',
  [intl.creditCheck('from')]: 'Fra',
  [intl.creditCheck('generate-report')]: 'Hent PDF-rapport',
  [intl.creditCheck('highlights')]: 'Bemærkninger',
  [intl.creditCheck('historical-scores')]: 'Se historiske score',
  [intl.creditCheck('monitor-company')]: 'Overvåg virksomhed',
  [intl.creditCheck('name')]: 'Navn',
  [intl.creditCheck('no-company-redirect')]:
    'Ingen virksomhed valgt, sender dig tilbage til "søg virksomhed"',
  [intl.creditCheck('no-data')]:
    'Koncernhierarki er endnu ikke tilgængelig for dette land',
  [intl.creditCheck('no-subsidiaries')]: 'Selskabet har ingen datterselskaber',
  [intl.creditCheck('otherPosition')]: 'Andre roller',
  [intl.creditCheck('ownership')]: 'Ejerandele',
  [intl.creditCheck('pep')]: 'PEP-liste',
  [intl.creditCheck('period')]: 'Årsrapport periode',
  [intl.creditCheck('position')]: 'Rolle',
  [intl.creditCheck('report-button')]: 'Del rapport',
  [intl.creditCheck('select-list')]: 'Vælg en liste',
  [intl.creditCheck('seniority')]: 'Anciennitet',
  [intl.creditCheck('share')]: 'Del',
  [intl.creditCheck('share-internal-header')]: 'Brugere fra din organisation',
  [intl.creditCheck('share-report')]: 'Del PDF-rapport',
  [intl.creditCheck('share-report-description')]:
    'Her kan du sende en kreditrapport til en valgfri e-mailadresse eller til en bruger i din organisation.',
  [intl.creditCheck('share-report-message')]:
    'Tilføj en besked sammen med rapporten',
  [intl.creditCheck('share-specific-email-header')]:
    'Send til en valgfri e-mail',
  [intl.creditCheck('shares')]: 'Aktier',
  [intl.creditCheck('specialRights')]: 'Særlige bemærkninger',
  [intl.creditCheck('stop-monitor-company')]: 'Stop overvågning',
  [intl.creditCheck('subheader-placeholder')]: 'E-mail på modtager',
  [intl.creditCheck('title')]: 'Kredittjek',
  [intl.creditCheck('to')]: 'Til',
  [intl.creditCheck('use-check-for-auditor-title')]:
    'Er du sikker på, du vil bruge ét kredittjek?',
  [intl.creditCheck('use-credit')]: 'Brug kredittjek',
  [intl.creditCheck('votingRights')]: 'Stemmerettigheder',

  [intl.creditPolicy('phone-number-registry-title')]:
    'Registreret telefonnummer',
  [intl.creditPolicy("AUDITOR'S REPORT ON EXTENDED REVIEW")]:
    'Erklæring om udvidet gennemgang',
  [intl.creditPolicy("AUDITOR'S REPORT")]: 'Revisorpåtegning',
  [intl.creditPolicy("AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)")]:
    'Andre erklæringer uden sikkerhed',
  [intl.creditPolicy("THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)")]:
    'Den uafhængige revisors erklæringer (review)',
  [intl.creditPolicy('1')]: '1',
  [intl.creditPolicy('1-and-more')]: '1 og mere',
  [intl.creditPolicy('1-year')]: '1 år',
  [intl.creditPolicy('10-and-more')]: '10',
  [intl.creditPolicy('2')]: '2',
  [intl.creditPolicy('2-and-more')]: '2 og mere',
  [intl.creditPolicy('2-year')]: '2 år',
  [intl.creditPolicy('3')]: '3',
  [intl.creditPolicy('3-and-more')]: '3 og mere',
  [intl.creditPolicy('3-year')]: '3 år',
  [intl.creditPolicy('4')]: '4',
  [intl.creditPolicy('4-and-more')]: '4 og mere',
  [intl.creditPolicy('5')]: '5',
  [intl.creditPolicy('5-and-more')]: '5 og mere',
  [intl.creditPolicy('6-and-more')]: '6 og mere',
  [intl.creditPolicy('7-and-more')]: '7 og mere',
  [intl.creditPolicy('8-and-more')]: '8 og mere',
  [intl.creditPolicy('9-and-more')]: '9 og mere',
  [intl.creditPolicy('NO AUDITOR ASSISTANCE')]: 'Ingen revisor',
  [intl.creditPolicy('POLICY_AGE')]: 'Virksomheden er for ung',
  [intl.creditPolicy('POLICY_AUDITOR_ASSISTANCE')]:
    'Revisorerklæringen er ikke tilladt',
  [intl.creditPolicy('POLICY_BANKRUPTCIES')]: 'For mange konkurser',
  [intl.creditPolicy('POLICY_COMPANY_TYPE')]: 'Afvist virksomhedstype',
  [intl.creditPolicy('POLICY_DISTANCE_TO_CEO')]: 'Stor afstand til CEO',
  [intl.creditPolicy('POLICY_ENTITY_ID')]: 'Afvist person',
  [intl.creditPolicy('POLICY_INDUSTRY')]: 'Industri afvist',
  [intl.creditPolicy('POLICY_LOCAL_ID')]: 'Afvist selskab',
  [intl.creditPolicy('POLICY_NEGATIVE_EQUITY')]: 'Negativ egenkapital',
  [intl.creditPolicy('POLICY_PHONE_NUMBER_REGISTRY')]:
    'Telefonnummer er ikke registreret',
  [intl.creditPolicy('POLICY_PREPAID_PHONE_NUMBER')]: 'Taletidskort numre',
  [intl.creditPolicy('POLICY_RISIKA_SCORE')]: 'Lav kreditscore',
  [intl.creditPolicy('POLICY_VAT')]: 'Moms',
  [intl.creditPolicy('above-1-year')]: 'Over 1 år',
  [intl.creditPolicy('above-2-year')]: 'Over 2 år',
  [intl.creditPolicy('above-3-year')]: 'Over 3 år',
  [intl.creditPolicy('above-4-year')]: 'Over 4 år',
  [intl.creditPolicy('activate-advanced-view')]:
    'Dette er en avanceret funktion. Kontakt os, hvis du vil prøve det!',
  [intl.creditPolicy('age-limitation-information')]:
    'Vælg den laveste virksomhedsalder som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('age-limitation-title')]: 'Laveste virksomhedsalder',
  [intl.creditPolicy('allow-phone-number')]: 'Tillad',
  [intl.creditPolicy('are-you-sure')]:
    'Det ser ud til, at du har foretaget nogle ændringer i din kreditpolitik. Er du sikker på, at du vil forlade uden at gemme?',
  [intl.creditPolicy('are-you-sure-dialog')]:
    'Denne handling deaktiverer kreditpolitikken for hele din organisation. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicy('are-you-sure-dialog-clear-field')]:
    'Denne handling sletter alle poster i dette felt. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicy('auditor-assistance-information')]:
    'Vælg det laveste niveau af erklæring fra revisor som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('auditor-assistance-title')]:
    'Ikke godkendte erklæringstyper',
  [intl.creditPolicy('auditor-placeholder')]: 'Vælg mange',
  [intl.creditPolicy('auditors_report')]: 'Revisorpåtegning',
  [intl.creditPolicy('bancrupcy-limit-information')]:
    'Vælg det højeste antal af relateret konkurser som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('bancrupcy-limit-title')]:
    'Maksimalt antal relateret konkurser',
  [intl.creditPolicy('company-does-not-comply-with-policy')]:
    'Virksomheden overholder ikke kreditpolitikken',
  [intl.creditPolicy('company-types-information')]:
    'Frasorter virksomhedstyper i jeres kreditpolitik',
  [intl.creditPolicy('company-types-title')]: 'Frasorteret virksomhedstyper',
  [intl.creditPolicy('copied-success')]: 'Politik kopieret med succes',
  [intl.creditPolicy('copy-policy')]: 'Kopiér politik',
  [intl.creditPolicy('copy-policy-title')]:
    'Vælg det land og de felter, du vil kopiere!',
  [intl.creditPolicy('distance-to-ceo-information')]:
    "CEO's afstand i km fra virksomhedsadressen",
  [intl.creditPolicy('distance-to-ceo-title')]: 'Afstand til CEO',
  [intl.creditPolicy('dk')]: 'Danmark',
  [intl.creditPolicy('dont-allow-phone-number')]: 'Tillad ikke',
  [intl.creditPolicy('exclude-companies-information')]:
    'Frasorter specifikke virksomheder i jeres kreditpolitik',
  [intl.creditPolicy('exclude-companies-title')]:
    'Frasorter specifikke virksomheder i jeres kreditpolitik',
  [intl.creditPolicy('exclude-persons-information')]:
    'Frasorter specifikke personer i jeres kreditpolitik',
  [intl.creditPolicy('exclude-persons-title')]: 'Frasorter personer',
  [intl.creditPolicy('exclude-vat-information')]:
    'Skal et firma være momsregistreret, eller skal det bare være under den tilladte grænse for et land?',
  [intl.creditPolicy('exclude-vat-title')]:
    'Ekskluder virksomheder, der ikke er momsregistrerede',
  [intl.creditPolicy('less-than-1')]: 'lindre end 1',
  [intl.creditPolicy('more-than-3')]: '3 og mere',
  [intl.creditPolicy('more-than-4')]: '4 og mere',
  [intl.creditPolicy('more-than-3-years')]: 'Mere end 3 år',
  [intl.creditPolicy('more-than-4-years')]: 'Mere end 4 år',
  [intl.creditPolicy('more-than-5')]: '5 og mere',
  [intl.creditPolicy('name_of_auditor')]: 'Revisor',
  [intl.creditPolicy('negative-equity-information')]:
    'Vælg det antal år uden negativ egenkapital som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('negative-equity-title')]:
    'Seneste år uden negativ egenkapital',
  [intl.creditPolicy('no')]: 'Norge',
  [intl.creditPolicy('no-conflicts')]: 'Ingen konflikter med kreditpolitikken.',
  [intl.creditPolicy('none')]: 'Ingen',
  [intl.creditPolicy('off')]: 'Tillad',
  [intl.creditPolicy('phone-number-registry-information')]:
    'Er telefonnummeret registreret i telefonbogen',
  [intl.creditPolicy('phone-number-registry-title')]:
    'Registreret telefonnummer',
  [intl.creditPolicy('policy-not-available-for-non-admin-users')]:
    'Kreditpolitikken er kun tilgængelig for administratorer i jeres organisation. Hvis du mener at du skulle kunne ændre i kreditpolitikken, kontakt derved jeres administrator eller os på contact@risika.dk.',
  [intl.creditPolicy('policy-off')]: 'Kreditpolitik deaktiveret',
  [intl.creditPolicy('policy-on')]: 'Kreditpolitik aktiveret',
  [intl.creditPolicy('policy-switch-failed')]:
    'Kunne ikke skifte kreditpolitik',
  [intl.creditPolicy('policy-switch-off-success')]:
    'Din kreditpolitik for {country} er deaktiveret',
  [intl.creditPolicy('policy-switch-on-success')]:
    'Din kreditpolitik for {country} er aktiveret',
  [intl.creditPolicy('prepaid-phone-number-information')]:
    'Bliv notificeret hvis et firma bliver oprettet med et taletidskort nummer',
  [intl.creditPolicy('prepaid-phone-number-title')]:
    'Tillad taletidskort numre',
  [intl.creditPolicy('risika-score-information')]:
    'Vælg den laveste Risika Score som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('risika-score-title')]: 'Laveste Risika Score',
  [intl.creditPolicy('save-failed')]:
    'Kunne ikke gemme kreditpolitikken for {country}. Prøv igen senere!',
  [intl.creditPolicy('save-success')]: 'Gemt kreditpolitik for {country}',
  [intl.creditPolicy('se')]: 'Sverige',
  [intl.creditPolicy('search')]: 'Søg og tilføj',
  [intl.creditPolicy('select')]: 'Select',
  [intl.creditPolicy('select-many')]: 'Vælg mange',
  [intl.creditPolicy('should-be')]: 'Tillad ikke',
  [intl.creditPolicy('should-be-and-exceeded')]:
    'Kun hvis det er over den lokale momsgrænse',
  [intl.creditPolicy('specific-address-title')]: 'Exclude addresses',
  [intl.creditPolicy('specific-industry-information')]:
    'Frasorter specifikke industrier i jeres kreditpolitik',
  [intl.creditPolicy('specific-industry-title')]: 'Frasorter industrier',
  [intl.creditPolicy('too-many-values')]:
    'Værdier for {country} overskrider grænsen. Tilføjet kun 500 virksomheder!',
  [intl.creditPolicy('type_of_assistance')]: 'Erklæringstype',
  [intl.creditPolicy('uk')]: 'UK',
  [intl.creditPolicy('up-to-1')]: '1 og mindre',
  [intl.creditPolicy('up-to-2')]: '2 og mindre',
  [intl.creditPolicy('up-to-3')]: '3 og mindre',
  [intl.creditPolicy('up-to-4')]: '4 og mindre',
  [intl.creditPolicy('up-to-5')]: '5 og mindre',
  [intl.customerManagement('add-new-users')]: 'Add New Users',
  [intl.customerManagement('are-you-sure-dialog')]:
    'Are you sure you want to revoke access for this company?',
  [intl.customerManagement('customer-plan')]: 'Customer Plan',
  [intl.customerManagement('customer-plan-basic')]: 'Basic',
  [intl.customerManagement('customer-plan-enterprise')]: 'Enterprise',
  [intl.customerManagement('customer-plan-freemium')]: 'Freemium',
  [intl.customerManagement('customer-plan-pro')]: 'Pro',
  [intl.customerManagement('customer-plan-standard')]: 'Standard',
  [intl.customerManagement('customer-plan-trial')]: 'Trial',
  [intl.customerManagement('customer-type')]: 'Customer Type',
  [intl.customerManagement('customer-type-customer')]: 'Customer',
  [intl.customerManagement('customer-type-lost-customer')]: 'Lost Customer',
  [intl.customerManagement('customer-type-lost-trial')]: 'Lost Trial',
  [intl.customerManagement('customer-type-risika')]: 'Risika (internal)',
  [intl.customerManagement('customer-type-trial')]: 'Trial',
  [intl.customerManagement('customer-updated')]: 'Customer updated',
  [intl.customerManagement('existing-customers')]: 'Existing Customers',
  [intl.customerManagement('existing-users')]: 'Existing Users',
  [intl.customerManagement('expiry-date')]: 'Expiry Date',
  [intl.customerManagement('handle-access')]: 'Revoke access',
  [intl.customerManagement('manage-customer')]: 'Manage Customer',
  [intl.customerManagement('my-customers')]: 'My Customers',
  [intl.customerManagement('new-customer')]: 'New Customer',
  [intl.customerManagement('new-customer-title')]: 'Create New Customer',
  [intl.customerManagement('responsible-person')]: 'Set responsible person',
  [intl.customerManagement('revoke-access')]: 'Revoke Access',
  [intl.customerManagement('title')]: 'Customer Management',
  [intl.customerManagement('users')]: 'Users',
  [intl.developer('confirm-remove-webhook-description')]:
    'OBS: hvis du fjerner webhooken og beslutter dig for at oprette en ny, så vil der blive genereret en ny secret.',
  [intl.developer('confirm-remove-webhook-title')]:
    'Er du sikker på du vil fjerne jeres webhook?',
  [intl.developer('documentation')]: 'dokumentation',
  [intl.developer('title')]: 'Udvikler',
  [intl.developer('webhook-add-info')]:
    'For mere information i forhold til anvendelse af webhooks, venligst tjek vores',
  [intl.developer('webhook-existing-info')]:
    'Din webhook er opsat. Du kan læse mere om, hvordan du anvender dem fra vores',
  [intl.developer('webhook-secret')]: 'Webhook Secret',
  [intl.developer('webhook-title')]: 'Webhook',
  [intl.developer('webhook-url')]: 'Webhook URL',
  [intl.developer('webhook-url-remove-button')]: 'Fjern',
  [intl.developer('webhook-url-save-button')]: 'Tilføj',
  [intl.developer('webhook-url-test-button')]: 'Test',
  [intl.enforcedPasswordChange('confirm-password-label')]:
    'Bekræft dit password',
  [intl.enforcedPasswordChange('description')]:
    'Af sikkerhedsmæssige grunde er du nødt til at skifte password.',
  [intl.enforcedPasswordChange('min-characters')]: 'Mindst 8 tegn',
  [intl.enforcedPasswordChange('must-match-password')]:
    'Skal være det samme som password',
  [intl.enforcedPasswordChange('password-change-success')]:
    'Perfekt! Du kan nu fortsætte med at bruge Risika',
  [intl.enforcedPasswordChange('password-label')]: 'Password',
  [intl.enforcedPasswordChange('submit')]: 'Skift password',
  [intl.enforcedPasswordChange('title')]: 'Ændring af password påkrævet',
  [intl.exportButton('description')]:
    '{count, plural, =0 {Intet at eksportere} one {Eksportering på {count} række} other {Eksportering på {count} rækker}}',
  [intl.exportButton('max-export')]:
    '{count, plural, other {Begrænset til de første {count}}}',
  [intl.exportButton('max-out-of')]:
    '{count, plural, other {ud af {count} rækker}}',
  [intl.exportButton('text')]: 'Eksporter',
  [intl.faq('Antal ansatte')]: 'Antal ansatte',
  [intl.faq('Audit')]: 'Revision',
  [intl.faq('Company size')]: 'Virksomhedsstørelse',
  [intl.faq('Connected bankruptcies')]: 'Relaterede konkurser',
  [intl.faq('Credit days')]: 'Kredit dage',
  [intl.faq('Credit maximum')]: 'Kreditmaks',
  [intl.faq('Distress percentage year')]: 'Konkursprocenter 2020',
  [intl.faq('Effect')]: 'Effekt',
  [intl.faq('Erklæringstype')]: 'Erklæringstype',
  [intl.faq('Explanation')]: 'Forklaring',
  [intl.faq('Financial key figures')]: 'Finansielle nøgletal',
  [intl.faq('Fractile')]: 'Fraktil',
  [intl.faq('Fraktil')]: 'Fraktil',
  [intl.faq('How')]: 'Hvordan',
  [intl.faq(
    'I 2019 gik 2% af danske virksomheder under konkurs eller under tvangsopløsning.',
  )]: '',
  [intl.faq('Key figure performance')]: 'Nøgletal',
  [intl.faq('Level of audit')]: 'Erklæringstype',
  [intl.faq('Maximum')]: 'Maksimum',
  [intl.faq('Number of employees')]: 'Antal ansatte',
  [intl.faq('Number of employees')]: 'Antal ansatte',
  [intl.faq('Other Ratios')]: 'Øvrige nøgletal',
  [intl.faq('Ownership')]: 'Ejerskab',
  [intl.faq('Performance')]: 'Performance',
  [intl.faq('Reason')]: 'Årsag',
  [intl.faq('Risika score')]: 'Risika score',
  [intl.faq('Risk')]: 'Risiko',
  [intl.faq('Sole proprietorship model')]: 'Enkeltmandsmodel',
  [intl.faq('UC: 77.93%, Gini: 55.86%')]: 'UC: 77.93%, Gini: 55.86%',
  [intl.faq('Understanding risika score and figures')]:
    'Forstå risika score og nøgletal',
  [intl.faq('Unscored companies')]: 'Virksomheder uden score',
  [intl.faq('Virksomhedsstørelse')]: 'Virksomhedsstørelse',
  [intl.faq('What')]: 'Hvad',
  [intl.faq('Why')]: 'Hvorfor',
  [intl.faq(
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%',
  )]:
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%',
  [intl.faq('definition')]: 'Definition',
  [intl.faq('variable')]: 'Nøgletal',
  [intl.financial('probability-of-closure')]: 'Konkurssandsynlighed',
  [intl.financial('distress')]: 'Konkurssandsynlighed',
  [intl.financialRatio('no-content')]: 'Intet indhold tilgængeligt',
  [intl.financialRatio('performance')]: 'Performance',
  [intl.firstTimeVisit('confirm-password-label')]: 'Bekræft dit password',
  [intl.firstTimeVisit('description')]:
    'Safety first! Du skal blot vælge dit nye password til platformen og så er du i gang.',
  [intl.firstTimeVisit('min-characters')]: 'Mindst 8 tegn',
  [intl.firstTimeVisit('must-match-password')]:
    'Skal være det samme som password',
  [intl.firstTimeVisit('password-label')]: 'Password',
  [intl.firstTimeVisit('submit')]: 'Kom i gang',
  [intl.firstTimeVisit('title')]: 'Vælg dit password',
  [intl.generic('actions')]: 'Handlinger',
  [intl.generic('active')]: 'Aktiv',
  [intl.generic('add')]: 'Tilføj',
  [intl.generic('clear-selection')]: 'Ryd',
  [intl.generic('advanced-views-not-allowed')]:
    'Avancerede visninger er ikke tilgængelige på din nuværende plan. Klik her for at se mere!',
  [intl.generic('all')]: 'Alle',
  [intl.generic('back')]: 'Tilbage',
  [intl.generic('cancel')]: 'Annuller',
  [intl.generic('checks-left')]: 'kredittjek tilbage',
  [intl.generic('clear')]: 'Fjern',
  [intl.generic('companies')]: 'Virksomheder',
  [intl.generic('company')]: 'Virksomhed',
  [intl.generic('confirm-password')]: 'Bekræft password',
  [intl.generic('create')]: 'Opret',
  [intl.generic('cvr-number')]: 'CVR-nummer',
  [intl.generic('denmark')]: 'Danmark',
  [intl.generic('do-not-want-to-wait')]:
    'Vil du ikke vente? Opdater dit abonnement nedenfor!',
  [intl.generic('edit')]: 'Rediger',
  [intl.generic('email')]: 'E-mail',
  [intl.generic('emails')]: 'E-mails',
  [intl.generic('employees')]: 'ansatte',
  [intl.generic('error-reach-us-on-chat')]:
    'Der opstod en fejl! Venlist kontakt os på chatten',
  [intl.generic('failed-to-fetch-list')]:
    'Der gik noget galt med at få data til "{name}"!',
  [intl.generic('feature-not-available')]:
    'Denne funktion er ikke tilgængelig i dit abonnement.',
  [intl.generic('first-name')]: 'Fornavn',
  [intl.generic('first-page')]: 'Første Side',
  [intl.generic('fte')]: 'årsværk',
  [intl.generic('get-auditor-information')]: 'Se revisorinformation',
  [intl.generic('get-credit-information')]: 'Se kreditoplysninger',
  [intl.generic('get-highlight-information')]: 'Se opmærksomhedspunkter',
  [intl.generic('import')]: 'Importér',
  [intl.generic('inactive')]: 'Inaktiv',
  [intl.generic('language')]: 'Sprog',
  [intl.generic('last-name')]: 'Efternavn',
  [intl.generic('last-page')]: 'Sidste Side',
  [intl.generic('learn-more')]: 'Lær mere',
  [intl.generic('missing-address')]: 'Adresse mangler',
  [intl.generic('monitor')]: 'Overvåg',
  [intl.generic('monitor-stop')]: 'Stop overvågning',
  [intl.generic('more-checks-on-monday')]:
    'Du får {credit_rating_cap} på mandag',
  [intl.generic('name')]: 'Navn',
  [intl.generic('never')]: 'Ikke tilgængeligt',
  [intl.generic('next-page')]: 'Næste Side',
  [intl.generic('no')]: 'Nej',
  [intl.generic('no-credit-information')]:
    'Ingen tilgængelige kreditoplysninger for dette firma!',
  [intl.generic('no-data')]: 'Intet data',
  [intl.generic('norway')]: 'Norge',
  [intl.generic('number-of-companies')]: 'Antal virksomheder',
  [intl.generic('optional')]: 'valgfrit',
  [intl.generic('password')]: 'Password',
  [intl.generic('phone')]: 'Telefon',
  [intl.generic('previous-page')]: 'Forrige Side',
  [intl.generic('remove')]: 'Fjern',
  [intl.generic('restore-default')]: 'Gendan standard',
  [intl.generic('risika-score')]: 'Risika score',
  [intl.generic('roles')]: 'Roller',
  [intl.generic('rows')]: 'Rækker',
  [intl.generic('save')]: 'Gem',
  [intl.generic('selected')]: 'Valgt',
  [intl.generic('send')]: 'Send',
  [intl.generic('show-more')]: 'Vis mere',
  [intl.generic('stop')]: 'Stop',
  [intl.generic('sweden')]: 'Sverige',
  [intl.generic('total')]: 'Total',
  [intl.generic('update')]: 'Opdater',
  [intl.generic('yes')]: 'Ja',
  [intl.generic('you-have')]: 'Du har',
  [intl.generic('open-all')]: 'Åben alle',
  [intl.generic('close-all')]: 'Luk alle',
  [intl.generic('more-details')]: 'Flere detaljer',
  [intl.generic('less-details')]: 'Færre detaljer',
  [intl.generic('days')]: 'dage',
  [intl.generic('unknown')]: 'Ukendt',

  [intl.generic('company-id')]: 'Virksomheds ID',
  [intl.generic('company-name')]: 'Virksomheds Navn',
  [intl.generic('company-type')]: 'Virksomheds Type',
  [intl.generic('date-of-incorporation')]: 'Stiftelsesdato',
  [intl.generic('email')]: 'Email',
  [intl.generic('status')]: 'Status',
  [intl.generic('risika-score')]: 'Risikas score',
  [intl.generic('credit-max')]: 'Kreditmaksimum',
  [intl.generic('phone')]: 'Telefon',
  [intl.generic('street')]: 'Gade',
  [intl.generic('street-number')]: 'Gadenummer',
  [intl.generic('coname')]: 'Coname',
  [intl.generic('zip-code')]: 'Postnummer',
  [intl.generic('city')]: 'By',
  [intl.generic('municipality')]: 'Kommune',
  [intl.generic('postal-district')]: 'Postdistrikt',
  [intl.generic('country')]: 'Land',
  [intl.generic('number-of-employees')]: 'Antal medarbejdere',
  [intl.generic('industry-code')]: 'Branchekoder',
  [intl.generic('industry-description')]: 'Branchebeskrivelse',
  [intl.generic('internal-id')]: 'Internt id',
  [intl.generic('currency')]: 'Valuta',
  [intl.generic('equity')]: 'Egenkapital',
  [intl.generic('profit-loss')]: 'Årets resultat',
  [intl.generic('solidity')]: 'Soliditetsgrad',
  [intl.generic('liquidity')]: 'Likviditetsgrad',
  [intl.generic('local-id')]: 'Virksomheds ID',

  [intl.grading('average')]: 'Middel',
  [intl.grading('not-available')]: 'n/a',
  [intl.grading('strong')]: 'Stærk',
  [intl.grading('very-strong')]: 'Meget stærk',
  [intl.grading('very-weak')]: 'Meget svag',
  [intl.grading('weak')]: 'Svag',
  [intl.history('advancedfilters')]: 'Filtrering',
  [intl.history('companyhierarchy')]: 'Koncernhierarki',
  [intl.history('creditpolicy')]: 'Kreditpolitik',
  [intl.history('home')]: 'Forside',
  [intl.history('monitoring')]: 'Overvågning',
  [intl.history('company-comparison')]: 'Sammenlign',
  [intl.history('observational-lists')]: 'Observationslister',
  [intl.home('faq-score-calculation-description')]:
    'Vi udregner Risikascoren ved at anvende en scorecard-model, som inddeler alle virksomheder i risikogrupperne 1 - 10 baseret på en branchejusterede finansielle nøgletal, virksomhedens alder, erklæringstype, relaterede konkurser, tvangsopløsninger og industrien virksomheden befinder sig i. Af regnskabstal og andre variable, der indgår i modellen, er årets resultat, EBITDA, EBIT, finansielle udgifter, omsætningsaktiver, balance, gæld, egenkapital, valuta, virksomhedsform, stiftelsesdato, erklæringstype og industri.',
  [intl.home('faq-score-calculation-title')]:
    'Hvordan er Risika scoren beregnet?',
  [intl.home('faq-title')]: 'Ofte stillede spørgsmål (FAQ)',
  [intl.home('integrations-description')]:
    'Få indsigt i, hvordan du kan automatisere kreditprocessen i din virksomhed ved at bruge en integration til dine eksisterende CRM og ERP systemer.',
  [intl.home('integrations-title')]:
    'Integrér Risika direkte ind i dine eksisterende værktøjer',
  [intl.home('monitor-list-label')]: 'Dine overvågningslister',
  [intl.home('monitor-overview-title')]: 'Dine overvågede virksomheder',
  [intl.home('monitor-recent-changes')]: 'Seneste ændringer',
  [intl.home('no-changes')]: 'Ingen ændringer',
  [intl.home('sales-and-marketing-description')]:
    'Opsæt din egen interne og branchespecifikke kreditpolitik direkte på platformen, hvorefter alt brug af Risika automatisk tilrettes efter denne politik. På den måde maksimeres sikkerheden for at indgå de korrekte aftaler med dine B2B kunder.',
  [intl.home('sales-and-marketing-title')]:
    'Forbedrer din risikostyring ved en automatisering af kreditpolitikken',
  [intl.home('newsletter-description')]:
    'Den nye scorecardmodel er nu live på Risika platformen. Dette betyder at mange virksomheder i Danmark og Norge har fået en ny og mere præcis Risika score. Du kan her læse mere omkring den nye model. Derudover så er du også velkommen til at kontakte os, hvis du har nogle spørgsmål.',
  [intl.home('newsletter-title')]: 'De seneste nyheder på Risika platformen',
  [intl.home('subtitle')]:
    'Lær mere om, hvordan du får det meste ud af platformen.',
  [intl.home('title')]: 'Velkommen til Risika',
  [intl.homePage('contact-availability')]: 'Tilgængelig de næste 3 timer',
  [intl.homePage('contact-title')]: 'Har du et spørgsmål?',
  [intl.homePage('go-to-monitoring')]: 'Gå til overvågning',
  [intl.homePage('invite-button')]: 'Invitér',
  [intl.homePage('invite-description')]:
    'Hvis du inviterer en forretningspartner og de prøver systemet inden for en uge, så modtager du 2 entrébilletter til Tivoli.',
  [intl.homePage('invite-description-fields')]:
    'Udfyld felterne nedenfor, så sender vi en e-mail med prøve-login til personen inden for 24 timer.',
  [intl.homePage('invite-form-company')]:
    'Søg efter virksomhed eller skriv CVR-nummer',
  [intl.homePage('invite-form-email')]: 'Arbejdsmail',
  [intl.homePage('invite-form-invite-button')]: 'Send invitation',
  [intl.homePage('invite-form-name')]: 'Fulde navn',
  [intl.homePage('invite-form-no-options')]: 'Ingen virksomheder',
  [intl.homePage('invite-sent-success')]:
    'Tak! Vi sender invitationen afsted inden for de næste 24 timer',
  [intl.homePage('invite-suplementary-text')]:
    'Kender du nogen, der vil prøve Risika?',
  [intl.homePage('invite-title')]:
    'Inviter en forretningspartner og kom i Tivoli',
  [intl.homePage('monitor-changes-subtitle')]: 'Ændringer de seneste 7 dage',
  [intl.homePage('monitor-changes-title')]: 'Mest relevante ændringer',
  [intl.homePage('monitor-overview-empty')]: 'Ingen virksomheder i overvågning',
  [intl.homePage('monitor-overview-import')]:
    'Importer din kundeliste og kom hurtigt i gang',
  [intl.homePage('monitor-portfolio-subtitle')]:
    '{count, plural, =0 {Du kan ikke overvåge flere virksomheder} one {Du kan stadig overvåge en virksomhed} other {Du kan stadig overvåge {count} virksomheder}}',
  [intl.homePage('monitor-portfolio-title')]: 'Dine overvågede virksomheder',
  [intl.homePage('monitor-suggestions-empty')]: 'Ingen foreslag endnu',
  [intl.homePage('monitor-suggestions-empty-description')]:
    'Så snart du har søgt på en virksomhed vil du få foreslag',
  [intl.homePage('monitor-suggestions-subtitle')]:
    'Baseret på dine seneste søgninger',
  [intl.homePage('monitor-suggestions-title')]: 'Foreslag til overvågning',
  [intl.homePage('news-letter-april-main')]: 'Risikas april opdatering',
  [intl.homePage('news-letter-april-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('news-letter-february-main')]: 'Risikas februar opdatering',
  [intl.homePage('news-letter-february-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('news-letter-march-main')]: 'Risikas marts opdatering',
  [intl.homePage('news-letter-march-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('recent-searches-subtitle')]:
    'Du kan enkelt tilføje dem til din nuværende liste',
  [intl.homePage('recent-searches-title')]: 'Seneste søgninger',
  [intl.homePage('relevant-changes-empty')]:
    'Ingen ændringer de seneste 7 dage.',
  [intl.homePage('relevant-changes-empty-description')]:
    'Så snart der er sket en ændring, vil du se den her',
  [intl.homePage('resources-and-integrations-title')]: 'Lær mere om Risika',
  [intl.homePage('resources-api-solution')]: 'API-løsning',
  [intl.homePage('resources-api-solution-description')]:
    'Læs mere om vores API-løsning',
  [intl.homePage('resources-click-copy-example')]:
    'Klik for kopiering i Kredittjek',
  [intl.homePage('resources-click-copy-example-description')]:
    'Let kopiering af data på en virksomhed',
  [intl.homePage('resources-dynamics-crm')]: 'Dynamics CRM',
  [intl.homePage('resources-dynamics-crm-description')]:
    'Læs mere om vores Microsoft Dynamics integration',
  [intl.homePage('resources-features-and-integrations')]:
    'Funktioner & Integrationer',
  [intl.homePage('resources-features-and-integrations-description')]:
    'Læs om vores funktioner og integrationer',
  [intl.homePage('resources-monitoring-delete-example')]:
    'Slet flere overvågede selskaber samtidigt',
  [intl.homePage('resources-monitoring-delete-example-description')]:
    'Demo af hvordan du sletter flere selskaber samtidigt',
  [intl.homePage('resources-monitoring-example')]:
    'Hvordan virker vores overvågning?',
  [intl.homePage('resources-monitoring-example-desription')]:
    'Demo af de forskellige funktioner i overvågning',
  [intl.homePage('resources-monitoring-import-example')]:
    'Importer virksomheder i overvågning',
  [intl.homePage('resources-monitoring-import-example-description')]:
    'Demo af importering af virksomheder i overvågning',
  [intl.homePage('resources-navision')]: 'Navision',
  [intl.homePage('resources-navision-description')]:
    'Læs mere om vores Microsoft Navision integration',
  [intl.homePage('resources-search-example')]: 'Søg efter virksomheder',
  [intl.homePage('resources-search-example-description')]:
    'Demo af hvordan du kan søge efter virksomheder',
  [intl.homePage('welcome')]: 'Velkommen {name}',
  [intl.importButton('description')]:
    'CSV og Excel (XLSX) format understøttet. Kolonnen der indeholder CVR-numre skal hedde "cvr"',
  [intl.importButton('text')]: 'Importer',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('')]: ' ',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('annual-report')]: 'Årsrapport',
  [intl.keyFinancialFigures('assets')]: 'Aktiver i alt',
  [intl.keyFinancialFigures('balance')]: 'Passiver i alt',
  [intl.keyFinancialFigures('basic-view')]: 'Simpel oversigt',
  [intl.keyFinancialFigures('cash')]: 'Likvide beholdninger',
  [intl.keyFinancialFigures('consolidated-statement')]: 'Koncernregnskab',
  [intl.keyFinancialFigures('contributedCapital')]: 'Selskabskapital',
  [intl.keyFinancialFigures('currentAssets')]: 'Omsætningsaktiver',
  [intl.keyFinancialFigures('currentFinancialAssets')]: 'Værdipapirer',
  [intl.keyFinancialFigures('debt')]: 'Samlet gæld',
  [intl.keyFinancialFigures('depreciation')]: 'Afskrivninger',
  [intl.keyFinancialFigures('detailed-view')]: 'Detaljeret',
  [intl.keyFinancialFigures('dividend')]: 'Udbytte',
  [intl.keyFinancialFigures('ebit')]: 'EBIT',
  [intl.keyFinancialFigures('ebitda')]: 'EBITDA',
  [intl.keyFinancialFigures('equity')]: 'Egenkapital',
  [intl.keyFinancialFigures('full-view')]: 'Fuld oversigt',
  [intl.keyFinancialFigures('grossResult')]: 'Bruttoresultat',
  [intl.keyFinancialFigures('intangibleAssets')]: 'Immaterielle anlægsaktiver',
  [intl.keyFinancialFigures('interestExpenses')]: 'Finansielle udgifter',
  [intl.keyFinancialFigures('interestIncome')]: 'Finansielle indtægter',
  [intl.keyFinancialFigures('inventories')]: 'Varelager',
  [intl.keyFinancialFigures('liabilitiesAndEquity')]: 'Passiver i alt',
  [intl.keyFinancialFigures('longTermDebt')]: 'Langfristet gæld',
  [intl.keyFinancialFigures('noncurrentAssets')]: 'Anlægsaktiver i alt',
  [intl.keyFinancialFigures('noncurrentFinancialAssets')]:
    'Finansielle anlægsaktiver',
  [intl.keyFinancialFigures('not-available')]: 'Ikke tilgængelig',
  [intl.keyFinancialFigures('ordinaryProfit')]: 'Ordinært resultat',
  [intl.keyFinancialFigures('otherNetFinancialIncome')]:
    'Netto øvrige finansielle poster',
  [intl.keyFinancialFigures('otherShortTermDebt')]: 'Anden gæld',
  [intl.keyFinancialFigures('otherShortTermReceivables')]:
    'Andre tilgodehavender',
  [intl.keyFinancialFigures('parent-statement')]: 'Moderregnskab',
  [intl.keyFinancialFigures('profitLoss')]: 'Årets resultat',
  [intl.keyFinancialFigures('propertyPlantAndEquipment')]:
    'Materielle anlægsaktiver',
  [intl.keyFinancialFigures('provisions')]: 'Hensatte forpligtelser',
  [intl.keyFinancialFigures('reserves')]: 'Andre reserver',
  [intl.keyFinancialFigures('retainedEarnings')]: 'Overført resultat',
  [intl.keyFinancialFigures('revenue')]: 'Omsætning',
  [intl.keyFinancialFigures('shareCapital')]: 'Selskabskapital',
  [intl.keyFinancialFigures('shortTermDebt')]: 'Kortfristet gæld',
  [intl.keyFinancialFigures('shortTermDebtToBanks')]:
    'Kortfristet gæld til banker',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Gæld til koncernselskaber',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Tilgodehavender fra koncernselskaber',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Tilgodehavender fra salg og tjenesteydelser',
  [intl.keyFinancialFigures('shortTermTaxPayables')]: 'Selskabsskat',
  [intl.keyFinancialFigures('shortTermTradePayables')]: 'Leverandørgæld',
  [intl.keyFinancialFigures('staffExpenses')]: 'Lønomkostninger',
  [intl.keyFinancialFigures('taxExpenses')]: 'Skat af årets resultat',
  [intl.keyFinancialFigures('title')]: 'Regnskabstal',
  [intl.keyFinancialFigures('total-debt')]: 'Samlet gæld',
  [intl.keyFinancialFigures('currency')]: 'Valuta',
  [intl.keyFinancialFigures('equity')]: 'Egenkapital',
  [intl.keyFinancialFigures('profit-loss')]: 'Årets resultat',
  [intl.keyFinancialFigures('solidity')]: 'Soliditetsgrad',
  [intl.keyFinancialFigures('liquidity')]: 'Likviditetsgrad',
  [intl.keyFinancialFigures('capital')]: 'Kapital',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Tilgodehavender fra salg og tjenesteydelser',
  [intl.loginRegister('forgot-your-password')]: 'Glemt din adgangskode',
  [intl.loginRegister('login-submit-button')]: 'Log på',
  [intl.loginRegister('login-to-account')]: 'Log ind på din konto',
  [intl.loginRegister('register-create-account')]: 'Opret din konto',
  [intl.loginRegister('register-create-button')]: 'Opret',
  [intl.loginRegister('register-popup-failure')]:
    'Oprettelse af konto mislykkedes! Kontakt os venligst, så hjælper vi.',
  [intl.loginRegister('register-popup-password')]:
    'Adgangskoderne stemmer ikke overens',
  [intl.loginRegister('register-popup-success')]:
    'Konto oprettet! Du kan nu logge ind.',
  [intl.loginRegister('remember-me')]: 'Husk mig',
  [intl.loginRegister('static-content-paragraph-1')]:
    'Vores vision er at levere de bedste økonomiske risikostyringsløsninger til vores kunder globalt.',
  [intl.loginRegister('static-content-paragraph-2')]:
    'For at opnå denne vision udvinder vi værdifulde indsigter fra både ikke-traditionelle og offentlige datakilder ved hjælp af avancerede algoritmer. Slutmålet er tilpassede, automatiserede og intuitive løsninger, der hjælper dig og din virksomhed med at minimere risikoen, spare tid og tage bedre beslutninger.',
  [intl.loginRegister('static-content-welcome')]: 'Velkommen til Risika!',
  [intl.mainRecommendation('auditor_selected')]: 'Revisor tilvalgt',
  [intl.mainRecommendation('company-id')]: 'Virksomheds ID',
  [intl.mainRecommendation('company-id-dk')]: 'Virksomheds ID (CVR)',
  [intl.mainRecommendation('company-id-no')]: 'Virksomheds ID (Orgnr.)',
  [intl.mainRecommendation('company-id-se')]: 'Virksomheds ID (Org.nr.)',
  [intl.mainRecommendation('copy_company_address')]:
    'Firmas adresse kopieret til udklipsholder',
  [intl.mainRecommendation('copy_company_local_id')]:
    'Virksomhedens lokale id kopieret til udklipsholder',
  [intl.mainRecommendation('copy_company_name')]:
    'Firmanavn kopieret til udklipsholder',
  [intl.mainRecommendation('currency')]: 'Angivet i ',
  [intl.mainRecommendation('edit-view')]: 'Visning',
  [intl.mainRecommendation('get-credit-information')]: 'Få kreditoplysninger',
  [intl.mainRecommendation('latest-annual-report')]: 'Seneste årsrapport',
  [intl.mainRecommendation('performance')]: 'Historisk udvikling',
  [intl.mainRecommendation('recommendation-credit')]: 'Ingen kredit',
  [intl.mainRecommendation('recommendation-credit-unknown')]: 'Ukendt',
  [intl.mainRecommendation('recommended-credit-description')]:
    'Anbefalet kreditmaksimum',
  [intl.mainRecommendation('recommended-days')]:
    '{amount, plural, =0 {Ingen kredit dage} one {Maks {amount} dag} other {Maks {amount} dage}}',
  [intl.mainRecommendation('recommended-days-description')]:
    'Anbefalet antal kreditdage',
  [intl.mainRecommendation('risika-estimation')]: 'Risikas estimering',
  [intl.mainRecommendation('risk-level')]: 'Risiko niveau',
  [intl.mainRecommendation('risk-level-high')]: 'Høj risiko',
  [intl.mainRecommendation('risk-level-high-short')]: 'Høj',
  [intl.mainRecommendation('risk-level-low')]: 'Lav risiko',
  [intl.mainRecommendation('risk-level-low-short')]: 'Lav',
  [intl.mainRecommendation('risk-level-medium')]: 'Middel risiko',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Middel',
  [intl.mainRecommendation('risk-level-sole-proprietorship')]:
    'Enkeltmandsvirksomhed',
  [intl.mainRecommendation('risk-level-unknown')]: 'Ukendt risiko',
  [intl.mainRecommendation('statstidende-header')]: 'Konkurs dekret',
  [intl.mainRecommendation('statstidende-title')]: 'Statstidende',
  [intl.mainRecommendation('status')]: 'Status: ',
  [intl.mainRecommendation('status-active-from')]: 'Statusændring fra: ',
  [intl.mainRecommendation('you-have')]: 'Du har',

  [intl.mainRecommendation('explanation')]: 'Forklaring',
  [intl.mainRecommendation('recommendation')]: 'Anbefaling',
  [intl.mainRecommendation('credit-max')]: 'Kreditmaksimum',
  [intl.mainRecommendation('credit-days')]: 'Antal kreditdage',
  [intl.mainRecommendation('credit-policy')]: 'Kredit politik',
  [intl.mainRecommendation('date')]: 'Dato: ',
  [intl.mainRecommendation('case-id')]: 'Sagsnummer: ',
  [intl.mainRecommendation('statstidende-link')]: 'Link: ',
  [intl.mainRecommendation('bankruptcy-noticed')]: 'Konkurs dekret',
  [intl.mainRecommendation('status-label')]: 'Status: ',
  [intl.mainRecommendation('valid-from')]: 'Gyldig fra: ',
  [intl.mainRecommendation('undergoing-bankruptcy')]: 'Under konkursbehandling',

  [intl.paymentRemarks('widget-title')]: 'Betalingsanmærkninger',
  [intl.paymentRemarks('widget-text')]:
    '{count, plural, one {Der er {count} registreret betalingsanmærkning på denne virksomhed.} other {Der er {count} registrerede betalingsanmærkninger på denne virksomhed.}}',
  [intl.paymentRemarks('widget-open-modal')]: 'Se betalings\u00ADanmærkninger',
  [intl.paymentRemarks('creditor')]: 'Kreditorer',
  [intl.paymentRemarks('type')]: 'Anmærkningstype',
  [intl.paymentRemarks('case-id')]: 'Sags ID',
  [intl.paymentRemarks('amount')]: 'Beløb',
  [intl.paymentRemarks('registered')]: 'Registreret',
  [intl.paymentRemarks('status')]: 'Status',
  [intl.paymentRemarks('total-remarks')]:
    '{count, plural, one {{count} betalingsanmærkning samlet} other {{count} betalingsanmærkninger samlet}}',
  [intl.paymentRemarks('asterisk-1')]:
    '* Ukendte beløb skyldes, at sagens valuta ikke er i NOK.',
  [intl.paymentRemarks('asterisk-2')]:
    '** Ved sammenlægning af to selskaber vil sager fra selskabet, der er opløst efter en fusion, blive oprettet med et nyt CVR-nummer.',
  [intl.paymentRemarks('legal-collector')]:
    'Betalingsanmærkninger indsamles fra {collector} og er gyldige til brug i kreditrapportering i Norge. Ifølge norsk lov kan betalingsanmærkninger opbevares i fire år, hvorefter de slettes fra vores system.',
  [intl.paymentRemarks('total-unsettled-amount')]: 'Samlet ubetalt beløb',
  [intl.paymentRemarks('partially-paid-cases')]: 'Delvist betalte sager',
  [intl.paymentRemarks('unique-creditors')]: 'Unikke kreditorer',
  [intl.paymentRemarks('avg-unsettled-time')]: 'Gennemsnitlig gældsperiode',
  [intl.paymentRemarks('active-creditors')]: 'aktive kreditorer',

  [intl.monitorFrequency('daily')]: 'Hver dag',
  [intl.monitorFrequency('daily-select')]: 'Dagligt',
  [intl.monitorFrequency('friday-select')]: 'Fredag',
  [intl.monitorFrequency('how-often-label')]: 'Hvor ofte?',
  [intl.monitorFrequency('monday-select')]: 'Mandag',
  [intl.monitorFrequency('monthly')]: 'Hver måned',
  [intl.monitorFrequency('monthly-select')]: 'Månedligt (første mandag)',
  [intl.monitorFrequency('saturday-select')]: 'Lørdag',
  [intl.monitorFrequency('sunday-select')]: 'Søndag',
  [intl.monitorFrequency('thursday-select')]: 'Torsdag',
  [intl.monitorFrequency('title')]: 'Hvor ofte vil du have besked?',
  [intl.monitorFrequency('tuesday-select')]: 'Tirsdag',
  [intl.monitorFrequency('wednesday-select')]: 'Onsdag',
  [intl.monitorFrequency('weekly-friday')]: 'Hver fredag',
  [intl.monitorFrequency('weekly-monday')]: 'Hver mandag',
  [intl.monitorFrequency('weekly-saturday')]: 'Hver lørdag',
  [intl.monitorFrequency('weekly-select')]: 'Ugentligt',
  [intl.monitorFrequency('weekly-sunday')]: 'Hver søndag',
  [intl.monitorFrequency('weekly-thursday')]: 'Hver torsdag',
  [intl.monitorFrequency('weekly-tuesday')]: 'Hver tirsdag',
  [intl.monitorFrequency('weekly-wednesday')]: 'Hver onsdag',
  [intl.monitorFrequency('which-day-label')]: 'Hvilken dag?',
  [intl.navigation('advanced-filters')]: 'Filtrering',
  [intl.navigation('company-comparison')]: 'Sammenlign',
  [intl.navigation('cfo-view')]: 'CFO view',
  [intl.navigation('chat')]: 'Support',
  [intl.navigation('company-hierarchy')]: 'Koncernhierarki',
  [intl.navigation('credit-check')]: 'Kredittjek',
  [intl.navigation('credit-policy')]: 'Kreditpolitik',
  [intl.navigation('customer-management')]: 'Kunder',
  [intl.navigation('dashboard')]: 'Dashboard',
  [intl.navigation('developer')]: 'Udvikler',
  [intl.navigation('faq')]: 'FAQ',
  [intl.navigation('home')]: 'Forside',
  [intl.navigation('login')]: 'Log ind',
  [intl.navigation('logout')]: 'Log ud',
  [intl.navigation('relation-overview')]: 'Personer',
  [intl.navigation('risk-monitoring')]: 'Overvåg',
  [intl.navigation('search-business')]: 'Søgning',
  [intl.navigation('settings')]: 'Indstillinger',
  [intl.navigation('user-management')]: 'Brugerstyring',
  [intl.navigation('revoked-warning')]: 'Du har ikke adgang',

  [intl.navigationTooltip('company-hierarchy')]:
    'Søg efter en virksomhed med datterselskaber',
  [intl.navigationTooltip('credit-check')]: 'Søg på en virksomhed først',
  [intl.navigationTooltip('relation-overview')]: 'Søg efter en person først',

  [intl.news('no-articles')]: 'Ingen artikler på nuværende tidspunkt.',
  [intl.news('show-less')]: 'Færre',
  [intl.news('show-more')]: 'Flere',
  [intl.news('title')]: 'Nyheder',
  [intl.notFound('best-experience')]: 'For den bedste oplevelse',
  [intl.notFound('better-performance')]: 'Bedre ydeevne',
  [intl.notFound('not-found-402')]:
    'Hvis du er på en demo, så er begrænsningerne i dit abonnement nok desværre nået. Du kan kontakte den person, der er ansvarlig for dit abonnement i din virksomhed eller kontakte os direkte',
  [intl.notFound('not-found-404')]:
    'Du er desværre kommet ind på en side der ikke eksisterer. Du kan klikke på knappen for at komme tilbage til Dashboard.',
  [intl.notFound('not-found-500')]:
    'Vi oplever i øjeblikket tekniske problemer. Vi arbejder hårdt på at få dem løst og systemet skulle gerne være online inden for kort tid!',
  [intl.notFound('not-found-503')]:
    'Der gik noget galt med din anmodning. Det er muligt, at vores servere er nede for vedligeholdelse eller overbelastet. Prøv igen om få minutter.',
  [intl.notFound('not-found-button-dashboard')]: 'Gå til "Dashboard"',
  [intl.notFound('not-found-button-risika-dk')]: 'Gå til "Risika.dk"',
  [intl.notFound('not-supported-header')]: 'Ikke understøttet',
  [intl.notFound('not-supported-text')]:
    'For at sikre den bedste oplevelse for vores brugere har vi deaktiveret support for Internet Explorer. Følg venligst linkene nedenfor for at downloade en af ​​vores anbefalede browsere.',
  [intl.notFound('pricing-button')]: 'Gå til "Priser og planer"',
  [intl.notFound('under-meintenance-header')]: 'Under kontrol',
  [intl.notFound('under-meintenance-text')]:
    'Risika dashboard og API er i øjeblikket under kontrol. Vi kommer snart tilbage!',
  [intl.popconfirm('cancel')]: 'Annuller',
  [intl.popconfirm('confirm')]: 'Okay',
  [intl.popconfirm('title')]: 'Er du sikker?',
  [intl.relationOverview('end-date')]: 'Slutdato',
  [intl.relationOverview('function')]: 'Funktion',
  [intl.relationOverview('function-status')]: 'Funktionsstatus',
  [intl.relationOverview('no-entity-redirect')]:
    'Ingen virksomhed eller individ valgt, sender dig tilbage til Søg Virksomhed',
  [intl.relationOverview('start-date')]: 'Startdato',
  [intl.relationOverview('title')]: 'Relationsoverblik',
  [intl.relations('auditor')]: 'Revisor',
  [intl.relations('board_member')]: 'Medlem',
  [intl.relations('branch_manager')]: 'Filialbestyrer',
  [intl.relations('ceo')]: 'Adm. direktør',
  [intl.relations('founder')]: 'Stifter',
  [intl.relations('legal_owner')]: 'Legal ejer',
  [intl.relations('management')]: 'Direktion',
  [intl.relations('president')]: 'Formand',
  [intl.relations('real_owner')]: 'Reel ejer',
  [intl.relations('stakeholder')]: 'Interessant',
  [intl.relations('substitute')]: 'Suppleant',
  [intl.relations('vice_president')]: 'Næstformand',
  [intl.relevantFinancialRatios('asset_turnover')]:
    'Aktivernes omsætningshastighed',
  [intl.relevantFinancialRatios('based-on-parent-statement')]:
    'Baseret på moderregnskabstal',
  [intl.relevantFinancialRatios('basic_earning_power')]: 'Indtjeningsevne',
  [intl.relevantFinancialRatios('capacity_ratio')]: 'Kapacitetsgrad',
  [intl.relevantFinancialRatios('cash_conversion_rate')]:
    'Pengestrømskonverteringsrate',
  [intl.relevantFinancialRatios('cash_ratio')]: 'Pengeraten',
  [intl.relevantFinancialRatios('current_assets_to_equity')]:
    'Omsætningsaktiver-til-egenkapital rate',
  [intl.relevantFinancialRatios('current_ratio')]: 'Likviditetsgrad',
  [intl.relevantFinancialRatios('debt_ratio')]: 'Gældsgrad',
  [intl.relevantFinancialRatios('debt_to_equity_ratio')]: 'Gearing',
  [intl.relevantFinancialRatios('ebitda_to_debt_ratio')]:
    'EBITDA til gældsprocent',
  [intl.relevantFinancialRatios('fixed_assets_to_long_term_liabilities_ratio')]:
    'Kapitalbindingsgrad',
  [intl.relevantFinancialRatios('gross_margin')]: 'Dækningsgrad',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Rentedækningsgrad',
  [intl.relevantFinancialRatios('interest_margin')]: 'Rentemarginal',
  [intl.relevantFinancialRatios('interest_rate_on_debt_with_average')]:
    'Gældsrente',
  [intl.relevantFinancialRatios('not-available')]: 'Ikke tilgængeligt',
  [intl.relevantFinancialRatios('one_year_change_in_equity')]:
    'Ændring i egenkapital',
  [intl.relevantFinancialRatios('operating_margin')]: 'Overskudsgrad',
  [intl.relevantFinancialRatios('profit_margin')]: 'Resultatgrad',
  [intl.relevantFinancialRatios('return_on_assets')]: 'Afkastningsgrad',
  [intl.relevantFinancialRatios('return_on_equity')]: 'Egenkapitalforrentning',
  [intl.relevantFinancialRatios('solidity_ratio')]: 'Soliditetsgrad',
  [intl.relevantFinancialRatios('title')]: 'Relevante finansielle nøgletal',
  [intl.reportBug('agreement')]:
    'Ved at indgive en rapport tillader du os at bruge din e-mail som reference til dette problem.',
  [intl.reportBug('description')]: 'Beskriv den fejl du oplevede',
  [intl.reportBug('drag-drop')]:
    'Du kan Træk- & Slippe eller Gennemse for at vedhæfte nogle billeder.',
  [intl.reportBug('error-in-credit-information')]: 'Fejl i kreditinformation',
  [intl.reportBug('error-in-financial-data')]: 'Fejl i finansiel data',
  [intl.reportBug('error-in-general-information')]:
    'Fejl i general virksomhedsinformation',
  [intl.reportBug('error-in-highlights')]: 'Fejl i opmærksomhedspunkter',
  [intl.reportBug('error-in-relation-data')]: 'Fejl i relationer',
  [intl.reportBug('other')]: 'Andet',
  [intl.reportBug('report-bug-paper')]: 'Rapportér en fejl',
  [intl.reportBug('send-button')]: 'Send fejlrapport',
  [intl.reportBug('type-of-report')]: 'Hvilken slags fejl oplevede du?',
  [intl.restriction('basic-description')]:
    'Dit abonnement dækker ikke denne funktion. Klik på knappen herunder for at få flere oplysninger',
  [intl.restriction('create-new-list-content')]:
    'Denne funktion er ikke tilgængelig i dit abonnement.',
  [intl.restriction('create-new-list-title')]: 'Monitoring lists',
  [intl.restriction('credit-reports-button')]: 'Priser & planer',
  [intl.restriction('credit-reports-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe kreditrapporter som en separat funktion.',
  [intl.restriction('credit-reports-title')]: 'Kreditrapporter',
  [intl.restriction('export-button')]: 'Priser & planer',
  [intl.restriction('export-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe muligheden for eksport som en separat funktion.',
  [intl.restriction('export-title')]: 'Eksportering',
  [intl.restriction('key-figures-title')]: 'Regnskabstal',
  [intl.restriction('risk-monitoring-button')]: 'Priser & planer',
  [intl.restriction('risk-monitoring-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe overvågning som en separat funktion.',
  [intl.restriction('risk-monitoring-title')]: 'Overvågning',
  [intl.observationalLists('title')]: 'Observationslister',
  [intl.observationalLists('risika-score-title')]: 'Risika kreditscore',
  [intl.observationalLists('risika-score-by-time-title')]:
    'Udvikling i Risika kredit score over tid',
  [intl.observationalLists('risika-score-by-company-title')]:
    'Risika kredit score fordelt på selskabstype',
  [intl.observationalLists('risika-score-distribution-title')]:
    'Risika kreditscore fordeling',
  [intl.observationalLists('risika-score-employees-title')]:
    'Risika kredit score fordelt på antal medarbejdere',
  [intl.observationalLists('risika-score-industry-title')]:
    'Risika kredit score fordelt på  brancher',
  [intl.observationalLists('key-figures-industry-title')]:
    'Nøgletal fordelt på brancher',
  [intl.observationalLists('development-selected-key-figures-title')]:
    'Udvikling i udvalgte nøgletal',
  [intl.observationalLists('geographical-risika-score-title')]:
    'Risika kredit score fordelt geografisk',
  [intl.observationalLists('risika-score-over-time-title')]:
    'Udvikling i Risika kredit score over tid',
  [intl.observationalLists('risika-key-figures-by-company-type-title')]:
    'Nøgletal fordelt på selskabstype',
  [intl.observationalLists('tab-risika-credit-score')]: 'Risika Kreditscore',
  [intl.observationalLists('tab-financial-performance')]:
    'Økonomisk præstation',
  [intl.observationalLists('tab-companies')]: 'Virksomheder',
  [intl.observationalLists('annual-result-average-title')]: 'Årsresultat, Gns.',
  [intl.observationalLists('equity-average-title')]: 'Egenkapital, Gns.',
  [intl.observationalLists('employees-average-title')]:
    'Antal medarbejdere, Gns.',
  [intl.observationalLists('list-id-error')]:
    'Angiv venligst et gyldigt liste-id!',
  [intl.riskMonitoring('intelligence-suite-tooltip')]: 'Intelligence Suite',
  [intl.riskMonitoring('company')]: 'Virksomhed',
  [intl.riskMonitoring('company-search')]: 'Søg efter en virksomhed',
  [intl.riskMonitoring('company-type')]: 'Virksomhedstype',
  [intl.riskMonitoring('companyName')]: 'Virksomhed',
  [intl.riskMonitoring('companyType')]: 'Virksomhedstype',
  [intl.riskMonitoring('country')]: 'Land',
  [intl.riskMonitoring('credit-max')]: 'Kreditmaks',
  [intl.riskMonitoring('currency')]: 'Valuta',
  [intl.riskMonitoring('date-of-incorporation')]: 'Stiftelsesdato',
  [intl.riskMonitoring('dateOfIncorporation')]: 'Stiftelsesdato',
  [intl.riskMonitoring('edit-company-title')]: 'Rediger virksomhed',
  [intl.riskMonitoring('employee-interval')]: 'Ansatte',
  [intl.riskMonitoring('employeeInterval')]: 'Ansatte',
  [intl.riskMonitoring('equity')]: 'Egenkapital',
  [intl.riskMonitoring('from-to-count')]: '{from}-{to} af {count}',
  [intl.riskMonitoring('import-from-file')]: 'Importér fra fil',
  [intl.riskMonitoring('import-from-file-company-id-field')]:
    "Vælg feltet med virksomheds ID'et",
  [intl.riskMonitoring('import-from-file-include-internal-id')]:
    'Inkluder internt ID',
  [intl.riskMonitoring('import-from-file-internal-id-field')]:
    'Vælg feltet med jeres interne ID',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Indtast manuelt',
  [intl.riskMonitoring('import-from-manual-entry-helper-text')]:
    'Adskilt med mellemrum, komma eller semikolon',
  [intl.riskMonitoring('internal-id')]: 'Internt ID',
  [intl.riskMonitoring('internalId')]: 'Internt ID',
  [intl.riskMonitoring('liquidity')]: 'Likviditetsgrad',
  [intl.riskMonitoring('local-id')]: 'Virksomheds ID',
  [intl.riskMonitoring('local-id-search')]: 'Søg efter Virksomheds ID',
  [intl.riskMonitoring('internal-id-search')]: 'Søg efter Internt ID',
  [intl.riskMonitoring('localId')]: 'Virksomheds ID (CVR)',
  [intl.riskMonitoring('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoring('no-data')]: 'Intet data tilgængeligt',
  [intl.riskMonitoring('profit-loss')]: 'Årets resultat',
  [intl.riskMonitoring('profitLoss')]: 'Årets resultat',
  [intl.riskMonitoring('reject-companies')]: 'Føj til listen i Kreditpolitik',
  [intl.riskMonitoring('remaining-monitoring')]: 'Overvågninger tilbage',
  [intl.riskMonitoring('remove')]: 'Fjern',
  [intl.riskMonitoring('risika-score')]: 'Score',
  [intl.riskMonitoring('rows-per-page')]: 'Pr. side',
  [intl.riskMonitoring('score')]: 'Score',
  [intl.riskMonitoring('solidity')]: 'Soliditetsgrad',
  [intl.riskMonitoring('status')]: 'Status',
  [intl.riskMonitoring('summation-change-duration')]:
    'Ændringer den seneste uge',
  [intl.riskMonitoring('title')]: 'Overvågning',
  [intl.riskMonitoring('top-negative')]: 'Mest negative',
  [intl.riskMonitoring('you-already-have-preset-with-name')]:
    'Du har allerede en indstilling med dette navn',
  [intl.riskMonitoring('please-select-a-name')]: 'Vælg et navn',
  [intl.riskMonitoring('preset-name')]: 'Eksport-navn',
  [intl.riskMonitoring('select-preset')]: 'Vælg eksport',
  [intl.riskMonitoring('no-presets-saved')]: 'Ingen eksport gemt',
  [intl.riskMonitoring('capital')]: 'Kapital',
  [intl.riskMonitoringNew('add')]: 'Tilføj',
  [intl.riskMonitoringNew('add-companies-button')]: 'Importer',
  [intl.riskMonitoringNew('add-list-name-snackbar')]:
    'Venlist vælg et navn til listen!',
  [intl.riskMonitoringNew('add-name')]: 'Navngiv din liste',
  [intl.riskMonitoringNew('add-new-list')]: 'Ny liste',
  [intl.riskMonitoringNew('add-new-subscription')]: 'Tilføj notifikation',
  [intl.riskMonitoringNew('add-to-monitoring-counter')]:
    'Forsøger import af {count} virksomheder til "{listName}". Sikker på du vil fortsætte?',
  [intl.riskMonitoringNew('add-to-monitoring-list-title')]:
    'Indlæs fil eller indtast CVR-numre manuelt',
  [intl.riskMonitoringNew('added-some-companies')]:
    'Beløbet overstiger din nuværende overvågningsplan. Tilføjet kun nogle virksomheder.',
  [intl.riskMonitoringNew('adding-companies-wait')]:
    'Tilføjelse af virksomheder. Vent venligst...',
  [intl.riskMonitoringNew('are-you-sure-delete')]:
    'Er du sikker på, du vil slette listen "{text}"?',
  [intl.riskMonitoringNew('are-you-sure-delete-entries')]:
    '{count, plural, one {Er du sikker på du vil slette virksomheden?} other {Er du sikker på du vil slette virksomhederne?}}',
  [intl.riskMonitoringNew('back')]: 'Tilbage',
  [intl.riskMonitoringNew('copy-button-text')]: 'Kopier',
  [intl.riskMonitoringNew('copy-to-another-list-title')]:
    'Vælg den liste du ønsker at kopiere til',
  [intl.riskMonitoringNew('create-list-button')]: 'Gem liste',
  [intl.riskMonitoringNew('create-new-list-text-field')]: 'Opret ny liste',
  [intl.riskMonitoringNew('create-subscription')]: 'Opret notifikation',
  [intl.riskMonitoringNew('cvt-textfield-label')]:
    "Indtast venligst CVR-numre (lokale ID'er) her",
  [intl.riskMonitoringNew('delete')]: 'Slet',
  [intl.riskMonitoringNew('delete-companies-title')]:
    '{count, plural, one {Slet markerede virksomheder} other {Slet markerede virksomheder}}',
  [intl.riskMonitoringNew('delete-dialog-title')]: 'Slet liste',
  [intl.riskMonitoringNew('delete-failed')]: 'Kunne ikke slette listen',
  [intl.riskMonitoringNew('delete-items')]: 'Slet',
  [intl.riskMonitoringNew('delete-list')]: 'Slet liste',
  [intl.riskMonitoringNew('delete-success')]: 'Listen er slettet',
  [intl.riskMonitoringNew('deleting-companies-wait')]:
    'Sletning af virksomheder. Vent venligst...',
  [intl.riskMonitoringNew('drag-drop')]:
    'Træk & slip en fil ind her eller Gennemse (CSV eller Excel)',
  [intl.riskMonitoringNew('edit-button')]: 'Omdøb',
  [intl.riskMonitoringNew('edit-list-button')]: 'Omdøb liste',
  [intl.riskMonitoringNew('edit-list-text-field')]: 'Omdøb liste',
  [intl.riskMonitoringNew('edit-name')]: 'Nyt navn',
  [intl.riskMonitoringNew('existing-subscriptions')]:
    'Eksisterende notifikationer',
  [intl.riskMonitoringNew('export-companies-button')]: 'Eksporter',
  [intl.riskMonitoringNew('failed-validation')]:
    '{amount} virksomheder fejlede validering og blev ikke tilføjet!',
  [intl.riskMonitoringNew('field-list-header')]:
    'Vælg venligst den kolonne, der indeholder CVR-nummer',
  [intl.riskMonitoringNew('handle-settings-button')]: 'Bord præferencer',
  [intl.riskMonitoringNew('handle-visibility-button')]: 'Visning',
  [intl.riskMonitoringNew('items-copied')]:
    '{count, plural, one {Virksomheden blev kopieret} other {{count} virksomheder blev kopieret}}',
  [intl.riskMonitoringNew('items-deleted')]:
    '{count, plural, one {Virksomheden blev slettet} other {{count} virksomheder blev slettet}}',
  [intl.riskMonitoringNew('items-failed-to-copy')]:
    'Der opstod en fejl da vi prøvede at kopiere virksomhederne',
  [intl.riskMonitoringNew('items-failed-to-delete')]:
    'Der opstod en fejl da vi prøvede at slette virksomhederne',
  [intl.riskMonitoringNew('items-failed-to-import')]:
    'Virksomheder kunne ikke importeres.',
  [intl.riskMonitoringNew('items-failed-to-move')]:
    'Der opstod en fejl da vi prøvede at flytte virksomhederne',
  [intl.riskMonitoringNew('items-imported')]: 'Virksomhederne blev importeret',
  [intl.riskMonitoringNew('items-moved')]:
    '{count, plural, one {Virksomheden blev flyttet} other {{count} virksomheder blev flyttet}}',
  [intl.riskMonitoringNew('monitor-add-subscription')]: 'Tilføj notifikation',
  [intl.riskMonitoringNew('monitor-settings')]: 'Indstil præferencer',
  [intl.riskMonitoringNew('move-button-text')]: 'Flyt',
  [intl.riskMonitoringNew('move-to-another-list-title')]:
    'Vælg den liste du ønsker at flytte til',
  [intl.riskMonitoringNew('new-list-added-failed')]:
    'Kunne ikke tilføje ny liste',
  [intl.riskMonitoringNew('new-list-added-success')]: 'Ny liste tilføjet',
  [intl.riskMonitoringNew('new-list-private')]: 'Privat',
  [intl.riskMonitoringNew('new-list-public')]: 'Offentlig',
  [intl.riskMonitoringNew('new-list-subtitle')]:
    'Vælg de brugere, der må se listen',
  [intl.riskMonitoringNew('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoringNew('no-subscriptions-text')]:
    'Det ser ud til, at du ikke modtager ændringer på nogle lister. Tryk på knappen ovenfor for at tilføje en ny!',
  [intl.riskMonitoringNew('remove-internal-id')]: 'Slet internt ID',
  [intl.riskMonitoringNew('remove-internal-id-description')]:
    "{count, plural, one {Du er ved at slette {count} internt ID} other {Du er ved at slette {count} interne ID'er}}",
  [intl.riskMonitoringNew('remove-internal-id-title')]:
    "{count, plural, one {Slet internt ID} other {Slet interne ID'er}}",
  [intl.riskMonitoringNew('save-changes')]: 'Gem ændringer',
  [intl.riskMonitoringNew('select-lists')]: 'Vælg lister',
  [intl.riskMonitoringNew('select-subscription-lists')]:
    'Vælg lister du vil modtage ændringer på',
  [intl.riskMonitoringNew('select-subscription-options')]:
    'Vælg hvad du vil høre om',
  [intl.riskMonitoringNew('selected')]:
    '{count, plural, =0 {Ingen valgt} other {{count} valgt}}',
  [intl.riskMonitoringNew('settings-header')]:
    'Vælg hvilke kolonner, du gerne vil se',
  [intl.riskMonitoringNew('submit')]: 'Bekræft',
  [intl.riskMonitoringNew('subscribed-to-all')]:
    'Du abonnerer på alle tilgængelige lister',
  [intl.riskMonitoringNew('subscription-add-failed')]:
    'Der opstod et problem med oprettelsen af din notifikation',
  [intl.riskMonitoringNew('subscription-add-success')]: 'Notifikation oprettet',
  [intl.riskMonitoringNew('subscription-delete-failed')]:
    'Der opstod et problem da vi prøvede at slette din notifikation',
  [intl.riskMonitoringNew('subscription-delete-success')]:
    'Notifikation slettet',
  [intl.riskMonitoringNew('subscription-updated-success')]:
    'Notifikationen blev opdateret',
  [intl.riskMonitoringNew('table-actions-tooltip')]: 'Vælg først en række',
  [intl.riskMonitoringNew('text-field-hint')]:
    'Brug komma eller mellemrum imellem hvert CVR-nummer',
  [intl.riskMonitoringNew('update')]: 'Opdater',
  [intl.riskMonitoringNew('update-subscriptions')]: 'Dine notifikationer',
  [intl.riskMonitoringNew('update-subscriptions-title')]: 'Dine notifikationer',
  [intl.riskMonitoringNew('visibility-header')]:
    'Opdater hvem der må se listen',
  [intl.riskMonitoringNew('your-lists')]: 'Dine lister',
  [intl.riskMonitoringNew('sum-of-all-ids-found')]:
    'Summen af alle ID fundet i filen: {count}.',
  [intl.riskMonitoringNew('faulty-ids-found-message')]:
    '{count, plural, one {{count} defekt virksomheds ID fundet.} other {{count} defekte virksomheds ID fundet.}}',
  [intl.riskMonitoringNew('faulty-ids-button-export-label')]:
    'Eksportér defekt(e) ID',
  [intl.riskMonitoringNew('faulty-ids-file-name')]: 'DefekteID',
  [intl.riskMonitoringNew('duplicate-ids-found-message')]:
    '{count, plural, one {{count} duplikere virksomheds ID fundet.} other {{count} duplikere virksomheds ID fundet.}}',
  [intl.riskMonitoringNew('duplicate-ids-button-export-label')]:
    'Eksportér duplikere ID',
  [intl.riskMonitoringNew('duplicate-ids-file-name')]: 'DuplikereID',
  [intl.riskMonitoringOverview('all')]: 'Alle',
  [intl.riskMonitoringOverview('da_DK')]: 'Danmark',
  [intl.riskMonitoringOverview('delete-all-button')]: 'Slet alle',
  [intl.riskMonitoringOverview('delete-all-popup-text')]:
    'Denne handling vil slette alle de virksomheder, som du overvåger i øjeblikket. Er du sikker på, at du vil fortsætte?',
  [intl.riskMonitoringOverview('delete-all-popup-title')]:
    'Slet alle overvågede virksomheder',
  [intl.riskMonitoringOverview('high-risk')]: 'Høj risiko',
  [intl.riskMonitoringOverview('low-risk')]: 'Lav risiko',
  [intl.riskMonitoringOverview('medium-risk')]: 'Middel risiko',
  [intl.riskMonitoringOverview('no-score')]: 'Ingen score',
  [intl.riskMonitoringOverview('no_NO')]: 'Norge',
  [intl.riskMonitoringOverview('sv_SE')]: 'Sverige',
  [intl.riskMonitoringOverview('total')]: 'Total',
  [intl.riskMonitoringSubscription('add-description')]:
    'Du kan oprette et abonnement på de parametre, du ønsker at høre om. Vi sender en ugentlig e-mail, hvis der er sket nogle ændringer i de virksomheder, du overvåger.',
  [intl.riskMonitoringSubscription('add-subscription')]: 'Tilføj overvågning',
  [intl.riskMonitoringSubscription('add-subscription-title')]:
    'Tilføj et overvågningsabonnement',
  [intl.riskMonitoringSubscription('address')]: 'Adresse',
  [intl.riskMonitoringSubscription('all-important-changes')]:
    'Alle vigtige ændringer',
  [intl.riskMonitoringSubscription('all-regular-changes')]:
    'Alle almindelige ændringer',
  [intl.riskMonitoringSubscription('annual_reports')]: 'Årsrapporter',
  [intl.riskMonitoringSubscription('company_type')]: 'Virksomhedstype',
  [intl.riskMonitoringSubscription('email')]: 'E-mail',
  [intl.riskMonitoringSubscription('employees')]: 'Antal ansatte',
  [intl.riskMonitoringSubscription('gazette')]: 'Statstidende',
  [intl.riskMonitoringSubscription('important-changes')]: 'Vigtige ændringer',
  [intl.riskMonitoringSubscription('industry')]: 'Industri',
  [intl.riskMonitoringSubscription('manage-description')]:
    'Du har mulighed for at fjerne dit overvågningabonnement eller ændre de parametre, du ønsker at høre om i fremtiden.',
  [intl.riskMonitoringSubscription('manage-subscription')]: 'Præferencer',
  [intl.riskMonitoringSubscription('manage-subscription-title')]:
    'Indstil dit overvågningsabonnement',
  [intl.riskMonitoringSubscription('name')]: 'Virksomhedsnavn',
  [intl.riskMonitoringSubscription('phone_number')]: 'Telefonnummer',
  [intl.riskMonitoringSubscription('receive-updates-description')]:
    'Når der kommer ændringer som du synes er relevante, så sender vi dem til dig.',
  [intl.riskMonitoringSubscription('receive-updates-title')]:
    'Opdateringer til din e-mail',
  [intl.riskMonitoringSubscription('regular-changes')]: 'Almindelige ændringer',
  [intl.riskMonitoringSubscription('relations')]: 'Relationer',
  [intl.riskMonitoringSubscription('remove-subscription')]:
    'Fjern notifikation',
  [intl.riskMonitoringSubscription('score')]: 'Risika score',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-added')]:
    'Vi kunne desværre ikke tilføje dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-removed')]:
    'Vi kunne desværre ikke fjerne dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-updated')]:
    'Vi kunne desværre ikke opdatere dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-subscription-added')]:
    'Dit abonnement er tilføjet',
  [intl.riskMonitoringSubscription('snackbar-subscription-removed')]:
    'Dit abonnement er fjernet',
  [intl.riskMonitoringSubscription('snackbar-subscription-updated')]:
    'Dit abonnement er blevet opdateret',
  [intl.riskMonitoringSubscription('status')]: 'Virksomhedsstatus',
  [intl.searchBusiness('api-error')]: 'Anmodningen mislykkedes: {message}',
  [intl.searchBusiness('clear-range-picker')]: 'Nulstil Risika Score',
  [intl.searchBusiness('company')]: 'Virksomhed',
  [intl.searchBusiness('employees')]: 'Ansatte',
  [intl.searchBusiness('local-id')]: 'Virksomheds ID',
  [intl.searchBusiness('max-export')]:
    '{count, plural, other {Eksportering begrænset til de første {count} rækker}}',
  [intl.searchBusiness('no-data')]: 'Intet data tilgængelig',
  [intl.searchBusiness('personal-id')]: 'Personal Id',
  [intl.searchBusiness('results-time-elapsed')]:
    '{count, plural, one {# resultat} other {# resultater}} på {time, plural, other {#}} sekunder',
  [intl.searchBusiness('risika-score')]: 'Risika score',
  [intl.searchBusiness('search-field')]: 'Søg',
  [intl.searchBusiness('search-placeholder')]:
    'Skriv virksomhedsnavn/id (cvr), adresse, mail eller tlf.nr.',
  [intl.searchBusiness('selector-companies')]:
    '{count, plural, one {Virksomhed ({countDisplay})} other {Virksomheder ({countDisplay})}}',
  [intl.searchBusiness('selector-no-companies')]:
    'Vi kunne ikke finde nogle virksomheder. Søger efter personer i stedet.',
  [intl.searchBusiness('selector-no-companies')]:
    'Vi kunne ikke finde nogle virksomheder. Søger efter personer i stedet.',
  [intl.searchBusiness('selector-people')]:
    '{count, plural, one {Person (#)} other {Personer (#)}}',
  [intl.searchBusiness('status')]: 'Status',
  [intl.searchBusiness('title')]: 'Søgning',
  [intl.searchBusiness('type')]: 'Type',
  [intl.settings('address')]: 'Virksomhedsadresse',
  [intl.settings('all-users')]: 'Alle brugere',
  [intl.settings('are-you-sure-delete-user')]:
    'Er du sikker på, at du vil slette denne bruger?',
  [intl.settings('cant-delete-yourself')]: 'Du kan ikke slette dig selv!',
  [intl.settings('cant-have-user-and-admin')]:
    'Du kan enten vælge "Bruger" eller "Administrator"!',
  [intl.settings('code-helper-text')]:
    'Kopier teksten og tilføj til din hjemmeside',
  [intl.settings('company-details-title')]: 'Detaljer om din aftale',
  [intl.settings('company-information-title')]: 'Virksomhedsinformation',
  [intl.settings('company-information-updated-success')]:
    'Virksomhedsoplysningerne er opdateret',
  [intl.settings('confirm-new-password')]: 'Bekræft nyt password',
  [intl.settings('confirm-password-requirements')]:
    'Bekræftelsen af password skal være det samme som password',
  [intl.settings('content-copied')]: 'Indhold kopieret',
  [intl.settings('copy-icon')]: 'Kopier indhold',
  [intl.settings('customerType')]: 'Abonnement',
  [intl.settings('dark')]: 'Mørk',
  [intl.settings('domain-label')]: 'Domæne',
  [intl.settings('general-information')]: 'Generel information',
  [intl.settings('generate')]: 'Generer',
  [intl.settings('invalid-phone')]: 'Ugyldigt telefonnummer',
  [intl.settings('invalid-url')]: 'Tilføj venlist en gyldig URL',
  [intl.settings('invalid-url')]: 'Ugyldig URL',
  [intl.settings('invalid-url-phone')]: 'Ugyldig URL og telefonnummer',
  [intl.settings('large')]: 'Stor',
  [intl.settings('created-at')]: 'Oprettet kl',
  [intl.settings('last-login')]: 'Sidste aktivitet',
  [intl.settings('light')]: 'Lys',
  [intl.settings('link-type')]: 'Link type',
  [intl.settings('logo-title')]:
    'Step 1: Fortæl os, hvor du vil bruge vores logo',
  [intl.settings('mail-send')]: 'E-mail sendt',
  [intl.settings('medium')]: 'Medium',
  [intl.settings('monitoring-used')]: 'Antal overvågninger',
  [intl.settings('name')]: 'Virksomhedsnavn',
  [intl.settings('new-password')]: 'Nyt password',
  [intl.settings('password-requirements')]:
    'Dit password skal være minimum 4 karaktere',
  [intl.settings('password-title')]: 'Password',
  [intl.settings('password-updated-successfully')]:
    'Adgangskode blev opdateret',
  [intl.settings('phone-label')]: 'Telefonnummer',
  [intl.settings('preview-title')]: 'Step 2: Design dit logo',
  [intl.settings('radio-text')]: 'Hvilken baggrund vil logoet være på?',
  [intl.settings('remainingMonitoring')]: 'Resterende overvågning',
  [intl.settings('remainingUsers')]: 'Resterende brugere',
  [intl.settings('risika-logo')]: 'Få et Risika logo',
  [intl.settings('see-code')]: 'Se koden',
  [intl.settings('select-helper-text')]: 'HTML eller URL?',
  [intl.settings('share-logo')]: 'Del logo',
  [intl.settings('share-logo-message')]: 'Tilføj en besked sammen med logoet',
  [intl.settings('size-text')]: 'Størrelse på logoet',
  [intl.settings('small')]: 'Lille',
  [intl.settings('step-3')]: 'Step 3: Send koden til din udvikler',
  [intl.settings('termsOfService')]: 'Forretningsbetingelser',
  [intl.settings('title')]: 'Indstillinger',
  [intl.settings('update-avatar')]: 'OPDATER AVATAR',
  [intl.settings('update-company')]: 'Opdater virksomhed',
  [intl.settings('update-password')]: 'Opdater password',
  [intl.settings('update-user')]: 'Opdater bruger',
  [intl.settings('user-information-title')]: 'Brugerinformation',
  [intl.settings('user-management')]: 'Brugerstyring',
  [intl.settings('users-used')]: 'Antal brugere',
  [intl.settings('whitelist')]: 'Website',
  [intl.settings('whitelist-helper-text')]:
    'Efter indtastning af dit websted, tryk "enter" for at validere det. Tryk derefter på Generer-knappen.',
  [intl.settings('scoring-model')]: 'Scoremodel',
  [intl.settings('click-to-change-currency')]: 'Klik for at ændre valuta',

  [intl.shareReport('add-custom-message')]: 'Besked til modtager (valgfrit)',
  [intl.shareReport('select-recipient')]: 'Vælg modtager',
  [intl.shareReport('sender-name')]: 'Afsenders navn (valgfrit)',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from NO',
  )]: 'Din abonnementsplan tillader ikke, at du overvåger ressourcer fra Norge',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from SE',
  )]:
    'Din abonnementsplan tillader ikke, at du overvåger ressourcer fra Sverige',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor this many companies',
  )]:
    'Din abonnementsplan tillader ikke, at du overvåger disse mange virksomheder',
  [intl.snackbar('account-activated-failed')]: 'Der opstod et problem',
  [intl.snackbar('account-activated-success')]:
    'Perfekt! Du kan nu begynde at bruge Risika',
  [intl.snackbar('add-companies-monitoring')]:
    'Virksomhederne bliver nu overvåget',
  [intl.snackbar('add-companies-monitoring-failed')]:
    'Importering fejlede: vær venlig at tjekke at en af kolonnerne hedder "cvr"',
  [intl.snackbar('add-company-monitoring')]: 'Virksomheden bliver nu overvåget',
  [intl.snackbar('added-back')]: 'bliver overvåget igen',
  [intl.snackbar('all-added-back')]: 'virksomheder bliver overvåget igen',
  [intl.snackbar('companies-added-to-list')]:
    'Virksomheder tilføjet til {list}',
  [intl.snackbar('companies-failed-to-add')]:
    'Virksomhederne kunne ikke tilføjes til {list}',
  [intl.snackbar('company-added-to-list')]: 'Virksomheden tilføjet til {list}',
  [intl.snackbar('company-removed-from-list')]:
    'Virksomheden fjernet fra {list}',
  [intl.snackbar('copied')]: 'Kopieret',
  [intl.snackbar('dismiss')]: 'Luk',
  [intl.snackbar('export-failed')]: 'Din eksportering fejlede',
  [intl.snackbar('export-success')]: 'Din eksportering er klar',
  [intl.snackbar('failed-to-add-company')]: 'Kunne ikke oprette virksomheden',
  [intl.snackbar('failed-to-add-emails')]:
    'Kunne ikke oprette brugere med e-mails',
  [intl.snackbar('fields-required')]: 'Udfyld venligst de krævede felter',
  [intl.snackbar('invalid-email')]: 'Tilføj venligst en gyldig e-mail',
  [intl.snackbar('logo-shared-succes')]: 'Logoet bliver sendt.',
  [intl.snackbar('maximum-export-exceded-exporting-less')]:
    'Maksimal mængde virksomheder, der skal eksporteres. Eksport af de første {exportLimit} virksomheder',
  [intl.snackbar('no-subsidiaries')]: 'Selskabet har ingen datterselskaber',
  [intl.snackbar('password-not-allowed')]: 'Venlist vælg et andet passwors',
  [intl.snackbar('remove-companies-monitoring')]:
    'Alle virksomheder bliver ikke længere overvåget',
  [intl.snackbar('remove-company-monitoring')]:
    'Virksomheden bliver ikke længere overvåget',
  [intl.snackbar('remove-failed')]:
    'Der opstod en fejl da vi fjernede jeres webhook',
  [intl.snackbar('remove-successfull')]: 'Jeres webhook er blevet fjernet',
  [intl.snackbar('report-error-something-wrong')]: 'Der opstod et problem',
  [intl.snackbar('report-error-timeout')]:
    'På nuværende tidspunkt har vi ikke en rapport for',
  [intl.snackbar('report-failed-to-submit')]: 'Rapporten kunne ikke indsendes',
  [intl.snackbar('report-submited')]: 'Rapport indsendt med succes',
  [intl.snackbar('reset-filters')]: 'Filtrene er blevet nulstillet',
  [intl.snackbar('settings-saved')]: 'Indstillinger gemt',
  [intl.snackbar('shared-succes')]: 'Rapporten bliver sendt.',
  [intl.snackbar('test-failed')]: 'Der opstod en fejl med testen: {error}',
  [intl.snackbar('test-successfull')]:
    'Du vil modtage et eksempel svar inden for 2 minutter',
  [intl.snackbar('undo')]: 'Fortryd',
  [intl.snackbar('users-added-successfully')]: 'Alle brugere oprettet',
  [intl.table('loading')]: 'Loader...',
  [intl.table('next')]: 'Næste',
  [intl.table('no-rows')]: 'Ingen rækker tilgængelige',
  [intl.table('of')]: 'af',
  [intl.table('page')]: 'Side',
  [intl.table('previous')]: 'Forrige',
  [intl.table('rows')]: 'rækker',
  [intl.toolbar('add-email')]: 'Tilføj e-mail',
  [intl.toolbar('add-phone')]: 'Tilføj telefon',
  [intl.toolbar('billing-plan')]: 'Faktureringsplan',
  [intl.toolbar('edit-profile')]: 'Rediger profil',
  [intl.toolbar('greeting')]: 'Hej',
  [intl.toolbar('logout')]: 'Log ud',
  [intl.toolbar('role')]: 'Rolle',
  [intl.toolbar('welcome-to-risika')]: 'Velkommen til Risika Dashboard',
  [intl.tooltip('click-to-copy')]: 'Klik for at kopiere',
  [intl.userManagement('delete-dialog-content')]:
    'Er du sikker på, at du vil slette {user}?',
  [intl.userManagement('delete-dialog-title')]: 'Slet bruger',
  [intl.userManagement('failed-to-load-users')]: 'Kunne ikke indlæse brugere',
  [intl.userManagement('invalid-email')]: 'Ugyldig e-mail',
  [intl.userManagement('invite-header')]: 'Inveter brugere til Risika',
  [intl.userManagement('invite-users-button')]: 'Inveter brugere',
  [intl.userManagement('invites-send-failed')]:
    'Vi kunne ikke sende e-mail til {email}',
  [intl.userManagement('invites-send-success')]:
    'Invitation til {email} blev sendt med success',
  [intl.userManagement('maximum-invites-reached')]:
    'Du har nået dit maksimum af invitation. Klik her for at købe flere',
  [intl.userManagement('more-than-30')]: '30+ dage',
  [intl.userManagement('no-data-yet')]: 'Ingen data',
  [intl.userManagement('role-change-failed')]: 'Kunne ikke ændre rolle',
  [intl.userManagement('role-change-success')]: 'Successivt ændret rolle',
  [intl.userManagement('send-invite-button')]: 'Inviter',
  [intl.userManagement('user-delete-failed')]: 'Brugeren kunne ikke slettes',
  [intl.userManagement('user-delete-success')]: 'Bruger blev slettet',
  [intl.visualScore('high-risk')]: 'Høj risiko',
  [intl.visualScore('low-risk')]: 'Lav risiko',
  [intl.visualScore('medium-risk')]: 'Middel risiko',
  [intl.companyComparison('title')]: 'Sammenlign',
  [intl.companyComparison('add-to-comparison-button')]:
    'Tilføj til sammenligning',
  [intl.companyComparison('add-to-comparison-tooltip')]:
    'Føj dette firma til en sammenlignende liste',
  [intl.companyComparison('dialog-cannot-add-title')]:
    'Virksomheden kunne ikke tilføje',
  [intl.companyComparison('dialog-cannot-add-content')]:
    'Virksomheden kunne ikke tilføjes til sammenligningslisten.',
  [intl.autoSelectCompanies('search-box-title')]: 'Søg virksomheder',
  [intl.keyFigures('title')]: 'Nøgletal',
  [intl.keyFiguresDevelopment('title')]: 'Udvikling i nøgletal',
  [intl.keyFiguresDevelopment('liquidity-ratio')]: 'Likviditetsgrad',
  [intl.keyFiguresDevelopment('result')]: 'Resultat',
  [intl.charts('radar-tooltip-score-since')]: 'Siden',
  [intl.charts('radar-tooltip-ratio')]:
    '{key}en er på {value}%, hvilket betragtes som en {rating} {key} sammenlignet med andre virksomheder i samme branche.',
  [intl.charts('radar-key-figures-info')]:
    'Diagrammet viser, hvordan virksomhedens nøgletal ser ud sammenlignet med andre virksomheder i samme branche. De fire valgte nøgletal repræsenterer virksomhedens rentabilitet, soliditet, likviditet og konkursrisiko.',
  [intl.alikeCompanies('dialog-not-found-title')]: 'Ingen virksomhed at vise',
  [intl.alikeCompanies('dialog-not-found-content')]:
    'Ingen lignende virksomhed blev fundet.',
  [intl.alikeCompanies('tooltip')]:
    'En lignende virksomhed vil blive fundet ud fra den første virksomhed, der er i sammenligningen. Den lignende virksomhed findes ud fra aktive virksomheder, der er i samme branche og som har nogenlunde samme antal ansatte og kapital.',
  [intl.portfolioInsights('drawer-trigger-button')]: 'Porteføljeindsigt',
  [intl.portfolioInsights('tab-summary-label')]: 'Resumé',
  [intl.portfolioInsights('tab-compliance-label')]: 'Overholdelse',
  [intl.portfolioInsights('tab-scheduler-label')]: 'Planlægger',
  [intl.portfolioInsights('tab-scheduler-destination')]: 'Modtager',
  [intl.portfolioInsights('tab-scheduler-destination-email')]: 'Email',
  [intl.portfolioInsights('tab-scheduler-destination-email-recipients')]:
    'Email-modtagere',
  [intl.portfolioInsights('tab-scheduler-destination-email-search')]:
    'Søg email',
  [intl.portfolioInsights('tab-scheduler-frequency-label')]: 'Frekvens',
  [intl.portfolioInsights('tab-scheduler-frequency-send-every')]: 'Send hver',
  [intl.portfolioInsights('tab-scheduler-frequency-week')]: 'Uge',
  [intl.portfolioInsights('tab-scheduler-frequency-month')]: 'Måned',
  [intl.portfolioInsights('tab-scheduler-frequency-year')]: 'År ',
  [intl.portfolioInsights('tab-scheduler-frequency-send-on')]: 'Dag',
  [intl.portfolioInsights('tab-scheduler-frequency-day-mon')]: 'Man',
  [intl.portfolioInsights('tab-scheduler-frequency-day-tue')]: 'Tir',
  [intl.portfolioInsights('tab-scheduler-frequency-day-wed')]: 'Ons',
  [intl.portfolioInsights('tab-scheduler-frequency-day-thu')]: 'Tor',
  [intl.portfolioInsights('tab-scheduler-frequency-day-fri')]: 'Fre',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sat')]: 'Lør',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sun')]: 'Søn',
  [intl.portfolioInsights('tab-scheduler-frequency-send-time')]: 'Tidspunkt',
  [intl.portfolioInsights('tab-scheduler-frequency-time')]: 'Tid',
  [intl.portfolioInsights('tab-scheduler-status')]: 'Status',
  [intl.portfolioInsights('tab-scheduler-status-active')]: 'Aktiv',
  [intl.portfolioInsights('tab-scheduler-status-disabled')]: 'Deaktiveret',
  [intl.portfolioInsights('tab-scheduler-save-changes')]: 'Gem ændringer',
  [intl.portfolioInsights('portfolio-score')]: 'PORTEFØLJE SCORE',
  [intl.portfolioInsights('portfolio-risk-distribution')]:
    'Porteføljerisikofordeling',
  [intl.portfolioInsights('portfolio-risk-distribution-subtitle')]:
    '{currentCompaniesCount} af {allCompaniesCount} virksomheder',
  [intl.portfolioInsights('portfolio-score')]: 'PORTEFØLJESCORE',
  [intl.portfolioInsights('portfolio-risk-distribution')]:
    'Portefølje risikofordeling',
  [intl.portfolioInsights('Low risk')]: 'Lav risiko',
  [intl.portfolioInsights('Medium risk')]: 'Middel risiko',
  [intl.portfolioInsights('High risk')]: 'Høj risiko',
  [intl.portfolioInsights('Unknown risk')]: 'Ukendt risiko',
  [intl.portfolioInsights('portfolio-risk-history')]:
    'Portefølje risikohistorik',
  [intl.portfolioInsights('credit-policy-compliance')]:
    'Overensstemmelse med kreditpolitik',
  [intl.portfolioInsights('credit-policy-violation')]:
    'Krænkelse af kreditpolitik',
  [intl.portfolioInsights('compliant-label')]: 'Overensstemmende',
  [intl.portfolioInsights('non-compliant-label')]: 'Ikke-kompatibel',
  [intl.portfolioInsights('compliance-history')]: 'Overensstemmelse historik',
  [intl.errors('hubspot_error')]:
    'Emailverificering fejlede. Brug venligst en firma-email eller kontakt vores kundeservice',
  [intl.errors('companies_local_id_country_unique')]: 'CVR er allerede i brug',
  [intl.errors('company_already_exists')]: 'CVR er allerede i brug',
  [intl.errors('invalid_local_id')]: 'Du har brugt et ugyldigt CVR-nummer',
  [intl.errors('error_creating_new_customer')]:
    'Noget gik galt. Kontakt venligst vores kundeservice på contact@risika.com',
}

export default messages
