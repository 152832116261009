export default {
  risikaWarning: '#FF684F',
  risikaBlack: '#010B0C',
  risika: '#0A373D',
  risikaSecondary: '#245157',
  risikaContrastDark: '#91580D',
  risikaContrastDim: '#C99755',
  risikaContrast: '#FFCC89',
  risikaTertiaryDark: '#0A5E68',
  risikaTertiaryDim: '#238C99',
  risikaTertiary: '#54BDCB',
  risikaTertiaryLight: '#71DEEC',
  risikaBlue: '#55A1C9',
  risikaBlueDark: '#285F7D',
  risikaBlueLight: '#85D4FF',
  risikaLight: '#F6FBFC',
  risikaLightDark: '#E5EEF0',

  // What originality are we seeing here!!!
  risikaWhite: '#FFFFFF',

  // Notifications
  // TODO: Pick better colors, especially for negative/blue background
  default: '#F5F5F5',
  info: '#F8E71C',
  warning: 'orange',
  danger: 'red',
  success: 'green',
  // Basic colors
  gray: 'gray',
  lightest_gray: '#F5F5F5',
  light_gray: '#979797',
  purple: '#822A85',
  yellow: '#F8E71C',
  orange: 'orange',
  dark_orange: 'darkorange',
  red: 'red',

  // Colors for Performance in Financial Ratios
  rating_1: '#DC172F', // Very weak
  rating_2: '#DC172F', // Very weak
  rating_3: '#F26825', // Weak
  rating_4: '#F26825', // Weak
  rating_5: '#FEBE0E', // Average
  rating_6: '#FEBE0E', // Average
  rating_7: '#90C210', // Strong
  rating_8: '#90C210', // Strong
  rating_9: '#4EA206', // Very strong
  rating_10: '#4EA206', // Very strong

  // Colors for Performance in Financial Ratios
  dark_rating_1: '#bc1428', // Very weak
  dark_rating_2: '#bc1428', // Very weak
  dark_rating_3: '#d15921', // Weak
  dark_rating_4: '#d15921', // Weak
  dark_rating_5: '#d8a20d', // Average
  dark_rating_6: '#d8a20d', // Average
  dark_rating_7: '#7da50e', // Strong
  dark_rating_8: '#7da50e', // Strong
  dark_rating_9: '#408405', // Very strong
  dark_rating_10: '#408405', // Very strong

  // Alternative background colors
  bg_rating_1: '#DC172F',
  bg_rating_2: '#DC172F',
  bg_rating_3: '#F26825',
  bg_rating_4: '#F26825',
  bg_rating_5: '#FEBE0E',
  bg_rating_6: '#FEBE0E',
  bg_rating_7: '#90C210',
  bg_rating_8: '#90C210',
  bg_rating_9: '#4EA206',
  bg_rating_10: '#4EA206',
  bg_rating_null: '#555555',
  bg_rating_undefined: '#555555',

  negative: '#D0021B',
  neutral: '#039BE5',
  positive: '#77B267',

  lowRisk: '#90C211',
  mediumRisk: '#FFA401',
  highRisk: '#DC152F',
  noScore: '#828282',
  noScoreDark: '#5b5a5a',

  newOrange: '#E73434',
  newBlue: '#57BECA',
}
