import { TextField } from '@material-ui/core'
import PhoneNumber from 'awesome-phonenumber'

import intl from 'localization/components'
const Phone = ({ messages, props }) => {
  const { value, onChange } = props
  const phone = new PhoneNumber(value?.toString() ?? '', 'dk').getNumber(
    'international',
  )
  return (
    <TextField
      style={{ width: '100%' }}
      value={phone}
      label={messages[intl.companyInfo('phone')]}
      onChange={(values) => {
        return onChange(values.target.value)
      }}
    />
  )
}
export default Phone
