import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { addUserSetting } from 'services/cmAPI/addUserSetting'

export function usePutUserSettings() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(addUserSetting, {
    onMutate: async ({ tableName, key, visibility }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('getSettingsStructure')
      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData('getSettingsStructure')

      // Return a context object with the snapshotted value
      return { previousSettings }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('getSettingsStructure')
      enqueueSnackbar('User is updated', { variant: 'success' })
    },
    onError: (err) => {
      enqueueSnackbar('Failed to update', { variant: 'error' })
      console.error('updateCustomer failed: ', err)
    },
  })
}
