import { CustomerRestrictionsResponse } from 'services/cmAPI/getCustomer'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'
import { updateModuleDeclaration } from 'typescript'
import {
  ExceptionType,
  FormatDataTypes,
  NewValue,
  UpdateCustomerTypes,
} from './Restrictions.types'

const caps = [
  'credit_ratings',
  'credit_reports',
  'lookups',
  'monitoring_count',
  'monitoring_exports',
  'search_exports',
]
// const creditPolicyCaps = ['exclude_companies', 'exclude_persons']

const rights = [
  'allow_api',
  'allow_basic',
  'allow_browser_extension',
  'allow_company_export',
  'allow_company_search',
  'allow_countries',
  'allow_credit',
  'allow_credit_policy',
  'allow_ean',
  'allow_financial',
  'allow_hierarchy',
  'allow_highlights',
  'allow_monitoring',
  'allow_monitoring_export',
  'allow_notes',
  'allow_pdf_reports',
  'allow_person_export',
  'allow_person_search',
  'allow_relations',
  'observational_lists',
  'search_exports_limit',
  'tier',
]

const newRights = ['observational_lists']

const countries = ['DE', 'DK', 'FI', 'NO', 'SE', 'UK']

const camelize = (s) => {
  if (s?.length < 3) return s
  const camelCase = s.replace(/_./g, (x) => x[1].toUpperCase())
  const allButFirstLetter = camelCase.replace(/([A-Z])/g, ' $1')
  return allButFirstLetter.charAt(0).toUpperCase() + allButFirstLetter.slice(1)
}

export const findExceptions = (
  customerPlanData: CustomerRestrictionsResponse,
  defaultsForPlan: any,
): ExceptionType[] => {
  let exceptions = []

  if (customerPlanData.max_users !== defaultsForPlan.max_users) {
    exceptions = [
      ...exceptions,
      {
        key: 'max_users',
        type: 'right',
        label: 'Max user',
        currValue: customerPlanData.max_users,
        defaultValue: defaultsForPlan.max_users,
      },
    ]
  }

  rights.forEach((right) => {
    let currValue = customerPlanData.rights[right]
    let defaultValue = defaultsForPlan[right]
    let isEqual
    switch (typeof currValue) {
      case 'object':
        if (currValue?.length === 0) {
          currValue = null
        }
        isEqual = JSON.stringify(currValue) === JSON.stringify(defaultValue)
        break
      default:
        isEqual = currValue === defaultValue
        break
    }

    if (!isEqual) {
      exceptions = [
        ...exceptions,
        {
          key: right,
          type: 'right',
          label: camelize(right),
          currValue: currValue,
          defaultValue: defaultValue,
        },
      ]
    }
  })
  newRights.forEach((right) => {
    let currValue = customerPlanData.rights.global[right]
    let defaultValue = defaultsForPlan?.rights?.global?.[right]
    let isEqual
    switch (typeof currValue) {
      case 'object':
        if (currValue?.length === 0) {
          currValue = null
        }
        isEqual = JSON.stringify(currValue) === JSON.stringify(defaultValue)
        break
      default:
        isEqual = currValue === defaultValue
        break
    }
    if (!isEqual) {
      exceptions = [
        ...exceptions,
        {
          key: right,
          type: 'right',
          label: camelize(right),
          currValue: currValue,
          defaultValue: defaultValue,
          isGlobal: true,
        },
      ]
    }
  })

  caps.forEach((cap) => {
    const currValue = customerPlanData.caps[cap]

    const defaultValue = defaultsForPlan[cap]

    if (currValue !== defaultValue) {
      exceptions = [
        ...exceptions,
        {
          key: cap,
          type: 'cap',
          label: camelize(cap),
          currValue,
          defaultValue,
        },
      ]
    }
  })
  // creditPolicyCaps.forEach((cap) => {
  //   const currValue = customerPlanData.caps[cap]
  //   console.log('currValue', customerPlanData)
  //   console.log('defaultsForPlan', defaultsForPlan)
  //   const defaultValue = defaultsForPlan.credit_policy[cap]
  //   console.log('cap', cap)
  //   console.log('defaultValue', defaultValue)

  //   if (currValue !== defaultValue) {
  //     exceptions = [
  //       ...exceptions,
  //       {
  //         key: cap,
  //         type: 'cap',
  //         label: camelize(cap),
  //         currValue,
  //         defaultValue,
  //       },
  //     ]
  //   }
  // })

  countries.forEach((country) => {
    let currValue = customerPlanData.rights[country]
    const defaultValue = defaultsForPlan.rights[country]
    if (currValue.score_models?.length === 0) {
      currValue = {}
    }
    if (JSON.stringify(currValue) !== JSON.stringify(defaultValue)) {
      exceptions = [
        ...exceptions,
        {
          key: country,
          type: 'country',
          label: camelize(country),
          currValue: customerPlanData.rights[country]?.score_models,
          defaultValue: defaultsForPlan.rights[country]?.score_models,
        },
      ]
    }
  })

  return exceptions.filter((x) => {
    if (x.currValue === undefined) {
      // Not sure why i had to
      if (x.defaultValue === undefined) {
        // do it like that
        return false
      }
    }
    return true
  })
}

const handleFormatTheUpdateData = ({
  newValue,
  location,
  key,
}: FormatDataTypes) => {
  if (location) {
    return {
      rights: {
        [location]: {
          [key]: newValue,
        },
      },
    }
  }
  return {
    [key]: newValue,
  }
}

export const handleUpdateRestrictions = ({
  selectedCustomer,
  updateCustomer,
  newValue,
  element,
}: UpdateCustomerTypes) => {
  updateCustomer({
    companyId: selectedCustomer,
    reqBody: {
      data: handleFormatTheUpdateData({
        newValue,
        location: element.updateLocation,
        key: element.key,
      }),
    },
  })
}
