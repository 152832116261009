import React, { useState } from 'react'
import { AreYouSure, Button } from 'components'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'
import { default as cx } from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { faHandshakeSlash } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles'
import colors from 'styles/colors'

export const useStyles = makeStyles((theme) => ({
  ciCol: {
    marginRight: theme.spacing(3),
  },
  ciBtn: {
    color: 'white',
    alignSelf: 'center',
  },
  ciBtnRevoke: {
    backgroundColor: colors?.rating_3,
  },
  ciBtnGrant: {
    backgroundColor: colors?.rating_7,
  },
}))

const RevokeAccess = ({ customerData, selectedCustomer }) => {
  const classes = useStyles()
  const { mutate, isLoading } = useUpdateCustomer()
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false)

  const isRevoked = customerData.is_revoked

  const handleRevoke = () => {
    const body: any = {}
    body.data = {}
    body.is_revoked = !isRevoked
    mutate({ reqBody: body, companyId: selectedCustomer.toString() })
  }

  return (
    <>
      {isRevoked ? (
        <Button
          className={cx(classes.ciBtn, classes.ciBtnGrant)}
          onClick={() => setIsConfirmationOpened(true)}
          icon={<FontAwesomeIcon icon={faHandshake} />}
        >
          {isLoading ? 'Loading' : 'Grant Access'}
        </Button>
      ) : (
        <Button
          className={cx(classes.ciBtn, classes.ciBtnRevoke)}
          onClick={() => setIsConfirmationOpened(true)}
          icon={<FontAwesomeIcon icon={faHandshakeSlash} />}
        >
          {isLoading ? 'Loading' : ' Revoke Access'}
        </Button>
      )}
      <AreYouSure
        open={isConfirmationOpened}
        setOpen={setIsConfirmationOpened}
        onSubmit={handleRevoke}
        title={'Revoke access'}
        content={'Are you sure you want to revoke access for this company?'}
      />
    </>
  )
}

export default RevokeAccess
