import colors from 'styles/colors'
import { makeStyles } from '@material-ui/core'
import { theme } from 'styles/RootTheme'

export const useStyles = makeStyles(() => ({
  default: {
    color: theme.palette.common.white,
    backgroundColor: colors?.risikaTertiaryDark,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 100,
    color: 'white',
  },
}))
