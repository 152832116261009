import callCmAPI from 'services/helpers/callCmAPI'

export type IntegrationsResponse = {
  data: {
    created_at: string
    customer_id: number
    id: number
    token: string
    type: 'economic'
    user_id: number
  }[]
  success: boolean
}

export const getIntegrationsForCustomer = (
  customerId: string,
): Promise<IntegrationsResponse['data']> =>
  callCmAPI(`/integration/${customerId}`, {
    method: 'get',
  })
    .then((res) => res?.data?.data)
    .catch((err) => err)
