import { useDefaultPlans } from 'services/queries/useDefaultPlans'
import { useQueryClient } from 'react-query'
import {
  // AvailableCountriesCapital,
  // SimplifiedModelT,
  CustomerStatusData,
} from 'types'

// new way to reach default plans
// migrate from hardcoded file PlanData.js

const useDefaults = () => {
  const { data: plans } = useDefaultPlans()
  const queryClient = useQueryClient()

  const customerData: CustomerStatusData = queryClient.getQueryData([
    'getCustomer',
  ])
  const planName = customerData?.subscription_plan

  // Waiting clarification from Product and Backend team
  // const defaultModelForCountry = (
  //   country: AvailableCountriesCapital,
  // ): SimplifiedModelT => {

  //   const rights = data?.find((plan) => plan.name === planName)?.rights
  //   if (!rights) return null
  //   const models = simplifyModels(rights)
  //   const countryModel = models[country]
  //   return countryModel
  // }

  const getDefaultPlan = () => {
    const defPlan = plans?.find((plan) => plan.name === planName)
    return defPlan
  }

  return { getDefaultPlan }
}

export default useDefaults
