import callCmAPI from 'services/helpers/callCmAPI'
import { UserSettingsTableNameEnum } from 'types'

/******************
 * Fetch from API *
 ******************/

type SettingsStructure = {
  settings: {
    key: string
    visible: boolean
  }[]
  tableName: UserSettingsTableNameEnum
}

export const getSettingsAll = (id: number): Promise<SettingsStructure[]> =>
  callCmAPI(`/user-settings/all/${id}`, {
    method: 'get',
  })
    .then((res) => Promise.resolve(res.data.data)) // :)
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })
