import moment from 'moment'
import { Typography } from '@mui/material'
const LastLoginView = ({ props }) => {
  const userData = props?.userData
  const apiUserData = props?.apiUserData
  const createdAt = userData?.created_at ?? apiUserData?.iat ?? null
  return <Typography>{moment(createdAt).format('DD.MM.YYYY')}</Typography>
}

export default LastLoginView
