import * as React from 'react'
import { useCustomer } from 'services/queries/useCustomer'
import { useParams } from 'react-router'
import { Grid, Stack, TextField } from '@mui/material'
import Rights from './widgets/Rights'
import Counters from './widgets/Counters'
import RestoreDefaultsDialog from './widgets/RestoreDefaultDialog'
import CountrySpecific from './widgets/CountrySpecific'
import { countriesForCountrySpecific } from './Restrictions.model'
import { CountrySpecificTypes } from 'services/cmAPI/getCustomer'

const Restrictions = ({ usersLength }: { usersLength: number }) => {
  const { id: selectedCustomer } = useParams()
  const [search, setSearch] = React.useState('')

  const useCustomerQuery = useCustomer(selectedCustomer)
  const data = useCustomerQuery.data
  const handleDataCounters = () => {
    return {
      counters: {
        ...data?.counters,
        max_users: data.max_users,
        search_exports_limit: data?.rights?.search_exports_limit,
      },
      caps: {
        ...data?.caps,
        max_users: usersLength,
        search_exports_limit: data?.rights?.search_exports_limit,
      },
    }
  }

  // Return only the country specific data
  const handleDataCountrySpecific = (): CountrySpecificTypes => {
    let localData = {}
    countriesForCountrySpecific.forEach(({ countryCode }) => {
      localData = {
        ...localData,
        [countryCode]: data.rights[countryCode],
      }
    })
    return localData as CountrySpecificTypes
  }

  return data ? (
    <Stack width={1} alignItems="center">
      <Stack
        width={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
      >
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ width: '80%' }}
          margin="normal"
          placeholder="Search"
          variant="outlined"
        />
        <RestoreDefaultsDialog />
      </Stack>
      <Grid sx={{ minHeight: '10rem', flexGrow: 1 }} container spacing={2}>
        <Grid display="flex" item xs={6}>
          <Rights search={search} data={data.rights} />
        </Grid>
        <Grid display="flex" item xs={6}>
          <Counters search={search} data={handleDataCounters()} />
        </Grid>
        <Grid display="flex" item xs={12}>
          <CountrySpecific search={search} data={handleDataCountrySpecific()} />
        </Grid>
      </Grid>
    </Stack>
  ) : null
}

export default Restrictions
