import React, { useState } from 'react'
import { default as cx } from 'classnames'
import { Button, ConditionallyRender } from 'components'
import {
  Button as MuiButton,
  Stepper,
  Typography,
  Paper,
  StepContent,
  StepLabel,
  Step,
  Tooltip,
} from '@material-ui/core'
import searchSuggestions from 'services/cmAPI/getSearchSuggestions'
import CreateCompany from './CreateCompany'
import CreateUsers from './CreateUsers'

import { injectIntl } from 'react-intl'

import { validateLocalId } from 'services/helpers/localId'
import { useUserAuth } from 'contexts/googleAuth'
import { useCreateCustomer } from 'services/queries/useCreateCustomer'

import { makeStyles } from '@material-ui/core'
import { useQueryClient } from 'react-query'

export const useStyles = makeStyles(() => ({
  tabContent: {
    padding: 24,
  },
}))

const NewCustomer = (props) => {
  const {
    closeFn,
    risikaUsers,
    intl: { messages },
  } = props
  const { user } = useUserAuth()
  const queryClient = useQueryClient()
  const currentRisikaUserId = risikaUsers?.find(
    (risikaUser) => risikaUser.email.split('.')[0] === user.email.split('.')[0],
  )?.id

  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)

  const responsibleOptions = risikaUsers.map((risikaUser) => {
    return {
      text: `${risikaUser.email} | ${risikaUser.name}`,
      value: risikaUser.id,
    }
  })

  const initialCreateCustomerState = {
    country: 'DK',
    local_id: '',
    name: '',
    emails: '',
    plan: 'BASIC',
    responsible_user_id: currentRisikaUserId,
  }
  const devaultValidate = {
    local_id: false,
  }
  const [validate, setValidate] = React.useState(devaultValidate)
  const [createCustomerState, setCreateCustomerState] = useState(
    initialCreateCustomerState,
  )
  const [newUsers, setNewUsers] = React.useState([{ userName: '', email: '' }])
  const [validateUsers, setValidateUsers] = React.useState(false)
  const { mutate: createCustomer } = useCreateCustomer()

  const createNewCustomer = async (event) => {
    event.preventDefault()

    const { local_id, name, plan, responsible_user_id, country } =
      createCustomerState

    const userData =
      newUsers.length === 1 && newUsers[0].email === ''
        ? []
        : newUsers.map((user) => {
            return {
              name: user.userName,
              email: user.email,
              // TODO @Shtono currently this field is mandatory
              phone_number: 'default',
            }
          })

    const dataBody = {
      local_organization_id: {
        id: local_id,
        country,
      },
      name,
      subscription_plan: plan,
      responsible_user_id,
      userData,
    }

    closeFn()
    createCustomer(dataBody)
  }

  const handleCreateCustomerChange = (type) => (event) => {
    const { value } = event.target
    switch (type) {
      case 'local_id':
        setValidate({
          ...validate,
          [type]: validateLocalId({
            id: value?.split(' ')?.join(''),
            country: createCustomerState.country ?? 'DK',
          }),
        })
        if (
          validateLocalId({
            id: value?.replace(' ', ''),
            country: createCustomerState.country ?? 'DK',
          })
        ) {
          searchSuggestions(value, createCustomerState.country)
            .then((data) => {
              const { name } = data[0]
              setCreateCustomerState({
                ...createCustomerState,
                name,
                local_id: value,
              })
            })

            .catch(() => {
              setCreateCustomerState({
                ...createCustomerState,
                local_id: value,
              })
            })
        } else {
          setCreateCustomerState({ ...createCustomerState, local_id: value })
        }

        return
      case 'name':
        setCreateCustomerState({ ...createCustomerState, name: value })
        return
      case 'country':
        setCreateCustomerState({ ...createCustomerState, country: value })
        return
      case 'plan':
        setCreateCustomerState({ ...createCustomerState, plan: value })
        return
      case 'emails':
        setCreateCustomerState({ ...createCustomerState, emails: value })
        return
      case 'responsible_user_id':
        setCreateCustomerState({
          ...createCustomerState,
          responsible_user_id: value,
        })
        return
      default:
        return null
    }
  }

  function getSteps() {
    return ['Create company', 'Create users']
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CreateCompany
            handleCreateCustomerChange={handleCreateCustomerChange}
            createCustomerState={createCustomerState}
            messages={messages}
            responsibleOptions={responsibleOptions}
            validate={validate}
          />
        )
      case 1:
        return (
          <CreateUsers
            handleCreateCustomerChange={handleCreateCustomerChange}
            createCustomerState={createCustomerState}
            messages={messages}
            responsibleOptions={responsibleOptions}
            users={newUsers}
            setUsers={setNewUsers}
            validateUsers={validateUsers}
            setValidateUsers={setValidateUsers}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={classes.tabContent}>
      <form onSubmit={createNewCustomer}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {getSteps().map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        style={{
                          margin: '1rem',
                        }}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>

                      <ConditionallyRender
                        condition={activeStep === 0}
                        when={
                          <div>
                            <Tooltip
                              title={
                                validate.local_id
                                  ? ''
                                  : 'Please enter a valid Local Id to continue!'
                              }
                              placement="top"
                            >
                              <span>
                                <Button
                                  style={{
                                    margin: '1rem',
                                  }}
                                  disabled={!validate.local_id}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}
                                  className={classes.button}
                                >
                                  {activeStep === getSteps().length - 1
                                    ? 'Finish'
                                    : 'Next'}
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        }
                        otherwise={
                          <MuiButton
                            className={cx(classes.submit)}
                            type="submit"
                            variant="contained"
                            color="primary"
                            aria-label="SEND INVITES"
                            disabled={!validateUsers}
                          >
                            Create
                          </MuiButton>
                        }
                      />
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === getSteps().length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Back to top
              </Button>
            </Paper>
          )}
        </div>
      </form>
    </div>
  )
}

export default injectIntl(NewCustomer)
