import * as React from 'react'
import { useSnackbar } from 'notistack'
import MaterialTable from 'material-table'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { userTableTheme } from './userTableTheme'
import { AreYouSure, Sidescroll } from 'components'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { columns, icons, localization, snackbarText } from './UserTable.model'
import { CustomerT } from 'types'
import { useQueryClient } from 'react-query'
import {
  handleTableAction,
  handleUserData,
  validate,
} from './UserTable.controller'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { GetUsersReturnType } from 'services/cmAPI/getUsers'
import { useDeleteOnlyApiUser } from 'services/queries/useDeleteOnlyApiUser'
import { TablePagination } from '@material-ui/core'
import { userIssueApiUserToken } from 'services/queries/useIssueApiUserToken'

type UserTableProps = {
  // users: Array<UserTableT>,
  users: GetUsersReturnType[],
  maxUsers: number,
  updateUser: Function,
  createNewUser: Function,
  removeUser: Function,
  isCustomerManagement?: boolean,
  showTitle?: Boolean,
  customerData: CustomerT,
  isExpired?: Boolean,
}

const UserTable = ({
  customerData,
  users,
  maxUsers,
  updateUser, // Async
  createNewUser, // Async
  removeUser,
  showTitle = true,
  isExpired,
}: UserTableProps) => {
  const [areYouSureDeleteApiUser, setAreYouSureDeleteApiUser] =
    React.useState(false)
  const [deletingApiUserId, setDeletingApiUserId] = React.useState(null)
  const { mutateAsync: deleteOnlyApiUser } = useDeleteOnlyApiUser()
  const { mutate: issueApiUserToken } = userIssueApiUserToken()
  const reactIntl = useIntl()
  const [isCreatingTestUser, setIsCreatingTestUser] = React.useState(false)
  const messages = reactIntl.messages
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateQuery = () => {
    queryClient.invalidateQueries('getUsers' + customerData.id)
  }
  const handleDeleteApiUser = () => {
    deleteOnlyApiUser(deletingApiUserId)
    setAreYouSureDeleteApiUser(false)
  }
  const dashboardUsers = users?.filter((user) => user.userData !== null)
  return (
    <Sidescroll conditionalySidescroll maxWidth={'819px'}>
      <MuiThemeProvider theme={userTableTheme}>
        <FormGroup sx={{ marginLeft: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setIsCreatingTestUser(!isCreatingTestUser)}
                checked={isCreatingTestUser}
              />
            }
            label="Crate test user"
          />
        </FormGroup>
        <AreYouSure
          setOpen={setAreYouSureDeleteApiUser}
          onSubmit={() => handleDeleteApiUser()}
          open={areYouSureDeleteApiUser}
          title="Delete Api User"
          content="Are you sure you want to delete this Api User? This will revoke all access to the API and the Dashboard."
        />
        <MaterialTable
          options={{
            pageSize: 50,
            addRowPosition: 'first',
            pageSizeOptions: [50, 100],
          }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} style={{ paddingRight: '2rem' }} />
            ),
          }}
          localization={localization(messages)}
          elevation={0}
          title={showTitle ? messages[intl.settings('all-users')] : ''}
          columns={columns(messages)}
          icons={icons}
          actions={[
            (rowData) => {
              return {
                icon: icons.DeleteApi,
                tooltip: rowData?.userData
                  ? 'To delete this user you should use the other delete button'
                  : 'Delete Api User',
                onClick: (event, rowData) => {
                  setAreYouSureDeleteApiUser(true)
                  return setDeletingApiUserId(rowData?.apiUserData.id)
                },
                disabled: rowData?.userData,
              }
            },
            (rowData) => {
              return {
                icon: icons.UserApiToken,
                tooltip: rowData?.apiUserData
                  ? 'Issue API token for this user'
                  : 'You can not issue tokens for this user. Try creating a new one',
                onClick: (event, rowData) => {
                  return issueApiUserToken(rowData?.apiUserData.id)
                },
                disabled: !rowData?.apiUserData,
              }
            },
          ]}
          data={users}
          editable={{
            isDeletable: (rowData) => rowData?.userData,
            isEditable: (rowData) => rowData?.userData,
            onRowAdd: ({ userData: newUserData }) => {
              return handleTableAction({
                action: () => {
                  const _hsq = (window._hsq = window._hsq || [])
                  if (!isCreatingTestUser) {
                    _hsq.push([
                      'identify',
                      {
                        email: newUserData.email,
                      },
                    ])
                  }
                  _hsq.push(['trackPageView'])
                  return createNewUser(
                    handleUserData(newUserData, customerData),
                  )
                },
                successText: () =>
                  enqueueSnackbar(snackbarText('create', true), {
                    variant: 'success',
                  }),
                illegalFunctionText: () =>
                  enqueueSnackbar(
                    isExpired
                      ? "You can't add users to an expired company."
                      : "You can't add more users to this company.",
                    {
                      variant: 'error',
                    },
                  ),
                failureText: () =>
                  enqueueSnackbar(snackbarText('create', false), {
                    variant: 'error',
                  }),
                invalidateQuery,
                isFunctionLegal: !isExpired
                  ? maxUsers && dashboardUsers.length < maxUsers
                  : false,
                validation: validate(newUserData),
              })
            },
            onRowUpdate: (newUserData) => {
              return handleTableAction({
                action: () =>
                  updateUser({
                    userId: newUserData.userData.id,
                    data: {
                      role: newUserData.userData.role,
                      name: newUserData.userData.name,
                      phone_number: newUserData.userData?.phone_number ?? '',
                    },
                  }),
                successText: () =>
                  enqueueSnackbar(snackbarText('update', true), {
                    variant: 'success',
                  }),
                failureText: () => {
                  enqueueSnackbar(snackbarText('update', false), {
                    variant: 'error',
                  })
                },
                invalidateQuery,
              })
            },
            onRowDelete: (oldData) =>
              handleTableAction({
                action: () => removeUser(oldData.userData.id),
                successText: () =>
                  enqueueSnackbar(snackbarText('delete', true), {
                    variant: 'success',
                  }),
                failureText: () =>
                  enqueueSnackbar(snackbarText('delete', false), {
                    variant: 'error',
                  }),
                invalidateQuery,
              }),
          }}
        />
      </MuiThemeProvider>
    </Sidescroll>
  )
}

export default UserTable
