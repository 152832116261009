import callCmAPI from 'services/helpers/callCmAPI'
import { UserSettingsTableNameEnum } from 'types'

/******************
 * Fetch from API *
 ******************/

type SettingsStructure = {
  keys: string[]
  tableName: UserSettingsTableNameEnum
}

type AddUserSetting = {
  tableName: UserSettingsTableNameEnum
  key: string
  customerId: number
  state: boolean
}

export const updateSettingsVisibility = ({
  tableName,
  key,
  customerId,
  state,
}: AddUserSetting): Promise<SettingsStructure[]> =>
  callCmAPI('/user-settings/update-visibility', {
    method: 'put',
    data: {
      tableName,
      key,
      customerId,
      state,
    },
  })
    .then((res) => Promise.resolve(res.data.data)) // :)
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })
