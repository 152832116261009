import callCmAPI from 'services/helpers/callCmAPI'
import { CustomerT, WebApi2Response } from 'types/global'
/******************
 * Fetch from API *
 ******************/

export const getCustomers = (): Promise<WebApi2Response<CustomerT[]>> =>
  callCmAPI('/customer', {
    method: 'get',
  })
    .then(({ data }) => {
      return data.data
    })
    .catch((err) => err)
