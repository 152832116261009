import { AxiosResponse } from 'axios'
import axios from 'axios'

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validatePhone = (phone) => {
  // Checks for numbers + and -
  const re = /^[0-9 ()+-]+$/
  if (phone === undefined) {
    return true
  }
  return re.test(String(phone).toLowerCase())
}
export const validate = (data) => {
  if (data.name === '') {
    return 'name-validation'
  }
  if (!validateEmail(data.email)) {
    return 'email-validation'
  }
  if (!validatePhone(data.phoneNumber)) {
    return 'phone-validation'
  }
  return ''
}

type addRowProps = {
  action: () => AxiosResponse
  data: any
  successText: () => void
  failureText: () => void
  invalidateQuery: () => void
  isFunctionLegal: boolean
  illegalFunctionText?: () => void
  validation: string
}

export const handleTableAction = async ({
  action,
  successText,
  failureText,
  invalidateQuery,
  isFunctionLegal = true,
  illegalFunctionText,
  validation,
}: addRowProps) => {
  if (isFunctionLegal) {
    if (validation) {
      return failureText()
    }

    try {
      const res = await action()
      if (axios.isAxiosError(res)) {
        return failureText()
      }
      invalidateQuery()
      return successText()
    } catch (error) {
      return failureText()
    }
  }
  return illegalFunctionText ? illegalFunctionText() : undefined
}

export const handleUserData = (newData, customerData) => {
  return [
    {
      name: newData.name,
      email: newData.email,
      phone_number: newData.phoneNumber,
      role: newData.role,
      customer_id: customerData.id,
    },
  ]
}
