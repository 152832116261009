import { ConditionallyRender } from 'components'

// MUI
import { Box, IconButton } from '@material-ui/core'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import { useStyles } from './styles/edit-button-complex-pen-icon'

/**
 * Renders edit component with visual icons
 *
 */

const EditButtonComplexPenIcon = ({
  // **   manage saving process    */
  startSavingProcedure,
  completeSavingProcedure,
  cancelSavingProcedure,
  // **   editing state            */
  isEditing,
}) => {
  const classes = useStyles()

  return (
    <Box component="span" className={classes.ebcContainerVertical}>
      <ConditionallyRender
        condition={isEditing === false}
        when={
          <IconButton
            onClick={() => {
              startSavingProcedure()
            }}
          >
            <FontAwesomeIcon icon={faPen} className={classes.ebcIcon} />
          </IconButton>
        }
        otherwise={
          <>
            <Box component="span">
              <IconButton
                className={classes.ebcButtonIconAccept}
                onClick={() => {
                  completeSavingProcedure()
                }}
              >
                <FontAwesomeIcon icon={faCheck} className={classes.ebcIcon} />
              </IconButton>
            </Box>
            <Box component="span">
              <IconButton
                className={classes.ebcButtonIconReject}
                onClick={() => {
                  cancelSavingProcedure()
                }}
              >
                <FontAwesomeIcon icon={faTimes} className={classes.ebcIcon} />
              </IconButton>
            </Box>
          </>
        }
      />
    </Box>
  )
}

export default EditButtonComplexPenIcon
