import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { deleteOnlyApiUser } from 'services/cmAPI/deletOnlyApiUser'

export function useDeleteOnlyApiUser() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(deleteOnlyApiUser, {
    onSuccess: async () => {
      enqueueSnackbar('API User deleted successfully', { variant: 'success' })
      queryClient.refetchQueries({
        // @ts-ignore
        predicate: (query) => query?.queryKey?.[0]?.startsWith('getUsers'),
      })
    },
    onError: (err) => {
      enqueueSnackbar('Delete user failed', { variant: 'error' })
    },
  })
}
